import React from "react";
import { useNavigate } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";

import HeaderIcon from "../HeaderIcon/HeaderIcon";
import ProfileIcon from "../ProfileIcon/ProfileIcon";
import NotificationIcon from "../NotificationIcon/NotificationIcon";

const HeaderIcons = () => {
  const navigation = useNavigate();

  return (
    <div className="header-icons">
      <HeaderIcon
        icon={<QuestionCircleOutlined rev="" />}
        title="Support"
        onClick={() => navigation("/support-ticket")}
      />
      <NotificationIcon />
      <ProfileIcon />
    </div>
  );
};

export default HeaderIcons;
