import { takeLatest, put } from "redux-saga/effects";
import { authSetDataAction, authSetUser, LoginAction, LoginActionType } from "./index";
import axios, { AxiosResponse } from "axios";
import { BASE_AUTH, BASE_URL, LOGIN_LOCAL_KEY } from "src/ENV";
import { promissoryNoteRestApi, userRestApi } from "src/services";
import { IUser } from "src/models";

function* loginSaga(action: LoginActionType) {
  const Authorization = BASE_AUTH as string;
  const url = BASE_URL + "/oauth/token";
  try {
    const data = new FormData();
    data.append("username", action.payload.username);
    data.append("password", action.payload.password);
    data.append("grant_type", "password");
    const response: AxiosResponse = yield axios.post(url, data, { headers: { Authorization } });
    // if (action.payload.remember) {
    localStorage.setItem(LOGIN_LOCAL_KEY, JSON.stringify(response.data));
    // }
    const lender: AxiosResponse = yield promissoryNoteRestApi.getMyLoans({
      offset: 0,
      pageNumber: 0,
      pageSize: 100,
    });
    const borrower: AxiosResponse = yield promissoryNoteRestApi.getMyBorrows({
      offset: 0,
      pageNumber: 0,
      pageSize: 100,
    });
    let plans: AxiosResponse | null = null;
    try {
      plans = yield userRestApi.getActivePlan();
    } catch (error) {
      //get plans error
    }

    const responseUser: AxiosResponse = yield userRestApi.getProfile();
    const user = responseUser.data as unknown as IUser;

    const roles = [];
    borrower.data?.my_notes?.totalElements > 0 && roles.push("borrower");
    lender.data?.my_notes?.totalElements > 0 && roles.push("lender");

    response.data.plan = plans?.data?.registration_plan;
    response.data.roles = roles;

    user.lenders = lender.data?.my_notes ?? [];
    user.borrowers = borrower.data?.my_notes ?? [];
    user.activePlan = plans?.data?.registration_plan;

    localStorage.setItem(LOGIN_LOCAL_KEY, JSON.stringify(response.data));
    yield put(authSetDataAction("login", response.data, "data"));
    yield put(authSetUser(user));
  } catch (e: any) {
    yield put(authSetDataAction("login", e.response?.data, "error"));
  }
}

export const authSaga = [takeLatest(LoginAction, loginSaga)];
