import React from "react";
import { Checkbox, Input, Radio, Select, Spin } from "antd";

import { GeneralForm, TopNavController, UploadBox } from "../../common/components";
import useSupportTicket from "./useSupportTicket";
import TextArea from "antd/lib/input/TextArea";
import { check } from "prettier";

const SupportTicket = () => {
  const {
    loading,
    onClickBack,
    onDocumentUpload,
    sendSupport,
    subject,
    setSubject,
    description,
    setDescription,
    type,
    setType,
    subjectError,
    descriptionError,
    typeError,
    agree,
    attachments,
    setAgree,
    deleteDoc,
  } = useSupportTicket();

  return (
    <div className="support-ticket-page">
      <TopNavController showCancel={false} onClickPrev={onClickBack} />
      <GeneralForm
        headerTitle="Create A Support Ticket"
        buttonText="Submit"
        onClick={sendSupport}
        setCheck={setAgree}
        disabled={!agree}
        loading={loading}
        checkboxVisible={false}>
        <div className="support-ticket-page__main-wrapper">
          <div className="support-ticket-page__main-wrapper__form">
            <div className="support-ticket-page__main-wrapper__form__item">
              <div className="support-ticket-page__main-wrapper__form__item__radio">
                <Radio
                  checked={type === "ACCOUNT_AND_BILLING_SUPPORT"}
                  onChange={(e) => setType(e.target.checked ? "ACCOUNT_AND_BILLING_SUPPORT" : "")}>
                  Account and Billing Support
                </Radio>
                <Radio
                  checked={type === "TECHNICAL_SUPPORT"}
                  onChange={(e) => setType(e.target.checked ? "TECHNICAL_SUPPORT" : "")}>
                  Technical Support
                </Radio>
              </div>
              <span className="support-ticket-page__main-wrapper__form__item__error">
                {typeError}
              </span>
            </div>

            <div className="support-ticket-page__main-wrapper__form__item">
              <div className="support-ticket-page__main-wrapper__form__item__header">
                Subject <span>*</span>
              </div>
              <Input
                value={subject}
                placeholder="Subject"
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
              <span className="support-ticket-page__main-wrapper__form__item__error">
                {subjectError}
              </span>
            </div>

            <div className="support-ticket-page__main-wrapper__form__item">
              <div className="support-ticket-page__main-wrapper__form__item__header">
                Description <span>*</span>
              </div>
              <TextArea
                value={description}
                rows={5}
                placeholder="Please describe the error or the request."
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <span className="support-ticket-page__main-wrapper__form__item__error">
                {descriptionError}
              </span>
            </div>

            <div className="support-ticket-page__main-wrapper__form__item">
              <div className="support-ticket-page__main-wrapper__form__item__header">
                Attachments
              </div>
              <UploadBox
                withUser={true}
                type="DOCUMENT"
                defaultUrl={""}
                onClickDelete={deleteDoc}
                attachments={attachments}
                onChange={(_, fileResult, s3Data, url) => onDocumentUpload(s3Data, fileResult, url)}
              />
            </div>
          </div>
        </div>
      </GeneralForm>
    </div>
  );
};

export default SupportTicket;
