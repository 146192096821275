import axios from "axios";

import { BASE_URL } from "../ENV";
import { ISupportTicket, ProfilePhotoUpdate } from "../models";

export const useUser = () => {
  const updateProfile = async (
    firstName: string,
    lastName: string,
    city: string,
    state: string,
    zipCode: string,
    fullAddress: string,
    profileImage?: ProfilePhotoUpdate,
  ) => {
    const sendData = {
      first_name: firstName,
      last_name: lastName,
      city: city,
      state: state,
      zip_code: zipCode,
      full_address: fullAddress,
      profile_picture: profileImage ?? undefined,
    };
    const response = axios.put(`${BASE_URL}/api/v1/users`, sendData);

    return response;
  };

  const getCredential = async () => {
    const response = axios.get(`${BASE_URL}/api/v1/users/credential`);

    return response;
  };

  const getProfile = async () => {
    const response = axios.get(`${BASE_URL}/api/v1/users/profile`);

    return response;
  };

  return { updateProfile, getCredential, getProfile };
};

export const useNotifications = () => {
  const getNotifications = async () => {
    const response = axios.get(`${BASE_URL}/api/v1/notifications`);

    return response;
  };

  const readAllNotification = async () => {
    const response = axios.put(`${BASE_URL}/api/v1/notifications/read-all`);

    return response;
  };

  return { getNotifications, readAllNotification };
};

export const useSupportTicket = () => {
  const create = async (data: ISupportTicket) => {
    const response = axios.post(`${BASE_URL}/api/v1/support-ticket/send`, data);

    return response;
  };

  return { create };
};
