import React from "react";

import { FormRadio, FormRadioProps } from "src/common/components";

export interface Step1Props {
  years: FormRadioProps[];
  selectedYear?: string;
  onClick: (year: string) => void;
  error?: any;
  step?: number;
}

const Step1 = ({ years, onClick, selectedYear, error, step }: Step1Props) => {
  return (
    <div>
      <div className="tax-create-step-1">
        {years.map((item, index) => (
          <FormRadio
            key={index}
            title={item.title}
            onClick={onClick}
            selected={item.title === selectedYear}
          />
        ))}
      </div>
      {error && 1 == error?.step ? (
        <div style={{ marginTop: 9 }}>
          <span className="tax-create-error">{error?.errorTxt}</span>
        </div>
      ) : null}
    </div>
  );
};

export default Step1;
