import { FC, useState } from "react";
import { Row, Form, Button, Input, Typography, DatePicker } from "antd";
import { CalendarFilled, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { promissorySetDataAction, RootState } from "../../store";
import Previous from "../../common/components/Previous";

const LumpSumPayment: FC = () => {
  const dateFormat = "MM/DD/YYYY";
  const addCommas = (value: any) => {
    return value
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const removeCommas = (str: string) => {
    return str.replace(/,/g, "");
  };
  const lumpValue = useSelector((store: RootState) => store.promissoryReducer);
  const [hasLumpSum, setHasLumpSum] = useState<boolean>(true);

  const [payment, setPayment] = useState<any>(
    lumpValue.lump_sum_payment ? addCommas(lumpValue.lump_sum_payment) : null,
  );

  const amountVal = lumpValue?.amount || 0;

  const [days, setDays] = useState<any>(
    lumpValue.lump_sum_payment_due ? lumpValue.lump_sum_payment_due : "",
  );

  const [disable, setDisable] = useState<any>(
    lumpValue.has_lump_sum_payment == true || lumpValue.has_lump_sum_payment == false
      ? !lumpValue.has_lump_sum_payment
      : null,
  );

  const [form] = Form.useForm();
  const initialvalues = {
    has_lump_sum_payment:
      lumpValue.has_lump_sum_payment == true || lumpValue.has_lump_sum_payment == false
        ? lumpValue.has_lump_sum_payment
        : null,
    lump_sum_payment: lumpValue.lump_sum_payment ? lumpValue.lump_sum_payment : null,
    lump_sum_payment_due: lumpValue.lump_sum_payment_due
      ? moment(lumpValue.lump_sum_payment_due)
      : "",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleHasLateFee = (has: any, disable: any) => {
    setHasLumpSum(has);
    setDisable(disable);
    form.setFieldsValue({
      has_lump_sum_payment: false,
      lump_sum_payment: null,
      lump_sum_payment_due: null,
    });
    if (disable) {
      setDays("");
      setPayment(null);
    }
  };

  const handleRemove = (e: any, remove = false, type = "") => {
    if (e.key.length === 1 && /\D/.test(e.key)) {
      e.preventDefault();
    }
    if (e.key == "Backspace" && remove) setPayment(payment.slice(0, -1));
  };

  const handelSign = (val: string, sign: string, callback: any) => {
    callback(val.replace(sign, ""));
    callback(addCommas(val));
  };

  const handleClickNo = () => {
    handleHasLateFee(false, true);

    const errorExplanations = document.querySelectorAll(
      ".note-form-box .ant-form-item-explain-error",
    );
    errorExplanations.forEach((element) => {
      const htmlElement = element as HTMLElement;
      htmlElement.style.display = "none";
    });
  };

  return (
    <Row className="note" justify={"center"}>
      <Previous location={"/installments"}></Previous>
      <Typography.Title className="interest-text" style={{ color: "white", fontSize: 25 }}>
        Lump Sum Payment
      </Typography.Title>
      <Form
        form={form}
        className="note-form-box"
        initialValues={initialvalues}
        onFinish={(values) => {
          const paymentWithoutComma = values && payment ? +removeCommas(payment) : null;
          const fields: any = {
            has_lump_sum_payment: values.has_lump_sum_payment,
            lump_sum_payment: paymentWithoutComma,
            lump_sum_payment_due: values.lump_sum_payment_due ? values.lump_sum_payment_due : "",
          };

          dispatch(promissorySetDataAction(fields));
          navigate("/late-fee");
        }}>
        <div style={{ textAlign: "center", marginBottom: 12 }}>
          <Typography.Text style={{ color: "white", display: "inline-flex" }}>
            Is there a lump sum payment?
          </Typography.Text>
        </div>
        <Form.Item
          name="has_lump_sum_payment"
          rules={[{ required: true, message: "Please select one of the items" }]}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <Button
              className={`note-btn-yes ${disable == true ? "note-btn-enable" : ""}`}
              onClick={() => {
                handleHasLateFee(true, false);
                form.setFieldsValue({ has_lump_sum_payment: true });
              }}>
              Yes
            </Button>
            <Button
              className={`note-btn-no ${disable == false ? "note-btn-enable" : ""}`}
              onClick={handleClickNo}>
              No
            </Button>
          </div>
        </Form.Item>
        <Form.Item
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
          label="How much is the lump sum payment?"
          rules={[
            {
              required: disable == false ? true : false,
              message: "Please enter lump sum payment!",
            },
            {
              validator: async (_, endDatetime) => {
                const lumpSum = payment;
                if (payment && amountVal < lumpSum) {
                  return Promise.reject(
                    "The lump sum amount should not be more than the loan amount.",
                  );
                }
              },
            },
          ]}
          required
          name="lump_sum_payment">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <Input
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className="note-formItem-input"
              placeholder="Enter the lump sum amount($)"
              style={{ borderRadius: 5 }}
              disabled={disable == true || disable == null ? true : false}
              size={"large"}
              value={payment ? "$" + payment : ""}
              onKeyDown={(e) => handleRemove(e, true, "amount")}
              onChange={(e) => handelSign(e.target.value, "$", setPayment)}
            />
          </div>
        </Form.Item>
        <Form.Item
          name="lump_sum_payment_due"
          className="lump-input-date"
          label="When is the lump sum payment due?"
          required
          rules={[
            {
              required: disable == false ? true : false,
              message: "Please Select your Date!",
            },
          ]}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}>
          <DatePicker
            disabledDate={(d) => {
              const lastPaymentDue = lumpValue.last_payment_due;
              return (
                !d.isBetween(lumpValue.first_payment_due, lumpValue.last_payment_due) &&
                !d.isSame(lastPaymentDue, "day")
              );
            }}
            format={dateFormat}
            value={days}
            className="lump-input-date-inp"
            // dropdownClassName={"date-pick-test-"}
            suffixIcon={<CalendarFilled rev="" />}
            disabled={disable == true || disable == null ? true : false}
            dropdownAlign={{ offset: [320, 0, 0] }}
            placeholder="mm/dd/yyyy"
          />
        </Form.Item>
        <div className="note-form-line"></div>
        <Button
          style={{ marginTop: "63px" }}
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" style={{ marginTop: 4 }} />}>
          Next
        </Button>
      </Form>
    </Row>
  );
};

export default LumpSumPayment;
