import { useState } from "react";
import { userRestApi } from "./../services/http";
import { useLocation, useNavigate } from "react-router-dom";

export function useSignUpForm() {
  const [signupResponse, setSignupResponse] = useState<any>({});
  const [fetching, setFetching] = useState(false);
  const [errorHandle, setErrorHandle] = useState<any>("");

  const ERROR_USER_ALREADY_EXIST = 1004;

  const ERROR_USER_NOT_VERIFIED = 2004;

  const doesHandleRequest = true;

  const navigate = useNavigate();

  const [registerLoc, setregisterLoc] = useState([]);
  localStorage.setItem("/register", JSON.stringify(registerLoc));

  const currentUrl = window.location.pathname;

  const errorsMsg: any = {
    404: "Invalid Email or Password",
    1003: "Email already in use",
    1004: "Username already exist",
  };
  const handleSubmit = async (val: any) => {
    const params = {
      first_name: val.firstName,
      last_name: val.lastName,
      email: val.email,
      username: val.username,
      password: val.password,
      mobile_number: val.PhoneNumber,
    };
    setFetching(true);
    try {
      const response = await userRestApi.add(params);
      !doesHandleRequest && setSignupResponse(response);
      navigate("/verify", {
        state: { email: params.email, prevUrl: currentUrl, registerValues: params },
      });
    } catch (error: any) {
      setFetching(false);

      const err: any = error.response;
      const errCode: any = err.data?.code;

      !doesHandleRequest && setSignupResponse(err.data);

      if (
        err.status === 400 &&
        [1003, ERROR_USER_ALREADY_EXIST, ERROR_USER_NOT_VERIFIED].includes(errCode)
      ) {
        if (doesHandleRequest) {
          switch (errCode) {
            case ERROR_USER_NOT_VERIFIED:
              //go to verfication page+
              navigate("/verify", {
                state: { email: params.email, prevUrl: currentUrl, registerValues: params },
              });
              setErrorHandle("not verfied");
              break;
            default:
              setErrorHandle(errorsMsg[errCode]);
              break;
          }
        }
      } else if (err.data?.message && doesHandleRequest) {
        setErrorHandle(err.data.message);
      } else {
        doesHandleRequest;
      }
      return new Error(error);
    }
  };

  return {
    handleSubmit,
    errorHandle,
    // location,
    errorsMsg,
    fetching,
  };
}
