import { useState } from "react";
import { Button } from "antd";
import { CountdownRenderProps } from "react-countdown";

const useTimer = (onSendAgain: () => void) => {
  const [active, setActive] = useState(true);
  const [date, setDate] = useState(Date.now() + 180000);

  const renderTimer = (props: CountdownRenderProps) => {
    return active ? (
      <div className="timer__active">
        <span>{`please try again after ${props.formatted.minutes}:${props.formatted.seconds}`}</span>
      </div>
    ) : (
      <div className="timer__button-wrapper">
        <Button type="primary" onClick={onClick}>
          Send Again
        </Button>
      </div>
    );
  };

  const onClick = () => {
    setDate(Date.now() + 180000);
    setActive(true);
    onSendAgain();
  };

  return { onClick, renderTimer, active, date, setActive, setDate };
};

export default useTimer;
