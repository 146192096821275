import React from "react";
import moment from "moment";

import { AmortizationScheduleResponseDTO } from "src/api";
import { formatNumber } from "src/utils/NumberUtil";

export interface AmortizationScheduleTableProps {
  data: AmortizationScheduleResponseDTO[];
}
const AmortizationScheduleTable = ({ data }: AmortizationScheduleTableProps) => {
  const sortedData = data.sort(
    (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  console.log(sortedData);
  return (
    <div className="amortization-table">
      <table cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Payment</th>
            <th>Principal</th>
            <th>Interest</th>
            <th>Balance</th>
          </tr>
        </thead>

        <tbody>
          {sortedData.map((row, index) => (
            <tr key={index}>
              <td>{moment(row.date).format("MM/DD/YYYY")}</td>
              <td>{formatNumber(row.scheduled_payment!)}</td>
              <td>{formatNumber(row.principal!)}</td>
              <td>{formatNumber(row.interest!)}</td>
              <td>{formatNumber(row.starting_balance!)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AmortizationScheduleTable;
