import React from "react";
import { Button } from "antd";

import { formatNumber } from "../../../utils/NumberUtil";
import LoanInfoLine from "./LoanInfoLine";
import { FullPromissoryNoteDTOResponse } from "src/api";

export interface LoanInfoProps {
  loanAmount: number;
  paidBackAmount: number;
  startDate: string;
  firstPaymentDate: string;
  lastPaymentDate: string;
  rate: number;
  onClickUpdate: () => void;
  onClickView: () => void;
  note?: FullPromissoryNoteDTOResponse;
}

const LoanInfo = ({
  firstPaymentDate,
  lastPaymentDate,
  loanAmount,
  rate,
  startDate,
  onClickUpdate,
  onClickView,
  note,
}: LoanInfoProps) => {
  let allAamortization = 0;
  let paid_back = 0;

  note?.amortization_schedules!.forEach((amortization: any) => {
    if (amortization.status === "PAID") {
      paid_back += amortization.scheduled_payment!;
    }
    allAamortization += amortization.scheduled_payment!;
  });
  return (
    <div className="loan-info">
      <div className="loan-info__header">
        <div className="loan-info__header__section">
          <div className="loan-info__header__section__title">Loan Amount</div>
          <div className="loan-info__header__section__value">{`$${formatNumber(loanAmount)}`}</div>
        </div>
        <div>
          <div className="loan-info__header__section__title">Paid Back</div>
          <div className="loan-info__header__section__value">
            {`$${formatNumber(paid_back)}`}
            <span>(%{((paid_back * 100) / allAamortization).toFixed(2)})</span>
          </div>
        </div>
      </div>
      <div className="loan-info__body">
        <LoanInfoLine title="Agreement Begins" value={startDate} />
        <LoanInfoLine title="First Payment Date" value={firstPaymentDate} />
        <LoanInfoLine title="Last Payment Date" value={lastPaymentDate} />
        <LoanInfoLine title="Interest Rate" value={`${rate}%`} />
      </div>
      <div className="loan-info__buttons">
        <Button className="styled-button" type="primary" onClick={onClickView}>
          View Note
        </Button>
        <Button className="styled-button text-blue" onClick={onClickUpdate}>
          Modify Note
        </Button>
      </div>
    </div>
  );
};

export default LoanInfo;
