import React from "react";

import { GeneralForm, TopNavController } from "src/common/components";
import usePaymentReminder from "./usePaymentReminder";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";

const PaymentReminder = () => {
  const {
    content,
    title,
    onClickNext,
    onClickPrev,
    step,
    onClickCancel,
    days,
    onChangeValue,
    onClickRemove,
    setDays,
    useReminder,
    setUseReminder,
    contacts,
    emails,
    onClickAddContact,
    hasError,
  } = usePaymentReminder();

  const renderer = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            daysLater={days}
            onChangeDaysLater={setDays}
            onClick={setUseReminder}
            useReminder={useReminder}
          />
        );
      case 2:
        return (
          <Step2
            hsaError={hasError}
            onClickAddContact={onClickAddContact}
            emails={emails}
            contacts={contacts}
            onChangeValue={onChangeValue}
            onClickRemove={onClickRemove}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="payment-reminder-page">
      <TopNavController
        onClickCancel={onClickCancel}
        showCancel={step !== 7}
        onClickPrev={() => {
          onClickPrev();
        }}
      />
      <GeneralForm
        width="424px"
        buttonText="Next"
        headerContent={{
          __html: content,
        }}
        checkboxVisible={false}
        headerTitle={title}
        onClick={onClickNext}>
        {renderer()}
      </GeneralForm>
    </div>
  );
};

export default PaymentReminder;
