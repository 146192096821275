import { Input, Button, Checkbox, Divider } from "antd";
import useLoginRegisterModal from "./useLoginRegisterModal";
import { Link } from "react-router-dom";

export interface IRegisterModalProps {
  onRegisterDone: (username: string, password: string) => void;
}

const RegisterModal = ({ onRegisterDone }: IRegisterModalProps) => {
  const {
    register,
    loading,
    openLoginModal,
    username,
    setUsername,
    usernameError,
    password,
    setPassword,
    passwordError,
    firstName,
    setFirstName,
    firstNameError,
    lastName,
    setLastName,
    lastNameError,
    email,
    setEmail,
    emailError,
    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    confirmPassword,
    setConfirmPassword,
    confirmPasswordError,
    setAcceptTerms,
    acceptTermsError,
    registerError,
  } = useLoginRegisterModal((username, password) => {
    onRegisterDone(username, password);
    return;
  });

  return (
    <>
      <h4 className="login-register-modal__header">Register</h4>
      <div className="login-register-modal__items">
        <div className="login-register-modal__items__item">
          <span className="login-register-modal__items__item__title">
            First Name <span>*</span>
          </span>
          <Input
            status={firstNameError ? "error" : ""}
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          <span className="login-register-modal__items__item__error">{firstNameError}</span>
        </div>
        <div className="login-register-modal__items__item">
          <span className="login-register-modal__items__item__title">
            Last Name <span>*</span>
          </span>
          <Input
            status={lastNameError ? "error" : ""}
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
          <span className="login-register-modal__items__item__error">{lastNameError}</span>
        </div>
        <div className="login-register-modal__items__item">
          <span className="login-register-modal__items__item__title">
            Email <span>*</span>
          </span>
          <Input
            status={emailError ? "error" : ""}
            placeholder="Email address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <span className="login-register-modal__items__item__error">{emailError}</span>
        </div>
        <div className="login-register-modal__items__item">
          <span className="login-register-modal__items__item__title">
            Phone Number <span>*</span>
          </span>
          <Input
            status={phoneNumberError ? "error" : ""}
            placeholder="Phone Number"
            type="number"
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
          />
          <span className="login-register-modal__items__item__error">{phoneNumberError}</span>
        </div>
        <div className="login-register-modal__items__item">
          <span className="login-register-modal__items__item__title">
            Username <span>*</span>
          </span>
          <Input
            status={usernameError ? "error" : ""}
            placeholder="Username"
            autoComplete="one-time-code"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <span className="login-register-modal__items__item__error">{usernameError}</span>
        </div>
        <div className="login-register-modal__items__item">
          <span className="login-register-modal__items__item__title">
            Password <span>*</span>
          </span>
          <Input
            status={passwordError ? "error" : ""}
            placeholder="Password"
            autoComplete="one-time-code"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="login-register-modal__items__item__error">{passwordError}</span>
        </div>
        <div className="login-register-modal__items__item">
          <span className="login-register-modal__items__item__title">
            Confirm Password <span>*</span>
          </span>
          <Input
            status={confirmPasswordError ? "error" : ""}
            placeholder="Confirm Password"
            autoComplete="one-time-code"
            value={confirmPassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span className="login-register-modal__items__item__error">{confirmPasswordError}</span>
        </div>
        <Divider style={{ margin: 0, backgroundColor: "#fff" }} />
        <Checkbox onChange={(e) => setAcceptTerms(e.target.checked)}>
          I agree to Loanthem’s{" "}
          <span className="styled-button-link">
            <Link to={"/terms-of-services"} target="_blank">
              Terms of Service{" "}
            </Link>
          </span>
          and{" "}
          <span className="styled-button-link">
            <Link to={"/privacy-policy"} target="_blank">
              Privacy Policy
            </Link>
          </span>
        </Checkbox>
        <span className="login-register-modal__items__item__error">{acceptTermsError}</span>
        <span className="login-register-modal__items__item__error">{registerError}</span>

        <Button
          className="login-btn"
          htmlType={"submit"}
          type="primary"
          block
          loading={loading}
          onClick={() => register(onRegisterDone)}>
          Register
        </Button>
        <div className="login-register-modal__items__text" style={{ textAlign: "center" }}>
          <span>Already have an account? </span>
          <span
            className="login-register-modal__items__text__link styled-button-link"
            onClick={openLoginModal}>
            Sign in
          </span>
        </div>
      </div>
    </>
  );
};

export default RegisterModal;
