import { useEffect, useState } from "react";

import { UseUser } from "../../../hooks";
import { INotification } from "../../../models";

const useNotificationMenu = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [loading, setLoading] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);

  const { getNotifications } = UseUser.useNotifications();

  const getNotificationsAsync = async () => {
    try {
      const response = await getNotifications();
      let unreadCounts = 0;
      const data: INotification[] = response.data?.content?.map((item: any) => {
        const bodyParsed = JSON.parse(item.body);

        item.status === "UNREAD" && unreadCounts++;
        return {
          id: item.id,
          photo: item.photo,
          status: item.status,
          type: bodyParsed.notification_type,
          createdDate: bodyParsed.created_date ?? "",
          itemId: bodyParsed.id ?? "",
          firstName: bodyParsed.first_name ?? "",
          lastName: bodyParsed.last_name ?? "",
          noteName: bodyParsed.note_name ?? "",
          ownerType: bodyParsed.notification_owner_type ?? "",
          lenderName: bodyParsed.lender_name ?? "",
          borrowerName: bodyParsed.borrower_name ?? "",
          amount: bodyParsed.amount ?? 0,
        };
      });
      setUnreadCount(unreadCounts);
      setNotifications(data);
      setLoading(false);
    } catch (error) {
      console.log("get notifications error: ", error);
    }
  };

  useEffect(() => {
    getNotificationsAsync();
  }, []);

  return { notifications, loading, unreadCount, getNotificationsAsync };
};

export default useNotificationMenu;
