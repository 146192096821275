/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import moment from "moment";
import { Button, Checkbox } from "antd";
import { Link } from "react-router-dom";

import Row from "../Row";

export interface Step3Props {
  currentPlan: string;
  currentPlanEndDate: string | undefined;
  onClickRenew: () => void;
  onClickCancel: () => void;
  onClickBuy: (plan: "PREMIUM_PACKAGE" | "MULTIPLE_PACKAGE" | "GO_NEXT") => void;
  buyLoading?: boolean;
  renewLoading?: boolean;
}

const Step3 = ({
  currentPlan,
  onClickBuy,
  onClickRenew,
  onClickCancel,
  buyLoading = false,
  renewLoading = false,
  currentPlanEndDate,
}: Step3Props) => {
  const [checkAgreement, setCheckAgreement] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);

  const expirationDate = currentPlanEndDate
    ? new Date(currentPlanEndDate).setUTCFullYear(new Date(currentPlanEndDate).getFullYear())
    : new Date().setUTCFullYear(new Date().getFullYear() + 1);

  return (
    <div className="update-subscription-page__step-2">
      <div className="update-subscription-page__step-2__header">
        1-Year Premium
        <br />
        $49.95
      </div>
      <div className="update-subscription-page__step-2__rows">
        <Row enable={true} title="Multiple Notes & Edit" />
        <Row enable={true} title="Secure Payment Processing" />
        <Row enable={true} title="Payment Reminders and Receipts" />
        <Row enable={true} title="Demand and Loan Release Letters" />
        <Row enable={true} title="Tax From Prep" />
      </div>
      <div className="update-subscription-page__step-2__agreement">
        <Checkbox checked={checkAgreement} onChange={(e) => setCheckAgreement(e.target.checked)} />
        <span>
          Your 1-Year Premium Package will be automatically renewed on{" "}
          {moment(expirationDate).format("MMMM DD, YYYY")}
        </span>
      </div>
      <div className="update-subscription-page__step-2__agreement">
        <Checkbox checked={checkTerms} onChange={(e) => setCheckTerms(e.target.checked)} />
        <span>
          I agree to Loanthem's
          <Link to="/terms-of-services" target="_blank">
            {" "}
            Terms of Service{" "}
          </Link>
          and
          <Link target="_blank" to="/privacy-policy">
            {" "}
            Privacy Policy{" "}
          </Link>
        </span>
      </div>
      {currentPlan === "PREMIUM_PACKAGE" && (
        <>
          <Button
            onClick={onClickRenew}
            className={`styled-button ${!checkTerms ? "disable" : ""}`}
            type="ghost"
            loading={renewLoading}
            disabled={!checkTerms}>
            Renew Subscription
          </Button>

          <Button
            onClick={onClickCancel}
            className={`styled-button red`}
            type="primary"
            disabled={!checkTerms}>
            Cancel Subscription
          </Button>
        </>
      )}
      {currentPlan !== "PREMIUM_PACKAGE" && (
        <Button
          onClick={() => onClickBuy("PREMIUM_PACKAGE")}
          className={`styled-button green ${!checkTerms ? "disable" : ""}`}
          type="ghost"
          loading={buyLoading}
          disabled={!checkTerms}>
          Buy Plan
        </Button>
      )}
    </div>
  );
};

export default Step3;
