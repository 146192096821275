import { FC, useEffect, useState } from "react";
import { Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { CancelNoteGeneration } from "../components";
import { authSetDataAction, authSetUser } from "src/store";

import "../css/note.css";

interface prevInterface {
  location: string;
  showCancel?: boolean;
  stateValues?: object;
}

const Previous: FC<prevInterface> = ({ location, showCancel = true, stateValues = {} }) => {
  const [openModal, setOpenModal] = useState(false);
  const { user } = useSelector((state: any) => state.authReducer);
  const locationRoute = useLocation();
  const dispatch = useDispatch();

  const checkIfNewUser = () => {
    let noBorrowers = false;
    let noLenders = false;

    if (!user) {
      return false;
    }

    if (Array.isArray(user.lenders)) {
      noLenders = user.lenders.length === 0;
    } else {
      noLenders = user.borrowers.totalElements === 0;
    }

    if (Array.isArray(user.borrowers)) {
      noBorrowers = user.borrowers.length === 0;
    } else {
      noBorrowers = user.borrowers.totalElements === 0;
    }

    return noLenders || noBorrowers;
  };

  useEffect(() => {
    if (location === "/login") {
      dispatch(authSetDataAction("login", null, "error"));
      dispatch(authSetUser());
    }
  }, []);

  return (
    <>
      <div className={`note-default ${!showCancel || checkIfNewUser() ? "align-right" : ""}`}>
        <Link
          to={location}
          state={{
            prevUrl: locationRoute.pathname,
            previousValues: stateValues != undefined ? stateValues : {},
          }}>
          <div className="note-default-previous">
            <LeftOutlined rev="" style={{ color: "white", marginRight: 3 }} />
            <Typography.Text style={{ color: "white" }}>Previous</Typography.Text>
          </div>
        </Link>
        {showCancel && !checkIfNewUser() && (
          <Typography.Text
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => setOpenModal(true)}>
            Cancel
          </Typography.Text>
        )}
      </div>
      <CancelNoteGeneration open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

export default Previous;
