import React from "react";
import { useNavigate } from "react-router-dom";

import { GeneralDraft, TopNavController } from "../../common/components";
import { useSelector } from "react-redux";

const FAQ = () => {
  const { isLogin } = useSelector((store: any) => ({
    isLogin: store.authReducer.login.status === "success",
  }));
  const navigate = useNavigate();

  return (
    <div className="faq-page">
      <TopNavController
        showCancel={false}
        onClickPrev={() => {
          // navigate(-1);
          isLogin ? navigate("/home") : navigate(-1);
        }}
      />
      <div className="faq-page__preview">
        <GeneralDraft headerTitle="FAQ">
          <embed src={`/FAQ.html#toolbar=0`} type="application/pdf" height="600px" width="100%" />
        </GeneralDraft>
      </div>
    </div>
  );
};

export default FAQ;
