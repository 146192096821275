import { Table, Space } from "antd";
import { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SendOutlined } from "@ant-design/icons";
import { Form, Button, Input, Typography, Tag } from "antd";

import useAdmin from "./useAdmin";
import { BASE_URL } from "src/ENV";

const Admin: FC = () => {
  const { logout } = useAdmin();
  const { login } = useSelector((store: any) => ({
    login: store.authReducer.login,
  }));
  const [form] = Form.useForm();
  const [selected, setSelected] = useState("ticket");
  const [reviewList, setReviewList] = useState([]);
  const [supportList, setSupportList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [supportId, setSupportId] = useState(false);
  const [reciverEmail, setReciverEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [desc, setDesc] = useState<any>("");

  const [responseMsg, setResponseMsg] = useState("");
  const { TextArea } = Input;

  /*  const dataSource = [
    {بیل
      key: "1",
      email: "Mike",
      type: "fefef",
      desc: "10 Downing Street",
      attach: "erfvef",
    },
    {
      key: "2",
      email: "Mike",
      type: "fefef",
      desc: "10 Downing Street",
      attach: "erfvef",
    },
  ]; */
  function downloadImage(url: any, filename: any) {
    const link = document.createElement("a");
    /*     link.href = url;
     */ link.download = filename;
    link.click();
  }
  const openResponseModal = (record: any) => {
    const { ticket_id, email } = record;
    if (ticket_id !== supportId) {
      setDesc("");
      setSubject("");
      form.setFieldsValue({ description: "", subject: "" });
    }
    setSupportId(ticket_id);
    setModalOpen(true);
    setReciverEmail(email);
  };
  const columns: any = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Type",
      dataIndex: "ticket_type",
      key: "type",
    },
    {
      title: "description",
      dataIndex: "description",
      key: "desc",
    },
    {
      title: "Attachment",
      dataIndex: "attachments",
      key: "attach",
      render: (plans: any) =>
        plans.length > 0 ? (
          plans.map((item: any, index: number) => (
            <Tag
              key={index}
              style={{ cursor: "pointer", width: 100, textAlign: "center" }}
              color={"green"}>
              <a target="_blank" href={item.file_path} download={item.file_path} rel="noreferrer">
                Download
              </a>
            </Tag>
          ))
        ) : (
          <Tag style={{ cursor: "pointer", width: 100, textAlign: "center" }} color={"red"}>
            No Attachment{" "}
          </Tag>
        ),
    },
    {
      title: "Reply",
      key: "replay",
      render: (_: any, record: any) => (
        <Space size="middle">
          <SendOutlined rev="" onClick={() => openResponseModal(record)} />{" "}
        </Space>
      ),
    },
  ];

  const reviewColumns: any = [
    {
      title: "Email",
      dataIndex: "user_email",
      key: "email",
    },
    {
      title: "description",
      dataIndex: "description",
      key: "desc",
    },
    {
      title: "Ease Of Use",
      dataIndex: "ease_of_use",
      key: "ease_of_use",
    },
    {
      title: "Expense",
      dataIndex: "expense",
      key: "expense",
    },
    {
      title: "Overall Value",
      dataIndex: "overall_value",
      key: "overall_value",
    },
    {
      title: "Functionality",
      dataIndex: "scope_of_functionality",
      key: "scope_of_functionality",
    },
    {
      title: "Speed",
      dataIndex: "speed",
      key: "speed",
    },
    {
      title: "Style",
      dataIndex: "style",
      key: "style",
    },
  ];
  const userColumns = [
    {
      title: "User Email",
      dataIndex: "user_email",
      key: "user_email",
    },
    {
      title: "Registration Plan",
      dataIndex: "user_registration_plans",
      key: "user_registration_plans",
      render: (plans: any) => (plans.length > 0 ? plans[plans.length - 1].registration_plan : ""),
    },
    {
      title: "Start Date",
      dataIndex: "user_registration_plans",
      key: "start_date",
      render: (plans: any) =>
        plans.length > 0 ? new Date(plans[plans.length - 1].start_date).toLocaleDateString() : "",
    },
    {
      title: "End Date",
      dataIndex: "user_registration_plans",
      key: "end_date",
      render: (plans: any) =>
        plans.length > 0 ? new Date(plans[plans.length - 1].end_date).toLocaleDateString() : "",
    },
    {
      title: "Expired",
      dataIndex: "user_registration_plans",
      key: "expire",
      render: (plans: any) =>
        plans.length > 0 ? (plans[plans.length - 1].enabled ? "not expired" : "expired") : "",
    },
  ];

  const getSupportList = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/support-ticket`, {
        headers: { Authorization: "Bearer " + login.data.access_token },
      });
      const data = await response.json();
      console.log(data);
      setSupportList(data);
    } catch (err) {
      console.log(err);
    }
  };
  const getReviewsList = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/app-review/reviews`, {
        headers: { Authorization: "Bearer " + login.data.access_token },
      });
      const data = await response.json();
      setReviewList(data.app_reviews);
    } catch (err) {
      console.log(err);
    }
  };
  const getUserList = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/users/plans`, {
        headers: { Authorization: "Bearer " + login.data.access_token },
      });
      const data = await response.json();
      console.log(data);
      setUserList(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    (async () => {
      getSupportList();
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  const sendResponse = async (val: any) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    /*     const fields = { ...val, receiver_email: reciverEmail };
     */ const fields = { subject, description: desc, receiver_email: reciverEmail };
    console.log("efefgef", fields);

    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/support-ticket/${supportId}/ticket-response`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + login.data.access_token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(fields),
        },
      );

      const data = await response.json();
      console.log(data);
      setModalOpen(false);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setModalOpen(false);
    }
  };

  const closeModal = (e: any) => {
    if (e.target.classList.contains("admin-modal")) {
      e.stopPropagation();
      setModalOpen(false);
    }
  };
  const changePanel = async (route: string) => {
    setSelected(route);
    if (route === "ticket") {
      await getSupportList();
    } else if (route === "user") {
      await getUserList();
    } else {
      await getReviewsList();
    }
  };
  const returnTable = () => {
    if (selected == "review") {
      return <Table className="my-table" dataSource={reviewList} columns={reviewColumns} />;
    } else if (selected == "ticket") {
      return <Table className="my-table" dataSource={supportList} columns={columns} />;
    } else if (selected == "user") {
      return <Table className="my-table" dataSource={userList} columns={userColumns} />;
    }
  };

  return (
    <>
      <div
        onClick={closeModal}
        className="admin-modal"
        style={{ display: modalOpen ? "flex" : "none" }}>
        {/*     <div className="admin-modal-container">





        </div> */}
        <Form
          style={{ height: "auto", backgroundColor: "#00092f" }}
          onFinish={sendResponse}
          form={form}
          className="note-form-box">
          <Form.Item
            className="note-formItem-input"
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
            rules={[{ required: true, message: "Please enter subject!" }]}
            name="subject">
            <div>
              <Typography.Title
                className="note-title"
                style={{
                  color: "white",
                  fontSize: 14,
                  fontWeight: 400,
                  paddingBottom: 20,
                  textAlign: "left",
                }}>
                Subject
                <span style={{ color: "#d0434c", fontSize: 20 }}>*</span>
              </Typography.Title>
              <Input
                className="note-formItem-input"
                placeholder="Enter Subject"
                style={{ borderRadius: 5 }}
                size={"large"}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
          </Form.Item>
          <div
            style={{
              marginTop: 33,
            }}>
            <Typography.Title
              className="note-title"
              style={{
                color: "white",
                fontSize: 14,
                fontWeight: 400,
                paddingBottom: 20,
                textAlign: "left",
              }}>
              Description
              <span style={{ color: "#d0434c", fontSize: 20 }}>*</span>
            </Typography.Title>
            <Form.Item
              className="note-formItem-input label-wrap"
              style={{ display: "flex", flexDirection: "column" }}
              rules={[{ required: true, message: "Please enter description!" }]}
              name="description">
              <TextArea
                style={{ borderRadius: 5, resize: "none" }}
                rows={6}
                placeholder={"description"}
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="note-form-line"></div>
          <div className="amount-formItem-btn">
            <Button
              style={{ marginTop: "40px" }}
              className="note-form-btn"
              type="primary"
              htmlType={"submit"}>
              {isLoading ? "Loading" : "Submit"}
            </Button>
          </div>
        </Form>
      </div>
      <div className="admin_container">
        {/*        <div className="admin_row">
          <div className="admin_sidebar_container">
            <div className="admin_sidebar">
              <div className="sidebar_header">
                <h2>Admin Panel</h2>
                <hr />
              </div>
              <div className="sidebar_content_container">
                <div className="admin_sidebar_list">
                  <span
                    className="admin_sidebar_list_items"
                    onClick={() => {
                      setSelected("review");
                    }}>
                    Reviews
                  </span>
                  <span
                    className="admin_sidebar_list_items"
                    onClick={() => {
                      setSelected("ticket");
                    }}>
                    Tickets
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="admin_content_container">
            {selected == "ticket" ? <Table dataSource={supportList} columns={columns} /> : null}
          </div>
        </div> */}

        <div className="admin-navbar">
          <span
            className={selected == "ticket" ? "selected" : ""}
            onClick={async () => await changePanel("ticket")}>
            Tickets
          </span>
          <span
            className={selected == "review" ? "selected" : ""}
            onClick={async () => await changePanel("review")}>
            Reviews
          </span>
          <span
            className={selected == "user" ? "selected" : ""}
            onClick={async () => await changePanel("user")}>
            Users
          </span>
          <span onClick={logout}>Logout</span>
        </div>
        <div className="admin_content_container">{returnTable()}</div>
      </div>
    </>
  );
};

export default Admin;
