import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState, authSetDataAction, authSetUser } from "../store";
import { promissoryNoteRestApi, userRestApi } from "../services/http";
import { LOGIN_LOCAL_KEY } from "src/ENV";
import { IUser } from "src/models";
import { useLocation } from "react-router-dom";

const noteRoutes = [
  "/amount",
  "/interest",
  "/agreement-payment",
  "/installments",
  "/lump-sum-payment",
  "/late-fee",
  "/failure-to-pay",
  "/demand-payment",
  "/collateral",
  "/collateral-transfer",
  "/jurisdiction",
  "/enforcement",
  "/witness-signing",
  "/witness-info",
  "/extra-clause",
  "/individual-info/lender",
  "/bussiness-info/lender",
  "/borrower",
  "/individual-info/borrower",
  "/bussiness-info/lender",
  "/co-signer",
  "/co-signer-info",
  "/third-party",
  "/note-name",
  "/draft",
  "/select-plan",
];

const useApp = (initSet = true) => {
  const { login } = useSelector((store: RootState) => ({
    login: store.authReducer,
  }));
  const promissory = useSelector((state: RootState) => state.promissoryReducer);
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userIsFetching, setUserIsFetching] = useState(true);

  const setUser = async () => {
    if (login.login.status !== "success") {
      setUserIsFetching(false);
      return;
    }

    try {
      const response = await userRestApi.getProfile();
      const user = response.data as unknown as IUser;
      const lenderResponse = await promissoryNoteRestApi.getMyLoans({
        offset: 0,
        pageNumber: 0,
        pageSize: 100,
      });
      const borrowerResponse = await promissoryNoteRestApi.getMyBorrows({
        offset: 0,
        pageNumber: 0,
        pageSize: 100,
      });

      let planResponse;
      try {
        planResponse = await userRestApi.getActivePlan();
      } catch (error) {
        console.log("error, get user plan", error);
      }

      user.lenders = lenderResponse.data?.my_notes?.content;
      user.borrowers = borrowerResponse.data?.my_notes?.content;
      user.activePlan = planResponse?.data?.registration_plan;

      //if user is new should redirect to create note or not have plan
      if (!login.login.data?.authorities.includes("ADMIN")) {
        if (
          (!user.lenders ||
            user.lenders.length === 0 ||
            !user.borrowers ||
            user.borrowers.length === 0) &&
          !user.activePlan
        ) {
          if (noteRoutes.includes(promissory.route ?? "")) {
            initSet &&
              navigate(promissory.route ?? "", {
                replace: true,
              });
          } else if (
            !promissory.editMode &&
            ((promissory.route !== "/draft" && promissory.route !== "/select-plan") ||
              (location.pathname !== "/draft" && location.pathname !== "/select-plan"))
          ) {
            initSet &&
              navigate("/amount", {
                replace: true,
              });
          }
        }
      } else {
        initSet &&
          navigate("/admin", {
            replace: true,
          });
      }
      dispatch(authSetUser(user));
    } catch (error) {
      localStorage.removeItem(LOGIN_LOCAL_KEY);
      dispatch(authSetDataAction("login", null, "error"));
      navigate("/", {
        replace: true,
      });
    }
    setUserIsFetching(false);
  };

  useEffect(() => {
    initSet && setUser();
  }, []);

  return { userIsFetching, setUser };
};

export default useApp;
