import { FC, useState } from "react";
import { Row, Form, Button, Input, Typography, Space, DatePicker } from "antd";
import { RightOutlined } from "@ant-design/icons";
import InfoBox from "./../../common/components/InfoBox";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import Previous from "src/common/components/Previous";

const Enforcement: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const dispatch = useDispatch();

  const [enforce, setEnforce] = useState<any>(
    note.note_enforce_venue != undefined ? note.note_enforce_venue : null,
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = (val: any) => {
    dispatch(promissorySetDataAction(val));
    navigate("/witness-signing");
  };

  const initialVal = {
    note_enforce_venue: note.note_enforce_venue != undefined ? note.note_enforce_venue : null,
  };
  return (
    <Row className="note" justify={"center"}>
      <Previous location="/jurisdiction" />
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Enforcement
      </Typography.Title>
      <Form
        initialValues={initialVal}
        form={form}
        className="note-form-box"
        onFinish={(val) => handleSubmit(val)}>
        <Typography.Title
          className="note-title"
          style={{ color: "white", fontSize: 14, fontWeight: 400, paddingBottom: 10 }}>
          In case of default, in which legal venue does the Lender wish to enforce the Note?
          <span style={{ color: "red" }}>*</span>
        </Typography.Title>
        <Form.Item
          name="note_enforce_venue"
          rules={[{ required: true, message: "Please select one of the items" }]}
          style={{ marginTop: "50px" }}>
          <div className="note-demandPaymenct">
            <Button
              className={`note-secondery-btn-yes ${
                enforce == "COURT_OF_LAW" ? "note-btn-enable" : ""
              }`}
              onClick={() => {
                setEnforce("ARBITRATION");
                form.setFieldsValue({ note_enforce_venue: "ARBITRATION" });
              }}>
              Arbitration
            </Button>
            <Button
              className={`note-secondery-btn-no ${
                enforce == "ARBITRATION" ? "note-btn-enable" : ""
              }`}
              onClick={() => {
                setEnforce("COURT_OF_LAW");
                form.setFieldsValue({ note_enforce_venue: "COURT_OF_LAW" });
              }}>
              Court of Law
            </Button>
          </div>
        </Form.Item>
        <div style={{ height: "50px" }} />
        <div className="note-form-line"></div>
        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
      <InfoBox text="Courts of Law and Arbitration proceedings have different rules and procedures.  If necessary, please consult a legal professional." />
    </Row>
  );
};

export default Enforcement;
