import React from "react";
import { Button, Spin } from "antd";
import moment from "moment";

import { GeneralDraft, TopNavController } from "src/common/components";
import useAmortizationSchedule from "./useAmortizationSchedule";
import AmortizationScheduleTable from "./AmortizationScheduleTable";
import { formatNumber } from "src/utils/NumberUtil";

import { ReactComponent as IconDownload } from "../../assets/icons/ic-download.svg";
import { ReactComponent as IconShare } from "../../assets/icons/ic-share.svg";

const AmortizationSchedule = () => {
  const { downloadPdf, onClickPrev, dataLoaded, detail, scheduleData, loadingPdf } =
    useAmortizationSchedule();

  return (
    <div className="amortization-schedule-page">
      <TopNavController
        showCancel={false}
        onClickPrev={() => {
          onClickPrev();
        }}
      />
      <div className="amortization-schedule-page__preview">
        <GeneralDraft headerTitle={"Amortization Schedule"}>
          {dataLoaded ? (
            <div className="amortization-schedule-page__preview__content">
              <div className="amortization-schedule-page__preview__content__info">
                <div className="amortization-schedule-page__preview__content__info__row">
                  <span className="amortization-schedule-page__preview__content__info__row__key">
                    Loan Amount:
                  </span>
                  <span className="amortization-schedule-page__preview__content__info__row__value">{`$${formatNumber(
                    detail?.amount ?? 0,
                  )}`}</span>
                </div>
                <div className="amortization-schedule-page__preview__content__info__row">
                  <span className="amortization-schedule-page__preview__content__info__row__key">
                    First payment:
                  </span>
                  <span className="amortization-schedule-page__preview__content__info__row__value">
                    {detail?.first_payment_due
                      ? moment(detail?.first_payment_due).format("MMMM DD, YYYY")
                      : ""}
                  </span>
                </div>
                <div className="amortization-schedule-page__preview__content__info__row">
                  <span className="amortization-schedule-page__preview__content__info__row__key">
                    Last Payment:
                  </span>
                  <span className="amortization-schedule-page__preview__content__info__row__value">
                    {detail?.last_payment_due
                      ? moment(detail?.last_payment_due).format("MMMM DD, YYYY")
                      : ""}
                  </span>
                </div>
                <div className="amortization-schedule-page__preview__content__info__row">
                  <span className="amortization-schedule-page__preview__content__info__row__key">
                    Interest Rate:
                  </span>
                  <span className="amortization-schedule-page__preview__content__info__row__value">
                    {`${detail?.interest ? detail?.interest : 0}%`}
                  </span>
                </div>
              </div>
              <AmortizationScheduleTable data={scheduleData!} />
            </div>
          ) : (
            <div className="amortization-schedule-page__preview__spin">
              <Spin />
            </div>
          )}
        </GeneralDraft>
        <div className="amortization-schedule-page__preview__buttons">
          <Button
            className="styled-button text-blue border-blue background-white styled-button-hover-white"
            type="primary"
            loading={loadingPdf}
            onClick={downloadPdf}
            icon={<IconDownload />}>
            Download
          </Button>
          {/* <Button
            className="styled-button text-blue border-blue background-white"
            type="primary"
            icon={<IconShare />}>
            Share
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default AmortizationSchedule;
