import { FC, useState } from "react";
import { Row, Form, Button, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import InfoBox from "./../../common/components/InfoBox";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import Previous from "src/common/components/Previous";

const DemandPayment: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const [demand, setDemand] = useState<any>(
    note.loan_pay_ability_type != undefined ? note.loan_pay_ability_type : "",
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const initialVal = {
    loan_pay_ability_type:
      note.loan_pay_ability_type != undefined ? note.loan_pay_ability_type : "",
  };
  const handleSubmit = (val: any) => {
    /*     const data = val.loan_pay_ability_type ? "IMMEDIATELY" : "ONLY_AS_SCHEDULED";
     */
    dispatch(promissorySetDataAction(val));
    navigate("/collateral");
  };
  return (
    <Row className="note" justify={"center"}>
      <Previous location="/failure-to-pay" />
      <div className="note-title" style={{ color: "white" }}>
        Demand of Full Payment
      </div>
      <Form
        initialValues={initialVal}
        form={form}
        className="note-form-box"
        onFinish={(val) => handleSubmit(val)}>
        <Typography.Title
          className="note-title"
          style={{ color: "white", fontSize: 14, fontWeight: 400, paddingBottom: 20 }}>
          The Loan is Payable?
          <span style={{ color: "red" }}>*</span>
        </Typography.Title>
        <Form.Item
          name="loan_pay_ability_type"
          rules={[{ required: true, message: "Please select one of the items." }]}
          style={{ marginTop: "50px" }}>
          <div className="note-demandPaymenct">
            <Button
              className={`note-secondery-btn-yes btn-lable  ${
                demand == "ONLY_AS_SCHEDULED" ? "note-btn-enable" : ""
              }`}
              style={{ fontSize: "12px" }}
              onClick={() => {
                setDemand("ONLY_AS_SCHEDULED");
                form.setFieldsValue({ loan_pay_ability_type: "ONLY_AS_SCHEDULED" });
              }}>
              Only As Scheduled
            </Button>
            <Button
              style={{ fontSize: "12px", padding: "9px 19px" }}
              className={`note-secondery-btn-no ${
                demand == "IMMEDIATELY" ? "note-btn-enable" : ""
              }`}
              onClick={() => {
                setDemand("IMMEDIATELY");
                form.setFieldsValue({ loan_pay_ability_type: "IMMEDIATELY" });
              }}>
              Upon Written Demand
            </Button>
          </div>
        </Form.Item>
        <div style={{ height: "50px" }} />
        <div className="note-form-line"></div>
        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
      <InfoBox
        space={true}
        text={
          " “Only As Scheduled,” means that payments will only be required to be paid at the time and in the amounts provided for in the promissory note." +
          "\n" +
          "\n" +
          "“Upon Written Demand,” means that the Lender can demand full payment at any time after the signing of the  promissory note. Federal and/or state law may require Lenders to provide borrowers with a written demand letter that includes: names of lenders and borrowers, principal  amount, interest rate, amount due, due date, late fees or charges, payment address, amongst other requirements."
        }
      />
    </Row>
  );
};

export default DemandPayment;
/* text={
  " “Only As Scheduled,” means that payments will only be required to be paid at the time and in the amounts provided for in the promissory note. " +
  "\n" +
  "“Upon Written Demand,” means that the Lender can demand full payment at any time after the signing of the  promissory note. Federal and/or state law may require Lenders to provide borrowers   with a written demand letter that includes: names of lenders and borrowers, principal  amount, interest rate, amount due, due date, late fees or charges, payment address,     amongst other requirements."
} */
