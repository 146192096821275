import { FC } from "react";
import { Row, Typography, Col } from "antd";
import "../css/infBox.css";
import { InfoCircleFilled } from "@ant-design/icons";

interface infoProps {
  text: string;
  ContainerClass?: string;
}

const ErrorBox: FC<infoProps> = (props) => {
  return (
    <Row className={`info-row ${props.ContainerClass}`} justify={"center"}>
      <div className="error-container">
        <Row justify="center" className="info-items-row">
          <Col span={1}>
            <InfoCircleFilled rev="" style={{ color: "#d91337", marginLeft: "10px" }} />
          </Col>
          <Col span={23}>
            <Typography.Paragraph className="error-text">{props.text}</Typography.Paragraph>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

export default ErrorBox;
