import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState, promissoryRemoveDataAction, promissorySetEditModeAction } from "../store";

const usePromissoryNote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const promissory = useSelector((state: RootState) => state.promissoryReducer);

  const onClickNewNote = () => {
    if (promissory) {
      if (promissory.editMode) {
        dispatch(promissoryRemoveDataAction());
        dispatch(promissorySetEditModeAction(false));
        navigate("/amount");
      } else {
        const generatePromissoryNoteLink =
          promissory.route && promissory.route !== "" ? promissory.route : "/amount";
        navigate(generatePromissoryNoteLink);
      }
    } else {
      navigate("/amount");
    }
  };

  return { onClickNewNote };
};

export default usePromissoryNote;
