export const BASE_URL = "https://api-dev.loanthem.com";
export const BASE_AUTH = "Basic bG9hbnRoZW06c2VjcmV0";
export const LOGIN_LOCAL_KEY = "application_auth";
export const CART_LOCAL_KEY = "odin_cart";
export const CONSUME_LOCAL_KEY = "odin_consume";
export const AWS_REGION = "us-west-1";
export const AWS_SIGNATURE_VERSION = "V4";
export const AWS_BUCKET = "od-technologies";
export const PROMISSORY_NOTE_KEY = "promissory_local";
export const PROMISSORY_ROUTE_KEY = "promissory_route_key";
export const SINAN_API_URL = "https://service.sinanserver.net";
