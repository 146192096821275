import { useEffect } from "react";

import { UseUser } from "../../../hooks";

const useNotification = (onReadNotifications: () => void) => {
  const { readAllNotification } = UseUser.useNotifications();

  const readAll = async () => {
    await readAllNotification();
    onReadNotifications();
  };

  useEffect(() => {
    setTimeout(() => {
      readAll();
    }, 5000);
  }, []);
};

export default useNotification;
