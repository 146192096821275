// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store/reducers";
import {
  handleHideLoginRegisterModal,
  handleShowLoginRegisterModal,
} from "../../store/actions/siteActions/siteAction";
import { loginAction } from "src/store";
import { userRestApi } from "src/services";
import { emailValidation } from "src/utils/validation";

export const useLoginRegisterModal = (
  onResendDone: (username: string, password: string) => void,
) => {
  const dispatch = useDispatch();
  const { login: loginState } = useSelector((store: RootState) => ({
    login: store.authReducer.login,
  }));

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState("");
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [registerError, setRegisterError] = useState("");
  const [loginError, setLoginError] = useState("");

  const onClickCancel = () => {
    dispatch(handleHideLoginRegisterModal());
  };

  const login = () => {
    let error = false;
    setUsernameError("");
    setPasswordError("");
    setLoginError("");

    if (!username) {
      error = true;
      setUsernameError("Email or Username Required");
    } else if (username.length < 6 || username.length > 24) {
      error = true;
      setUsernameError("Email or Username should be between 6 and 24 character");
    }

    if (!password) {
      error = true;
      setPasswordError("Password Required");
    }

    if (error) {
      return;
    }

    dispatch(loginAction(username, password, true));
  };

  const register = async (onRegisterDone: (username: string, password: string) => void) => {
    let error = false;
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneNumberError("");
    setUsernameError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setAcceptTermsError("");
    setRegisterError("");

    if (!firstName) {
      error = true;
      setFirstNameError("FirstName Required");
    }

    if (!lastName) {
      error = true;
      setLastNameError("LastName Required");
    }

    if (!email) {
      error = true;
      setEmailError("Email Required");
    } else if (!emailValidation(email)) {
      error = true;
      setEmailError("Email is not valid");
    }

    if (!username) {
      error = true;
      setUsernameError("Username Required");
    } else if (username.length < 6 || username.length > 24) {
      error = true;
      setUsernameError("Username should be between 6 and 24 character");
    }

    if (!phoneNumber) {
      error = true;
      setPhoneNumberError("LastName Required");
    } else if (phoneNumber.length < 10 || phoneNumber.length > 12) {
      error = true;
      setPhoneNumberError("Phone Number should be between 10 and 12 numbers");
    }

    if (!password) {
      error = true;
      setPasswordError("Password Required");
    } else if (password.length < 8) {
      error = true;
      setPasswordError("Password must have a minimum length of 8");
    }

    if (!confirmPassword) {
      error = true;
      setConfirmPasswordError("Confirm Password Required");
    } else if (password !== confirmPassword) {
      error = true;
      setConfirmPasswordError("The password is not the same as the password confirmation");
    }

    if (!acceptTerms) {
      error = true;
      setAcceptTermsError("Please accept Loanthem’s Terms of Service and Privacy Policy");
    }

    if (error) {
      return;
    }

    setLoading(true);
    const params = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      username: username,
      password: password,
      mobile_number: phoneNumber,
    };

    try {
      const response = await userRestApi.add(params);
      if (response.status === 200) {
        onRegisterDone(username, password);
        openVerifyModal();
      }
    } catch (error) {
      try {
        if (error.response.data.code === 2004) {
          resendOtp(email, password);
        } else if (error.response.data.code === 1004) {
          setUsernameError(error.response.data.message);
        } else {
          setRegisterError(error.response.data.message);
        }
      } catch (error) {
        console.error("try to alert error message error: ", error);
      }
    }

    setLoading(false);
  };

  const verify = async (username: string, password: string) => {
    let error = false;
    setCodeError("");

    if (!code) {
      setCodeError("Code is Required");
      error = true;
    }

    if (error) {
      return;
    }

    setLoading(true);
    const params = {
      verification_code: code,
      username: username,
    };

    try {
      const response = await userRestApi.verify(params);
      if (response.status === 200) {
        dispatch(loginAction(username, password, true));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const resendOtp = async (username: string, password: string, openModal = true) => {
    setLoading(true);
    try {
      const response = await userRestApi.resendOTP(username);
      if (response.status === 200) {
        onResendDone(username, password);
        openModal && openVerifyModal();
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const openRegisterModal = () => {
    setRegisterError("");
    dispatch(handleShowLoginRegisterModal("REGISTER"));
  };

  const openLoginModal = () => {
    setLoginError("");
    dispatch(handleShowLoginRegisterModal("LOGIN"));
  };

  const openVerifyModal = () => {
    dispatch(handleShowLoginRegisterModal("VERIFY"));
  };

  useEffect(() => {
    if (loginState.status === "loading") {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (loginState.status === "success") {
      if (loading) {
        onClickCancel();
      }
    }

    if (loginState.status === "error") {
      if (loginState.error && loginState.error.code === "1007") {
        if (username && password) {
          resendOtp(username, password, false);
        }
      }

      if (loginState.error && loginState.error.code === "0") {
        setLoginError("The email address or password is incorrect!");
      }
    }
  }, [loginState]);

  useEffect(() => {
    setLoginError("");
    setRegisterError("");
  }, []);

  return {
    openLoginModal,
    openRegisterModal,
    openVerifyModal,
    onClickCancel,
    dispatch,
    login,
    register,
    verify,
    loading,
    username,
    setUsername,
    usernameError,
    setUsernameError,
    password,
    setPassword,
    passwordError,
    setPasswordError,
    firstName,
    setFirstName,
    firstNameError,
    setFirstNameError,
    lastName,
    setLastName,
    lastNameError,
    setLastNameError,
    email,
    setEmail,
    emailError,
    setEmailError,
    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    setPhoneNumberError,
    confirmPassword,
    setConfirmPassword,
    confirmPasswordError,
    setConfirmPasswordError,
    code,
    setCode,
    codeError,
    acceptTerms,
    setAcceptTerms,
    acceptTermsError,
    registerError,
    loginError,
    resendOtp,
  };
};

export default useLoginRegisterModal;
