import React from "react";
import { Button, Spin } from "antd";

import { GeneralDraft, TopNavController } from "src/common/components";
import useLoanRelease from "./useLoanRelease";

import { ReactComponent as IconDownload } from "../../assets/icons/ic-download.svg";
import { ReactComponent as IconShare } from "../../assets/icons/ic-share.svg";

const LoanRelease = () => {
  const { downloadPdf, onClickPrev, previewUrl } = useLoanRelease();

  return (
    <div className="loan-release-page">
      <TopNavController
        showCancel={false}
        onClickPrev={() => {
          onClickPrev();
        }}
      />
      <div className="loan-release-page__preview">
        <GeneralDraft
          headerTitle={"Loan Release"}
          content={{
            __html: "Loan Release",
          }}>
          {previewUrl ? (
            <iframe
              src={`${previewUrl}#toolbar=0`}
              width="100%"
              height="600px"
              title="Loan Release"
            />
          ) : (
            <div className="loan-release-page__preview__spin">
              <Spin />
            </div>
          )}
        </GeneralDraft>
        <div className="loan-release-page__preview__buttons">
          <Button
            className="styled-button text-blue border-blue background-white"
            type="primary"
            onClick={downloadPdf}
            icon={<IconDownload />}>
            Download
          </Button>
          <Button
            className="styled-button text-blue border-blue background-white styled-button-hover-white"
            type="primary"
            icon={<IconShare />}>
            Share
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoanRelease;
