import React, { FC } from "react";
import { Row, Form, Typography, Button, Col } from "antd";
import Password from "antd/es/input/Password";
import { userRestApi } from "src/services/http";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword: FC = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const email = state.email;

  return (
    <Row justify={"center"}>
      <Row>
        <Form
          className="resetPassword-form"
          onFinish={async (values) => {
            if (values != null) {
              try {
                await userRestApi.resetPassword({ email, password: values.newPassword });
                navigate("/login");
              } catch (error: any) {
                if (error?.response?.data?.message) {
                  if (error.response.status === 400) {
                    console.log(error.response);
                  } else {
                    console.log(error.response);
                  }
                } else {
                  console.log(error.response);
                }
              }
            }
          }}>
          <a
            href="/login"
            style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <img src={require("src/assets/images/Shape-close.png")} alt="" />
          </a>
          <div className="reset-password-title">New Password</div>
          <div className="resetPsw-header-text">
            Create a new password with at least 8 characters
          </div>
          <Form.Item
            className="resetPassword-form-input"
            label="Password"
            rules={[
              { required: true, message: "please enter your password" },
              { min: 8, message: "Password must have a minimum length of 8" },
            ]}
            required
            name="newPassword">
            <Password placeholder="Password" size={"large"} />
          </Form.Item>
          <Form.Item
            className="resetPassword-form-input"
            label="Retype Password"
            rules={[
              { required: true, message: "please enter your Retype password" },
              { min: 8, message: "Retype Password must have a minimum length of 8" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords that you entered do not match!");
                },
              }),
            ]}
            required
            name="retypePassword">
            <Password placeholder="Retype Password" size={"large"} />
          </Form.Item>
          <Button className="resetPassword-btn" htmlType={"submit"} type="primary" block>
            Reset Password
          </Button>
        </Form>
      </Row>
    </Row>
  );
};
export default ResetPassword;
