import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";

import { LOGIN_LOCAL_KEY, PROMISSORY_NOTE_KEY, PROMISSORY_ROUTE_KEY } from "src/ENV";
import {
  RootState,
  authSetDataAction,
  authSetUser,
  promissoryRemoveDataAction,
  promissorySetRouteAction,
} from "src/store";

export interface CancelNoteGenerationProps {
  open: boolean;
  onClose: () => void;
}

const CancelNoteGeneration = ({ open, onClose }: CancelNoteGenerationProps) => {
  const { status: loginStatus } = useSelector((state: RootState) => state.authReducer.login);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const prevents = ["/verify"];
    if (!prevents.includes(location.pathname)) {
      dispatch(promissorySetRouteAction(location.pathname));
    }
  }, [location]);

  const justExit = (relocate: boolean) => {
    if (relocate) {
      if (loginStatus === "success") {
        navigate("/home", {
          replace: true,
        });
      } else {
        navigate("/", {
          replace: true,
        });
      }
    }
    onClose();
  };

  const deleteDataAndExit = (relocate: boolean) => {
    localStorage.removeItem(PROMISSORY_NOTE_KEY);
    localStorage.removeItem(PROMISSORY_ROUTE_KEY);
    dispatch(promissoryRemoveDataAction());
    dispatch(promissorySetRouteAction(""));
    justExit(relocate);
  };

  const logoutAndExit = () => {
    localStorage.removeItem(LOGIN_LOCAL_KEY);
    dispatch(authSetDataAction("login", null, "error"));
    dispatch(authSetUser());
    deleteDataAndExit(false);
    navigate("/", {
      replace: true,
    });
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <div className="cancel-note-generation">
        <div className="cancel-note-generation__header">Cancel Note Generation</div>
        <div className="cancel-note-generation__note">
          By exiting from note generation flow, we will store the data so that you can start from
          where you left off.
        </div>
        <div className="cancel-note-generation__button-wrapper">
          <Button type="primary" onClick={() => justExit(true)}>
            Ok, Exit
          </Button>
          <Button type="text" onClick={() => deleteDataAndExit(true)}>
            Delete Data & Exit
          </Button>
          {loginStatus === "success" && (
            <Button type="text" onClick={logoutAndExit}>
              Logout & Exit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CancelNoteGeneration;
