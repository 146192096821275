import React from "react";

export interface LoanInfoLineProps {
  value: string;
  title: string;
}

const LoanInfoLine = ({ value, title }: LoanInfoLineProps) => {
  return (
    <div className="loan-info-line">
      <div className="loan-info-line__title">{title}</div>
      <div className="loan-info-line__divider" />
      <div className="loan-info-line__amount">{value}</div>
    </div>
  );
};

export default LoanInfoLine;
