import { FC, useState } from "react";
import { Row, Form, Button, Input, Typography, Space, DatePicker } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import Previous from "src/common/components/Previous";
import { useLocation, useNavigate } from "react-router-dom";
const ThirdPartyPage: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const previousURL = state ? state.previousURL : null;

  const [disable, setDisable] = useState<any>(
    note.third_party == true || note.third_party == false ? !note.third_party : null,
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handleSubmit = (values: any) => {
    const fields: any = {
      third_party: values.third_party,
    };
    dispatch(promissorySetDataAction(fields));
    navigate("/note-name");
  };
  const initialVal = {
    third_party: note.third_party == true || note.third_party == false ? note.third_party : null,
  };
  return (
    <Row className="note" justify={"center"}>
      <Previous location={previousURL ? "/co-signer-info" : "/co-signer"} />
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Third-Party Financing
      </Typography.Title>
      <Form
        initialValues={initialVal}
        form={form}
        className="note-form-box"
        onFinish={(val) => handleSubmit(val)}>
        <Typography.Title
          className="note-title"
          style={{ color: "white", fontSize: 14, fontWeight: 400, paddingBottom: 10 }}>
          Are you interested in third-party financing? <span style={{ color: "red" }}>*</span>
        </Typography.Title>
        <Form.Item
          name="third_party"
          rules={[{ required: true, message: "Please select one of the items" }]}
          style={{ marginTop: "50px" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Button
              className={`note-btn-yes ${disable == true ? "note-btn-enable" : ""}`}
              onClick={() => {
                setDisable(false);

                form.setFieldsValue({ third_party: true });
              }}>
              Yes
            </Button>
            <Button
              className={`note-btn-no ${disable == false ? "note-btn-enable" : ""}`}
              onClick={() => {
                setDisable(true);
                form.setFieldsValue({ third_party: false });
              }}>
              No
            </Button>
          </div>
        </Form.Item>
        <div style={{ height: "50px" }} />
        <div className="note-form-line"></div>
        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
    </Row>
  );
};

export default ThirdPartyPage;
