import React from "react";
import { Radio } from "antd";

export interface FormRadioProps {
  title: string;
  selected: boolean;
  onClick: (title: string) => void;
}

const FormRadio = ({ selected, title, onClick }: FormRadioProps) => {
  return (
    <div className="form-radio" onClick={() => onClick(title)}>
      <Radio className="form-radio__styled-radio" checked={selected} />
      <span>{title}</span>
    </div>
  );
};

export default FormRadio;
