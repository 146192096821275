import { Avatar, Popover } from "antd";
import { useSelector } from "react-redux";

import { RootState } from "../../../store";
import { getRandomColor } from "src/utils/ColorUtil";
import ProfileMenu from "./ProfileMenu";
import { useState } from "react";

const ProfileIcon = () => {
  const { user } = useSelector((store: RootState) => store.authReducer);
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  if (!user) {
    return <></>;
  }

  const abbreviation = `${String(user.first_name?.substring(0, 1))} ${user.last_name.substring(
    0,
    1,
  )}`;

  const color = getRandomColor(`${user.first_name} ${user.last_name}`);

  const handlePopoverVisibleChange = (visible: any) => {
    setPopoverVisible(visible);
  };
  return (
    <Popover
      visible={isPopoverVisible}
      onVisibleChange={handlePopoverVisibleChange}
      placement="topLeft"
      title={""}
      content={<ProfileMenu openModal={isPopoverVisible} setOpenModal={setPopoverVisible} />}
      trigger="hover">
      <div className="profile-icon__button">
        <Avatar
          size={32}
          style={{
            backgroundColor: color,
          }}
          src={user.profile_photo?.file_path}>
          {abbreviation}
        </Avatar>
        <span>{`${user.first_name} ${user.last_name}`}</span>
      </div>
    </Popover>
  );
};

export default ProfileIcon;
