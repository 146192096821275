/* tslint:disable */
/* eslint-disable */
/**
 * LoanThem API Definitions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AmortizationScheduleResponseDTO
 */
export interface AmortizationScheduleResponseDTO {
    /**
     *
     * @type {number}
     * @memberof AmortizationScheduleResponseDTO
     */
    'monthly_Amount'?: number;
    /**
     *
     * @type {number}
     * @memberof AmortizationScheduleResponseDTO
     */
    'starting_balance'?: number;
    /**
     *
     * @type {number}
     * @memberof AmortizationScheduleResponseDTO
     */
    'closing_balance'?: number;
    /**
     *
     * @type {number}
     * @memberof AmortizationScheduleResponseDTO
     */
    'scheduled_payment'?: number;
    /**
     *
     * @type {number}
     * @memberof AmortizationScheduleResponseDTO
     */
    'principal'?: number;
    /**
     *
     * @type {number}
     * @memberof AmortizationScheduleResponseDTO
     */
    'interest'?: number;
    /**
     *
     * @type {string}
     * @memberof AmortizationScheduleResponseDTO
     */
    'date'?: string;
    /**
     *
     * @type {string}
     * @memberof AmortizationScheduleResponseDTO
     */
    'formatted_date'?: string;
    /**
     *
     * @type {string}
     * @memberof AmortizationScheduleResponseDTO
     */
    'status'?: string;
}
/**
 *
 * @export
 * @interface AppReviewDTO
 */
export interface AppReviewDTO {
    /**
     *
     * @type {number}
     * @memberof AppReviewDTO
     */
    'ease_of_use'?: number;
    /**
     *
     * @type {number}
     * @memberof AppReviewDTO
     */
    'scope_of_functionality'?: number;
    /**
     *
     * @type {number}
     * @memberof AppReviewDTO
     */
    'speed'?: number;
    /**
     *
     * @type {number}
     * @memberof AppReviewDTO
     */
    'expense'?: number;
    /**
     *
     * @type {number}
     * @memberof AppReviewDTO
     */
    'style'?: number;
    /**
     *
     * @type {number}
     * @memberof AppReviewDTO
     */
    'overall_value'?: number;
    /**
     *
     * @type {string}
     * @memberof AppReviewDTO
     */
    'description'?: string;
}
/**
 *
 * @export
 * @interface BaseDocumentRequestDTO
 */
export interface BaseDocumentRequestDTO {
    /**
     *
     * @type {string}
     * @memberof BaseDocumentRequestDTO
     */
    'file_name'?: string;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentRequestDTO
     */
    'original_file_name'?: string;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentRequestDTO
     */
    'document_type'?: BaseDocumentRequestDTODocumentTypeEnum;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentRequestDTO
     */
    'file_path'?: string;
    /**
     *
     * @type {number}
     * @memberof BaseDocumentRequestDTO
     */
    'file_size': number;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentRequestDTO
     */
    'file_type'?: BaseDocumentRequestDTOFileTypeEnum;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentRequestDTO
     */
    'description'?: string;
}

export const BaseDocumentRequestDTODocumentTypeEnum = {
    ProfilePhoto: 'PROFILE_PHOTO'
} as const;

export type BaseDocumentRequestDTODocumentTypeEnum = typeof BaseDocumentRequestDTODocumentTypeEnum[keyof typeof BaseDocumentRequestDTODocumentTypeEnum];
export const BaseDocumentRequestDTOFileTypeEnum = {
    Jpeg: 'JPEG',
    Png: 'PNG',
    Wav: 'WAV',
    AudioMpeg: 'AUDIO_MPEG',
    Mp4: 'MP4',
    Mp3: 'MP3'
} as const;

export type BaseDocumentRequestDTOFileTypeEnum = typeof BaseDocumentRequestDTOFileTypeEnum[keyof typeof BaseDocumentRequestDTOFileTypeEnum];

/**
 *
 * @export
 * @interface BaseDocumentResponseDTO
 */
export interface BaseDocumentResponseDTO {
    /**
     *
     * @type {string}
     * @memberof BaseDocumentResponseDTO
     */
    'file_name'?: string;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentResponseDTO
     */
    'original_file_name'?: string;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentResponseDTO
     */
    'document_type'?: BaseDocumentResponseDTODocumentTypeEnum;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentResponseDTO
     */
    'file_path'?: string;
    /**
     *
     * @type {number}
     * @memberof BaseDocumentResponseDTO
     */
    'file_size': number;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentResponseDTO
     */
    'file_type'?: BaseDocumentResponseDTOFileTypeEnum;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentResponseDTO
     */
    'description'?: string;
    /**
     *
     * @type {boolean}
     * @memberof BaseDocumentResponseDTO
     */
    'confirmed'?: boolean;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentResponseDTO
     */
    'confirm_by'?: string;
    /**
     *
     * @type {string}
     * @memberof BaseDocumentResponseDTO
     */
    'confirm_date'?: string;
}

export const BaseDocumentResponseDTODocumentTypeEnum = {
    ProfilePhoto: 'PROFILE_PHOTO'
} as const;

export type BaseDocumentResponseDTODocumentTypeEnum = typeof BaseDocumentResponseDTODocumentTypeEnum[keyof typeof BaseDocumentResponseDTODocumentTypeEnum];
export const BaseDocumentResponseDTOFileTypeEnum = {
    Jpeg: 'JPEG',
    Png: 'PNG',
    Wav: 'WAV',
    AudioMpeg: 'AUDIO_MPEG',
    Mp4: 'MP4',
    Mp3: 'MP3'
} as const;

export type BaseDocumentResponseDTOFileTypeEnum = typeof BaseDocumentResponseDTOFileTypeEnum[keyof typeof BaseDocumentResponseDTOFileTypeEnum];

/**
 *
 * @export
 * @interface CredentialResponseDTO
 */
export interface CredentialResponseDTO {
    /**
     *
     * @type {string}
     * @memberof CredentialResponseDTO
     */
    'access_key_id'?: string;
    /**
     *
     * @type {string}
     * @memberof CredentialResponseDTO
     */
    'secret_access_key'?: string;
    /**
     *
     * @type {number}
     * @memberof CredentialResponseDTO
     */
    'expiration_date_in_milliseconds'?: number;
    /**
     *
     * @type {string}
     * @memberof CredentialResponseDTO
     */
    'session_token'?: string;
    /**
     *
     * @type {string}
     * @memberof CredentialResponseDTO
     */
    'folder'?: string;
}
/**
 *
 * @export
 * @interface CreditSideDTO
 */
export interface CreditSideDTO {
    /**
     *
     * @type {boolean}
     * @memberof CreditSideDTO
     */
    'is_primary'?: boolean;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'credit_side_type'?: CreditSideDTOCreditSideTypeEnum;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'first_name'?: string;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'middle_name'?: string;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'last_name'?: string;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'business_name'?: string;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'official_title'?: string;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'city'?: string;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'state'?: string;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'zip_code'?: string;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'full_address'?: string;
    /**
     *
     * @type {string}
     * @memberof CreditSideDTO
     */
    'email'?: string;
}

export const CreditSideDTOCreditSideTypeEnum = {
    Individual: 'INDIVIDUAL',
    Corporation: 'CORPORATION'
} as const;

export type CreditSideDTOCreditSideTypeEnum = typeof CreditSideDTOCreditSideTypeEnum[keyof typeof CreditSideDTOCreditSideTypeEnum];

/**
 *
 * @export
 * @interface Document
 */
export interface Document {
    /**
     *
     * @type {string}
     * @memberof Document
     */
    'fileName'?: string;
    /**
     *
     * @type {string}
     * @memberof Document
     */
    'originalFileName'?: string;
    /**
     *
     * @type {string}
     * @memberof Document
     */
    'documentType'?: DocumentDocumentTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Document
     */
    'filePath'?: string;
    /**
     *
     * @type {number}
     * @memberof Document
     */
    'fileSize'?: number;
    /**
     *
     * @type {string}
     * @memberof Document
     */
    'fileType'?: DocumentFileTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Document
     */
    'fileDescription'?: string;
    /**
     *
     * @type {boolean}
     * @memberof Document
     */
    'confirmed'?: boolean;
    /**
     *
     * @type {string}
     * @memberof Document
     */
    'confirmBy'?: string;
    /**
     *
     * @type {string}
     * @memberof Document
     */
    'confirmDate'?: string;
}

export const DocumentDocumentTypeEnum = {
    ProfilePhoto: 'PROFILE_PHOTO'
} as const;

export type DocumentDocumentTypeEnum = typeof DocumentDocumentTypeEnum[keyof typeof DocumentDocumentTypeEnum];
export const DocumentFileTypeEnum = {
    Jpeg: 'JPEG',
    Png: 'PNG',
    Wav: 'WAV',
    AudioMpeg: 'AUDIO_MPEG',
    Mp4: 'MP4',
    Mp3: 'MP3'
} as const;

export type DocumentFileTypeEnum = typeof DocumentFileTypeEnum[keyof typeof DocumentFileTypeEnum];

/**
 *
 * @export
 * @interface EditUserRequestDTO
 */
export interface EditUserRequestDTO {
    /**
     *
     * @type {string}
     * @memberof EditUserRequestDTO
     */
    'first_name': string;
    /**
     *
     * @type {string}
     * @memberof EditUserRequestDTO
     */
    'last_name': string;
    /**
     *
     * @type {string}
     * @memberof EditUserRequestDTO
     */
    'city'?: string;
    /**
     *
     * @type {string}
     * @memberof EditUserRequestDTO
     */
    'state'?: string;
    /**
     *
     * @type {string}
     * @memberof EditUserRequestDTO
     */
    'zip_code'?: string;
    /**
     *
     * @type {string}
     * @memberof EditUserRequestDTO
     */
    'full_address'?: string;
    /**
     *
     * @type {BaseDocumentRequestDTO}
     * @memberof EditUserRequestDTO
     */
    'profile_picture'?: BaseDocumentRequestDTO;
}
/**
 *
 * @export
 * @interface FullPromissoryNoteDTORequest
 */
export interface FullPromissoryNoteDTORequest {
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'name'?: string;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'rememberAdress'?: string;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'amount'?: number;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'chargable'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'has_interest'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'interest'?: number;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'compound_period'?: number;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'agreement_begin'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'first_payment_due'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'last_payment_due'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'payment_period'?: FullPromissoryNoteDTORequestPaymentPeriodEnum;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'has_lump_sum_payment'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'lump_sum_payment'?: number;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'lump_sum_payment_due'?: string;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'has_late_fee'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'late_fee_days'?: number;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'late_fee_percent'?: number;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'late_fee_amount'?: number;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'has_penalty'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'payment_penalty_percent'?: number;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'has_interest_rate'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'interest_increase_rate_percent'?: number;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'interest_increase_rate_days'?: number;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'has_full_payment'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTORequest
     */
    'immediate_full_payment_days'?: number;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'loan_pay_ability_type'?: FullPromissoryNoteDTORequestLoanPayAbilityTypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'collateral'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'collateral_description'?: string;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'personal_property_collateral'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'transfer_type'?: FullPromissoryNoteDTORequestTransferTypeEnum;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'lenders_resided_state'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'lender_promissory_note_resided_state'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'lender_promissory_note_resided_city'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'note_enforce_venue'?: FullPromissoryNoteDTORequestNoteEnforceVenueEnum;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'witness_type'?: FullPromissoryNoteDTORequestWitnessTypeEnum;
    /**
     *
     * @type {Set<CreditSideDTO>}
     * @memberof FullPromissoryNoteDTORequest
     */
    'witness_infos'?: Set<CreditSideDTO>;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'sign_party'?: FullPromissoryNoteDTORequestSignPartyEnum;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'has_extra_claues'?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof FullPromissoryNoteDTORequest
     */
    'extra_clause'?: Array<string>;
    /**
     *
     * @type {Set<CreditSideDTO>}
     * @memberof FullPromissoryNoteDTORequest
     */
    'lenders'?: Set<CreditSideDTO>;
    /**
     *
     * @type {Set<CreditSideDTO>}
     * @memberof FullPromissoryNoteDTORequest
     */
    'borrowers'?: Set<CreditSideDTO>;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'has_co_signer'?: boolean;
    /**
     *
     * @type {Set<CreditSideDTO>}
     * @memberof FullPromissoryNoteDTORequest
     */
    'co_signers'?: any;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTORequest
     */
    'third_party'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTORequest
     */
    'installment_payment_method'?: FullPromissoryNoteDTORequestInstallmentPaymentMethodEnum;
}

export const FullPromissoryNoteDTORequestPaymentPeriodEnum = {
    Once: 'ONCE',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    BiAnnually: 'BI_ANNUALLY',
    Yearly: 'YEARLY'
} as const;

export type FullPromissoryNoteDTORequestPaymentPeriodEnum = typeof FullPromissoryNoteDTORequestPaymentPeriodEnum[keyof typeof FullPromissoryNoteDTORequestPaymentPeriodEnum];
export const FullPromissoryNoteDTORequestLoanPayAbilityTypeEnum = {
    OnlyAsScheduled: 'ONLY_AS_SCHEDULED',
    Immediately: 'IMMEDIATELY'
} as const;

export type FullPromissoryNoteDTORequestLoanPayAbilityTypeEnum = typeof FullPromissoryNoteDTORequestLoanPayAbilityTypeEnum[keyof typeof FullPromissoryNoteDTORequestLoanPayAbilityTypeEnum];
export const FullPromissoryNoteDTORequestTransferTypeEnum = {
    Signing: 'SIGNING',
    FullPayment: 'FULL_PAYMENT'
} as const;

export type FullPromissoryNoteDTORequestTransferTypeEnum = typeof FullPromissoryNoteDTORequestTransferTypeEnum[keyof typeof FullPromissoryNoteDTORequestTransferTypeEnum];
export const FullPromissoryNoteDTORequestNoteEnforceVenueEnum = {
    CourtOfLaw: 'COURT_OF_LAW',
    Arbitration: 'ARBITRATION'
} as const;

export type FullPromissoryNoteDTORequestNoteEnforceVenueEnum = typeof FullPromissoryNoteDTORequestNoteEnforceVenueEnum[keyof typeof FullPromissoryNoteDTORequestNoteEnforceVenueEnum];
export const FullPromissoryNoteDTORequestWitnessTypeEnum = {
    Witness: 'WITNESS',
    Notary: 'NOTARY',
    NoWitness: 'NO_WITNESS'
} as const;

export type FullPromissoryNoteDTORequestWitnessTypeEnum = typeof FullPromissoryNoteDTORequestWitnessTypeEnum[keyof typeof FullPromissoryNoteDTORequestWitnessTypeEnum];
export const FullPromissoryNoteDTORequestSignPartyEnum = {
    Digitally: 'DIGITALLY',
    Hand: 'HAND'
} as const;

export type FullPromissoryNoteDTORequestSignPartyEnum = typeof FullPromissoryNoteDTORequestSignPartyEnum[keyof typeof FullPromissoryNoteDTORequestSignPartyEnum];
export const FullPromissoryNoteDTORequestInstallmentPaymentMethodEnum = {
    Cash: 'CASH',
    Check: 'CHECK',
    BankWireTransfer: 'BANK_WIRE_TRANSFER',
    AchTransfer: 'ACH_TRANSFER',
    DigitalPaymentServices: 'DIGITAL_PAYMENT_SERVICES',
    CreditDebit: 'CREDIT_DEBIT'
} as const;

export type FullPromissoryNoteDTORequestInstallmentPaymentMethodEnum = typeof FullPromissoryNoteDTORequestInstallmentPaymentMethodEnum[keyof typeof FullPromissoryNoteDTORequestInstallmentPaymentMethodEnum];

/**
 *
 * @export
 * @interface FullPromissoryNoteDTOResponse
 */
export interface FullPromissoryNoteDTOResponse {
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'id'?: number;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'note_paid_amount'?: number;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'name'?: string;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'amount'?: number;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'chargable'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'has_interest'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'interest'?: number;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'compound_period'?: number;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'agreement_begin'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'first_payment_due'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'last_payment_due'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'payment_period'?: FullPromissoryNoteDTOResponsePaymentPeriodEnum;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'has_lump_sum_payment'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'lump_sum_payment'?: number;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'lump_sum_payment_due'?: string;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'has_late_fee'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'late_fee_days'?: number;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'late_fee_percent'?: number;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'late_fee_amount'?: number;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'has_penalty'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'payment_penalty_percent'?: number;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'has_interest_rate'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'interest_increase_rate_percent'?: number;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'interest_increase_rate_days'?: number;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'has_full_payment'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'immediate_full_payment_days'?: number;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'loan_pay_ability_type'?: FullPromissoryNoteDTOResponseLoanPayAbilityTypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'collateral'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'collateral_description'?: string;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'personal_property_collateral'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'transfer_type'?: FullPromissoryNoteDTOResponseTransferTypeEnum;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'lenders_resided_state'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'lender_promissory_note_resided_state'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'lender_promissory_note_resided_city'?: string;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'note_enforce_venue'?: FullPromissoryNoteDTOResponseNoteEnforceVenueEnum;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'witness_type'?: FullPromissoryNoteDTOResponseWitnessTypeEnum;
    /**
     *
     * @type {Set<CreditSideDTO>}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'witness_infos'?: Set<CreditSideDTO>;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'sign_party'?: FullPromissoryNoteDTOResponseSignPartyEnum;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'has_extra_claues'?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'extra_clause'?: Array<string>;
    /**
     *
     * @type {Set<CreditSideDTO>}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'lenders'?: Set<CreditSideDTO>;
    /**
     *
     * @type {Set<CreditSideDTO>}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'borrowers'?: Set<CreditSideDTO>;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'has_co_signer'?: boolean;
    /**
     *
     * @type {Set<CreditSideDTO>}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'co_signers'?: Set<CreditSideDTO>;
    /**
     *
     * @type {boolean}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'third_party'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'installment_payment_method'?: FullPromissoryNoteDTOResponseInstallmentPaymentMethodEnum;
    /**
     *
     * @type {string}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'status'?: FullPromissoryNoteDTOResponseStatusEnum;
    /**
     *
     * @type {Array<AmortizationScheduleResponseDTO>}
     * @memberof FullPromissoryNoteDTOResponse
     */
    'amortization_schedules'?: Array<AmortizationScheduleResponseDTO>;
}

export const FullPromissoryNoteDTOResponsePaymentPeriodEnum = {
    Once: 'ONCE',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    BiAnnually: 'BI_ANNUALLY',
    Yearly: 'YEARLY'
} as const;

export type FullPromissoryNoteDTOResponsePaymentPeriodEnum = typeof FullPromissoryNoteDTOResponsePaymentPeriodEnum[keyof typeof FullPromissoryNoteDTOResponsePaymentPeriodEnum];
export const FullPromissoryNoteDTOResponseLoanPayAbilityTypeEnum = {
    OnlyAsScheduled: 'ONLY_AS_SCHEDULED',
    Immediately: 'IMMEDIATELY'
} as const;

export type FullPromissoryNoteDTOResponseLoanPayAbilityTypeEnum = typeof FullPromissoryNoteDTOResponseLoanPayAbilityTypeEnum[keyof typeof FullPromissoryNoteDTOResponseLoanPayAbilityTypeEnum];
export const FullPromissoryNoteDTOResponseTransferTypeEnum = {
    Signing: 'SIGNING',
    FullPayment: 'FULL_PAYMENT'
} as const;

export type FullPromissoryNoteDTOResponseTransferTypeEnum = typeof FullPromissoryNoteDTOResponseTransferTypeEnum[keyof typeof FullPromissoryNoteDTOResponseTransferTypeEnum];
export const FullPromissoryNoteDTOResponseNoteEnforceVenueEnum = {
    CourtOfLaw: 'COURT_OF_LAW',
    Arbitration: 'ARBITRATION'
} as const;

export type FullPromissoryNoteDTOResponseNoteEnforceVenueEnum = typeof FullPromissoryNoteDTOResponseNoteEnforceVenueEnum[keyof typeof FullPromissoryNoteDTOResponseNoteEnforceVenueEnum];
export const FullPromissoryNoteDTOResponseWitnessTypeEnum = {
    Witness: 'WITNESS',
    Notary: 'NOTARY',
    NoWitness: 'NO_WITNESS'
} as const;

export type FullPromissoryNoteDTOResponseWitnessTypeEnum = typeof FullPromissoryNoteDTOResponseWitnessTypeEnum[keyof typeof FullPromissoryNoteDTOResponseWitnessTypeEnum];
export const FullPromissoryNoteDTOResponseSignPartyEnum = {
    Digitally: 'DIGITALLY',
    Hand: 'HAND'
} as const;

export type FullPromissoryNoteDTOResponseSignPartyEnum = typeof FullPromissoryNoteDTOResponseSignPartyEnum[keyof typeof FullPromissoryNoteDTOResponseSignPartyEnum];
export const FullPromissoryNoteDTOResponseInstallmentPaymentMethodEnum = {
    Cash: 'CASH',
    Check: 'CHECK',
    BankWireTransfer: 'BANK_WIRE_TRANSFER',
    AchTransfer: 'ACH_TRANSFER',
    DigitalPaymentServices: 'DIGITAL_PAYMENT_SERVICES',
    CreditDebit: 'CREDIT_DEBIT'
} as const;

export type FullPromissoryNoteDTOResponseInstallmentPaymentMethodEnum = typeof FullPromissoryNoteDTOResponseInstallmentPaymentMethodEnum[keyof typeof FullPromissoryNoteDTOResponseInstallmentPaymentMethodEnum];
export const FullPromissoryNoteDTOResponseStatusEnum = {
    Draft: 'DRAFT',
    Paid: 'PAID',
    Cancelled: 'CANCELLED',
    Expired: 'EXPIRED',
    Complete: 'COMPLETE'
} as const;

export type FullPromissoryNoteDTOResponseStatusEnum = typeof FullPromissoryNoteDTOResponseStatusEnum[keyof typeof FullPromissoryNoteDTOResponseStatusEnum];

/**
 *
 * @export
 * @interface MyPromissoryNotePagedResponseDto
 */
export interface MyPromissoryNotePagedResponseDto {
    /**
     *
     * @type {PageMyPromissoryNoteResponseDto}
     * @memberof MyPromissoryNotePagedResponseDto
     */
    'my_notes'?: PageMyPromissoryNoteResponseDto;
    /**
     *
     * @type {number}
     * @memberof MyPromissoryNotePagedResponseDto
     */
    'total_amount'?: number;
    /**
     *
     * @type {number}
     * @memberof MyPromissoryNotePagedResponseDto
     */
    'paid_amount'?: number;
}
/**
 *
 * @export
 * @interface MyPromissoryNoteResponseDto
 */
export interface MyPromissoryNoteResponseDto {
    /**
     *
     * @type {number}
     * @memberof MyPromissoryNoteResponseDto
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof MyPromissoryNoteResponseDto
     */
    'name'?: string;
    /**
     *
     * @type {number}
     * @memberof MyPromissoryNoteResponseDto
     */
    'amount'?: number;
    /**
     *
     * @type {string}
     * @memberof MyPromissoryNoteResponseDto
     */
    'my_role'?: MyPromissoryNoteResponseDtoMyRoleEnum;
    /**
     *
     * @type {UserMinimalResponseDTO}
     * @memberof MyPromissoryNoteResponseDto
     */
    'owner'?: UserMinimalResponseDTO;
    /**
     *
     * @type {UserMinimalResponseDTO}
     * @memberof MyPromissoryNoteResponseDto
     */
    'primary_lender'?: UserMinimalResponseDTO;
    /**
     *
     * @type {UserMinimalResponseDTO}
     * @memberof MyPromissoryNoteResponseDto
     */
    'primary_borrower'?: UserMinimalResponseDTO;
}

export const MyPromissoryNoteResponseDtoMyRoleEnum = {
    Owner: 'OWNER',
    Borrower: 'BORROWER',
    Lender: 'LENDER'
} as const;

export type MyPromissoryNoteResponseDtoMyRoleEnum = typeof MyPromissoryNoteResponseDtoMyRoleEnum[keyof typeof MyPromissoryNoteResponseDtoMyRoleEnum];

/**
 *
 * @export
 * @interface NotificationResponseDTO
 */
export interface NotificationResponseDTO {
    /**
     *
     * @type {number}
     * @memberof NotificationResponseDTO
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof NotificationResponseDTO
     */
    'body'?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationResponseDTO
     */
    'status'?: NotificationResponseDTOStatusEnum;
    /**
     *
     * @type {string}
     * @memberof NotificationResponseDTO
     */
    'created_time'?: string;
    /**
     *
     * @type {BaseDocumentResponseDTO}
     * @memberof NotificationResponseDTO
     */
    'photo'?: BaseDocumentResponseDTO;
}

export const NotificationResponseDTOStatusEnum = {
    Read: 'READ',
    Unread: 'UNREAD'
} as const;

export type NotificationResponseDTOStatusEnum = typeof NotificationResponseDTOStatusEnum[keyof typeof NotificationResponseDTOStatusEnum];

/**
 *
 * @export
 * @interface PageMyPromissoryNoteResponseDto
 */
export interface PageMyPromissoryNoteResponseDto {
    /**
     *
     * @type {number}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'totalElements'?: number;
    /**
     *
     * @type {number}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'totalPages'?: number;
    /**
     *
     * @type {number}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'size'?: number;
    /**
     *
     * @type {Array<MyPromissoryNoteResponseDto>}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'content'?: Array<MyPromissoryNoteResponseDto>;
    /**
     *
     * @type {number}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'number'?: number;
    /**
     *
     * @type {Sort}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'sort'?: Sort;
    /**
     *
     * @type {boolean}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'first'?: boolean;
    /**
     *
     * @type {Pageable}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'pageable'?: Pageable;
    /**
     *
     * @type {number}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'numberOfElements'?: number;
    /**
     *
     * @type {boolean}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'last'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PageMyPromissoryNoteResponseDto
     */
    'empty'?: boolean;
}
/**
 *
 * @export
 * @interface PageNotificationResponseDTO
 */
export interface PageNotificationResponseDTO {
    /**
     *
     * @type {number}
     * @memberof PageNotificationResponseDTO
     */
    'totalElements'?: number;
    /**
     *
     * @type {number}
     * @memberof PageNotificationResponseDTO
     */
    'totalPages'?: number;
    /**
     *
     * @type {number}
     * @memberof PageNotificationResponseDTO
     */
    'size'?: number;
    /**
     *
     * @type {Array<NotificationResponseDTO>}
     * @memberof PageNotificationResponseDTO
     */
    'content'?: Array<NotificationResponseDTO>;
    /**
     *
     * @type {number}
     * @memberof PageNotificationResponseDTO
     */
    'number'?: number;
    /**
     *
     * @type {Sort}
     * @memberof PageNotificationResponseDTO
     */
    'sort'?: Sort;
    /**
     *
     * @type {boolean}
     * @memberof PageNotificationResponseDTO
     */
    'first'?: boolean;
    /**
     *
     * @type {Pageable}
     * @memberof PageNotificationResponseDTO
     */
    'pageable'?: Pageable;
    /**
     *
     * @type {number}
     * @memberof PageNotificationResponseDTO
     */
    'numberOfElements'?: number;
    /**
     *
     * @type {boolean}
     * @memberof PageNotificationResponseDTO
     */
    'last'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PageNotificationResponseDTO
     */
    'empty'?: boolean;
}
/**
 *
 * @export
 * @interface PageUserSearchResponseDTO
 */
export interface PageUserSearchResponseDTO {
    /**
     *
     * @type {number}
     * @memberof PageUserSearchResponseDTO
     */
    'totalElements'?: number;
    /**
     *
     * @type {number}
     * @memberof PageUserSearchResponseDTO
     */
    'totalPages'?: number;
    /**
     *
     * @type {number}
     * @memberof PageUserSearchResponseDTO
     */
    'size'?: number;
    /**
     *
     * @type {Array<UserSearchResponseDTO>}
     * @memberof PageUserSearchResponseDTO
     */
    'content'?: Array<UserSearchResponseDTO>;
    /**
     *
     * @type {number}
     * @memberof PageUserSearchResponseDTO
     */
    'number'?: number;
    /**
     *
     * @type {Sort}
     * @memberof PageUserSearchResponseDTO
     */
    'sort'?: Sort;
    /**
     *
     * @type {boolean}
     * @memberof PageUserSearchResponseDTO
     */
    'first'?: boolean;
    /**
     *
     * @type {Pageable}
     * @memberof PageUserSearchResponseDTO
     */
    'pageable'?: Pageable;
    /**
     *
     * @type {number}
     * @memberof PageUserSearchResponseDTO
     */
    'numberOfElements'?: number;
    /**
     *
     * @type {boolean}
     * @memberof PageUserSearchResponseDTO
     */
    'last'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PageUserSearchResponseDTO
     */
    'empty'?: boolean;
}
/**
 *
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     *
     * @type {number}
     * @memberof Pageable
     */
    'offset'?: number;
    /**
     *
     * @type {Sort}
     * @memberof Pageable
     */
    'sort'?: Sort;
    /**
     *
     * @type {number}
     * @memberof Pageable
     */
    'pageNumber'?: number;
    /**
     *
     * @type {number}
     * @memberof Pageable
     */
    'pageSize'?: number;
    /**
     *
     * @type {boolean}
     * @memberof Pageable
     */
    'paged'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Pageable
     */
    'unpaged'?: boolean;
}
/**
 *
 * @export
 * @interface PaymentHistoryListDTO
 */
export interface PaymentHistoryListDTO {
    /**
     *
     * @type {Array<PaymentHistoryResponseDTO>}
     * @memberof PaymentHistoryListDTO
     */
    'paymentHistoryList'?: Array<PaymentHistoryResponseDTO>;
}
/**
 *
 * @export
 * @interface PaymentHistoryResponseDTO
 */
export interface PaymentHistoryResponseDTO {
    /**
     *
     * @type {string}
     * @memberof PaymentHistoryResponseDTO
     */
    'lender'?: string;
    /**
     *
     * @type {number}
     * @memberof PaymentHistoryResponseDTO
     */
    'borrower_id'?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentHistoryResponseDTO
     */
    'payment_time'?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentHistoryResponseDTO
     */
    'due_time'?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentHistoryResponseDTO
     */
    'location'?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentHistoryResponseDTO
     */
    'payment_service'?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentHistoryResponseDTO
     */
    'transaction_number'?: string;
    /**
     *
     * @type {number}
     * @memberof PaymentHistoryResponseDTO
     */
    'amount'?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentHistoryResponseDTO
     */
    'payment_status'?: PaymentHistoryResponseDTOPaymentStatusEnum;
    /**
     *
     * @type {Document}
     * @memberof PaymentHistoryResponseDTO
     */
    'profile_picture'?: Document;
}

export const PaymentHistoryResponseDTOPaymentStatusEnum = {
    Created: 'CREATED',
    Failed: 'FAILED',
    Paid: 'PAID',
    InProgress: 'IN_PROGRESS',
    PaidWithUnknownIssue: 'PAID_WITH_UNKNOWN_ISSUE'
} as const;

export type PaymentHistoryResponseDTOPaymentStatusEnum = typeof PaymentHistoryResponseDTOPaymentStatusEnum[keyof typeof PaymentHistoryResponseDTOPaymentStatusEnum];

/**
 *
 * @export
 * @interface PaymentResponseDTO
 */
export interface PaymentResponseDTO {
    /**
     *
     * @type {string}
     * @memberof PaymentResponseDTO
     */
    'session_id'?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentResponseDTO
     */
    'payment_url'?: string;
    /**
     *
     * @type {Array<AmortizationScheduleResponseDTO>}
     * @memberof PaymentResponseDTO
     */
    'amortization_list'?: Array<AmortizationScheduleResponseDTO>;
}
/**
 *
 * @export
 * @interface PromissoryNoteTempDTORequest
 */
export interface PromissoryNoteTempDTORequest {
    /**
     *
     * @type {number}
     * @memberof PromissoryNoteTempDTORequest
     */
    'amount'?: number;
    /**
     *
     * @type {boolean}
     * @memberof PromissoryNoteTempDTORequest
     */
    'has_interest'?: boolean;
    /**
     *
     * @type {number}
     * @memberof PromissoryNoteTempDTORequest
     */
    'interest'?: number;
    /**
     *
     * @type {string}
     * @memberof PromissoryNoteTempDTORequest
     */
    'first_payment_due'?: string;
    /**
     *
     * @type {string}
     * @memberof PromissoryNoteTempDTORequest
     */
    'last_payment_due'?: string;
    /**
     *
     * @type {string}
     * @memberof PromissoryNoteTempDTORequest
     */
    'payment_period'?: PromissoryNoteTempDTORequestPaymentPeriodEnum;
}

export const PromissoryNoteTempDTORequestPaymentPeriodEnum = {
    Once: 'ONCE',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    BiAnnually: 'BI_ANNUALLY',
    Yearly: 'YEARLY'
} as const;

export type PromissoryNoteTempDTORequestPaymentPeriodEnum = typeof PromissoryNoteTempDTORequestPaymentPeriodEnum[keyof typeof PromissoryNoteTempDTORequestPaymentPeriodEnum];

/**
 *
 * @export
 * @interface RegisterUserRequestDTO
 */
export interface RegisterUserRequestDTO {
    /**
     *
     * @type {string}
     * @memberof RegisterUserRequestDTO
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof RegisterUserRequestDTO
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof RegisterUserRequestDTO
     */
    'password': string;
    /**
     *
     * @type {string}
     * @memberof RegisterUserRequestDTO
     */
    'first_name': string;
    /**
     *
     * @type {string}
     * @memberof RegisterUserRequestDTO
     */
    'last_name': string;
    /**
     *
     * @type {string}
     * @memberof RegisterUserRequestDTO
     */
    'mobile_number': string;
}
/**
 *
 * @export
 * @interface RegistrationPlanDTO
 */
export interface RegistrationPlanDTO {
    /**
     *
     * @type {string}
     * @memberof RegistrationPlanDTO
     */
    'registration_plan'?: RegistrationPlanDTORegistrationPlanEnum;
    /**
     *
     * @type {number}
     * @memberof RegistrationPlanDTO
     */
    'price'?: number;
}

export const RegistrationPlanDTORegistrationPlanEnum = {
    SinglePackage: 'SINGLE_PACKAGE',
    MultiplePackage: 'MULTIPLE_PACKAGE',
    PremiumPackage: 'PREMIUM_PACKAGE'
} as const;

export type RegistrationPlanDTORegistrationPlanEnum = typeof RegistrationPlanDTORegistrationPlanEnum[keyof typeof RegistrationPlanDTORegistrationPlanEnum];

/**
 *
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     *
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
}
/**
 *
 * @export
 * @interface StripeOnboardingResponseDTO
 */
export interface StripeOnboardingResponseDTO {
    /**
     *
     * @type {string}
     * @memberof StripeOnboardingResponseDTO
     */
    'payment_url'?: string;
}
/**
 *
 * @export
 * @interface SupportTicketRequestDTO
 */
export interface SupportTicketRequestDTO {
    /**
     *
     * @type {string}
     * @memberof SupportTicketRequestDTO
     */
    'support_type'?: SupportTicketRequestDTOSupportTypeEnum;
    /**
     *
     * @type {string}
     * @memberof SupportTicketRequestDTO
     */
    'subject'?: string;
    /**
     *
     * @type {string}
     * @memberof SupportTicketRequestDTO
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof SupportTicketRequestDTO
     */
    'username'?: string;
    /**
     *
     * @type {string}
     * @memberof SupportTicketRequestDTO
     */
    'email'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof SupportTicketRequestDTO
     */
    'attachments'?: Array<string>;
}

export const SupportTicketRequestDTOSupportTypeEnum = {
    AccountAndBillingSupport: 'ACCOUNT_AND_BILLING_SUPPORT',
    TechnicalSupport: 'TECHNICAL_SUPPORT'
} as const;

export type SupportTicketRequestDTOSupportTypeEnum = typeof SupportTicketRequestDTOSupportTypeEnum[keyof typeof SupportTicketRequestDTOSupportTypeEnum];

/**
 *
 * @export
 * @interface TaxPrepDTO
 */
export interface TaxPrepDTO {
    /**
     *
     * @type {number}
     * @memberof TaxPrepDTO
     */
    'tax_year'?: number;
    /**
     *
     * @type {number}
     * @memberof TaxPrepDTO
     */
    'interest'?: number;
    /**
     *
     * @type {boolean}
     * @memberof TaxPrepDTO
     */
    'less_ten'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof TaxPrepDTO
     */
    'home_equity_loan'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof TaxPrepDTO
     */
    'self_financed'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof TaxPrepDTO
     */
    'real_property'?: boolean;
}
/**
 *
 * @export
 * @interface UserDeviceTokenRequestDTO
 */
export interface UserDeviceTokenRequestDTO {
    /**
     *
     * @type {string}
     * @memberof UserDeviceTokenRequestDTO
     */
    'user_device_token': string;
}
/**
 *
 * @export
 * @interface UserMinimalResponseDTO
 */
export interface UserMinimalResponseDTO {
    /**
     *
     * @type {number}
     * @memberof UserMinimalResponseDTO
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof UserMinimalResponseDTO
     */
    'first_name': string;
    /**
     *
     * @type {string}
     * @memberof UserMinimalResponseDTO
     */
    'last_name': string;
    /**
     *
     * @type {string}
     * @memberof UserMinimalResponseDTO
     */
    'full_name'?: string;
    /**
     *
     * @type {string}
     * @memberof UserMinimalResponseDTO
     */
    'business_name'?: string;
    /**
     *
     * @type {BaseDocumentResponseDTO}
     * @memberof UserMinimalResponseDTO
     */
    'profile_photo'?: BaseDocumentResponseDTO;
}
/**
 *
 * @export
 * @interface UserPasswordResetDTO
 */
export interface UserPasswordResetDTO {
    /**
     *
     * @type {string}
     * @memberof UserPasswordResetDTO
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof UserPasswordResetDTO
     */
    'password': string;
}
/**
 *
 * @export
 * @interface UserProfileResponseDTO
 */
export interface UserProfileResponseDTO {
    /**
     *
     * @type {string}
     * @memberof UserProfileResponseDTO
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof UserProfileResponseDTO
     */
    'email': string;
    /**
     *
     * @type {boolean}
     * @memberof UserProfileResponseDTO
     */
    'enabled'?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserProfileResponseDTO
     */
    'first_name': string;
    /**
     *
     * @type {string}
     * @memberof UserProfileResponseDTO
     */
    'last_name': string;
    /**
     *
     * @type {string}
     * @memberof UserProfileResponseDTO
     */
    'mobile_number': string;
    /**
     *
     * @type {string}
     * @memberof UserProfileResponseDTO
     */
    'activation_state'?: UserProfileResponseDTOActivationStateEnum;
    /**
     *
     * @type {Set<string>}
     * @memberof UserProfileResponseDTO
     */
    'roles'?: Set<UserProfileResponseDTORolesEnum>;
    /**
     *
     * @type {TaxPrepDTO}
     * @memberof UserProfileResponseDTO
     */
    'tax_prep'?: TaxPrepDTO;
    /**
     *
     * @type {BaseDocumentResponseDTO}
     * @memberof UserProfileResponseDTO
     */
    'profile_photo'?: BaseDocumentResponseDTO;
    /**
     *
     * @type {Array<UserRegistrationPlanDTO>}
     * @memberof UserProfileResponseDTO
     */
    'user_registration_plans'?: Array<UserRegistrationPlanDTO>;
    /**
     *
     * @type {string}
     * @memberof UserProfileResponseDTO
     */
    'city'?: string;
    /**
     *
     * @type {string}
     * @memberof UserProfileResponseDTO
     */
    'state'?: string;
    /**
     *
     * @type {string}
     * @memberof UserProfileResponseDTO
     */
    'zip_code'?: string;
    /**
     *
     * @type {string}
     * @memberof UserProfileResponseDTO
     */
    'full_address'?: string;
}

export const UserProfileResponseDTOActivationStateEnum = {
    Created: 'CREATED',
    Rejected: 'REJECTED',
    Activated: 'ACTIVATED'
} as const;

export type UserProfileResponseDTOActivationStateEnum = typeof UserProfileResponseDTOActivationStateEnum[keyof typeof UserProfileResponseDTOActivationStateEnum];
export const UserProfileResponseDTORolesEnum = {
    SuperAdmin: 'SUPER_ADMIN',
    Admin: 'ADMIN',
    User: 'USER'
} as const;

export type UserProfileResponseDTORolesEnum = typeof UserProfileResponseDTORolesEnum[keyof typeof UserProfileResponseDTORolesEnum];

/**
 *
 * @export
 * @interface UserRegistrationPlanDTO
 */
export interface UserRegistrationPlanDTO {
    /**
     *
     * @type {boolean}
     * @memberof UserRegistrationPlanDTO
     */
    'enabled'?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserRegistrationPlanDTO
     */
    'registration_plan'?: UserRegistrationPlanDTORegistrationPlanEnum;
    /**
     *
     * @type {string}
     * @memberof UserRegistrationPlanDTO
     */
    'start_date'?: string;
    /**
     *
     * @type {string}
     * @memberof UserRegistrationPlanDTO
     */
    'status'?: UserRegistrationPlanDTOStatusEnum;
}

export const UserRegistrationPlanDTORegistrationPlanEnum = {
    SinglePackage: 'SINGLE_PACKAGE',
    MultiplePackage: 'MULTIPLE_PACKAGE',
    PremiumPackage: 'PREMIUM_PACKAGE'
} as const;

export type UserRegistrationPlanDTORegistrationPlanEnum = typeof UserRegistrationPlanDTORegistrationPlanEnum[keyof typeof UserRegistrationPlanDTORegistrationPlanEnum];
export const UserRegistrationPlanDTOStatusEnum = {
    Created: 'CREATED',
    CancelRequest: 'CANCEL_REQUEST',
    Canceled: 'CANCELED',
    CanceledByNewPurchase: 'CANCELED_BY_NEW_PURCHASE',
    Expired: 'EXPIRED',
    Used: 'USED'
} as const;

export type UserRegistrationPlanDTOStatusEnum = typeof UserRegistrationPlanDTOStatusEnum[keyof typeof UserRegistrationPlanDTOStatusEnum];

/**
 *
 * @export
 * @interface UserResponseDTO
 */
export interface UserResponseDTO {
    /**
     *
     * @type {string}
     * @memberof UserResponseDTO
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof UserResponseDTO
     */
    'email': string;
    /**
     *
     * @type {boolean}
     * @memberof UserResponseDTO
     */
    'enabled'?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserResponseDTO
     */
    'first_name': string;
    /**
     *
     * @type {string}
     * @memberof UserResponseDTO
     */
    'last_name': string;
    /**
     *
     * @type {string}
     * @memberof UserResponseDTO
     */
    'mobile_number': string;
    /**
     *
     * @type {string}
     * @memberof UserResponseDTO
     */
    'activation_state'?: UserResponseDTOActivationStateEnum;
    /**
     *
     * @type {Set<string>}
     * @memberof UserResponseDTO
     */
    'roles'?: Set<UserResponseDTORolesEnum>;
}

export const UserResponseDTOActivationStateEnum = {
    Created: 'CREATED',
    Rejected: 'REJECTED',
    Activated: 'ACTIVATED'
} as const;

export type UserResponseDTOActivationStateEnum = typeof UserResponseDTOActivationStateEnum[keyof typeof UserResponseDTOActivationStateEnum];
export const UserResponseDTORolesEnum = {
    SuperAdmin: 'SUPER_ADMIN',
    Admin: 'ADMIN',
    User: 'USER'
} as const;

export type UserResponseDTORolesEnum = typeof UserResponseDTORolesEnum[keyof typeof UserResponseDTORolesEnum];

/**
 *
 * @export
 * @interface UserSearchResponseDTO
 */
export interface UserSearchResponseDTO {
    /**
     *
     * @type {number}
     * @memberof UserSearchResponseDTO
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof UserSearchResponseDTO
     */
    'first_name'?: string;
    /**
     *
     * @type {string}
     * @memberof UserSearchResponseDTO
     */
    'last_name'?: string;
    /**
     *
     * @type {string}
     * @memberof UserSearchResponseDTO
     */
    'username'?: string;
    /**
     *
     * @type {string}
     * @memberof UserSearchResponseDTO
     */
    'email'?: string;
}
/**
 *
 * @export
 * @interface UserStripeAccountResponseDTO
 */
export interface UserStripeAccountResponseDTO {
    /**
     *
     * @type {string}
     * @memberof UserStripeAccountResponseDTO
     */
    'stripe_account_status'?: UserStripeAccountResponseDTOStripeAccountStatusEnum;
}

export const UserStripeAccountResponseDTOStripeAccountStatusEnum = {
    Completed: 'COMPLETED',
    Uncompleted: 'UNCOMPLETED'
} as const;

export type UserStripeAccountResponseDTOStripeAccountStatusEnum = typeof UserStripeAccountResponseDTOStripeAccountStatusEnum[keyof typeof UserStripeAccountResponseDTOStripeAccountStatusEnum];

/**
 *
 * @export
 * @interface VerifyUserRequestDTO
 */
export interface VerifyUserRequestDTO {
    /**
     *
     * @type {string}
     * @memberof VerifyUserRequestDTO
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof VerifyUserRequestDTO
     */
    'verification_code': string;
}

/**
 * AppReviewRestApi - axios parameter creator
 * @export
 */
export const AppReviewRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {AppReviewDTO} appReviewDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add2: async (appReviewDTO: AppReviewDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appReviewDTO' is not null or undefined
            assertParamExists('add2', 'appReviewDTO', appReviewDTO)
            const localVarPath = `/api/v1/app-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appReviewDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyReview: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/app-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppReviewRestApi - functional programming interface
 * @export
 */
export const AppReviewRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppReviewRestApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {AppReviewDTO} appReviewDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async add2(appReviewDTO: AppReviewDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppReviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.add2(appReviewDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyReview(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppReviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyReview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppReviewRestApi - factory interface
 * @export
 */
export const AppReviewRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppReviewRestApiFp(configuration)
    return {
        /**
         *
         * @param {AppReviewDTO} appReviewDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add2(appReviewDTO: AppReviewDTO, options?: any): AxiosPromise<AppReviewDTO> {
            return localVarFp.add2(appReviewDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyReview(options?: any): AxiosPromise<AppReviewDTO> {
            return localVarFp.getMyReview(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppReviewRestApi - object-oriented interface
 * @export
 * @class AppReviewRestApi
 * @extends {BaseAPI}
 */
export class AppReviewRestApi extends BaseAPI {
    /**
     *
     * @param {AppReviewDTO} appReviewDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppReviewRestApi
     */
    public add2(appReviewDTO: AppReviewDTO, options?: AxiosRequestConfig) {
        return AppReviewRestApiFp(this.configuration).add2(appReviewDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppReviewRestApi
     */
    public getMyReview(options?: AxiosRequestConfig) {
        return AppReviewRestApiFp(this.configuration).getMyReview(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationRestApi - axios parameter creator
 * @export
 */
export const NotificationRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserNotifications: async (pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getAllUserNotifications', 'pageable', pageable)
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/read-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        seenNotification: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('seenNotification', 'notificationId', notificationId)
            const localVarPath = `/api/v1/notifications/{notification_id}/seen`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationRestApi - functional programming interface
 * @export
 */
export const NotificationRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationRestApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUserNotifications(pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNotificationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUserNotifications(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadNotificationCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadNotificationCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async seenNotification(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.seenNotification(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationRestApi - factory interface
 * @export
 */
export const NotificationRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationRestApiFp(configuration)
    return {
        /**
         *
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserNotifications(pageable: Pageable, options?: any): AxiosPromise<PageNotificationResponseDTO> {
            return localVarFp.getAllUserNotifications(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationCount(options?: any): AxiosPromise<string> {
            return localVarFp.getUnreadNotificationCount(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAll(options?: any): AxiosPromise<string> {
            return localVarFp.readAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        seenNotification(notificationId: number, options?: any): AxiosPromise<string> {
            return localVarFp.seenNotification(notificationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationRestApi - object-oriented interface
 * @export
 * @class NotificationRestApi
 * @extends {BaseAPI}
 */
export class NotificationRestApi extends BaseAPI {
    /**
     *
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationRestApi
     */
    public getAllUserNotifications(pageable: Pageable, options?: AxiosRequestConfig) {
        return NotificationRestApiFp(this.configuration).getAllUserNotifications(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationRestApi
     */
    public getUnreadNotificationCount(options?: AxiosRequestConfig) {
        return NotificationRestApiFp(this.configuration).getUnreadNotificationCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationRestApi
     */
    public readAll(options?: AxiosRequestConfig) {
        return NotificationRestApiFp(this.configuration).readAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} notificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationRestApi
     */
    public seenNotification(notificationId: number, options?: AxiosRequestConfig) {
        return NotificationRestApiFp(this.configuration).seenNotification(notificationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentRestApi - axios parameter creator
 * @export
 */
export const PaymentRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} promissoryNoteId
         * @param {number} amortizationCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPayment: async (promissoryNoteId: number, amortizationCount: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promissoryNoteId' is not null or undefined
            assertParamExists('acceptPayment', 'promissoryNoteId', promissoryNoteId)
            // verify required parameter 'amortizationCount' is not null or undefined
            assertParamExists('acceptPayment', 'amortizationCount', amortizationCount)
            const localVarPath = `/api/v1/payments/accept-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (promissoryNoteId !== undefined) {
                localVarQueryParameter['promissoryNoteId'] = promissoryNoteId;
            }

            if (amortizationCount !== undefined) {
                localVarQueryParameter['amortizationCount'] = amortizationCount;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLinks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payments/account-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} planName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSession: async (planName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planName' is not null or undefined
            assertParamExists('getPaymentSession', 'planName', planName)
            const localVarPath = `/api/v1/payments/select-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (planName !== undefined) {
                localVarQueryParameter['plan-name'] = planName;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginToStripeDashboard: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payments/stripe-dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} promissoryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentHistoryOfPromissoryNote: async (promissoryNoteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promissoryNoteId' is not null or undefined
            assertParamExists('paymentHistoryOfPromissoryNote', 'promissoryNoteId', promissoryNoteId)
            const localVarPath = `/api/v1/payments/payments_history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (promissoryNoteId !== undefined) {
                localVarQueryParameter['promissoryNoteId'] = promissoryNoteId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewPlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payments/renew-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SupportTicketRequestDTO} supportTicketRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicket: async (supportTicketRequestDTO: SupportTicketRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportTicketRequestDTO' is not null or undefined
            assertParamExists('supportTicket', 'supportTicketRequestDTO', supportTicketRequestDTO)
            const localVarPath = `/api/v1/payments/support-ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supportTicketRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webHooksHandle1: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('webHooksHandle1', 'body', body)
            const localVarPath = `/api/v1/payments/hooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentRestApi - functional programming interface
 * @export
 */
export const PaymentRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentRestApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {number} promissoryNoteId
         * @param {number} amortizationCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptPayment(promissoryNoteId: number, amortizationCount: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptPayment(promissoryNoteId, amortizationCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountLinks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeOnboardingResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountLinks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} planName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentSession(planName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentSession(planName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginToStripeDashboard(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeOnboardingResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginToStripeDashboard(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} promissoryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentHistoryOfPromissoryNote(promissoryNoteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentHistoryListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentHistoryOfPromissoryNote(promissoryNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewPlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewPlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SupportTicketRequestDTO} supportTicketRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportTicket(supportTicketRequestDTO: SupportTicketRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportTicket(supportTicketRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webHooksHandle1(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webHooksHandle1(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentRestApi - factory interface
 * @export
 */
export const PaymentRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentRestApiFp(configuration)
    return {
        /**
         *
         * @param {number} promissoryNoteId
         * @param {number} amortizationCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPayment(promissoryNoteId: number, amortizationCount: number, options?: any): AxiosPromise<PaymentResponseDTO> {
            return localVarFp.acceptPayment(promissoryNoteId, amortizationCount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLinks(options?: any): AxiosPromise<StripeOnboardingResponseDTO> {
            return localVarFp.accountLinks(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} planName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSession(planName: string, options?: any): AxiosPromise<PaymentResponseDTO> {
            return localVarFp.getPaymentSession(planName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginToStripeDashboard(options?: any): AxiosPromise<StripeOnboardingResponseDTO> {
            return localVarFp.loginToStripeDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} promissoryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentHistoryOfPromissoryNote(promissoryNoteId: number, options?: any): AxiosPromise<PaymentHistoryListDTO> {
            return localVarFp.paymentHistoryOfPromissoryNote(promissoryNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewPlan(options?: any): AxiosPromise<PaymentResponseDTO> {
            return localVarFp.renewPlan(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SupportTicketRequestDTO} supportTicketRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicket(supportTicketRequestDTO: SupportTicketRequestDTO, options?: any): AxiosPromise<string> {
            return localVarFp.supportTicket(supportTicketRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webHooksHandle1(body: string, options?: any): AxiosPromise<string> {
            return localVarFp.webHooksHandle1(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentRestApi - object-oriented interface
 * @export
 * @class PaymentRestApi
 * @extends {BaseAPI}
 */
export class PaymentRestApi extends BaseAPI {
    /**
     *
     * @param {number} promissoryNoteId
     * @param {number} amortizationCount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestApi
     */
    public acceptPayment(promissoryNoteId: number, amortizationCount: number, options?: AxiosRequestConfig) {
        return PaymentRestApiFp(this.configuration).acceptPayment(promissoryNoteId, amortizationCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestApi
     */
    public accountLinks(options?: AxiosRequestConfig) {
        return PaymentRestApiFp(this.configuration).accountLinks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} planName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestApi
     */
    public getPaymentSession(planName: string, options?: AxiosRequestConfig) {
        return PaymentRestApiFp(this.configuration).getPaymentSession(planName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestApi
     */
    public loginToStripeDashboard(options?: AxiosRequestConfig) {
        return PaymentRestApiFp(this.configuration).loginToStripeDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} promissoryNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestApi
     */
    public paymentHistoryOfPromissoryNote(promissoryNoteId: number, options?: AxiosRequestConfig) {
        return PaymentRestApiFp(this.configuration).paymentHistoryOfPromissoryNote(promissoryNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestApi
     */
    public renewPlan(options?: AxiosRequestConfig) {
        return PaymentRestApiFp(this.configuration).renewPlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SupportTicketRequestDTO} supportTicketRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestApi
     */
    public supportTicket(supportTicketRequestDTO: SupportTicketRequestDTO, options?: AxiosRequestConfig) {
        return PaymentRestApiFp(this.configuration).supportTicket(supportTicketRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestApi
     */
    public webHooksHandle1(body: string, options?: AxiosRequestConfig) {
        return PaymentRestApiFp(this.configuration).webHooksHandle1(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromissoryNoteRestApi - axios parameter creator
 * @export
 */
export const PromissoryNoteRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add1: async (fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fullPromissoryNoteDTORequest' is not null or undefined
            assertParamExists('add1', 'fullPromissoryNoteDTORequest', fullPromissoryNoteDTORequest)
            const localVarPath = `/api/v1/promissory-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fullPromissoryNoteDTORequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit: async (id: number, fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('edit', 'id', id)
            // verify required parameter 'fullPromissoryNoteDTORequest' is not null or undefined
            assertParamExists('edit', 'fullPromissoryNoteDTORequest', fullPromissoryNoteDTORequest)
            const localVarPath = `/api/v1/promissory-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fullPromissoryNoteDTORequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmortizationListPreview: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAmortizationListPreview', 'id', id)
            const localVarPath = `/api/v1/promissory-notes/amortizations/{id}/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PromissoryNoteTempDTORequest} promissoryNoteTempDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmortizationListTempPreview: async (promissoryNoteTempDTORequest: PromissoryNoteTempDTORequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            assertParamExists('getAmortizationListTempPreview', 'promissoryNoteTempDTORequest', promissoryNoteTempDTORequest)
            const localVarPath = `/api/v1/promissory-notes/amortizations/temp-preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promissoryNoteTempDTORequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} promissoryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmortizations: async (promissoryNoteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promissoryNoteId' is not null or undefined
            assertParamExists('getAmortizations', 'promissoryNoteId', promissoryNoteId)
            const localVarPath = `/api/v1/promissory-notes/{promissory_note_id}/amortizations`
                .replace(`{${"promissory_note_id"}}`, encodeURIComponent(String(promissoryNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemandImmediatePreview: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDemandImmediatePreview', 'id', id)
            const localVarPath = `/api/v1/promissory-notes/{id}/immediate-demand/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemandPreview: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDemandPreview', 'id', id)
            const localVarPath = `/api/v1/promissory-notes/{id}/demand/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT'} documentFileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPDF: async (documentFileName: 'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentFileName' is not null or undefined
            assertParamExists('getDocumentPDF', 'documentFileName', documentFileName)
            const localVarPath = `/api/v1/promissory-notes/document/{document_file_name}`
                .replace(`{${"document_file_name"}}`, encodeURIComponent(String(documentFileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanReleasePreview: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLoanReleasePreview', 'id', id)
            const localVarPath = `/api/v1/promissory-notes/{id}/loan-release/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyBorrows: async (pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getMyBorrows', 'pageable', pageable)
            const localVarPath = `/api/v1/promissory-notes/borrows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyLoans: async (pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getMyLoans', 'pageable', pageable)
            const localVarPath = `/api/v1/promissory-notes/loans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromissoryNote: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPromissoryNote', 'id', id)
            const localVarPath = `/api/v1/promissory-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromissoryNotePreview: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPromissoryNotePreview', 'id', id)
            const localVarPath = `/api/v1/promissory-notes/{id}/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromissoryNoteTempPreview: async (fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fullPromissoryNoteDTORequest' is not null or undefined
            assertParamExists('getPromissoryNoteTempPreview', 'fullPromissoryNoteDTORequest', fullPromissoryNoteDTORequest)
            const localVarPath = `/api/v1/promissory-notes/temp-preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fullPromissoryNoteDTORequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpdateChoices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/promissory-notes/plans/upgradable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postToSign: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postToSign', 'id', id)
            const localVarPath = `/api/v1/promissory-notes/{id}/sign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAmortizationsToEmail: async (id: number, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendAmortizationsToEmail', 'id', id)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('sendAmortizationsToEmail', 'to', to)
            const localVarPath = `/api/v1/promissory-notes/amortizations/{id}/share/{to}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDemandLetterToEmail: async (id: number, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendDemandLetterToEmail', 'id', id)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('sendDemandLetterToEmail', 'to', to)
            const localVarPath = `/api/v1/promissory-notes/{id}/demand/share/{to}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendImmediateDemandLetterToEmail: async (id: number, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendImmediateDemandLetterToEmail', 'id', id)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('sendImmediateDemandLetterToEmail', 'to', to)
            const localVarPath = `/api/v1/promissory-notes/{id}/immediate-demand/share/{to}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLoanReleaseToEmail: async (id: number, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendLoanReleaseToEmail', 'id', id)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('sendLoanReleaseToEmail', 'to', to)
            const localVarPath = `/api/v1/promissory-notes/{id}/loan-releases/share/{to}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT'} documentFileName
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLoanReleaseToEmail1: async (documentFileName: 'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT', to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentFileName' is not null or undefined
            assertParamExists('sendLoanReleaseToEmail1', 'documentFileName', documentFileName)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('sendLoanReleaseToEmail1', 'to', to)
            const localVarPath = `/api/v1/promissory-notes/document/{document_file_name}/share/{to}`
                .replace(`{${"document_file_name"}}`, encodeURIComponent(String(documentFileName)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPromissoryNoteToEmail: async (id: number, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendPromissoryNoteToEmail', 'id', id)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('sendPromissoryNoteToEmail', 'to', to)
            const localVarPath = `/api/v1/promissory-notes/{id}/preview/{to}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webHooksHandle: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('webHooksHandle', 'body', body)
            const localVarPath = `/api/v1/promissory-notes/hooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromissoryNoteRestApi - functional programming interface
 * @export
 */
export const PromissoryNoteRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromissoryNoteRestApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async add1(fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullPromissoryNoteDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.add1(fullPromissoryNoteDTORequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edit(id: number, fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullPromissoryNoteDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edit(id, fullPromissoryNoteDTORequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmortizationListPreview(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAmortizationListPreview(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PromissoryNoteTempDTORequest} promissoryNoteTempDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmortizationListTempPreview(promissoryNoteTempDTORequest: PromissoryNoteTempDTORequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAmortizationListTempPreview(promissoryNoteTempDTORequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} promissoryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmortizations(promissoryNoteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AmortizationScheduleResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAmortizations(promissoryNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemandImmediatePreview(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemandImmediatePreview(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemandPreview(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemandPreview(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT'} documentFileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentPDF(documentFileName: 'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentPDF(documentFileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanReleasePreview(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanReleasePreview(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyBorrows(pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyPromissoryNotePagedResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyBorrows(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyLoans(pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyPromissoryNotePagedResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyLoans(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromissoryNote(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullPromissoryNoteDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromissoryNote(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromissoryNotePreview(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromissoryNotePreview(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromissoryNoteTempPreview(fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromissoryNoteTempPreview(fullPromissoryNoteDTORequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpdateChoices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RegistrationPlanDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpdateChoices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postToSign(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postToSign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAmortizationsToEmail(id: number, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAmortizationsToEmail(id, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDemandLetterToEmail(id: number, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendDemandLetterToEmail(id, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendImmediateDemandLetterToEmail(id: number, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendImmediateDemandLetterToEmail(id, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendLoanReleaseToEmail(id: number, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendLoanReleaseToEmail(id, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT'} documentFileName
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendLoanReleaseToEmail1(documentFileName: 'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT', to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendLoanReleaseToEmail1(documentFileName, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPromissoryNoteToEmail(id: number, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPromissoryNoteToEmail(id, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webHooksHandle(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webHooksHandle(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromissoryNoteRestApi - factory interface
 * @export
 */
export const PromissoryNoteRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromissoryNoteRestApiFp(configuration)
    return {
        /**
         *
         * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add1(fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options?: any): AxiosPromise<FullPromissoryNoteDTOResponse> {
            return localVarFp.add1(fullPromissoryNoteDTORequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit(id: number, fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options?: any): AxiosPromise<FullPromissoryNoteDTOResponse> {
            return localVarFp.edit(id, fullPromissoryNoteDTORequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmortizationListPreview(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAmortizationListPreview(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PromissoryNoteTempDTORequest} promissoryNoteTempDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmortizationListTempPreview(promissoryNoteTempDTORequest: PromissoryNoteTempDTORequest, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAmortizationListTempPreview(promissoryNoteTempDTORequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} promissoryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmortizations(promissoryNoteId: number, options?: any): AxiosPromise<Array<AmortizationScheduleResponseDTO>> {
            return localVarFp.getAmortizations(promissoryNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemandImmediatePreview(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getDemandImmediatePreview(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemandPreview(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getDemandPreview(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT'} documentFileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPDF(documentFileName: 'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT', options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getDocumentPDF(documentFileName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanReleasePreview(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getLoanReleasePreview(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyBorrows(pageable: Pageable, options?: any): AxiosPromise<MyPromissoryNotePagedResponseDto> {
            return localVarFp.getMyBorrows(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyLoans(pageable: Pageable, options?: any): AxiosPromise<MyPromissoryNotePagedResponseDto> {
            return localVarFp.getMyLoans(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromissoryNote(id: number, options?: any): AxiosPromise<FullPromissoryNoteDTOResponse> {
            return localVarFp.getPromissoryNote(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromissoryNotePreview(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getPromissoryNotePreview(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromissoryNoteTempPreview(fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getPromissoryNoteTempPreview(fullPromissoryNoteDTORequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpdateChoices(options?: any): AxiosPromise<Array<RegistrationPlanDTO>> {
            return localVarFp.getUpdateChoices(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postToSign(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.postToSign(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAmortizationsToEmail(id: number, to: string, options?: any): AxiosPromise<string> {
            return localVarFp.sendAmortizationsToEmail(id, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDemandLetterToEmail(id: number, to: string, options?: any): AxiosPromise<string> {
            return localVarFp.sendDemandLetterToEmail(id, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendImmediateDemandLetterToEmail(id: number, to: string, options?: any): AxiosPromise<string> {
            return localVarFp.sendImmediateDemandLetterToEmail(id, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLoanReleaseToEmail(id: number, to: string, options?: any): AxiosPromise<string> {
            return localVarFp.sendLoanReleaseToEmail(id, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT'} documentFileName
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLoanReleaseToEmail1(documentFileName: 'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT', to: string, options?: any): AxiosPromise<string> {
            return localVarFp.sendLoanReleaseToEmail1(documentFileName, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPromissoryNoteToEmail(id: number, to: string, options?: any): AxiosPromise<string> {
            return localVarFp.sendPromissoryNoteToEmail(id, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webHooksHandle(body: string, options?: any): AxiosPromise<string> {
            return localVarFp.webHooksHandle(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromissoryNoteRestApi - object-oriented interface
 * @export
 * @class PromissoryNoteRestApi
 * @extends {BaseAPI}
 */
export class PromissoryNoteRestApi extends BaseAPI {
    /**
     *
     * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public add1(fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).add1(fullPromissoryNoteDTORequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public edit(id: number, fullPromissoryNoteDTORequest: FullPromissoryNoteDTORequest, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).edit(id, fullPromissoryNoteDTORequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getAmortizationListPreview(id: number, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getAmortizationListPreview(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PromissoryNoteTempDTORequest} promissoryNoteTempDTORequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getAmortizationListTempPreview(promissoryNoteTempDTORequest: PromissoryNoteTempDTORequest, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getAmortizationListTempPreview(promissoryNoteTempDTORequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} promissoryNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getAmortizations(promissoryNoteId: number, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getAmortizations(promissoryNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getDemandImmediatePreview(id: number, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getDemandImmediatePreview(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getDemandPreview(id: number, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getDemandPreview(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT'} documentFileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getDocumentPDF(documentFileName: 'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT', options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getDocumentPDF(documentFileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getLoanReleasePreview(id: number, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getLoanReleasePreview(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getMyBorrows(pageable: Pageable, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getMyBorrows(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getMyLoans(pageable: Pageable, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getMyLoans(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getPromissoryNote(id: number, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getPromissoryNote(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getPromissoryNotePreview(id: number, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getPromissoryNotePreview(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {FullPromissoryNoteDTORequest} fullPromissoryNoteDTORequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getPromissoryNoteTempPreview(fullPromissoryNoteDTORequest:any, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getPromissoryNoteTempPreview(fullPromissoryNoteDTORequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public getUpdateChoices(options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).getUpdateChoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public postToSign(id: number, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).postToSign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public sendAmortizationsToEmail(id: number, to: string, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).sendAmortizationsToEmail(id, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public sendDemandLetterToEmail(id: number, to: string, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).sendDemandLetterToEmail(id, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public sendImmediateDemandLetterToEmail(id: number, to: string, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).sendImmediateDemandLetterToEmail(id, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public sendLoanReleaseToEmail(id: number, to: string, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).sendLoanReleaseToEmail(id, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT'} documentFileName
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public sendLoanReleaseToEmail1(documentFileName: 'DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN' | 'DOCUMENT_1098_MORT' | 'DOCUMENT_1040_SCHEDULE_B_REPORT' | 'DOCUMENT_1099_INT', to: string, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).sendLoanReleaseToEmail1(documentFileName, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public sendPromissoryNoteToEmail(id: number, to: string, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).sendPromissoryNoteToEmail(id, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromissoryNoteRestApi
     */
    public webHooksHandle(body: string, options?: AxiosRequestConfig) {
        return PromissoryNoteRestApiFp(this.configuration).webHooksHandle(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserRestApi - axios parameter creator
 * @export
 */
export const UserRestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {RegisterUserRequestDTO} registerUserRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add: async (registerUserRequestDTO: RegisterUserRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerUserRequestDTO' is not null or undefined
            assertParamExists('add', 'registerUserRequestDTO', registerUserRequestDTO)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerUserRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {TaxPrepDTO} taxPrepDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaxPrep: async (taxPrepDTO: TaxPrepDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxPrepDTO' is not null or undefined
            assertParamExists('addTaxPrep', 'taxPrepDTO', taxPrepDTO)
            const localVarPath = `/api/v1/users/tax-prep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxPrepDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UserDeviceTokenRequestDTO} userDeviceTokenRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDeviceToken: async (userDeviceTokenRequestDTO: UserDeviceTokenRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDeviceTokenRequestDTO' is not null or undefined
            assertParamExists('addUserDeviceToken', 'userDeviceTokenRequestDTO', userDeviceTokenRequestDTO)
            const localVarPath = `/api/v1/users/device-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDeviceTokenRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Set<BaseDocumentRequestDTO>} baseDocumentRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDocuments: async (baseDocumentRequestDTO: Set<BaseDocumentRequestDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseDocumentRequestDTO' is not null or undefined
            assertParamExists('addUserDocuments', 'baseDocumentRequestDTO', baseDocumentRequestDTO)
            const localVarPath = `/api/v1/users/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseDocumentRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserPlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/cancel-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} resetCode
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPasswordResetCodeValidation: async (resetCode: string, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetCode' is not null or undefined
            assertParamExists('checkPasswordResetCodeValidation', 'resetCode', resetCode)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('checkPasswordResetCodeValidation', 'email', email)
            const localVarPath = `/api/v1/users/password-reset-verification-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (resetCode !== undefined) {
                localVarQueryParameter['resetCode'] = resetCode;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCurrentLoggedInUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlayerProfilePhoto: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/delete-profile-photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UserDeviceTokenRequestDTO} userDeviceTokenRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDeviceToken: async (userDeviceTokenRequestDTO: UserDeviceTokenRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDeviceTokenRequestDTO' is not null or undefined
            assertParamExists('deleteUserDeviceToken', 'userDeviceTokenRequestDTO', userDeviceTokenRequestDTO)
            const localVarPath = `/api/v1/users/device-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDeviceTokenRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {EditUserRequestDTO} editUserRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserBasicInformation: async (editUserRequestDTO: EditUserRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editUserRequestDTO' is not null or undefined
            assertParamExists('editUserBasicInformation', 'editUserRequestDTO', editUserRequestDTO)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUserRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivePlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/active-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/dashboard-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorageCredential: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/credential`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendOTP: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('resendOTP', 'username', username)
            const localVarPath = `/api/v1/users/verification-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UserPasswordResetDTO} userPasswordResetDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (userPasswordResetDTO: UserPasswordResetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPasswordResetDTO' is not null or undefined
            assertParamExists('resetPassword', 'userPasswordResetDTO', userPasswordResetDTO)
            const localVarPath = `/api/v1/users/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPasswordResetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Pageable} pageable
         * @param {string} filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (pageable: Pageable, filter: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('searchUsers', 'pageable', pageable)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('searchUsers', 'filter', filter)
            const localVarPath = `/api/v1/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetCode: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('sendPasswordResetCode', 'email', email)
            const localVarPath = `/api/v1/users/password-reset-verification-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeActivePlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/plans/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userStripeAccountStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/stripe-account-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {VerifyUserRequestDTO} verifyUserRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify: async (verifyUserRequestDTO: VerifyUserRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyUserRequestDTO' is not null or undefined
            assertParamExists('verify', 'verifyUserRequestDTO', verifyUserRequestDTO)
            const localVarPath = `/api/v1/users/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyUserRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRestApi - functional programming interface
 * @export
 */
export const UserRestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRestApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {RegisterUserRequestDTO} registerUserRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async add(registerUserRequestDTO: RegisterUserRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.add(registerUserRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {TaxPrepDTO} taxPrepDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTaxPrep(taxPrepDTO: TaxPrepDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTaxPrep(taxPrepDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UserDeviceTokenRequestDTO} userDeviceTokenRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserDeviceToken(userDeviceTokenRequestDTO: UserDeviceTokenRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserDeviceToken(userDeviceTokenRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Set<BaseDocumentRequestDTO>} baseDocumentRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserDocuments(baseDocumentRequestDTO: Set<BaseDocumentRequestDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<BaseDocumentResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserDocuments(baseDocumentRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelUserPlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelUserPlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} resetCode
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPasswordResetCodeValidation(resetCode: string, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPasswordResetCodeValidation(resetCode, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCurrentLoggedInUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCurrentLoggedInUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlayerProfilePhoto(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlayerProfilePhoto(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UserDeviceTokenRequestDTO} userDeviceTokenRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserDeviceToken(userDeviceTokenRequestDTO: UserDeviceTokenRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserDeviceToken(userDeviceTokenRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {EditUserRequestDTO} editUserRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editUserBasicInformation(editUserRequestDTO: EditUserRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editUserBasicInformation(editUserRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivePlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRegistrationPlanDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivePlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStorageCredential(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CredentialResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStorageCredential(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendOTP(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendOTP(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UserPasswordResetDTO} userPasswordResetDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(userPasswordResetDTO: UserPasswordResetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(userPasswordResetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Pageable} pageable
         * @param {string} filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(pageable: Pageable, filter: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserSearchResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsers(pageable, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPasswordResetCode(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPasswordResetCode(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubscribeActivePlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsubscribeActivePlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userStripeAccountStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStripeAccountResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userStripeAccountStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {VerifyUserRequestDTO} verifyUserRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verify(verifyUserRequestDTO: VerifyUserRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verify(verifyUserRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRestApi - factory interface
 * @export
 */
export const UserRestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRestApiFp(configuration)
    return {
        /**
         *
         * @param {RegisterUserRequestDTO} registerUserRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add(registerUserRequestDTO: RegisterUserRequestDTO, options?: any): AxiosPromise<UserResponseDTO> {
            return localVarFp.add(registerUserRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {TaxPrepDTO} taxPrepDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaxPrep(taxPrepDTO: TaxPrepDTO, options?: any): AxiosPromise<string> {
            return localVarFp.addTaxPrep(taxPrepDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UserDeviceTokenRequestDTO} userDeviceTokenRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDeviceToken(userDeviceTokenRequestDTO: UserDeviceTokenRequestDTO, options?: any): AxiosPromise<string> {
            return localVarFp.addUserDeviceToken(userDeviceTokenRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Set<BaseDocumentRequestDTO>} baseDocumentRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDocuments(baseDocumentRequestDTO: Set<BaseDocumentRequestDTO>, options?: any): AxiosPromise<Set<BaseDocumentResponseDTO>> {
            return localVarFp.addUserDocuments(baseDocumentRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserPlan(options?: any): AxiosPromise<string> {
            return localVarFp.cancelUserPlan(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} resetCode
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPasswordResetCodeValidation(resetCode: string, email: string, options?: any): AxiosPromise<string> {
            return localVarFp.checkPasswordResetCodeValidation(resetCode, email, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCurrentLoggedInUser(options?: any): AxiosPromise<string> {
            return localVarFp.deleteCurrentLoggedInUser(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlayerProfilePhoto(options?: any): AxiosPromise<string> {
            return localVarFp.deletePlayerProfilePhoto(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UserDeviceTokenRequestDTO} userDeviceTokenRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDeviceToken(userDeviceTokenRequestDTO: UserDeviceTokenRequestDTO, options?: any): AxiosPromise<string> {
            return localVarFp.deleteUserDeviceToken(userDeviceTokenRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {EditUserRequestDTO} editUserRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserBasicInformation(editUserRequestDTO: EditUserRequestDTO, options?: any): AxiosPromise<UserProfileResponseDTO> {
            return localVarFp.editUserBasicInformation(editUserRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivePlan(options?: any): AxiosPromise<UserRegistrationPlanDTO> {
            return localVarFp.getActivePlan(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardStatus(options?: any): AxiosPromise<boolean> {
            return localVarFp.getDashboardStatus(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<UserProfileResponseDTO> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorageCredential(options?: any): AxiosPromise<CredentialResponseDTO> {
            return localVarFp.getStorageCredential(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendOTP(username: string, options?: any): AxiosPromise<string> {
            return localVarFp.resendOTP(username, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UserPasswordResetDTO} userPasswordResetDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(userPasswordResetDTO: UserPasswordResetDTO, options?: any): AxiosPromise<string> {
            return localVarFp.resetPassword(userPasswordResetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Pageable} pageable
         * @param {string} filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(pageable: Pageable, filter: string, options?: any): AxiosPromise<PageUserSearchResponseDTO> {
            return localVarFp.searchUsers(pageable, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetCode(email: string, options?: any): AxiosPromise<string> {
            return localVarFp.sendPasswordResetCode(email, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeActivePlan(options?: any): AxiosPromise<string> {
            return localVarFp.unsubscribeActivePlan(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userStripeAccountStatus(options?: any): AxiosPromise<UserStripeAccountResponseDTO> {
            return localVarFp.userStripeAccountStatus(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {VerifyUserRequestDTO} verifyUserRequestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify(verifyUserRequestDTO: VerifyUserRequestDTO, options?: any): AxiosPromise<UserResponseDTO> {
            return localVarFp.verify(verifyUserRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRestApi - object-oriented interface
 * @export
 * @class UserRestApi
 * @extends {BaseAPI}
 */
export class UserRestApi extends BaseAPI {
    /**
     *
     * @param {RegisterUserRequestDTO} registerUserRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public add(registerUserRequestDTO: RegisterUserRequestDTO, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).add(registerUserRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {TaxPrepDTO} taxPrepDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public addTaxPrep(taxPrepDTO: TaxPrepDTO, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).addTaxPrep(taxPrepDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UserDeviceTokenRequestDTO} userDeviceTokenRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public addUserDeviceToken(userDeviceTokenRequestDTO: UserDeviceTokenRequestDTO, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).addUserDeviceToken(userDeviceTokenRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Set<BaseDocumentRequestDTO>} baseDocumentRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public addUserDocuments(baseDocumentRequestDTO: Set<BaseDocumentRequestDTO>, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).addUserDocuments(baseDocumentRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public cancelUserPlan(options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).cancelUserPlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} resetCode
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public checkPasswordResetCodeValidation(resetCode: string, email: string, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).checkPasswordResetCodeValidation(resetCode, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public deleteCurrentLoggedInUser(options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).deleteCurrentLoggedInUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public deletePlayerProfilePhoto(options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).deletePlayerProfilePhoto(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UserDeviceTokenRequestDTO} userDeviceTokenRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public deleteUserDeviceToken(userDeviceTokenRequestDTO: UserDeviceTokenRequestDTO, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).deleteUserDeviceToken(userDeviceTokenRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {EditUserRequestDTO} editUserRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public editUserBasicInformation(editUserRequestDTO: EditUserRequestDTO, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).editUserBasicInformation(editUserRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public getActivePlan(options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).getActivePlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public getDashboardStatus(options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).getDashboardStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public getProfile(options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public getStorageCredential(options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).getStorageCredential(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public resendOTP(username: string, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).resendOTP(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UserPasswordResetDTO} userPasswordResetDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public resetPassword(userPasswordResetDTO: UserPasswordResetDTO, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).resetPassword(userPasswordResetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Pageable} pageable
     * @param {string} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public searchUsers(pageable: Pageable, filter: string, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).searchUsers(pageable, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public sendPasswordResetCode(email: string, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).sendPasswordResetCode(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public unsubscribeActivePlan(options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).unsubscribeActivePlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public userStripeAccountStatus(options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).userStripeAccountStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {VerifyUserRequestDTO} verifyUserRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestApi
     */
    public verify(verifyUserRequestDTO: VerifyUserRequestDTO, options?: AxiosRequestConfig) {
        return UserRestApiFp(this.configuration).verify(verifyUserRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


