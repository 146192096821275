import { AuthReducerType } from "./authReducerType";
import { AuthReducerInitial } from "./authReducerInitial";
import {
  AuthActionsType,
  AuthResetReducerAction,
  AuthSetDataAction,
  LoginAction,
  UserAction,
} from "src/store/actions/authActions";

export function authReducer(
  state: AuthReducerType = AuthReducerInitial(),
  action: AuthActionsType,
): AuthReducerType {
  switch (action.type) {
    case LoginAction:
      return { ...state, login: { status: "loading" } };
    case UserAction:
      return { ...state, user: action.payload.user };
    case AuthSetDataAction:
      return {
        ...state,
        [action.payload.target]: {
          status: action.payload.status === "data" ? "success" : "error",
          [action.payload.status]: action.payload.data,
        },
      };
    case AuthResetReducerAction:
      return {
        ...state,
        [action.payload.target]: { status: "idle" },
      };

    default:
      return state;
  }
  localStorage.setItam("loginAuth", JSON.stringify(state.login.status));
}
