/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { Button, Checkbox } from "antd";
import { Link } from "react-router-dom";

export interface Step1Props {
  onClick: (type: "PREMIUM" | "YEARLY") => void;
}

const Step1 = ({ onClick }: Step1Props) => {
  const [check, setCheck] = useState(false);

  return (
    <div className="update-subscription-page__step-1">
      <div className="update-subscription-page__step-1__agreement">
        <Checkbox checked={check} onChange={(e) => setCheck(e.target.checked)} />
        <span>
          I agree to Loanthem's
          <Link to="/terms-of-services" target="_blank">
            {" "}
            Terms of Service{" "}
          </Link>
          and
          <Link target="_blank" to="/privacy-policy">
            {" "}
            Privacy Policy{" "}
          </Link>
        </span>
      </div>
      <div className="update-subscription-page__step-1__buttons">
        <Button
          onClick={() => check && onClick("YEARLY")}
          className={`styled-button`}
          disabled={!check}
          type="primary">
          1-Year Multi Note Subscription
        </Button>

        <Button
          onClick={() => check && onClick("PREMIUM")}
          className={`styled-button green`}
          disabled={!check}
          type="primary">
          Premium Subscription
        </Button>
      </div>
    </div>
  );
};

export default Step1;
