import React from "react";
import { Button, Modal } from "antd";
import moment from "moment";

export interface CancelModalProps {
  open: boolean;
  onCancel: () => void;
  onClickConfirm: () => void;
  cancelLoading: boolean;
}

const CancelModal = ({ onCancel, onClickConfirm, open, cancelLoading }: CancelModalProps) => {
  const date = moment().add(14, "days").format("MMM DD, YYYY");
  return (
    <Modal open={open} onCancel={onCancel} footer={null}>
      <div className="cancel-modal">
        <div className="cancel-modal__header">Cancellation</div>
        <div className="cancel-modal__body">
          <span>Are you sure that you want to cancel your package?</span>
          <span>{`Cancellation will take place on ${date}, You will lose access to your data and your Loanthem service plan.`}</span>
        </div>
        <div className="cancel-modal__buttons">
          <Button onClick={onCancel} className={`styled-button`} type="primary">
            Oops, I was mistaken
          </Button>

          <Button
            onClick={onClickConfirm}
            className={`styled-button red hover-color-red`}
            type="ghost"
            loading={cancelLoading}>
            Cancel Subscription
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelModal;
