import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { promissoryNoteRestApi } from "../../services/http";
import { contents, titles } from "./config";
import { IReminderContact, ISelect } from "../../models";
import { UsePromissoryNoteApi } from "../../hooks";

const usePaymentReminder = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();

  const { deleteContacts, getContacts, updateContacts } =
    UsePromissoryNoteApi.useReminderContacts();

  const [step, setStep] = useState(1);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [contacts, setContacts] = useState<IReminderContact[]>([]);
  const [emails, setEmails] = useState<ISelect[]>([]);
  const [days, setDays] = useState(0);
  const [useReminder, setUseReminder] = useState<boolean | undefined>();
  const [hasError, setHasError] = useState(false);

  const getExistContacts = async () => {
    const responseContacts = await getContacts(id!);
    if (Array.isArray(responseContacts.data) && responseContacts.data.length > 0) {
      setDays(responseContacts.data[0].day);
      setUseReminder(true);
      setContacts(responseContacts.data);
    } else {
      const newContacts = [
        {
          day: days,
          email: "",
          phone: "",
        },
      ];
      setContacts(newContacts);
    }
  };

  const getEmails = async () => {
    const response = await promissoryNoteRestApi.getPromissoryNote(Number(id!));
    if (response.data && response.data?.borrowers) {
      const emails: ISelect[] = [];
      response.data?.borrowers.forEach((item) => {
        emails.push({
          label: String(item.email),
          value: String(item.email),
        });
      });
      setEmails(emails);
    }
  };

  const onClickBack = () => {
    navigate(`/dashboard/${type}/${id}`);
  };

  const onClickAddContact = () => {
    const newContacts = [...contacts, ...[{ day: days, email: "", phone: "" }]];
    setContacts(newContacts);
  };

  const validate = () => {
    setHasError(false);
    for (let i = 0; i < contacts.length; i++) {
      // console.log(contacts[i].phone.length);
      if (contacts[i].phone.length > 0) {
        if (contacts[i].phone.length < 10 || contacts[i].phone.length > 12) {
          setHasError(true);
          return false;
        } else if (!contacts[i].email || !contacts[i].phone) {
          setHasError(true);
          return false;
        }
      } else {
        if (!contacts[i].email) {
          setHasError(true);
          return false;
        }
      }
    }

    return true;
  };

  const onClickNext = () => {
    if (step === 1 && useReminder && days >= 0) {
      setStep(2);
      return;
    } else if (useReminder === undefined || !useReminder) {
      onClickBack();
      return;
    }

    if (step === 2) {
      if (validate()) {
        const data = contacts.map((item) => ({ ...item, day: days }));
        updateContacts(Number(id!), data);
        onClickBack();
      }

      return;
    }
  };

  const onClickPrev = () => {
    if (step === 2) {
      setStep(1);
    } else {
      onClickBack();
    }
  };

  const onClickCancel = () => {
    onClickBack();
  };

  const onChangeValue = (key: "EMAIL" | "PHONE", val: string, index: number) => {
    const newContacts = contacts.map((contact, arrayIndex) => {
      if (index === arrayIndex) {
        if (key === "EMAIL") {
          contact.email = val;
        } else {
          contact.phone = val;
        }
      }

      return contact;
    });
    setContacts(newContacts);
  };

  const onClickRemove = (index: number) => {
    deleteContacts(Number(id!), contacts[index].email);
    const newContacts = contacts.filter((_, arrayIndex) => index !== arrayIndex);
    setContacts(newContacts);
  };

  useEffect(() => {
    const title = titles[step - 1];
    const content = contents[step - 1];
    setTitle(title);
    setContent(content);
  }, [step]);

  useEffect(() => {
    getEmails();
    getExistContacts();
  }, []);

  return {
    step,
    title,
    content,
    days,
    useReminder,
    onClickNext,
    onClickPrev,
    onClickCancel,
    onClickRemove,
    onChangeValue,
    setDays,
    setUseReminder,
    contacts,
    emails,
    onClickAddContact,
    hasError,
  };
};

export default usePaymentReminder;
