import { FC, useState } from "react";
import { Row, Form, Button, Input, Typography, Space, DatePicker } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import Previous from "../../common/components/Previous";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
const CoSigner: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const [disable, setDisable] = useState<any>(
    note.has_co_signer == true || note.has_co_signer == false ? !note.has_co_signer : null,
  );
  const { state } = useLocation();
  const previousURL = state ? state.previousURL : null;

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleSubmit = (val: any) => {
    const fields: any = {
      has_co_signer: val.has_co_signer,
    };

    dispatch(promissorySetDataAction(fields));
    if (val.has_co_signer == true) {
      navigate("/co-signer-info");
    } else {
      navigate("/third-party");
    }
  };
  const initialVal = {
    has_co_signer: note.has_co_signer != undefined ? note.has_co_signer : null,
  };
  return (
    <Row className="note" justify={"center"}>
      <Previous
        location={
          previousURL ? "/bussiness-info/borrower" : "/individual-info/borrower"
        }></Previous>
      <div className="note-default"></div>
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Co-Signer
      </Typography.Title>
      <Form
        initialValues={initialVal}
        form={form}
        className="note-form-box"
        onFinish={(val) => handleSubmit(val)}>
        <Typography.Title
          className="note-title"
          style={{ color: "white", fontSize: 14, fontWeight: 400, paddingBottom: 10 }}>
          Is there a Co-Signer?
        </Typography.Title>
        <Form.Item
          style={{ marginTop: "50px" }}
          name="has_co_signer"
          rules={[{ required: true, message: "Please select one of the items" }]}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Button
              className={`note-btn-yes ${disable == true ? "note-btn-enable" : ""}`}
              style={{ backgroundColor: "#188A63", color: "white" }}
              onClick={() => {
                setDisable(false);
                form.setFieldsValue({ has_co_signer: true });
              }}>
              {" "}
              Yes
            </Button>
            <Button
              className={`note-btn-no ${disable == false ? "note-btn-enable" : ""}`}
              style={{ backgroundColor: "#C04E4D", color: "white" }}
              onClick={() => {
                setDisable(true);
                form.setFieldsValue({ has_co_signer: false });
              }}>
              {" "}
              No
            </Button>
          </div>
        </Form.Item>
        <div style={{ height: "50px" }} />
        <div className="note-form-line"></div>
        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
    </Row>
  );
};

export default CoSigner;
