import { useEffect, useState } from "react";
import { IFileResult } from "../../../models";
import useS3 from "../../../hooks/useS3";
import useFile from "../../../hooks/useFile";

const useUploadBox = (
  defaultUrl: string,
  withUser: boolean,
  onChange: (
    file: File | undefined,
    fileResult: IFileResult | undefined,
    s3Data: AWS.S3.ManagedUpload.SendData | undefined,
    url: string,
  ) => void,
) => {
  const [file, setFile] = useState<File | undefined>();
  const [fileResult, setFileResult] = useState<IFileResult | undefined>();

  const { uploadToS3, sending, url, removeUrl, s3Data } = useS3(defaultUrl);
  const { handleChange } = useFile({
    withUser,
    onDone: (fileResult, file, withUser) => {
      setFile(file);
      setFileResult(fileResult);
      uploadToS3(file, withUser);
    },
  });

  useEffect(() => {
    onChange(file, fileResult, s3Data, url ?? "");
  }, [url]);

  const clearImage = () => {
    setFile(undefined);
    setFileResult(undefined);
    removeUrl();
  };

  return { handleChange, sending, url, clearImage };
};

export default useUploadBox;
