import { FC, useState } from "react";
import { Row, Form, Button, Input, Typography, Space, DatePicker } from "antd";
import { RightOutlined } from "@ant-design/icons";
import InfoBox from "src/common/components/InfoBox";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import Previous from "src/common/components/Previous";

const FailureTopay: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);

  const [disable, setDisable] = useState<any>(
    note.has_full_payment == true || note.has_full_payment == false ? !note.has_full_payment : null,
  );
  const [hasFailure, setHasFailure] = useState(false);
  const [days, setDays] = useState<any>(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleHasFailure = (has: any, disable: any) => {
    setHasFailure(has);
    setDisable(disable);
    if (disable) {
      setDays(null);
    }
  };
  const handleNumberValidation = (e: any) => {
    if (e.key.length === 1 && /\D/.test(e.key)) {
      e.preventDefault();
    }
  };
  const initialVal = {
    has_full_payment:
      note.has_full_payment == true || note.has_full_payment == false
        ? note.has_full_payment
        : null,
    immediate_full_payment_days: note.immediate_full_payment_days
      ? note.immediate_full_payment_days
      : null,
  };
  return (
    <Row className="note" justify={"center"}>
      <Previous location="/late-fee" />
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Failure to Pay
      </Typography.Title>
      <Form
        initialValues={initialVal}
        form={form}
        className="note-form-box"
        onFinish={(values) => {
          navigate("/demand-payment");
          const fields: any = {
            has_full_payment: values.has_full_payment,
            immediate_full_payment_days: values.has_full_payment
              ? +values.immediate_full_payment_days
              : 0,
          };
          dispatch(promissorySetDataAction(fields));
        }}>
        <Typography.Title
          className="note-title"
          style={{ color: "white", fontSize: 14, fontWeight: 400, paddingBottom: 20 }}>
          Can the Lender demand full payment for late payment or failure to pay?
          <span
            style={{
              display: "inline-block",
              marginLeft: "4px",
              color: "#ff4d4f",
              fontSize: "14px",
              fontFamily: "SimSun,sans-serif",
              lineHeight: 1,
            }}>
            *
          </span>
        </Typography.Title>
        <Form.Item
          name="has_full_payment"
          rules={[{ required: true, message: "Please select one of the items" }]}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Button
              className={`note-btn-yes ${disable == true ? "note-btn-enable" : ""}`}
              onClick={() => {
                handleHasFailure(true, false);
                form.setFieldsValue({ has_full_payment: true });
              }}>
              Yes
            </Button>
            <Button
              className={`note-btn-no ${disable == false ? "note-btn-enable" : ""}`}
              onClick={() => {
                {
                  handleHasFailure(false, true);
                  form.setFieldsValue({ immediate_full_payment_days: "", has_full_payment: false });
                }
              }}>
              No
            </Button>
          </div>
        </Form.Item>

        <Form.Item
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column" }}
          label="After how many days late?"
          required
          rules={[{ required: disable == false ? true : false, message: "Please enter subject!" }]}
          name="immediate_full_payment_days">
          <Input
            style={{ height: "37px", borderRadius: "5px" }}
            placeholder={"0"}
            className="placeholder-center"
            onKeyDown={(e) => handleNumberValidation(e)}
            disabled={disable == true || disable == null ? true : false}
            suffix={
              <>
                <div style={{ borderRight: "1px solid black", height: "25px" }}></div>
                <button
                  style={{ height: "37px", border: "none", background: "transparent" }}
                  onClick={(e) => e.preventDefault()}
                  className="note-btn-group">
                  Day(s) Late
                </button>
              </>
            }
          />
        </Form.Item>
        <div
          className="note-form-line"
          style={{
            borderBottom: "1px solid rgba(164, 179, 244, 1)",
            width: "100%",
            marginTop: "20px",
            borderTop: "none",
          }}></div>
        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
      <InfoBox text="Federal and/or state law may require lenders to provide borrowers with a written demand letter that includes: names of lenders and borrowers, principal amount, interest rate, due date, amount due, late fees or charges, payment address, amongst other requirements." />
    </Row>
  );
};

export default FailureTopay;
