import React, { FC, useState } from "react";
import { Row, Form, Button, Input, Typography, Select } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import InfoBox from "src/common/components/InfoBox";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "../../store";
import Previous from "../../common/components/Previous";

const { Option } = Select;

const Interest: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);

  const textInterest =
    "LoanThem will not adjust the amount of interest to comply with state law.  Please consult state usury laws for the maximum amount of interest permitted.  LoanThem is not liable for " +
    "any note charging an illegal interest rate. \n \n " +
    "If the interest rate is below the Applicable Federal Rate (AFR), the IRS may impute interest income to the lender at the AFR, unless it is considered a non-taxable gift.";

  const [disable, setDisable] = useState<any>(
    note.has_interest == true || note.has_interest == false ? !note.has_interest : null,
  );

  const [hasLateFee, setHasLateFee] = useState<boolean>(true);
  const [interestPayment, setInterestPayment] = useState<any>(note.interest ? note.interest : 0);
  const [percent, setPercent] = useState<any>("");
  const [compound_period, setcompound_period] = useState<any>(
    note.compound_period != undefined ? note.compound_period : "",
  );
  const [form] = Form.useForm();

  const handleHasLateFee = (has: any, disable: any) => {
    setHasLateFee(has);
    setDisable(disable);
    if (disable) {
      setInterestPayment(0);
      setcompound_period("");
    }
  };

  const handleRemove = (e: any, remove = false, type = "") => {
    if ((e.key.length === 1 && /\D/.test(e.key)) || (type == "percent" && note)) {
      e.preventDefault();
    }
    if (e.key == "Backspace" && remove) setInterestPayment(interestPayment.slice(0, -1));
  };

  const handelSign = (val: string, sign: string, callback: any) => {
    callback(val.replace(sign, ""));
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialVal = {
    has_interest:
      note.has_interest == true || note.has_interest == false ? note.has_interest : null,
    chargable: note.chargable == true || note.chargable == false ? note.chargable : null,
    interest: note.interest ? note.interest : null,
    compound_period: note.compound_period != undefined ? note.compound_period.toString() : null,
  };

  return (
    <Row className="interest" justify={"center"}>
      <Previous location={"/amount"}></Previous>
      <Typography.Title className="interest-text" style={{ color: "white", fontSize: 25 }}>
        Interest
      </Typography.Title>
      <Form
        form={form}
        initialValues={initialVal}
        className="note-form-box"
        onFinish={(values) => {
          const fields: any = {
            has_interest: values.has_interest,
            interest: values.has_interest && interestPayment ? +interestPayment : null,
            compound_period: values.has_interest ? +values.compound_period : null,
            chargable: values.has_interest,
          };
          dispatch(promissorySetDataAction(fields));
          navigate("/agreement-payment");
        }}>
        <Form.Item style={{ textAlign: "center" }}>
          <Typography.Text style={{ color: "white", display: "inline-flex", paddingTop: 13 }}>
            Will interest be charged?
            <div style={{ color: "red" }}>*</div>
          </Typography.Text>
        </Form.Item>
        <Form.Item
          name="has_interest"
          rules={[{ required: true, message: "Please select one of the items" }]}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <Button
              className={`note-btn-yes ${disable == true ? "note-btn-enable" : ""}`}
              onClick={() => {
                handleHasLateFee(true, false);
                form.setFieldsValue({ has_interest: true });
              }}>
              Yes
            </Button>
            <Button
              className={`note-btn-no ${disable == false ? "note-btn-enable" : ""}`}
              onClick={() => {
                handleHasLateFee(false, true);
                form.resetFields();
                form.setFieldsValue({ has_interest: false });
                form.setFieldsValue({ interest: null });
                form.setFieldsValue({ compound_period: null });
              }}>
              No
            </Button>
          </div>
        </Form.Item>
        <Form.Item
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
          label="How much will the interest rate be?"
          rules={[
            {
              required: disable == false ? true : false,
              message: "Please enter the interest rate.",
            },
          ]}
          required
          name="interest">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <Input
              className="note-formItem-input"
              placeholder="Enter the interest rate"
              maxLength={4}
              style={{ borderRadius: 5 }}
              disabled={disable == true || disable == null ? true : false}
              size={"large"}
              name="interest"
              value={interestPayment ? interestPayment + "%" : ""}
              onKeyDown={(e) => handleRemove(e, true, "amount")}
              onChange={(e) => handelSign(e.target.value, "%", setInterestPayment)}
            />
          </div>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: disable == false ? true : false,
              message: "Please select compounded value",
            },
          ]}
          label="How often will interest be compounded?"
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column" }}
          name="compound_period"
          required>
          <Select
            style={{ borderRadius: 5 }}
            value={compound_period}
            placeholder="Select"
            size={"large"}
            disabled={disable == true || disable == null ? true : false}>
            <Option value={disable ? "" : "0"}>Never</Option>
          </Select>
        </Form.Item>
        <div className="note-form-line"></div>
        <Button
          style={{ marginTop: "40px" }}
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
      <InfoBox text={textInterest} />
    </Row>
  );
};

export default Interest;
