import React from "react";
import { Button, Input } from "antd";

export interface Step1Props {
  onClick: (val: boolean) => void;
  useReminder?: boolean;
  daysLater: number;
  onChangeDaysLater: (val: number) => void;
}

const Step1 = ({ onClick, useReminder, daysLater, onChangeDaysLater }: Step1Props) => {
  return (
    <div className="payment-reminder-page__step-1">
      <div className="payment-reminder-page__step-1__buttons">
        <Button
          onClick={() => onClick(true)}
          className={`styled-button green ${
            useReminder !== undefined && !useReminder ? "disabled" : ""
          }`}
          type="primary">
          Yes
        </Button>
        <Button
          onClick={() => onClick(false)}
          className={`styled-button red ${
            useReminder !== undefined && useReminder ? "disabled" : ""
          }`}
          type="primary">
          No
        </Button>
      </div>
      <div className="payment-reminder-page__step-1__input">
        <span className="payment-reminder-page__step-1__input__header">
          How many days before payment?<span className="red">*</span>
        </span>
        <Input
          value={daysLater}
          disabled={!useReminder}
          placeholder="Enter Amount of Interest"
          addonAfter="Day(s) Before"
          onChange={(e) => {
            onChangeDaysLater(Number(e.target.value));
          }}
        />
      </div>
    </div>
  );
};

export default Step1;
