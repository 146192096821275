import React from "react";
import { Avatar } from "antd";

export interface HistoryHeaderItemProps {
  key: number;
  date: string;
  profilePic: string;
  color: string;
  abbreviation: string;
  paymentStatus: string;
}

const HistoryHeaderItem = ({
  date,
  key,
  profilePic,
  color,
  abbreviation,
  paymentStatus,
}: HistoryHeaderItemProps) => {
  return (
    <div className="payment-history-header" key={key}>
      <Avatar
        size={40}
        style={{
          backgroundColor: color,
        }}
        src={profilePic}>
        {abbreviation}
      </Avatar>
      <div className="payment-history-header-contnet-container">
        <span>{date}</span>
        {paymentStatus == "PAID" ? <span>Paid</span> : null}
      </div>
    </div>
  );
};

export default HistoryHeaderItem;
