import React from "react";
import { Button, Modal } from "antd";

export interface SignModalProps {
  open: boolean;
  onCancel: () => void;
  text: string;
}

const SignModal = ({
  onCancel,
  open,
  text = "Please sign your Docusign to initiate installment processing",
}: SignModalProps) => {
  return (
    <Modal open={open} onCancel={onCancel} footer={null}>
      <div className="sign-modal">
        <div className="sign-modal__header">Error</div>
        <div className="sign-modal__text">{text}</div>
        <div className="sign-modal__buttons">
          <Button type="primary" onClick={onCancel}>
            Go Back
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SignModal;
