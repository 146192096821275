import React from "react";

import { ReactComponent as IconStarFill } from "../../assets/icons/ic-star-green.svg";
import { ReactComponent as IconStar } from "../../assets/icons/ic-star-gray.svg";

export interface StarProps {
  index: number;
  onClick: (index: number) => void;
  active: boolean;
}

const Star = ({ index, active, onClick }: StarProps) => {
  return (
    <li
      onClick={() => {
        onClick(index);
      }}>
      {active ? <IconStarFill /> : <IconStar />}
    </li>
  );
};

export default Star;
