import { applyMiddleware, createStore, Middleware } from "redux";
import createSagaMiddleware from "redux-saga";
import AppReducer from "./reducers";
import { RootSaga } from "./saga";

const middlewares: Middleware[] = [];

/* saga middleware  */
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);
/* saga middleware */

const compose =
  process.env.NODE_ENV === "development"
    ? require("redux-devtools-extension").composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares);
const Store = createStore(AppReducer, compose);
// sagaMiddleware.run();

sagaMiddleware.run(RootSaga);

export default Store;
