import axios from "axios";

import { BASE_URL } from "../ENV";

export const usePaymentApi = () => {
  const renewPayment = async () => {
    const response = axios.post(`${BASE_URL}/api/v1/payments/renew-plan?platform-name=WEB`);
    return response;
  };

  const selectPlan = async (
    plan: "PREMIUM_PACKAGE" | "MULTIPLE_PACKAGE" | "SINGLE_PACKAGE",
    platformName = "WEB",
  ) => {
    const response = axios.post(
      `${BASE_URL}/api/v1/payments/select-plan?platform-name=${platformName}&plan-name=${plan}`,
    );
    return response;
  };

  const cancelPlan = async () => {
    const response = axios.post(`${BASE_URL}/api/v1/users/cancel-plan`);
    return response;
  };

  const getHistory = async (promissoryNoteId: number | string) => {
    const response = axios.get(
      `${BASE_URL}/api/v1/payments/payments_history?promissoryNoteId=${promissoryNoteId}`,
    );
    return response;
  };

  const goToStripe = async () => {
    const response = axios.post(`${BASE_URL}/api/v1/payments/account-links`);
    return response;
  };

  const completeStripe = async () => {
    const response = axios.post(`${BASE_URL}/api/v1/payments/stripe-dashboard`);
    return response;
  };

  const acceptPayment = async (promissoryNoteId: number, amortizationCount: number) => {
    const response = axios.post(
      `${BASE_URL}/api/v1/payments/accept-payment`,
      {},
      {
        params: {
          promissoryNoteId,
          amortizationCount,
          "platform-name": "WEB",
        },
      },
    );
    return response;
  };

  return {
    renewPayment,
    selectPlan,
    cancelPlan,
    getHistory,
    goToStripe,
    completeStripe,
    acceptPayment,
  };
};
