import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { promissoryNoteRestApi } from "../../services";
import { RootState, promissoryRemoveDataAction, promissorySetRouteAction } from "../../store";
import { PROMISSORY_NOTE_KEY, PROMISSORY_ROUTE_KEY } from "src/ENV";
import Previous from "../../common/components/Previous";
import { handleShowLoginRegisterModal } from "src/store/actions/siteActions/siteAction";

const DraftPage: FC = () => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [amortizationPdfUrl, setamortizationPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const note = useSelector((store: RootState) => store.promissoryReducer);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((store: RootState) => store.authReducer);

  const note1 = {
    agreement_begin: note.agreement_begin,
    amount: note.amount ? note.amount : null,
    borrowers: note.borrowers ? note.borrowers : [],
    co_signers: note.co_signers ? note.co_signers : [],
    collateral: note.collateral,
    collateral_description: note.collateral_description ? note.collateral_description : "",
    compound_period: note.has_interest ? note.compound_period : null,
    extra_clause: note.extra_clause ? note.extra_clause : [""],
    first_payment_due: note.first_payment_due ? note.first_payment_due : "",
    has_co_signer: note.has_co_signer ? note.has_co_signer : false,
    has_extra_claues: note.has_extra_claues ? note.has_extra_claues : false,
    has_full_payment: note.has_full_payment ? note.has_full_payment : false,
    has_interest: note.has_interest ? note.has_interest : false,
    has_interest_rate: note.has_interest_rate ? note.has_interest_rate : false,
    has_late_fee: note.has_late_fee ? note.has_late_fee : false,
    has_lump_sum_payment: note.has_lump_sum_payment ? note.has_lump_sum_payment : false,
    has_penalty: note.has_penalty ? note.has_penalty : false,
    immediate_full_payment_days: note.immediate_full_payment_days
      ? note.immediate_full_payment_days
      : null,
    installment_payment_method: note.installment_payment_method
      ? note.installment_payment_method
      : "BANK_WIRE_TRANSFER",
    interest: note.interest ? note.interest : null,
    interest_increase_rate_days: note.interest_increase_rate_days
      ? note.interest_increase_rate_days
      : null,
    interest_increase_rate_percent: note.interest_increase_rate_percent
      ? note.interest_increase_rate_percent
      : null,
    last_payment_due: note.last_payment_due ? note.last_payment_due : "",
    late_fee_amount: note.late_fee_amount ? note.late_fee_amount : null,
    late_fee_days: note.late_fee_days ? note.late_fee_days : null,
    late_fee_percent: note.late_fee_percent ? note.late_fee_percent : null,
    lender_promissory_note_resided_city: note.lender_promissory_note_resided_city
      ? note.lender_promissory_note_resided_city
      : "",
    lender_promissory_note_resided_state: note.lender_promissory_note_resided_state
      ? note.lender_promissory_note_resided_state
      : "",
    lenders: note.lenders ? note.lenders : [],
    lenders_resided_state: note.lenders_resided_state ? note.lenders_resided_state : "",
    loan_pay_ability_type: note.loan_pay_ability_type ? note.loan_pay_ability_type : "",
    lump_sum_payment: note.lump_sum_payment ? note.lump_sum_payment : null,
    lump_sum_payment_due: note.lump_sum_payment_due ? note.lump_sum_payment_due : "",
    name: note.name ? note.name : "",
    note_enforce_venue: note.note_enforce_venue ? note.note_enforce_venue : "",
    payment_penalty_percent: note.payment_penalty_percent ? note.payment_penalty_percent : null,
    payment_period: note.payment_period ? note.payment_period : "",
    personal_property_collateral: note.personal_property_collateral
      ? note.personal_property_collateral
      : false,
    sign_party: note.sign_party ? note.sign_party : "",
    status: "DRAFT",
    third_party: note.third_party ? note.third_party : false,
    transfer_type: note.transfer_type ? note.transfer_type : "SIGNING",
    witness_infos: note.witness_infos ? note.witness_infos : [],
    witness_type: note.witness_type ? note.witness_type : "",
  };

  const amortiNote = {
    amount: note.amount ? note.amount : 0,
    has_interest: note.has_interest ? note.has_interest : false,
    interest: note.interest ? note.interest : 0,
    first_payment_due: note.first_payment_due ? note.first_payment_due : "",
    last_payment_due: note.last_payment_due ? note.last_payment_due : "",
    payment_period: note.payment_period,
    has_lump_sum_payment: note.has_lump_sum_payment ? note.has_lump_sum_payment : false,
    lump_sum_payment: note.lump_sum_payment ? note.lump_sum_payment : null,
    lump_sum_payment_due: note.lump_sum_payment_due ? note.lump_sum_payment_due : "",
    has_late_fee: note.has_late_fee ? note.has_late_fee : false,
    late_fee_days: note.late_fee_days ? note.late_fee_days : null,
    late_fee_percent: note.late_fee_percent ? note.late_fee_percent : null,
    late_fee_amount: note.late_fee_amount ? note.late_fee_amount : null,
  };

  const handleCreateAndBackHome = async () => {
    setLoading(true);
    if (
      user?.activePlan &&
      (user?.activePlan === "MULTIPLE_PACKAGE" || user?.activePlan === "PREMIUM_PACKAGE")
    ) {
      const response = await promissoryNoteRestApi.add1({
        name: note.name,
        amount: note.amount,
        chargable: note.chargable ? note.chargable : false,
        has_interest: note.has_interest,
        interest: note.interest,
        compound_period: note.compound_period,
        agreement_begin: note.agreement_begin,
        first_payment_due: note.first_payment_due,
        last_payment_due: note.last_payment_due,
        payment_period: note.payment_period,
        has_lump_sum_payment: note.has_lump_sum_payment,
        lump_sum_payment: note.lump_sum_payment,
        lump_sum_payment_due: note.lump_sum_payment_due,
        has_late_fee: note.has_late_fee,
        late_fee_days: note.late_fee_days,
        late_fee_percent: note.late_fee_percent,
        late_fee_amount: note.late_fee_amount,
        has_penalty: note.has_penalty,
        payment_penalty_percent: note.payment_penalty_percent,
        has_interest_rate: false,
        interest_increase_rate_percent: note.interest_increase_rate_percent,
        interest_increase_rate_days: note.interest_increase_rate_days,
        has_full_payment: note.has_full_payment,
        immediate_full_payment_days: note.immediate_full_payment_days,
        loan_pay_ability_type: note.loan_pay_ability_type,
        collateral: note.collateral,
        collateral_description: note.collateral_description,
        personal_property_collateral: note.personal_property_collateral,
        transfer_type: note.transfer_type,
        lenders_resided_state: note.lenders_resided_state,
        lender_promissory_note_resided_state: note.lender_promissory_note_resided_state,
        lender_promissory_note_resided_city: note.lender_promissory_note_resided_city,
        note_enforce_venue: note.note_enforce_venue,
        witness_type: note.witness_type,
        witness_infos: note.witness_infos,
        sign_party: note.sign_party,
        has_extra_claues: note.has_extra_claues,
        extra_clause: note.extra_clause ? note.extra_clause : [],
        lenders: note.lenders,
        borrowers: note.borrowers,
        has_co_signer: note.has_co_signer,
        co_signers: note.co_signers,
        third_party: note.third_party,
        installment_payment_method: note.installment_payment_method,
      });
      if (response.data && response.data.id) {
        promissoryNoteRestApi.postToSign(response.data.id);
        localStorage.removeItem(PROMISSORY_NOTE_KEY);
        localStorage.removeItem(PROMISSORY_ROUTE_KEY);
        dispatch(promissoryRemoveDataAction());
        dispatch(promissorySetRouteAction(""));
        navigation("/home", {
          replace: true,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    promissoryNoteRestApi
      .getPromissoryNoteTempPreview(note1, {
        responseType: "arraybuffer",
        data: note1,
      })
      .then((data: any) => {
        const blob = new Blob([data.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      });
  }, []);

  useEffect(() => {
    promissoryNoteRestApi
      .getAmortizationListTempPreview(amortiNote, {
        responseType: "arraybuffer",
        data: note1,
      })
      .then((data: any) => {
        const blob = new Blob([data.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        setamortizationPdfUrl(url);
      });
  }, []);

  let hasPlan = false;

  if (
    user?.activePlan &&
    (user?.activePlan === "MULTIPLE_PACKAGE" || user?.activePlan === "PREMIUM_PACKAGE")
  ) {
    hasPlan = true;
  }

  return (
    <div style={{ marginTop: 20 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}>
        <div
          style={{
            color: "white",
            fontSize: 24,
            fontWeight: 700,
            fontFamily: "Inter",
          }}>
          Promissory Note Draft
        </div>
        <div
          style={{
            color: "white",
            fontSize: 14,
            fontWeight: 400,
            fontFamily: "Inter",
          }}>
          Congratulations! Here is your draft Promissory Note
        </div>
      </div>
      <Previous location="/note-name" />
      {pdfUrl ? (
        <iframe src={`${pdfUrl}#toolbar=0`} width="100%" height="600px" title="PDF Preview" />
      ) : (
        <p style={{ textAlign: "center", color: "white" }}>Loading PDF...</p>
      )}

      <div className="draft-btns">
        {!user ? (
          <Button
            onClick={() => dispatch(handleShowLoginRegisterModal("LOGIN"))}
            style={{
              background: "#026BFF",
              borderRadius: "4px",
              border: "1px solid #026BFF",
              color: "white",
              fontWeight: 500,
            }}>
            Login Or Register
          </Button>
        ) : hasPlan ? (
          <Button
            onClick={() => handleCreateAndBackHome()}
            loading={loading}
            style={{
              background: "#026BFF",
              borderRadius: "4px",
              border: "1px solid #026BFF",
              color: "white",
              fontWeight: 500,
            }}>
            Create Note
          </Button>
        ) : (
          <Link to={"/select-plan"}>
            <Button
              style={{
                background: "#026BFF",
                borderRadius: "4px",
                border: "1px solid #026BFF",
                color: "white",
                fontWeight: 500,
              }}>
              Select your plan
            </Button>
          </Link>
        )}
        <Button
          className="draft-btns-center"
          onClick={() => {
            window.open(amortizationPdfUrl, "_blank");
          }}>
          Preview Amortization Schedule
        </Button>
        <Button
          style={{
            background: "white",
            borderRadius: "4px",
            border: "1px solid #026BFF",
            fontWeight: 500,
            color: "#026BFF",
          }}
          onClick={() => {
            window.open(pdfUrl, "_blank");
          }}>
          Preview Note
        </Button>
      </div>
    </div>
  );
};

export default DraftPage;
