import React from "react";
import { Input, Select, Spin } from "antd";

import { GeneralForm, TopNavController, UploadBox } from "../../common/components";
import useProfile from "./useProfile";

const Profile = () => {
  const {
    loading,
    onClickBack,
    updateProfile,
    onProfileUpload,
    city,
    firstName,
    fullAddress,
    lastName,
    setCity,
    setFirstName,
    setFullAddress,
    setLastName,
    setState,
    setZipCode,
    state,
    zipCode,
    cities,
    states,
    initiate,
    imageUrl,
    mobileNumber,
    email,
  } = useProfile();

  return (
    <div className="profile-page">
      <TopNavController showCancel={false} onClickPrev={onClickBack} />
      <GeneralForm
        headerTitle="Edit Profile"
        buttonText="Save"
        onClick={updateProfile}
        checkboxVisible={false}
        loading={loading}>
        {initiate ? (
          <Spin className="profile-loading" />
        ) : (
          <div className="profile-page__main-wrapper">
            <div className="profile-page__main-wrapper__upload">
              <UploadBox
                withUser={true}
                defaultUrl={imageUrl}
                onChange={(_, fileResult, s3Data, url) => onProfileUpload(s3Data, fileResult, url)}
              />
            </div>
            <div className="profile-page__main-wrapper__form">
              <div className="profile-page__main-wrapper__form__item">
                <div className="profile-page__main-wrapper__form__item__header">
                  First name <span>*</span>
                </div>
                <Input
                  value={firstName}
                  placeholder="First name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>

              <div className="profile-page__main-wrapper__form__item">
                <div className="profile-page__main-wrapper__form__item__header">
                  Last name <span>*</span>
                </div>
                <Input
                  value={lastName}
                  placeholder="Last name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>

              <div className="profile-page__main-wrapper__form__item">
                <div className="profile-page__main-wrapper__form__item__header">Email</div>
                <Input value={email} disabled placeholder="Email" />
              </div>

              <div className="profile-page__main-wrapper__form__item">
                <div className="profile-page__main-wrapper__form__item__header">Phone Number</div>
                <Input value={mobileNumber} disabled placeholder="Phone Number" />
              </div>

              <div className="profile-page__main-wrapper__form__item">
                <div className="profile-page__main-wrapper__form__item__header">
                  Zip Code <span>*</span>
                </div>
                <Input
                  value={zipCode}
                  placeholder="Zip code"
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                />
              </div>

              <div className="profile-page__main-wrapper__form__item">
                <div className="profile-page__main-wrapper__form__item__header">
                  Address <span>*</span>
                </div>
                <Input
                  value={fullAddress}
                  placeholder="Full Address"
                  onChange={(e) => {
                    setFullAddress(e.target.value);
                  }}
                />
              </div>

              <div className="profile-page__main-wrapper__form__item">
                <div className="profile-page__main-wrapper__form__item__header">
                  State <span>*</span>
                </div>
                <Select
                  defaultValue={state}
                  onChange={(val: string) => setState(val)}
                  options={states}
                />
              </div>

              <div className="profile-page__main-wrapper__form__item">
                <div className="profile-page__main-wrapper__form__item__header">
                  City <span>*</span>
                </div>
                <Select
                  defaultValue={city}
                  onChange={(val: string) => setCity(val)}
                  options={cities}
                />
              </div>
            </div>
          </div>
        )}
      </GeneralForm>
    </div>
  );
};

export default Profile;
