export function registerBgReducer(state: any = { bg: 1 }, action: any) {
  switch (action.type) {
    case "REGISTERSETBG":
      localStorage.setItem("REGISTERBG", action.payload);

      return { ...state, bg: action.payload };
    default:
      return state;
  }
}
