import { FC, useState, useEffect } from "react";
import { Row, Form, Button, Input, Typography, Select } from "antd";
import { RightOutlined } from "@ant-design/icons";
import InfoBox from "src/common/components/InfoBox";
import { useLocationService } from "./../../hooks/locationService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import Previous from "src/common/components/Previous";

const Jurisdiction: FC = () => {
  const { Option } = Select;
  const { cityList, isFetching, getPageCity, states, getStateList } = useLocationService();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const dispatch = useDispatch();

  // State variables to keep track of the input states
  const [isFirstInputFilled, setIsFirstInputFilled] = useState(false);
  const [isSecondInputFilled, setIsSecondInputFilled] = useState(false);
  const [isThirdInputDisabled, setIsThirdInputDisabled] = useState(true);

  useEffect(() => {
    getStateList();
    if (note.lenders_resided_state) {
      handelCity(note.lenders_resided_state);
      setIsFirstInputFilled(true);
    }
  }, []);

  const handelCity = (val: any) => {
    getPageCity(val);
    form.setFieldsValue({ city: "" });
  };

  const handleSubmit = (value: any) => {
    dispatch(promissorySetDataAction(value));
    navigate("/enforcement");
  };

  const initialValues: any = {
    lenders_resided_state: note.lenders_resided_state ? note.lenders_resided_state : null,
    lender_promissory_note_resided_state: note.lender_promissory_note_resided_state
      ? note.lender_promissory_note_resided_state
      : null,
    lender_promissory_note_resided_city: note.lender_promissory_note_resided_city
      ? note.lender_promissory_note_resided_city
      : null,
  };

  useEffect(() => {
    setIsThirdInputDisabled(!(isFirstInputFilled && isSecondInputFilled));
  }, [isFirstInputFilled, isSecondInputFilled]);

  return (
    <Row className="note" justify={"center"}>
      <Previous location="/collateral-transfer" />
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Jurisdiction
      </Typography.Title>
      <Form
        initialValues={initialValues}
        form={form}
        className="note-form-box"
        onFinish={(value) => handleSubmit(value)}>
        <Form.Item
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column" }}
          label="Where does the Lender reside?"
          rules={[{ required: true, message: "Please Select Lender's state" }]}
          required
          name="lenders_resided_state">
          <Select
            placeholder="Select"
            size={"large"}
            onChange={(e: any) => {
              handelCity(e);
              setIsFirstInputFilled(!!e);
              form.setFieldValue("lender_promissory_note_resided_city", null);
            }}>
            {states.map((e: any, i) => {
              return (
                <Option key={i} value={e.name}>
                  {e.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column" }}
          label="In which city?"
          rules={[{ required: true, message: "Please Enter city name" }]}
          required
          name="lender_promissory_note_resided_city">
          <Select
            placeholder="Select"
            size={"large"}
            disabled={!isFirstInputFilled}
            onChange={() => setIsSecondInputFilled(true)}>
            {cityList?.map((e: any, i) => {
              return (
                <Option key={e.id} value={e.id}>
                  {e.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Typography.Title
          className="note-title"
          style={{
            color: "white",
            fontSize: 14,
            fontWeight: 400,
            textAlign: "left",
            marginTop: 15,
          }}>
          In which state does the Lender prefer to enforce the promissory note?{" "}
          <span style={{ color: "#ff4d4f", lineHeight: 1, fontSize: "14px" }}>*</span>
        </Typography.Title>
        <Form.Item
          className="note-formItem-input label-wrap"
          style={{ display: "flex", flexDirection: "column", textAlign: "center" }}
          rules={[{ required: true, message: "Please Select state" }]}
          required
          name="lender_promissory_note_resided_state">
          <Select placeholder="Select" size={"large"}>
            {states.map((e: any, i) => {
              return (
                <Option key={i} value={e.name}>
                  {e.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <div className="note-form-line"></div>
        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
      <InfoBox text="Lender’s and Borrower’s contacts with the state, location of any collateral, and a state’s statute of limitations may be venue considerations. If necessary, please consult a legal professional." />
    </Row>
  );
};

export default Jurisdiction;
