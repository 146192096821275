/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { promissoryNoteRestApi } from "../../services/http";

const useDemandLetter = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const location = useLocation();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");

  const getPreview = async () => {
    const response = await promissoryNoteRestApi.getDemandPreview(Number(id!), {
      responseType: "blob",
    });
    //@ts-ignore
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    setPreviewUrl(url);
  };

  const getPreviewFull = async () => {
    const response = await promissoryNoteRestApi.getDemandImmediatePreview(Number(id!), {
      responseType: "blob",
    });
    //@ts-ignore
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    setPreviewUrl(url);
  };

  const onClickPrev = () => {
    navigate(`/dashboard/${type}/${id}`);
  };

  const downloadPdf = () => {
    if (previewUrl) {
      window.open(previewUrl, "_blank");
    }
  };

  useEffect(() => {
    const pathNameArray = location.pathname.split("/");
    if (pathNameArray[pathNameArray.length - 1] === "scheduled") {
      setTitle("Demand Letter For Payment Of Promissory Note");
      setContent("Demand and Notice for Payment of Promissory Note Amount");
      getPreview();
    } else {
      setTitle("Demand Letter For Immediate Full Payment of Promissory Note");
      setContent("Demand and Notice For Immediate Full Payment Of<br />Promissory Note Amount");
      getPreviewFull();
    }
  }, []);

  return {
    title,
    content,
    onClickPrev,
    previewUrl,
    downloadPdf,
  };
};

export default useDemandLetter;
