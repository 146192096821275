import { FC, useState } from "react";
import { Row, Form, Button, Input, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import Previous from "src/common/components/Previous";

const ExtraClause: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const dispatch = useDispatch();
  const [disable, setDisable] = useState<any>(
    note.has_extra_claues == true || note.has_extra_claues == false ? !note.has_extra_claues : null,
  );
  const [hasCollateral, setHasCollateral] = useState(false);
  const [collateral, setCollateralDetail] = useState("");

  const navigate = useNavigate();
  const { TextArea } = Input;

  const handleHasCollateral = (has: any, disable: any) => {
    setHasCollateral(has);
    setDisable(disable);
    if (disable) {
      setCollateralDetail("");
    }
  };

  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    const hasExtraClausesArray = values.has_extra_claues ? values.extra_clause.split("\n") : [];
    const fields: any = {
      has_extra_claues: values.has_extra_claues ? values.has_extra_claues : false,
      extra_clause: values.has_extra_claues ? [values.extra_clause] : [""],
    };
    console.log(fields);
    dispatch(promissorySetDataAction(fields));
    navigate("/lender");
  };

  const initialVal = {
    has_extra_claues:
      note.has_extra_claues == true || note.has_extra_claues == false
        ? note.has_extra_claues
        : null,
    extra_clause: note.extra_clause ? note.extra_clause[0] : "",
  };

  return (
    <Row className="note" justify={"center"}>
      <Previous
        location={note.witness_type === "NO_WITNESS" ? "/witness-signing" : "/witness-info"}
      />
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Extra Clauses
      </Typography.Title>
      <Form
        initialValues={initialVal}
        form={form}
        className="note-form-box"
        onFinish={(val) => handleSubmit(val)}>
        <Typography.Title
          className="note-title"
          style={{ color: "white", fontSize: 14, fontWeight: 400, paddingBottom: 20 }}>
          Would you like to add any extra clauses?
        </Typography.Title>
        <Form.Item
          name="has_extra_claues"
          rules={[{ required: true, message: "Please select one of the items" }]}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Button
              className={`note-btn-yes ${disable === true ? "note-btn-enable" : ""}`}
              onClick={() => {
                handleHasCollateral(true, false);
                form.setFieldsValue({ has_extra_claues: true });
              }}>
              Yes
            </Button>
            <Button
              className={`note-btn-no ${disable === false ? "note-btn-enable" : ""}`}
              onClick={() => {
                handleHasCollateral(false, true);
                form.setFieldsValue({ extra_clause: "", has_extra_claues: false });
              }}>
              No
            </Button>
          </div>
        </Form.Item>
        <Typography.Title
          className="note-title"
          style={{
            color: "white",
            fontSize: 14,
            fontWeight: 400,
            paddingBottom: 20,
            textAlign: "left",
          }}>
          Describe the additional duties and obligations of the Lender and the Borrower.{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography.Title>
        <Form.Item
          className="note-formItem-input label-wrap"
          style={{ display: "flex", flexDirection: "column" }}
          rules={[
            { required: disable === false ? true : false, message: "Please enter the subject." },
          ]}
          required
          name="extra_clause">
          <TextArea
            style={{ borderRadius: 5, resize: "none" }}
            rows={6}
            placeholder={"1.\n\n2.\n\n3"}
            maxLength={1000}
            disabled={disable === true || disable === null ? true : false}
            onChange={(e) => {
              const text = e.target.value;
              const lines = text.split("\n");
              setCollateralDetail(lines.join("\n"));
            }}
          />
        </Form.Item>
        <span className="note-max">Max. 1000 characters</span>
        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
    </Row>
  );
};

export default ExtraClause;
