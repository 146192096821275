import React, { FC } from "react";
import { Row } from "antd";

const About: FC = () => {
  return (
    <Row>
      <div className="about--container">
        <div style={{ display: "flex" }}>
          <div>
            <div>
              <h4 className="about--title">About</h4>

              <div className="about--txt">
                Loanthem™ is a fintech platform for friends and family to
                <br /> create promissory notes and make installment payments on
                <br /> loans between themselves. Loanthem is a Coolbits, LLC
                <br /> product.
              </div>
              <div>{<br />}</div>
              <div className="about--txt">
                The mission of Coolbits, LLC is to make great products.
              </div>
            </div>
            {/*<img src={require("src/assets/images/about-section.png")} alt="" />*/}
          </div>
          <div className="about-image-container" style={{ position: "relative" }}>
            <img
              style={{ position: "relative", bottom: "17%", width: "39vw" }}
              src={require("src/assets/images/About-screen1.png")}
              alt=""
            />
            <img
              style={{ position: "absolute", left: "5%", top: "11%", width: "39vw" }}
              src={require("src/assets/images/About-screen2.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    </Row>
  );
};

export default About;
