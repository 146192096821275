import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { RootState } from "../store";

const useNoteNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const promissory = useSelector((state: RootState) => state.promissoryReducer);
  const navigationCondition = () => {
    if (
      promissory &&
      !promissory.editMode &&
      promissory.route &&
      promissory.route !== "" &&
      promissory.route === "/select-plan"
    ) {
      return "/draft";
    } else {
      if (
        promissory &&
        !promissory.editMode &&
        promissory.route &&
        promissory.route !== "" &&
        promissory.route !== "/select-plan"
      ) {
        return promissory.route;
      } else {
        return "/amount";
      }
    }
  };
  // const generatePromissoryNoteLink =
  //   promissory && !promissory.editMode && promissory.route && promissory.route !== ""
  //     ? promissory.route
  //     : "/amount";
  const generatePromissoryNoteLink = navigationCondition();
  // console.log(generatePromissoryNoteLink, promissory.route);
  const noteRoutes = [
    "/amount",
    "/interest",
    "/agreement-payment",
    "/installments",
    "/lump-sum-payment",
    "/late-fee",
    "/failure-to-pay",
    "/demand-payment",
    "/collateral",
    "/collateral-transfer",
    "/jurisdiction",
    "/enforcement",
    "/witness-signing",
    "/witness-info",
    "/extra-clause",
    "/individual-info/lender",
    "/bussiness-info/lender",
    "/borrower",
    "/individual-info/borrower",
    "/bussiness-info/lender",
    "/co-signer",
    "/co-signer-info",
    "/third-party",
    "/note-name",
    "/draft",
    "/select-plan",
  ];

  useEffect(() => {
    if (
      promissory.editMode ||
      ((promissory.route === "/draft" || location.pathname == "/draft") &&
        (promissory.route === "/select-plan" || location.pathname == "/select-plan"))
    ) {
      return;
    }

    noteRoutes.forEach((item) => {
      if (location.pathname.includes(item)) {
        navigate(generatePromissoryNoteLink, {
          replace: true,
        });
      }
    });
  }, []);
};

export default useNoteNavigation;
