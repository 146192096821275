import { AnyAction } from "redux";

import { SiteReducerInitial } from "./siteReducerInitial";
import { SiteReducerType } from "./siteReducerType";

export function siteReducer(state: SiteReducerType = SiteReducerInitial(), action: AnyAction) {
  switch (action.type) {
    case "show_login_register_modal":
      return { ...state, showLoginRegisterModal: true, modalType: action.payload.modalType };
    case "hide_login_register_modal":
      return { ...state, showLoginRegisterModal: false };
    default:
      return state;
  }
}
