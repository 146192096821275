import React, { FC } from "react";
import { Row, Collapse, Divider } from "antd";

const text = `
  A promissory note is a legal promise or a legal agreement by the Borrower to repay a loan or a debt for a sum of money at a specified time or period in the future. Promissory notes can be used for any type of loans, but generally used for loans between individuals. 
`;
const text1 = `
 Promissory notes are often used for loans between friends and family for such things as starting a business, a down payment on a property, school tuition, medical expenses, equipment, and other types of debts. Generally, promissory notes are enforceable in courts of law to attach assets or garnish wages when the borrower defaults on a loan. 
`;
const text2 = `Generally, promissory notes are used for loans to friends, family, or acquaintances and are for smaller amounts of money than loan agreements and may only require the borrower to sign the promissory note. A loan agreement is usually more detailed and for loans of larger amounts and must be signed by both the lender and the borrower. Loan agreements may include joint and several liability clauses (making all borrowers responsible for the default of one of the parties), late charges, prepayment discounts or penalties, and clauses permitting parties to transfer the loan to third parties. `;
const FAQQuestion: FC = () => {
  return (
    <Row>
      <Divider style={{ color: "white" }} orientation="center">
        FQA
      </Divider>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Collapse style={{ background: "var(--Sub, #202848)", color: "white" }}>
          <Collapse.Panel style={{ color: "white" }} key="1" header="What is a promissory note?">
            <p style={{ color: "white" }}>{text}</p>
          </Collapse.Panel>
        </Collapse>
        <Collapse style={{ background: "var(--Sub, #202848)", color: "white" }}>
          <Collapse.Panel style={{ color: "white" }} key="1" header="Why a promissory note?">
            <p style={{ color: "white" }}>{text1}</p>
          </Collapse.Panel>
        </Collapse>
        <Collapse style={{ background: "var(--Sub, #202848)", color: "white" }}>
          <Collapse.Panel
            style={{ color: "white" }}
            key="1"
            header=" What is the difference between a promissory note and a loan document?">
            <p style={{ background: "var(--Sub, #202848)" }}>{text2}</p>
          </Collapse.Panel>
        </Collapse>
      </div>
    </Row>
  );
};

export default FAQQuestion;
