import { FC, useState } from "react";
import { Row, Form, Button, Input, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import Previous from "src/common/components/Previous";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
const NoteName: FC = () => {
  const navigate = useNavigate();
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const [noteName, setNoteName] = useState<string>(note.name != undefined ? note.name : "");
  const dispatch = useDispatch();

  const handleSubmit = (values: any) => {
    dispatch(promissorySetDataAction(values));
    navigate("/draft");
  };
  const initVals = {
    name: note.name != undefined ? note.name : "",
  };
  return (
    <Row className="note" justify={"center"}>
      <Previous location="/third-party" />
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Name your Note
      </Typography.Title>
      <Form initialValues={initVals} className="note-form-box" onFinish={handleSubmit}>
        <Form.Item>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Form.Item
              className="note-formItem-input"
              style={{ display: "flex", flexDirection: "column" }}
              label="Name"
              required
              rules={[{ required: true, message: "Please enter the name of your note!" }]}
              name="name">
              <Input style={{ height: "37px", borderRadius: "5px" }} />
            </Form.Item>
          </div>
        </Form.Item>
        <div style={{ height: "50px" }} />
        <div className="note-form-line"></div>
        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
    </Row>
  );
};

export default NoteName;
