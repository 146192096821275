import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";

import { LOGIN_LOCAL_KEY } from "src/ENV";
import { authSetDataAction, authSetUser } from "src/store";
import { BASE_URL } from "src/ENV";

export interface DeleteAccountModalProps {
  open: boolean;
  onClose?: () => void;
}

const DeleteAccountModal = ({ open, onClose }: DeleteAccountModalProps) => {
  const { status: loginStatus } = useSelector((state: any) => state.authReducer.login);
  const { login } = useSelector((store: any) => ({
    login: store.authReducer.login,
  }));

  const token = login.data?.access_token;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.removeItem(LOGIN_LOCAL_KEY);
    dispatch(authSetDataAction("login", null, "error"));
    dispatch(authSetUser(undefined));
    navigate("/", {
      replace: true,
    });
  };
  const deleteDataAndExit = (relocate: boolean) => {
    const url = `${BASE_URL}/api/v1/users`;
    // axios
    //   .delete(url, {
    //     headers: {
    //       Authorization: "Bearer " + token,
    //     },
    //   })
    //   .then((response) => {
    //     logout();
    //   })
    //   .catch((err) => {
    //     if (err.response) {
    //       console.log(err.response.data);
    //     }
    //   });
    // fetch(url, {
    //   method: "DELETE",
    //   headers: {
    //     Authorization: "Bearer " + token,
    //   },
    // })
    //   .then((response) => {
    //     return response.json(); // Assuming you want to parse JSON in the response
    //   })
    //   .then((data) => {
    //     console.log(data);
    //     // logout();
    //   })
    //   .catch((err) => {
    //     console.error("Fetch error:", err);
    //   });
  };
  // const res = userRestApi
  //   .deleteCurrentLoggedInUser()
  //   .then(() => {
  //     logout();
  //   })
  //   .catch((err) => {
  //     // setDeleteStatus(undefined);
  //     console.log(err);
  //   });
  // };

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <div className="cancel-note-generation">
        <div className="cancel-note-generation__header">Delete Account</div>
        <div className="cancel-note-generation__note">
          Are you sure that you want to delete your account? Once you delete your account, you will
          lose access to your promissory note, and will no longer have access to your dashboard.
        </div>
        <div className="cancel-note-generation__button-wrapper">
          <Button type="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="text" onClick={() => deleteDataAndExit(true)}>
            Delete Account
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
