import React from "react";
import { Input, Button } from "antd";
import useLoginRegisterModal from "./useLoginRegisterModal";
import { Timer } from "src/common/components";

export interface IVerifyModalProps {
  username: string;
  password: string;
}

const VerifyModal = ({ username, password }: IVerifyModalProps) => {
  const { loading, code, setCode, verify, codeError, resendOtp } = useLoginRegisterModal(() => {
    return;
  });

  return (
    <>
      <h4 className="login-register-modal__header">Verify</h4>
      <div className="login-register-modal__items">
        <div className="login-register-modal__items__item">
          <span className="login-register-modal__items__item__title">
            Please enter the 6-digit verification code sent to your registered e-mail.{" "}
            <span>*</span>
          </span>
          <Input
            status={codeError ? "error" : ""}
            placeholder="Code"
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
          <span className="login-register-modal__items__item__error">{codeError}</span>
        </div>
        <Timer onSendAgain={() => resendOtp(username, password)} />

        <Button
          className="login-btn"
          htmlType={"submit"}
          type="primary"
          block
          loading={loading}
          onClick={() => verify(username, password)}>
          Verify
        </Button>
      </div>
    </>
  );
};

export default VerifyModal;
