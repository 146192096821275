/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { paymentRestApi, promissoryNoteRestApi } from "../../services/http";
import { AmortizationScheduleResponseDTO } from "src/api";
import { UsePaymentApi } from "src/hooks";

const usePaymentInstallment = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { acceptPayment } = UsePaymentApi.usePaymentApi();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [sending, setSending] = useState(false);
  const [scheduleData, setScheduleData] = useState<AmortizationScheduleResponseDTO[]>([]);
  const [paidAmount, setPaidAmount] = useState(0);
  const [paidAmountCount, setPaidAmountCount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [dueAmountCount, setDueAmountCount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [remainingAmountCount, setRemainingAmountCount] = useState(0);
  const [errorModal, setErrorModal] = useState(false);
  const [errorCode, setErrorCode] = useState<null | number>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const onClickCloseModal = () => {
    setErrorModal(false);
  };

  const getAmortization = async () => {
    try {
      const responseDetail = await promissoryNoteRestApi.getPromissoryNote(Number(id!));
      const amortizations = responseDetail.data.amortization_schedules!;
      let paidAmount = 0;
      let paidAmountCount = 0;
      let dueAmount = 0;
      let dueAmountCount = 0;
      let remainingAmounts = 0;
      let remainingAmountCount = 0;

      const now = new Date();
      amortizations.map((item) => {
        if (item.status === "PAID") {
          paidAmount += item.monthly_Amount!;
          paidAmountCount++;
        } else {
          if (now.getTime() > new Date(item.date!).getTime()) {
            dueAmount += item.monthly_Amount!;

            dueAmountCount++;
          } else {
            remainingAmounts += item.monthly_Amount!;
            // setRemainingAmount(remainingAmount + item.monthly_Amount!);

            remainingAmountCount++;
          }
        }
      });
      // console.log(remainingAmounts);
      setScheduleData(amortizations);
      setPaidAmount(paidAmount);
      setPaidAmountCount(paidAmountCount);
      setDueAmount(dueAmount);
      setDueAmountCount(dueAmountCount);
      setRemainingAmount(remainingAmounts);
      setRemainingAmountCount(remainingAmountCount);
      setDataLoaded(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const confirm = async (count: number) => {
    setSending(true);
    try {
      const result = await acceptPayment(Number(id), count);
      window.location.href = result.data.payment_url!;
    } catch (error: any) {
      const err = { ...error };
      setErrorCode(err.response.data.code);
      setErrorModal(true);
    }
    setSending(false);
  };

  const onClickPrev = () => {
    navigate(`/dashboard/${type}/${id}`);
  };

  useEffect(() => {
    getAmortization();
  }, []);
  console.log(remainingAmount);

  useEffect(() => {
    switch (errorCode) {
      case 1033:
        setErrorMessage(
          "Please complete the stripe account and back to the app for the installment payment.",
        );
        break;
      case 1032:
        setErrorMessage(
          "Your Lender has not completed the Stripe account.Please wait while the account information is complete.",
        );
        break;
      default:
        setErrorMessage("Please sign your Docusign to initiate installment processing");
        break;
    }
  }, [errorCode]);

  return {
    onClickPrev,
    dataLoaded,
    scheduleData,
    type,
    paidAmount,
    paidAmountCount,
    dueAmount,
    dueAmountCount,
    remainingAmount,
    remainingAmountCount,
    confirm,
    sending,
    errorModal,
    onClickCloseModal,
    errorCode,
    errorMessage,
  };
};

export default usePaymentInstallment;
