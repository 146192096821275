import React, { FC, useState, useEffect } from "react";
import { Row, Form, Button, Input, Typography, Col, Select } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Previous from "src/common/components/Previous";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction } from "src/store";
import { useLocationService } from "./../../hooks/locationService";

const WitnessInfo: FC = () => {
  const { Option } = Select;
  const { cityList, getPageCity, states, getStateList } = useLocationService();
  const initObj = {
    business_name: "",
    city: "",
    credit_side_type: "INDIVIDUAL",
    email: "",
    first_name: "",
    full_address: "",
    last_name: "",
    middle_name: "",
    official_title: "",
    state: "",
    zip_code: "",
  };
  const note = useSelector((store: any) => store.promissoryReducer);
  const [initialValue, setInitialValue] = useState<any>(
    note.witness_infos != undefined && note.witness_infos.length > 0
      ? note.witness_infos
      : [initObj],
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    getStateList();
    if (note.witness_infos && note.witness_infos.length > 0) {
      handelCity(note.witness_infos[0].state);
    }
  }, []);
  /*   const initialValue = [
    {
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      state: "",
      zipCode: "",
      city: "",
    },
  ]; */
  const handleAddContent = () => {
    const valobj = {
      business_name: "",
      city: "",
      credit_side_type: "INDIVIDUAL",
      email: "",
      first_name: "",
      full_address: "",
      last_name: "",
      middle_name: "",
      official_title: "",
      state: "",
      zip_code: "",
    };
    const newVal = [...initialValue];
    newVal.push(valobj);
    setInitialValue(newVal);
  };
  const handleSubmit = (val: any) => {
    dispatch(promissorySetDataAction({ witness_infos: initialValue }));
    navigate("/extra-clause");
  };
  const changeVal = (val: any, index: any, name: any) => {
    const data: any = [...initialValue];
    data[index][name] = val;
    setInitialValue(data);
  };
  const generateInitial = () => {
    const obj: any = {};

    initialValue.map((e: any, i: any) => {
      obj[`business_name${i}`] = e.business_name;
      obj[`city${i}`] = e.city;
      obj[`credit_side_type${i}`] = e.credit_side_type;
      obj[`email${i}`] = e.email;
      obj[`first_name${i}`] = e.first_name;
      obj[`full_address${i}`] = e.full_address;
      obj[`last_name${i}`] = e.last_name;
      obj[`middle_name${i}`] = e.middle_name;
      obj[`official_title${i}`] = e.official_title;
      obj[`state${i}`] = e.state;
      obj[`zip_code${i}`] = e.zip_code;
    });
    return obj;
  };
  const removeItem = (e: any) => {
    e.preventDefault();
  };
  const handelCity = (val: any) => {
    getPageCity(val);
    form.setFieldsValue({ city: "" });
  };
  const handleNum = (e: any) => {
    if (e.key.length === 1 && /\D/.test(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <Row className="note" justify={"center"}>
      <Previous location="/witness-signing" />

      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        {note.witness_type == "WITNESS" ? " Witness Info" : "  Notary Info"}
      </Typography.Title>
      {/*       <Typography.Text onClick={handleAddContent} className="note-add-content">
        + Add Witness
      </Typography.Text> */}
      <Form
        className="note-form-box credit-side-form-box"
        initialValues={generateInitial()}
        onFinish={(values) => {
          handleSubmit(values);
        }}>
        {initialValue.map((e: any, i: any) => (
          <React.Fragment key={i}>
            <Typography.Title
              className="credit-side-form-item-header"
              style={{ color: "white", fontSize: 25 }}>
              {/*               Witness {i + 1}{" "}
               */}{" "}
              {i > 1 ? (
                <button onClick={(e) => removeItem(e)} className="remove-item-btn">
                  -
                </button>
              ) : null}
            </Typography.Title>
            <Row justify="space-around">
              <Col
                span={11}
                lg={11}
                md={11}
                xs={24}
                sm={24}
                className="credit-side-form-input-container">
                <Form.Item
                  className="note-formItem-input"
                  style={{ display: "flex", flexDirection: "column" }}
                  label="First Name"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name!",
                      validateTrigger: "onSubmit",
                    },
                    {
                      max: 40,
                      message: "First name must have a maximum length of 40",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name={`first_name${i}`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}>
                    <Input
                      value={initialValue[i].first_name}
                      onChange={(e) => changeVal(e.target.value, i, "first_name")}
                      className="credit-side-form-input"
                      style={{
                        height: "37px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col
                span={11}
                lg={11}
                md={11}
                xs={24}
                sm={24}
                className="credit-side-form-input-container">
                <Form.Item
                  className="note-formItem-input"
                  style={{ display: "flex", flexDirection: "column" }}
                  label="Middle Name"
                  name={`middle_name${i}`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}>
                    <Input
                      value={initialValue[i].middle_name}
                      onChange={(e) => changeVal(e.target.value, i, "middle_name")}
                      className="credit-side-form-input"
                      style={{
                        height: "37px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col
                span={11}
                lg={11}
                md={11}
                xs={24}
                sm={24}
                className="credit-side-form-input-container">
                <Form.Item
                  className="note-formItem-input"
                  style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                  label="Last Name"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name!",
                      validateTrigger: "onSubmit",
                    },
                    {
                      max: 40,
                      message: "Last name must have a maximum length of 40",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name={`last_name${i}`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}>
                    <Input
                      value={initialValue[i].last_name}
                      onChange={(e) => changeVal(e.target.value, i, "last_name")}
                      className="credit-side-form-input"
                      style={{
                        height: "37px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col
                span={11}
                lg={11}
                md={11}
                xs={24}
                sm={24}
                className="credit-side-form-input-container">
                <Form.Item
                  className="note-formItem-input"
                  style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                  label="Address"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter Address!",
                      validateTrigger: "onSubmit",
                    },
                    {
                      max: 40,
                      message: "Address must have a maximum length of 40",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name={`full_address${i}`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}>
                    <Input
                      value={initialValue[i].full_address}
                      onChange={(e) => changeVal(e.target.value, i, "full_address")}
                      className="credit-side-form-input"
                      style={{
                        height: "37px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>{" "}
              <Col
                span={11}
                lg={11}
                md={11}
                xs={24}
                sm={24}
                className="credit-side-form-input-container">
                <Form.Item
                  className="note-formItem-input"
                  style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                  label="State"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter State!",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name={`state${i}`}>
                  <Select
                    onChange={(e) => {
                      changeVal(e, i, "state");
                      handelCity(e);
                    }}
                    value={initialValue[i].state}
                    placeholder="Select"
                    size={"large"}>
                    {states.map((e: any, index) => {
                      return (
                        <Option key={index} value={e.name}>
                          {e.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>{" "}
              <Col
                span={11}
                lg={11}
                md={11}
                xs={24}
                sm={24}
                className="credit-side-form-input-container">
                <Form.Item
                  className="note-formItem-input"
                  style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                  label="City"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter City!",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name={`city${i}`}>
                  <Select
                    onChange={(e) => changeVal(e, i, "city")}
                    value={initialValue[i].city}
                    placeholder="Select"
                    size={"large"}>
                    {cityList?.map((e: any, index) => {
                      return (
                        <Option key={index} value={e.id}>
                          {e.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                span={11}
                lg={11}
                md={11}
                xs={24}
                sm={24}
                className="credit-side-form-input-container">
                <Form.Item
                  className="note-formItem-input"
                  style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                  label="Zip Code"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter zipcode!",
                      validateTrigger: "onSubmit",
                    },
                    {
                      max: 5,
                      message: "zip code must have a maximum length of 5",
                      validateTrigger: "onSubmit",
                    },
                    {
                      min: 5,
                      message: "zip code must have a minimum length of 5",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name={`zip_code${i}`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}>
                    <Input
                      value={initialValue[i].zip_code}
                      onKeyDown={(e) => handleNum(e)}
                      onChange={(e) => changeVal(e.target.value, i, "zip_code")}
                      className="credit-side-form-input"
                      style={{
                        height: "37px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col
                span={11}
                lg={11}
                md={11}
                xs={24}
                sm={24}
                className="credit-side-form-input-container">
                <Form.Item
                  className="note-formItem-input"
                  style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                  label="Email"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter email!",
                      validateTrigger: "onSubmit",
                    },
                    {
                      type: "email",
                      message: "The input is not a valid E-mail!",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name={`email${i}`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}>
                    <Input
                      value={initialValue[i].email}
                      onChange={(e) => changeVal(e.target.value, i, "email")}
                      className="credit-side-form-input"
                      style={{
                        height: "37px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                borderBottom: "1px solid white",
                margin: "25px 0 15px 0 ",
              }}></div>
          </React.Fragment>
        ))}

        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          style={{ margin: "auto", width: "50%" }}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
    </Row>
  );
};

export default WitnessInfo;
