import React from "react";

export interface LoanInfoLineProps {
  value: string;
  title: string;
  count: number;
  color: "GREEN" | "RED" | "GRAY" | "BLACK";
}

const PaymentInfo = ({ value, title, color, count }: LoanInfoLineProps) => {
  return (
    <div className={`payment-info ${color}`}>
      <div className="payment-info__line">
        <div className="payment-info__line__title">{title}</div>
        <div className={`payment-info__line__divider ${color}`} />
        <div className="payment-info__line__amount">${value}</div>
      </div>
      <div className="payment-info__count">
        {count} installment{`${count > 1 ? "s" : ""}`}
      </div>
    </div>
  );
};

export default PaymentInfo;
