import React, { FC, useState, useRef } from "react";
import { Button, Col, Form, Input, Row, Typography, Checkbox } from "antd";
import Password from "antd/es/input/Password";
import { Link, useLocation } from "react-router-dom";
import { useSignUpForm } from "./../../hooks/registerForm";
import ErrorBox from "../../common/components/ErrorBox";
import { max } from "moment";
import { useDispatch, useSelector } from "react-redux";

const Register: FC = () => {
  const [form]: any = Form.useForm();

  const { handleSubmit, errorHandle, fetching } = useSignUpForm();
  const registerBg = useSelector((state: any) => state.registerBgReducer);
  const { state } = useLocation();

  const getRegisterTitle = () => {
    switch (registerBg.bg) {
      case "1":
        return `Tax Form Prep`;
      case "2":
        return `Secure Payment Processing`;
      case "3":
        return `Single Notes, Multiple Notes, Premium Services`;
      case "4":
        return `Payment Reminders & Receipts`;
      default:
        return `Single Notes, Multiple Notes, Premium Services`;
    }
  };
  const getRegisterSubtitle = () => {
    switch (registerBg.bg) {
      case "1":
        return `With the Premium Plan, through a simple question and answer, both lenders and borrowers will have access to preliminary information to understand their possible tax liability as a result of the loan.   `;
      case "2":
        return `With the Premium Plan, pay and receive loan installment payments securely through Bank Wire Transfer, ACH Transfer, Zelle, Credit/Debit Card, Venmo, GooglePay, Apple Pay, PayPal, and other popular digital payment platforms. `;
      case "3":
        return `Subscribe to either the Single Note, Multiple Note, or Premium Plans.  The Single Note Plan is an inexpensive, no frills promissory note generation tool.  The Multiple Note Plan allows users to make as many promissory notes and edits as desired for a full year.  The Premium Plan provides full person to person (P2P) loan management from loan generation to payment processing to payment reminders and receipts, demand and loan release letters, to tax preparation for an entire year with no extra fees or costs.  Subscribe to  LoanThem and manage all of your loan needs from a single dashboard.`;
      case "4":
        return `With the Premium Plan, and for no extra charge or fee, lenders and borrowers may enroll to receive payment reminders and receipts for their loans.  Accordingly, borrowers will not forget when installments are due and lenders will be advised when the installments have been paid or not paid.     `;
      default:
        return `Subscribe to either the Single Note, Multiple Note, or Premium Plans.  The Single Note Plan is an inexpensive, no frills promissory note generation tool.  The Multiple Note Plan allows users to make as many promissory notes and edits as desired for a full year.  The Premium Plan provides full person to person (P2P) loan management from loan generation to payment processing to payment reminders and receipts, demand and loan release letters, to tax preparation for an entire year with no extra fees or costs.  Subscribe to  LoanThem and manage all of your loan needs from a single dashboard.`;
    }
  };
  const { previousValues: prevValues } = state ?? {};
  const initialValues = {
    email: prevValues?.email ? prevValues?.email : "",
    firstName: prevValues?.first_name ? prevValues?.first_name : "",
    lastName: prevValues?.last_name ? prevValues?.last_name : "",
    PhoneNumber: prevValues?.mobile_number ? prevValues?.mobile_number : "",
    password: prevValues?.password ? prevValues?.password : "",
    username: prevValues?.username ? prevValues?.username : "",
    confirmPassword: prevValues?.password ? prevValues?.password : "",
  };

  return (
    <Row justify={"center"}>
      <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
        <Row justify={"center"}>
          <div className="register-title-txt">
            <Typography.Title style={{ color: "white" }}>{getRegisterTitle()}</Typography.Title>
            <Typography.Text
              style={{
                color: "white",
                fontSize: 14,
                fontWeight: "400",
                textAlign: "justify",
              }}>
              {getRegisterSubtitle()}
            </Typography.Text>
          </div>
        </Row>
      </Col>
      <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
        <Row justify={"center"} style={{ marginBottom: 5 }}>
          <Col xs={24} sm={24} md={22} lg={18} xl={16} xxl={14}>
            <Typography.Title level={3} style={{ color: "white", marginTop: 15 }}>
              Create your free account
            </Typography.Title>
            <Form
              form={form}
              initialValues={initialValues}
              className={"register-form"}
              layout={"vertical"}
              onFinish={(values) => {
                handleSubmit(values);
              }}>
              <Form.Item
                className="register-form-input"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name",
                    validateTrigger: "onSubmit",
                  },
                  {
                    max: 40,
                    message: "First name must have a maximum length of 40",
                    validateTrigger: "onSubmit",
                  },
                  {
                    min: 2,
                    message: "First name must have a minimum length of 2",
                    validateTrigger: "onSubmit",
                  },
                ]}
                required
                name="firstName">
                <Input placeholder="First Name" size={"large"} className="register-input" />
              </Form.Item>
              <Form.Item
                className="register-form-input"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your last name",
                    validateTrigger: "onSubmit",
                  },
                  {
                    max: 40,
                    message: "Last name must have a maximum length of 40",
                    validateTrigger: "onSubmit",
                  },
                  {
                    min: 2,
                    message: "Last name must have a minimum length of 2",
                    validateTrigger: "onSubmit",
                  },
                ]}
                required
                name="lastName">
                <Input placeholder="Last Name" size={"large"} className="register-input" />
              </Form.Item>
              <Form.Item
                className="register-form-input"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email",
                    validateTrigger: "onSubmit",
                  },
                  {
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Email address must be a valid email",
                    validateTrigger: "onSubmit",
                  },
                ]}
                required
                name="email">
                <Input placeholder="Email" size={"large"} className="register-input" />
              </Form.Item>
              <Form.Item
                className="register-form-input"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number",
                    validateTrigger: "onSubmit",
                  },
                  {
                    max: 12,
                    message: "Phone Number must have a maximum length of 12",
                    validateTrigger: "onSubmit",
                  },
                  {
                    min: 10,
                    message: "Phone Number must have a minimum length of 10",
                    validateTrigger: "onSubmit",
                  },
                ]}
                required
                name="PhoneNumber">
                <Input
                  placeholder="Phone Number"
                  size={"large"}
                  className="register-input"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                className="register-form-input"
                label="Username"
                rules={[
                  {
                    required: true,
                    message: "Please enter your username",
                    validateTrigger: "onSubmit",
                  },
                  {
                    min: 6,
                    message: "Username must have a minimum length of 6",
                    validateTrigger: "onSubmit",
                  },
                ]}
                required
                name="username">
                <Input
                  placeholder="Username"
                  size={"large"}
                  className="register-input"
                  autoComplete="one-time-code"
                />
              </Form.Item>
              <Form.Item
                className="register-form-input"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                    validateTrigger: "onSubmit",
                  },
                  {
                    min: 8,
                    message: "Password must have a minimum length of 8",
                    validateTrigger: "onSubmit",
                  },
                ]}
                required
                name="password">
                <Password
                  placeholder="Password"
                  size={"large"}
                  className="register-input"
                  autoComplete="one-time-code"
                />
              </Form.Item>
              <Form.Item
                className="register-form-input"
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "please enter confirm password",
                    validateTrigger: "onSubmit",
                  },
                  {
                    min: 8,
                    message: "Confirm password must have a minimum length of 8",
                    validateTrigger: "onSubmit",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("The two passwords that you entered do not match!");
                    },
                  }),
                ]}
                required
                name="confirmPassword">
                <Password
                  placeholder="Confirm Password"
                  size={"large"}
                  className="register-input"
                  autoComplete="one-time-code"
                />
              </Form.Item>
              <div>{errorHandle ? <ErrorBox text={errorHandle} /> : ""}</div>
              <div className="register-form-line"></div>
              <Form.Item
                style={{ padding: "15px 19px" }}
                name="checkbox"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "You must accept the privacy policy",
                  },
                ]}
                required>
                <Checkbox>
                  I agree to Loanthem’s{" "}
                  <span className="styled-button-link">
                    <Link to={"/terms-of-services"} target="_blank">
                      Terms of Service
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span className="styled-button-link">
                    <Link to={"/privacy-policy"} target="_blank">
                      Privacy Policy
                    </Link>
                  </span>
                </Checkbox>
              </Form.Item>
              <Button
                className="register-btn"
                htmlType={"submit"}
                type="primary"
                loading={fetching}>
                Register
              </Button>
              <div className="register-already-text" style={{ textAlign: "center" }}>
                <Typography.Text
                  style={{
                    color: "#9CA3AF",
                  }}>
                  Already have an account?{" "}
                </Typography.Text>
                <Link to={"/login"}>
                  <Typography.Text
                    style={{
                      width: 45,
                      height: 20,
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#FFFFFF",
                    }}>
                    {""}Sign in
                  </Typography.Text>
                </Link>
              </div>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Register;
