/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef } from "react";
import { UserOutlined, CameraOutlined, CloseCircleFilled, UploadOutlined } from "@ant-design/icons";
import { Avatar, Spin } from "antd";

import useUploadBox from "./useUploadBox";
import UploadBoxItem from "./UploadBoxItem";
import { IFileResult, ProfilePhotoUpdate } from "../../../models";

export interface UploadBoxProps {
  onChange: (
    file: File | undefined,
    fileResult: IFileResult | undefined,
    s3Data: AWS.S3.ManagedUpload.SendData | undefined,
    url: string,
  ) => void;
  defaultUrl?: string;
  withUser: boolean;
  type?: "AVATAR" | "DOCUMENT";
  attachments?: ProfilePhotoUpdate[];
  onClickDelete?: (url: string) => void;
}

const UploadBox = ({
  onChange,
  defaultUrl,
  withUser,
  type = "AVATAR",
  attachments,
  onClickDelete,
}: UploadBoxProps) => {
  const ref = useRef(null);
  const { handleChange, sending, url, clearImage } = useUploadBox(
    defaultUrl ?? "",
    withUser,
    onChange,
  );

  const typeAvatar = () => {
    return (
      <div className="upload-box">
        <div className="upload-box__wrapper">
          {sending ? (
            <Spin />
          ) : (
            <Avatar
              size={120}
              src={defaultUrl}
              style={{ cursor: "pointer" }}
              onClick={() => {
                // @ts-ignore
                ref.current?.click();
              }}>
              <UserOutlined rev="" />
            </Avatar>
          )}
        </div>
        <div className="upload-box__icons">
          {url ? (
            <CloseCircleFilled
              style={{ fontSize: "20px", color: "#A4B3F4" }}
              rev=""
              onClick={() => {
                clearImage();
              }}
            />
          ) : (
            <CameraOutlined
              rev=""
              style={{ fontSize: "20px", color: "#A4B3F4" }}
              onClick={() => {
                // @ts-ignore
                ref.current?.click();
              }}
            />
          )}
        </div>

        <input
          onChange={handleChange}
          ref={ref}
          type="file"
          style={{ display: "none" }}
          accept="image/*"
        />
      </div>
    );
  };

  const typeDocument = () => {
    return (
      <>
        <div
          className="upload-box-document"
          onClick={() => {
            // @ts-ignore
            ref.current?.click();
          }}>
          <div className="upload-box-document__content">
            <UploadOutlined rev="" />
            <span>{url ? "Uploaded" : "Upload"}</span>
          </div>
          <input
            onChange={handleChange}
            ref={ref}
            type="file"
            multiple
            style={{ display: "none" }}
            accept="image/*"
          />
        </div>
        {attachments && attachments.length > 0 && (
          <div className="upload-box__items">
            {attachments.map((item, index) => (
              <UploadBoxItem attachment={item} key={index} onClickDelete={onClickDelete} />
            ))}
          </div>
        )}
      </>
    );
  };

  switch (type) {
    case "AVATAR":
      return typeAvatar();
    case "DOCUMENT":
      return typeDocument();
    default:
      return typeAvatar();
  }
};

export default UploadBox;
