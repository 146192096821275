import React, { FC, useState } from "react";
import { Button, Checkbox, Form, Input, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userRestApi } from "src/services/http";
import ErrorBox from "../../common/components/ErrorBox";

const ForgetPassword: FC = () => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const currentUrl = window.location.pathname;

  return (
    <Row justify={"center"}>
      <Row>
        <Form
          className="resetPassword-form"
          onFinish={async (values) => {
            if (values != null) {
              try {
                await userRestApi.sendPasswordResetCode(values.RetypePassword);
                navigate("/verify", {
                  state: { email: values.RetypePassword, prevUrl: currentUrl },
                });
              } catch (error: any) {
                if (error?.response?.data?.message) {
                  if (error.response.status === 400) {
                    setIsError(true);
                  } else {
                    setIsError(true);
                  }
                } else {
                  setIsError(true);
                }
              }
            }
          }}>
          <a href="/login" className="forgetPassword-close">
            <img src={require("src/assets/images/Shape-close.png")} alt="" />
          </a>
          <div className="forgetPassword-title">Forget Password</div>
          <div className="forgetPassword-header-text">Let us assist you...</div>
          <Form.Item
            className="resetPassword-form-input"
            label="Email address"
            rules={[
              { required: true, message: "please  enter your Email" },
              {
                type: "email",
                message: "The input is not a valid email.",
              },
            ]}
            required
            name="RetypePassword">
            <Input name="forgetPSW-inp" placeholder="Email" size={"large"} />
          </Form.Item>
          <Button className="resetPassword-btn" htmlType={"submit"} type="primary" block>
            Reset Password
          </Button>
          <div>{isError ? <ErrorBox text="The email address is incorrect!" /> : ""}</div>
        </Form>
      </Row>
    </Row>
  );
};
export default ForgetPassword;
