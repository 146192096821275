import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as IconChevron } from "../../../assets/icons/ic-chevron-right-white.svg";

export interface LinkBoxProps {
  title: string;
  icon: ReactNode;
  link?: string;
  forPremium?: boolean;
  onClick?: () => void;
  onClickPremium: () => void;
}
const LinkBox = ({ icon, link, title, forPremium, onClick, onClickPremium }: LinkBoxProps) => {
  const navigate = useNavigate();

  return (
    <div
      className="link-box"
      onClick={() => {
        if (forPremium) {
          onClickPremium();
        } else {
          link ? navigate(link) : onClick?.();
        }
      }}>
      <div className="link-box__left">
        {icon}
        <span className="link-box__left__title">{title}</span>
      </div>
      <div className="link-box__right">
        {forPremium && <span className="link-box__left__premium">Premium</span>}

        <IconChevron />
      </div>
    </div>
  );
};

export default LinkBox;
