export enum MimeTypes {
  "image/apng" = "image/apng",
  "image/avif" = "image/avif",
  "image/gif" = "image/gif",
  "image/jpeg" = "image/jpeg",
  "image/jpg" = "image/jpg",
  "image/png" = "image/png",
  "image/svg+xml" = "image/svg+xml",
  "image/webp" = "image/webp",
}

export const convertMimeType = (type: string): string => {
  switch (type) {
    case MimeTypes["image/apng"]:
    case MimeTypes["image/png"]:
      return "PNG";
    case MimeTypes["image/jpeg"]:
    case MimeTypes["image/jpg"]:
      return "JPEG";
    default:
      return "JPEG";
  }
};
