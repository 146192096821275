import { FC, useState } from "react";
import { Row, Button, Typography, List, Avatar } from "antd";

import Previous from "../../common/components/Previous";
import { usePaymentApi } from "src/hooks/usePaymentApi";

const SelectPlan: FC = () => {
  const { selectPlan } = usePaymentApi();

  const [loading, setLoading] = useState<string | null>(null);

  const handlingPlans = async (plan: "PREMIUM_PACKAGE" | "MULTIPLE_PACKAGE" | "SINGLE_PACKAGE") => {
    setLoading(plan);

    try {
      const response = await selectPlan(plan, "DRAFT_WEB");
      const paymentUrl = response?.data.payment_url;
      window.location.href = paymentUrl;
    } catch (error) {
      console.error(error);
    }
    setLoading(null);
  };

  const itemsFirstCart = [
    {
      title1: "Amortization Note",
    },
    {
      title: "Secure Payment Processing",
    },
    {
      title: "Payment Reminders and Receipts",
    },
    {
      title: "Demand and Loan Release Letters",
    },
    {
      title: "Tax Form Prep",
    },
  ];
  const itemsSecondCart = [
    {
      title1: "Multiple Notes & Edits",
    },
    {
      title: "Secure Payment Processing",
    },
    {
      title: "Payment Reminders and Receipts",
    },
    {
      title: "Demand and Loan Release Letters",
    },
    {
      title: "Tax Form Prep",
    },
  ];

  return (
    <Row className="note" justify={"center"}>
      <Previous location="/draft" />
      <Typography.Title
        style={{
          marginTop: 10,
          color: "white",
          textAlign: "center",
          fontSize: 25,
        }}>
        Select Your Plan
      </Typography.Title>

      <div className="landing-carts select-plan__plans" style={{ margin: "7px 0 30px" }}>
        <div className="landing-carts-first-cart">
          <Typography.Title className="landing-carts-price" style={{ color: "white" }}>
            $9.95
          </Typography.Title>
          <Typography.Text style={{ color: "#FFFFFF", opacity: 0.6, paddingLeft: 120 }}>
            Single Note
          </Typography.Text>
          <div className="landing-carts-first-cart-line"></div>
          <List
            itemLayout="horizontal"
            dataSource={itemsFirstCart}
            renderItem={(item) => (
              <List.Item className="landing-carts-list-texts" style={{ borderBottom: "none" }}>
                <List.Item.Meta
                  style={{ marginRight: 1 }}
                  avatar={
                    <Avatar
                      style={{
                        display: "block",
                        width: "85%",
                        height: "85%",
                        marginTop: 4,
                        marginLeft: 3,
                      }}
                      src={
                        item.title
                          ? require("src/assets/images/landing-cart-tick.png")
                          : require("src/assets/images/landing-cart-tick-true.png")
                      }
                    />
                  }
                  title={
                    <div style={{ color: "white" }}>
                      {item.title1}
                      {item.title}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
          <div className="landing-carts-first-cart-line"></div>
          <Button
            className="select-carts-btn"
            onClick={() => {
              handlingPlans("SINGLE_PACKAGE");
              // setSelectPlan("SINGLE_PACKAGE");
              // window.location.href = paymentUrl;
            }}
            loading={loading === "SINGLE_PACKAGE"}>
            Select Plan
          </Button>
        </div>

        <div className="landing-carts-second-cart">
          <div className="landing-carts-second-cart-label">Most popular</div>
          <div>
            <Typography.Title className="landing-carts-price" style={{ color: "white" }}>
              $29.95
            </Typography.Title>
            <Typography.Text style={{ color: "#FFFFFF", opacity: 0.6, paddingLeft: 98 }}>
              1-Year Multi Note
            </Typography.Text>
            <div className="landing-carts-first-cart-line"></div>
            <List
              itemLayout="horizontal"
              dataSource={itemsSecondCart}
              renderItem={(item) => (
                <List.Item className="landing-carts-list-texts" style={{ borderBottom: "none" }}>
                  <List.Item.Meta
                    style={{ marginRight: 5 }}
                    avatar={
                      <Avatar
                        style={{
                          display: "block",
                          width: "85%",
                          height: "85%",
                          marginTop: 4,
                          marginLeft: 3,
                        }}
                        src={
                          item.title
                            ? require("src/assets/images/landing-cart-tick.png")
                            : require("src/assets/images/landing-cart-tick-true.png")
                        }
                      />
                    }
                    title={
                      <div style={{ color: "white" }}>
                        {item.title1}
                        {item.title}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
            <div className="landing-carts-first-cart-line"></div>
            <Button
              className="select-carts-center-btn"
              onClick={() => {
                handlingPlans("MULTIPLE_PACKAGE");
                // setSelectPlan();
                // window.location.href = paymentUrl;
              }}
              loading={loading === "MULTIPLE_PACKAGE"}>
              Select Plan
            </Button>
          </div>
        </div>

        <div className="landing-carts-first-cart">
          <Typography.Title className="landing-carts-price" style={{ color: "white" }}>
            $49.95
          </Typography.Title>
          <Typography.Text style={{ color: "#FFFFFF", opacity: 0.6, paddingLeft: 105 }}>
            1-Year Premium
          </Typography.Text>
          <div className="landing-carts-first-cart-line"></div>

          <List
            itemLayout="horizontal"
            dataSource={itemsSecondCart}
            renderItem={(item) => (
              <List.Item className="landing-carts-list-texts" style={{ borderBottom: "none" }}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        display: "block",
                        width: "85%",
                        height: "85%",
                        marginTop: 4,
                        marginLeft: 3,
                      }}
                      src={require("src/assets/images/landing-cart-tick-true.png")}
                    />
                  }
                  title={
                    <div style={{ color: "white" }}>
                      {item.title1}
                      {item.title}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
          <div className="landing-carts-first-cart-line"></div>
          <Button
            className="select-carts-btn"
            onClick={() => {
              handlingPlans("PREMIUM_PACKAGE");
            }}
            loading={loading === "PREMIUM_PACKAGE"}>
            Select Plan
          </Button>
        </div>
      </div>
    </Row>
  );
};

export default SelectPlan;
