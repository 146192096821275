import React from "react";

export interface HeaderProps {
  title: string;
}

const Header = ({ title }: HeaderProps) => {
  return (
    <div className="header-cmp">
      <div className="header-cmp__title">{title}</div>
      <div className="header-cmp__divider" />
    </div>
  );
};

export default Header;
