import { PromissoryReducerType } from "./promissoryReducerType";
import { PROMISSORY_NOTE_KEY, PROMISSORY_ROUTE_KEY } from "src/ENV";

export const PromissoryReducerInitial = (): PromissoryReducerType => {
  const promissoryStringData = localStorage.getItem(PROMISSORY_NOTE_KEY as string);
  const promissoryRouteKey = localStorage.getItem(PROMISSORY_ROUTE_KEY as string);
  const state = {
    route: promissoryRouteKey ?? "",
    editMode: false,
  };
  if (promissoryStringData) {
    const partial = JSON.parse(promissoryStringData) as PromissoryReducerType;
    return {
      ...state,
      ...partial,
    };
  }
  return state;
};
