import axios from "axios";

import { BASE_URL } from "../ENV";
import { IGetAllBorrowers, IGetAllLenders, IReminderContact } from "../models";

export const useReminderContacts = () => {
  const getContacts = async (id: string | number) => {
    const response = axios.get(`${BASE_URL}/api/v1/promissory-notes/${id}/reminder-contact`);
    return response;
  };

  const updateContacts = async (id: string | number, contacts: IReminderContact[]) => {
    const response = axios.put(
      `${BASE_URL}/api/v1/promissory-notes/${id}/reminder-contact`,
      contacts,
    );
    return response;
  };

  const deleteContacts = async (id: string | number, email: string) => {
    const response = axios.delete(
      `${BASE_URL}/api/v1/promissory-notes/${id}/reminder-contact?contactEmail=${email}`,
    );
    return response;
  };

  return { getContacts, updateContacts, deleteContacts };
};

export const useNotes = () => {
  const getNote = async (id: string | number) => {
    const response = axios.get(`${BASE_URL}/api/v1/promissory-notes/${id}`);
    return response;
  };

  const getPreview = async (id: string | number) => {
    const response = axios.get(`${BASE_URL}/api/v1/promissory-notes/${id}/preview`, {
      responseType: "blob",
    });
    return response;
  };

  const getPercent = async () => {
    const response = axios.get(`${BASE_URL}/api/v1/promissory-notes/note-paid-progress`);
    return response;
  };

  const getBorrows = async (page = 0) => {
    const response = axios.get(`${BASE_URL}/api/v1/promissory-notes/borrows?page=${page}`);
    return response;
  };

  const getLenders = async (page = 0) => {
    const response = axios.get(`${BASE_URL}/api/v1/promissory-notes/loans?page=${page}`);
    return response;
  };

  const getAllBorrows = async (): Promise<IGetAllBorrowers> => {
    let page = 0;
    let totalPage = 0;
    let paidAmount = 0;
    let totalAmount = 0;
    let borrows: any[] = [];
    do {
      const response = await getBorrows(page);
      totalPage = response?.data?.my_notes?.totalPages;
      paidAmount = paidAmount + response?.data?.paid_amount;
      totalAmount = totalAmount + response.data?.total_amount;
      borrows = [...borrows, ...(response?.data?.my_notes?.content ?? [])];
      page++;
    } while (page < totalPage);

    return {
      borrows,
      paidAmount,
      totalAmount,
    };
  };

  const getAllLenders = async (): Promise<IGetAllLenders> => {
    let page = 0;
    let totalPage = 0;
    let paidAmount = 0;
    let totalAmount = 0;
    let lenders: any[] = [];
    do {
      const response = await getLenders(page);
      totalPage = response?.data?.my_notes?.totalPages;
      paidAmount = paidAmount + response?.data?.paid_amount;
      totalAmount = totalAmount + response.data?.total_amount;
      lenders = [...lenders, ...(response?.data?.my_notes?.content ?? [])];
      page++;
    } while (page < totalPage);

    return {
      lenders,
      paidAmount,
      totalAmount,
    };
  };

  return { getBorrows, getAllBorrows, getLenders, getAllLenders, getNote, getPreview, getPercent };
};
