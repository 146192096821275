import React from "react";
import { Spin } from "antd";

import { GeneralDraft, TopNavController } from "src/common/components";
import usePaymentInstallment from "./usePaymentInstallment";
import PaymentInstallmentBorrower from "./PaymentInstallmentBorrower";
import PaymentInstallmentLender from "./PaymentInstallmentLender";
import SignModal from "./SignModal";

const PaymentInstallment = () => {
  const {
    onClickPrev,
    dataLoaded,
    scheduleData,
    type,
    dueAmount,
    dueAmountCount,
    paidAmount,
    paidAmountCount,
    remainingAmount,
    remainingAmountCount,
    sending,
    confirm,
    errorModal,
    errorCode,
    onClickCloseModal,
    errorMessage,
  } = usePaymentInstallment();

  return (
    <>
      <div className="payment-installment-page">
        <TopNavController
          showCancel={false}
          onClickPrev={() => {
            onClickPrev();
          }}
        />
        <div className="payment-installment-page__preview">
          <GeneralDraft headerTitle={"Installment Payments"}>
            {dataLoaded ? (
              <div className="payment-installment-page__preview__content">
                {type === "borrow" ? (
                  <PaymentInstallmentBorrower
                    dueAmount={dueAmount}
                    dueAmountCount={dueAmountCount}
                    paidAmount={paidAmount}
                    paidAmountCount={paidAmountCount}
                    remainingDueAmount={remainingAmount}
                    remainingDueAmountCount={remainingAmountCount}
                  />
                ) : (
                  <PaymentInstallmentLender
                    listData={scheduleData}
                    dueAmount={dueAmount}
                    dueAmountCount={dueAmountCount}
                    paidAmount={paidAmount}
                    paidAmountCount={paidAmountCount}
                    remainingDueAmount={remainingAmount}
                    remainingDueAmountCount={remainingAmountCount}
                    sending={sending}
                    onClickConfirm={confirm}
                  />
                )}
              </div>
            ) : (
              <div className="payment-installment-page__preview__spin">
                <Spin />
              </div>
            )}
          </GeneralDraft>
        </div>
      </div>
      <SignModal text={errorMessage} onCancel={onClickCloseModal} open={errorModal} />
    </>
  );
};

export default PaymentInstallment;
