import { CloseCircleFilled } from "@ant-design/icons";

import { ProfilePhotoUpdate } from "../../../models";

export interface IUploadBoxItemProps {
  attachment: ProfilePhotoUpdate;
  onClickDelete?: (url: string) => void;
}

const UploadBoxItem = ({ attachment, onClickDelete }: IUploadBoxItemProps) => {
  return (
    <div className="upload-box__items__item">
      <span>{attachment.original_file_name}</span>
      <CloseCircleFilled rev="" onClick={() => onClickDelete?.(attachment.file_path)} />
    </div>
  );
};

export default UploadBoxItem;
