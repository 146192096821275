import React, { ReactNode } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router";

export interface NotificationItemProps {
  text: string;
  icon: ReactNode;
  date: string;
  hasButton: boolean;
  buttonText?: string;
  buttonLink?: string;
}
const NotificationItem = ({
  date,
  hasButton,
  icon,
  text,
  buttonLink,
  buttonText,
}: NotificationItemProps) => {
  const navigate = useNavigate();

  return (
    <div className={`notification-item`}>
      <div className="notification-item__icon-wrapper">{icon}</div>
      <div className="notification-item__text-wrapper">
        <div className="notification-item__text-wrapper__title">{text}</div>
        <div className="notification-item__text-wrapper__date-wrapper">
          <div className="notification-item__text-wrapper__date-wrapper__date">{date}</div>
          {hasButton ? (
            <div className="notification-item__text-wrapper__date-wrapper__button">
              <Button
                type="link"
                onClick={() => {
                  navigate(buttonLink ?? "/");
                }}>
                {buttonText}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
