import React, { Dispatch, ReactNode } from "react";
import { Button, Checkbox } from "antd";
import { Link } from "react-router-dom";

export interface GeneralFormProps {
  headerTitle: string;
  headerContent?: {
    __html: string;
  };
  buttonText: string;
  children: ReactNode;
  onClick: () => void;
  width?: string;
  loading?: boolean;
  showNextSection?: boolean;
  disabled?: boolean;
  setCheck?: Dispatch<React.SetStateAction<boolean>>;
  checkboxVisible?: boolean;
}

const GeneralForm = ({
  headerTitle,
  children,
  buttonText,
  headerContent,
  onClick,
  loading = false,
  width,
  showNextSection = true,
  disabled = false,
  setCheck,
  checkboxVisible = true,
}: GeneralFormProps) => {
  return (
    <div className="general-form">
      <div className="general-form__header">{headerTitle}</div>
      <div className="general-form__wrapper">
        <div
          className="general-form__wrapper__main"
          style={{
            width: width ?? "",
          }}>
          {headerContent && (
            <div
              className="general-form__wrapper__main__header"
              dangerouslySetInnerHTML={headerContent}
            />
          )}
          <div className="general-form__wrapper__main__content">{children}</div>
        </div>

        {showNextSection && (
          <div className="general-form__wrapper__submit">
            <div className="general-form__wrapper__submit__divider" />
            <div className="general-form__wrapper__submit__wrapper">
              {checkboxVisible ? (
                <Checkbox
                  onChange={(e) => {
                    setCheck && setCheck(e.target.checked);
                  }}>
                  By clicking, you agree to Loanthem’s{" "}
                  <span style={{ color: "#026BFF" }}>
                    <Link to={"/terms-of-services"} target="_blank">
                      Terms of Service
                    </Link>
                  </span>{" "}
                  and {""}
                  <span style={{ color: "#026BFF" }}>
                    <Link to={"/privacy-policy"} target="_blank">
                      Privacy Policy
                    </Link>
                  </span>
                </Checkbox>
              ) : (
                <></>
              )}
              <Button
                disabled={disabled}
                loading={loading}
                className="styled-button"
                type="primary"
                onClick={onClick}>
                {buttonText}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralForm;
