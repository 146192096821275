import axios, { AxiosResponse } from "axios";

import { SINAN_API_URL } from "../ENV";
import { ICity } from "../models/ILocation";

export const GetCities = async (state: string) => {
  const response: AxiosResponse = await axios.get(`${SINAN_API_URL}/api/v1/states/${state}/cities`);
  const cities: ICity[] = response.data?.map((city: any) => ({
    id: city.city_name,
    label: city.city_name,
  }));
  return cities;
};
