import { AuthReducerType } from "./authReducerType";
import { LOGIN_LOCAL_KEY } from "src/ENV";

export const AuthReducerInitial = (): AuthReducerType => {
  const loginStringData = localStorage.getItem(LOGIN_LOCAL_KEY as string);
  let loginData: AuthReducerType["login"]["data"] | undefined;

  if (loginStringData) {
    loginData = JSON.parse(loginStringData);
  }

  return {
    login: {
      status: loginData ? "success" : "idle",
      data: loginData,
    },
  };
};
