import { FC, useState } from "react";
import { Row, Form, Button, Input, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import InfoBox from "src/common/components/InfoBox";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import Previous from "src/common/components/Previous";
const CollateralTransfer: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const dispatch = useDispatch();
  const [disable, setDisable] = useState<any>(
    note.personal_property_collateral == true || note.personal_property_collateral == false
      ? !note.personal_property_collateral
      : null,
  );
  const [transferType, setTransferType] = useState<any>(note.transfer_type ?? null);
  const handleSubmit = (values: any) => {
    const fields: any = {
      personal_property_collateral: values.personal_property_collateral,
      transfer_type: values.transfer_type,
    };
    dispatch(promissorySetDataAction(fields));
    navigate("/jurisdiction");
  };
  const initialVal = {
    personal_property_collateral:
      note.personal_property_collateral == true || note.personal_property_collateral == false
        ? note.personal_property_collateral
        : null,
    transfer_type: note.transfer_type ?? null,
  };
  const navigate = useNavigate();
  const [form] = Form.useForm();

  return (
    <Row className="note" justify={"center"}>
      <Previous location="/collateral" />
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Collateral Transfer
      </Typography.Title>
      <Form
        initialValues={initialVal}
        form={form}
        className="note-form-box"
        onFinish={(val) => handleSubmit(val)}>
        <Typography.Title
          className="note-title"
          style={{ color: "white", fontSize: 14, fontWeight: 400, paddingBottom: 20 }}>
          Is the Lender providing the loan for the purchase of personal property?{" "}
        </Typography.Title>
        <Form.Item
          name="personal_property_collateral"
          rules={[{ required: true, message: "Please select one of the items" }]}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Button
              className={`note-btn-yes ${disable == true ? "note-btn-enable" : ""}`}
              onClick={() => {
                setDisable(false);
                form.setFieldsValue({ personal_property_collateral: true });
              }}>
              Yes
            </Button>
            <Button
              className={`note-btn-no ${disable == false ? "note-btn-enable" : ""}`}
              onClick={() => {
                setDisable(true);
                form.setFieldsValue({ personal_property_collateral: false });
                form.validateFields();
              }}>
              No
            </Button>
          </div>
        </Form.Item>
        <Form.Item
          name="transfer_type"
          rules={[
            {
              required: disable == false ? true : false,
              message: "Please select Transfer type",
            },
          ]}
          style={{ marginTop: "50px" }}>
          <Typography.Title
            className="note-title"
            style={{ color: "white", fontSize: 14, fontWeight: 400, paddingBottom: 20 }}>
            Is the title transferred at signing or only after full payment?
          </Typography.Title>
          <div className="note-demandPaymenct">
            <Button
              disabled={disable}
              className={`note-secondery-btn-yes btn-lable ${
                disable ? "note-btn-enable" : transferType !== "SIGNING" ? "note-btn-enable" : ""
              }`}
              style={{
                backgroundColor: "black",
                color: "white",
                borderColor: "black",
                fontSize: "12px",
              }}
              onClick={() => {
                setTransferType("SIGNING");
                form.setFieldsValue({ transfer_type: "SIGNING" });
              }}>
              At Signing
            </Button>
            <Button
              className={`note-secondery-btn-no btn-right ${
                disable
                  ? "note-btn-enable"
                  : transferType !== "FULL_PAYMENT"
                  ? "note-btn-enable"
                  : ""
              }`}
              disabled={disable}
              style={{
                backgroundColor: "white",
                color: "black",
                borderColor: "white",
                fontSize: "12px",
                padding: "9px 19px",
              }}
              onClick={() => {
                setTransferType("FULL_PAYMENT");
                form.setFieldsValue({ transfer_type: "FULL_PAYMENT" });
              }}>
              Full Payment
            </Button>
          </div>
        </Form.Item>

        <div className="note-form-line"></div>
        <Button
          style={{ marginTop: "70px" }}
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
      <InfoBox text="“Personal property,” refers to any moveable assets other than land or buildings. " />
    </Row>
  );
};

export default CollateralTransfer;
