import React, { FC, useEffect, useState } from "react";
import { Checkbox, Input, Row, Form, Typography, Button } from "antd";
import "@fontsource/lato";
import "./login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Password from "antd/es/input/Password";
import { useDispatch, useSelector } from "react-redux";
import { authSetDataAction, authSetUser, loginAction, RootState } from "src/store";
import ErrorBox from "src/common/components/ErrorBox";
import useLoginRegisterModal from "../loginRegisterModal/useLoginRegisterModal";

type FormType = {
  email: string;
  password: string;
  remember: boolean;
};

const Login: FC = () => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const { login } = useSelector((store: RootState) => ({
    login: store.authReducer.login,
  }));
  const { resendOtp } = useLoginRegisterModal(() => {
    return;
  });
  const promissory = useSelector((state: RootState) => state.promissoryReducer);
  const generatePromissoryNoteLink =
    promissory.route && promissory.route !== "" ? promissory.route : "/amount";

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (login.status === "error" && login.error) {
      setIsActive(true);
      if (Number(login.error?.code) === 1007) {
        resendOtp(form.getFieldValue("email"), form.getFieldValue("password"), false);
        navigate("/verify", {
          state: {
            email: form.getFieldValue("email"),
            prevUrl: location.pathname,
          },
        });
      }
      return;
    }

    if (login.status === "loading") {
      setIsActive(false);
    }

    if (login.status === "success") {
      document.cookie = `loggedIn=true; expires=${new Date(
        Date.now() + 86400000,
      ).toUTCString()}; path=/`;
      // Set cookie
      if (login.data?.authorities.includes("ADMIN")) {
        navigate("/admin");
      } else {
        if (
          login.data?.roles &&
          (login.data?.roles.includes("lender") || login.data?.roles.includes("borrower"))
        ) {
          navigate("/home");
        } else if (
          login.data?.plan &&
          (login.data?.plan === "MULTIPLE_PACKAGE" || login.data?.plan === "PREMIUM_PACKAGE")
        ) {
          navigate("/home");
        } else {
          if (generatePromissoryNoteLink == "/amount") {
            navigate(generatePromissoryNoteLink, {
              state: { disablePrev: true },
            });
          } else {
            navigate(generatePromissoryNoteLink);
          }
        }
      }
    }
  }, [login.status]);

  const onFinish = (value: FormType) => {
    dispatch(loginAction(value.email, value.password, value.remember));
  };

  return (
    <Row justify={"center"}>
      <Row style={{ display: "block" }}>
        <Form
          className={"login-form"}
          style={{
            height: isActive ? 638 : 550,
          }}
          layout={"vertical"}
          form={form}
          onFinish={onFinish}>
          <div className="login-form-title">Log in</div>
          <div className="login-header-text">
            Please enter your details to sign in to your account
          </div>
          <Form.Item
            className="login-form-input"
            label="Email / Username"
            rules={[{ required: true, message: "please enter your email / username" }]}
            required
            name="email">
            <Input placeholder="Email / Username" size={"large"} className="login-email-input" />
          </Form.Item>
          <Form.Item
            className="login-form-input"
            label="Password"
            rules={[
              { required: true, message: "please enter your password" },
              { min: 8, message: "Password must have a minimum length of 8" },
            ]}
            required
            name="password">
            <Password
              placeholder="Password"
              size={"large"}
              className="login-password-input"
              name="password"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox
                style={{
                  color: "white",
                  width: 124,
                  height: 24,
                  marginBottom: 6,
                }}>
                Remember me
              </Checkbox>
            </Form.Item>
            <Link to={"/forget-password"}>
              <Typography.Text style={{ color: "white" }}>Forgot password?</Typography.Text>
            </Link>
          </div>
          <Button
            className="login-btn"
            htmlType={"submit"}
            type="primary"
            block
            loading={login.status === "loading"}>
            Login
          </Button>
          <div>
            {login.status === "error" && login.error ? (
              <ErrorBox text="The email address or password is incorrect!" />
            ) : (
              ""
            )}
          </div>
          <div className="container">
            <div className="login-line"></div>
            <span className="login-form-countinue">Or continue with </span>
            <div className="login-line"></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              marginTop: 15,
            }}>
            <a href="https://play.google.com/store/games?hl=en&gl=US">
              <img src={require("src/assets/images/google-icons.png")} alt="" />
            </a>
            <a href="https://facebook.com">
              <img src={require("src/assets/images/facebook-icon.png")} alt="" />
            </a>
            <a href="https://www.apple.com/">
              <img src={require("src/assets/images/apple-icons.png")} alt="" />
            </a>
          </div>
        </Form>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 7,
          }}>
          <Typography.Text className="login-footer-text" style={{ color: "white" }}>
            Don’t have an account?
          </Typography.Text>
          <Link to={"/register"}>Register </Link>
        </div>
      </Row>
    </Row>
  );
};

export default Login;
