import axios from "axios";

import { SINAN_API_URL } from "../ENV";

const useLocation = () => {
  const getStates = async () => {
    const response = axios.get(`${SINAN_API_URL}/api/v1/countries/United States/states`);
    return response;
  };

  const getCities = async (stateName: string) => {
    const response = axios.get(`${SINAN_API_URL}/api/v1/states/${stateName}/cities`);
    return response;
  };

  return { getStates, getCities };
};

export default useLocation;
