import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { reviewRestApi } from "../../services/http";
import { AppReviewDTO } from "../../api";

const useLeaveReview = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<AppReviewDTO>({});

  const leaveReview = async () => {
    setLoading(true);
    await reviewRestApi.add2(model);
    setLoading(false);
    navigate(-1);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  return { loading, model, leaveReview, setModel, onClickBack };
};

export default useLeaveReview;
