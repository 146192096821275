import { ChangeEvent } from "react";

import { IFileResult } from "../models";

export interface IFile {
  onDone: (result: IFileResult, file: File, withUser: boolean) => void;
  withUser: boolean;
}

const useFile = ({ onDone, withUser }: IFile) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target.files;
    if (files) {
      for (let i = 0; i < files?.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const fileResult: IFileResult = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000),
            base64: reader.result,
            file: file,
          };
          onDone(fileResult, file, withUser);
        };
      }
    }
  };

  return { handleChange };
};

export default useFile;
