import React from "react";
import { Avatar } from "antd";

export interface ProfileLoanProps {
  id: string;
  img?: string;
  title: string;
  abbreviation: string;
  color: string;
  selected?: boolean;
  onClick?: (id: string) => void;
}

const ProfileLoan = ({
  title,
  img,
  selected = false,
  onClick,
  id,
  abbreviation,
  color,
}: ProfileLoanProps) => {
  return (
    <div
      className={`profile-loan ${selected ? "active" : ""}`}
      onClick={() => {
        onClick?.(id);
      }}>
      <div className="profile-loan__image-wrapper">
        <Avatar
          size={88}
          style={{
            backgroundColor: color,
          }}
          src={img}>
          {abbreviation}
        </Avatar>
      </div>
      <span className={`profile-loan__title ${selected ? "active" : ""}`}>{title}</span>
    </div>
  );
};

export default ProfileLoan;
