/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Rate } from "antd";

import Star from "./Star";

export interface RateSectionProps {
  title: string;
  onChange: (num: number) => void;
}
const RateSection = ({ title, onChange }: RateSectionProps) => {
  const [val, setVal] = useState(0);

  useEffect(() => {
    onChange(val);
  }, [val]);

  return (
    <div className="leave-review-page__section-rate">
      <span className="leave-review-page__section-rate__title">{title}</span>
      <div className="leave-review-page__section-rate__star-wrapper">
        <Rate
          allowHalf={false}
          characterRender={(_, props) => {
            return (
              <Star
                active={Number(props.index) + 1 <= val}
                index={Number(props.index)}
                onClick={(val) => setVal(val + 1)}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default RateSection;
