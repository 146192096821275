import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";

import "antd/dist/antd.min.css";

import Pages from "./pages";
import Layout from "./layout";
import { Provider } from "react-redux";
import { Store } from "./store";

const App: FC = () => {
  return (
    <Provider store={Store}>
      <ConfigProvider>
        <BrowserRouter>
          <Layout>
            <Pages />
          </Layout>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
