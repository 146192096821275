import React, { FC, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "src/store";
import { useSelector } from "react-redux";
import { Avatar, Button, List, Row, Typography, Col } from "antd";
import { useDispatch } from "react-redux";
import { setRegisterBgAction } from "../../store/actions/registerbgActions/registerBgAction";

const LandingPage: FC = () => {
  const promissory = useSelector((state: RootState) => state.promissoryReducer);
  const { user, login } = useSelector((store: RootState) => store.authReducer);
  const navigate = useNavigate();
  const generatePromissoryNoteLink =
    promissory.route && promissory.route !== "" ? promissory.route : "/amount";
  promissory.route && promissory.route !== "" ? promissory.route : "/amount";

  const dispatch = useDispatch();

  const itemsFirstCart = [
    {
      title1: "Amortization Note",
    },
    {
      title: "Secure Payment Processing",
    },
    {
      title: "Payment Reminders and Receipts",
    },
    {
      title: "Demand and Loan Release Letters",
    },
    {
      title: "Tax Form Prep",
    },
  ];

  const itemsSecondCart = [
    {
      title1: "Multiple Notes & Edits",
    },
    {
      title: "Secure Payment Processing",
    },
    {
      title: "Payment Reminders and Receipts",
    },
    {
      title: "Demand and Loan Release Letters",
    },
    {
      title: "Tax Form Prep",
    },
  ];

  useEffect(() => {
    if (user) {
      if (
        user.activePlan === "PREMIUM_PACKAGE" ||
        user.activePlan === "MULTIPLE_PACKAGE" ||
        (user.lenders && user.lenders?.length > 0) ||
        (user.borrowers && user.borrowers?.length > 0)
      ) {
        navigate("/home", {
          replace: true,
        });
      }
    }
  }, [user]);
  const handleNavigateRegister = (bg: any) => {
    dispatch(setRegisterBgAction(bg.toString()));
    navigate("/register");
  };
  return (
    <Row justify={"center"}>
      <Row style={{ display: "block" }}>
        <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
          <div className="landing-page-header-section">
            <div className="landing-page-header-txt-btn">
              <div className="landing-title--dec">
                <Typography.Title
                  className="landing-title--dec-title"
                  style={{ color: "white", fontSize: 43 }}>
                  Promissory Notes with Secure Payment Processing for Friend & Family Loans{" "}
                </Typography.Title>
                <Typography.Text className="landing-text">
                  Ensure friend and family harmony through the creation of enforceable <br />
                  promissory notes and Loanthem’s installment payment processing service.
                </Typography.Text>
              </div>
              {!(login && login.status === "success") && (
                <div className="landing-page-head-bts">
                  <Button
                    className="Landing-head-btn"
                    type="primary"
                    block
                    onClick={() => handleNavigateRegister(3)}
                    style={{ marginRight: 15, height: 36, borderRadius: 4 }}>
                    Register
                  </Button>
                  <Link to={generatePromissoryNoteLink}>
                    <Button
                      className="Landing-head-btn"
                      htmlType={"submit"}
                      type="primary"
                      block
                      style={{
                        marginRight: 21,
                        color: " #026BFF",
                        backgroundColor: "white",
                        fontWeight: 600,
                        height: 36,
                        borderRadius: 4,
                      }}>
                      Continue as Guest
                    </Button>
                  </Link>
                </div>
              )}
            </div>
            <img
              className="landing-page-background-img"
              src={require("src/assets/images/landing-page-img-header.png")}
              alt=""
            />
          </div>
          <div className="landing-section--carts">
            {/*<Col span={8}>*/}
            <div className="landing-head-cart">
              <div className="landing-head-cart-title">Secure Payment Processing</div>
              <Typography.Text className="landing-head-cart-text">
                {" "}
                Make secure installment payments from your bank or payment service.{" "}
              </Typography.Text>
              <Button onClick={() => handleNavigateRegister(2)} className="landing-head-cart-btn">
                Learn more
              </Button>
            </div>
            <div className="landing-head-cart-center">
              <div className="landing-head-cart-title">Payment Reminders & Receipts</div>
              <Typography.Text className="landing-head-cart-text">
                {" "}
                Payment reminders & receipts that ensure timely fullfillment of your loan terms.{" "}
              </Typography.Text>
              <Button onClick={() => handleNavigateRegister(4)} className="landing-head-cart-btn">
                Learn more
              </Button>
            </div>
            <div className="landing-head-cart">
              <div className="landing-head-cart-title">Tax Form Prep</div>
              <Typography.Text className="landing-head-cart-text">
                {" "}
                Quick analysis of possible lender or borrower tax liabilities for your loan.
              </Typography.Text>
              <Button onClick={() => handleNavigateRegister(1)} className="landing-head-cart-btn">
                Learn more
              </Button>
            </div>
          </div>
        </div>
        <div
          className="landing-page-background-center-fram"
          style={{
            backgroundImage: `url(${require("src/assets/images/landing-page-image-end.png")})`,
          }}>
          <div className="landing-center">
            <div style={{ marginTop: 50, marginLeft: 0, width: "90%" }}>
              <Typography.Title style={{ color: "white", fontSize: 34 }}>
                The All-In-One Person-to-Person <br /> Loan Management App
              </Typography.Title>
              <Typography.Text style={{ color: "white" }}>
                Manage loan payments, reminders, and receipts from your fingertips
              </Typography.Text>
            </div>
            <div className="landing-center-imgs">
              <a className="store_links" style={{ flex: 1 }} href="https://apple.com">
                <img
                  src={require("src/assets/images/apple-Landing.png")}
                  alt=""
                  style={{ marginRight: 5 }}
                />
              </a>
              <a className="store_links" style={{ flex: 1 }} href="https://play.google.com/store">
                <img src={require("src/assets/images/google-landing.png")} alt="" />
              </a>
            </div>
            <img
              className="landing-page-img-center-fram"
              src={require("src/assets/images/landing-page-img-center-fram.png")}
              alt=""
            />
          </div>
          <div style={{ position: "relative", marginTop: "15vh" }}>
            <div style={{ margin: "auto" }}>
              <img
                className="landing-page-fram-box"
                src={require("src/assets/images/landing-page-cenret-fram-box.png")}
                alt=""
              />
              <img
                className="landing-page-center-box"
                src={require("src/assets/images/landing-page-center-box.png")}
                alt=""
              />
              <img
                className="landing-page-center-fram-account2"
                src={require("src/assets/images/landing-page-center-fram-account2.png")}
                alt=""
              />
              <img
                className="landing-page-img-account"
                src={require("src/assets/images/landing-page-center-img-account.png")}
                alt=""
              />
            </div>
            <div className="landing-page-box-images">
              <img
                className="landing-page-iphone12"
                src={require("src/assets/images/landing-page-img-iphone12.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="landing-page-background-center-fram">
          <div className="landing-footer--imageTxt" style={{ textAlign: "center" }}>
            <div style={{ marginLeft: 0 }}>
              <Typography.Title style={{ color: "white" }}>
                Connect with our experts
              </Typography.Title>
              <Link to={"/support-ticket"}>
                <Button className="landing-image-btn">GET IN TOUCH</Button>
              </Link>
              <Typography.Title style={{ color: "white" }}>
                Simple, transparent pricing
              </Typography.Title>
            </div>
            <div style={{ margin: 8 }}>
              <Typography.Text style={{ color: "white" }}>
                Pick a plan that suit your needs: from individual or multiple promissory note
                creation to full loan management and <br />{" "}
              </Typography.Text>
              <span className="landing-footer--imageTxt-">
                {" "}
                installment payment processing, LoanThem provides a complete solution.
              </span>
            </div>
          </div>
        </div>
        <Row justify={"center"} gutter={16}>
          <div className="landing--carts">
            <div className="landing-carts-first-cart-">
              <div className="landing-carts-price-" style={{ color: "white" }}>
                $9.95
              </div>
              <div style={{ color: "#FFFFFF", opacity: 0.6, textAlign: "center" }}>Single Note</div>
              <div className="landing-carts-first-cart-line"></div>

              <List
                itemLayout="horizontal"
                dataSource={itemsFirstCart}
                renderItem={(item) => (
                  <List.Item className="landing-carts-list-texts" style={{ borderBottom: "none" }}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{
                            display: "block",
                            width: "85%",
                            height: "85%",
                            marginTop: 4,
                            marginLeft: 3,
                          }}
                          src={
                            item.title
                              ? require("src/assets/images/landing-cart-tick.png")
                              : require("src/assets/images/landing-cart-tick-true.png")
                          }
                        />
                      }
                      title={
                        <div className="landing-carts--txts" style={{ color: "white" }}>
                          {item.title1}
                          {item.title}
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
              <div className="landing-carts-first-cart-line"></div>
              <button className="landing-carts-btn">Select Plan</button>
            </div>
            <div className="landing-carts-second-cart-">
              <div className="landing-carts-second-cart-label">Most popular</div>
              <div>
                <div className="landing-carts-price-" style={{ color: "white" }}>
                  $29.95
                </div>
                <div style={{ color: "#FFFFFF", opacity: 0.6, textAlign: "center" }}>
                  1-Year Multi Note
                </div>
                <div className="landing-carts-first-cart-line"></div>
                <List
                  itemLayout="horizontal"
                  dataSource={itemsSecondCart}
                  renderItem={(item) => (
                    <List.Item
                      className="landing-carts-list-texts"
                      style={{ borderBottom: "none" }}>
                      <List.Item.Meta
                        style={{ marginRight: 5 }}
                        avatar={
                          <Avatar
                            style={{
                              display: "block",
                              width: "85%",
                              height: "85%",
                              marginTop: 4,
                              marginLeft: 3,
                            }}
                            src={
                              item.title
                                ? require("src/assets/images/landing-cart-tick.png")
                                : require("src/assets/images/landing-cart-tick-true.png")
                            }
                          />
                        }
                        title={
                          <div className="landing-carts--txts" style={{ color: "white" }}>
                            {item.title1}
                            {item.title}
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
                <div className="landing-carts-first-cart-line"></div>
                <button className="landing-carts-center-btn">Select Plan</button>
              </div>
            </div>
            <div className="landing-carts-first-cart-">
              <div className="landing-carts-price-" style={{ color: "white" }}>
                $49.95
              </div>
              <div style={{ color: "#FFFFFF", opacity: 0.6, textAlign: "center" }}>
                1-Year Premium
              </div>
              <div className="landing-carts-first-cart-line"></div>

              <List
                itemLayout="horizontal"
                dataSource={itemsSecondCart}
                renderItem={(item) => (
                  <List.Item className="landing-carts-list-texts" style={{ borderBottom: "none" }}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{
                            display: "block",
                            width: "85%",
                            height: "85%",
                            marginTop: 4,
                            marginLeft: 3,
                          }}
                          src={require("src/assets/images/landing-cart-tick-true.png")}
                        />
                      }
                      title={
                        <div className="landing-carts--txts" style={{ color: "white" }}>
                          {item.title1}
                          {item.title}
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
              <div className="landing-carts-first-cart-line"></div>
              <button className="landing-carts-btn">Select Plan</button>
            </div>
          </div>
        </Row>
        <div className="landing-page-footer-divBox">
          <img
            style={{ width: "100%", position: "absolute", height: 300 }}
            src={require("src/assets/images/landing-page-footer-box.png")}
            alt=""
          />
          <div className="landing-help-box">
            <div className="landing-help-box-First-text" style={{ color: "white" }}>
              Can’t find a plan to suit your needs?
            </div>
            <div
              className="landing-help-box-Second-text"
              style={{ color: "#A4B3F4", marginTop: -8 }}>
              We’re here to help
            </div>
            <Button className="landing-help-box-btn">Contact us</Button>
          </div>
        </div>
        <div style={{ marginTop: "15vh" }}>
          <div className="landing-page-text-end2">Can’t find a plan to suit your needs?</div>
          <div className="landing-page-text-end1">We’re here to help</div>
        </div>
      </Row>
    </Row>
  );
};
export default LandingPage;
