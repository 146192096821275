import { CheckCircleFilled } from "@ant-design/icons";
import React from "react";

export interface RowProps {
  title: string;
  enable: boolean;
}

const Row = ({ enable, title }: RowProps) => {
  return (
    <div className="row">
      <div className={`row__icon ${enable ? "active" : "deactive"}`}>
        <CheckCircleFilled rev="" />
      </div>
      <div className={`row__title ${enable ? "active" : "deactive"}`}>{title}</div>
    </div>
  );
};

export default Row;
