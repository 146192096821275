/* eslint-disable no-case-declarations */
import React from "react";
import { Avatar, Spin } from "antd";
import moment from "moment";

import NotificationItem from "./NotificationItem";
import { INotification } from "src/models";
import { formatNumber } from "src/utils/NumberUtil";
import { getRandomColor } from "src/utils/ColorUtil";
import useNotification from "./useNotification";

import { ReactComponent as IconMessage } from "../../../assets/icons/ic-notification-message.svg";
import { ReactComponent as IconWarning } from "../../../assets/icons/ic-notification-warning.svg";

export interface NotificationMenuProps {
  notifications: INotification[];
  loading: boolean;
  onreadNotifications: () => void;
}

const NotificationMenu = ({
  loading,
  notifications,
  onreadNotifications,
}: NotificationMenuProps) => {
  useNotification(onreadNotifications);

  const renderItem = (item: INotification) => {
    let icon;
    let text;
    let buttonText;
    let navigationLink = "";
    let hasButton = false;

    const date = moment(item.createdDate).format("MM/DD/YYYY");
    const fromNow = moment(item.createdDate).fromNow();
    console.log(item);
    switch (item.type) {
      case "PROMISSORY_NOTE_NOTIFICATION":
        text = `Your promissory note, "${item.noteName}", was created on ${date}.`;
        icon = <IconMessage />;
        buttonText = "View Note";
        navigationLink = `/dashboard/${item.itemId}/preview`;
        hasButton = true;
        break;
      case "PROMISSORY_NOTE_REMINDER":
        text = `Payment is due on "${date}".`;
        icon = <IconWarning />;
        buttonText = "Go to Payment";
        hasButton = true;
        break;
      case "PAYMENT_SESSION_NOTIFICATION":
        text = `${item.borrowerName} paid $${formatNumber(Number(item.amount))} to ${
          item.lenderName
        }.`;
        const borrowerNameArray = item.borrowerName ? item.borrowerName.split(" ") : [];
        const abbreviation = `${String(
          borrowerNameArray?.[0]?.substring(0, 1) ?? "",
        )} ${borrowerNameArray?.[1]?.substring(0, 1)}`;
        const color = getRandomColor(item.borrowerName);
        icon = (
          <Avatar
            size={32}
            style={{
              backgroundColor: color,
            }}>
            {abbreviation}
          </Avatar>
        );
        break;
      default:
        text = "";
        icon = <></>;
    }
    return (
      <li key={item.id} className={`${item.status === "UNREAD" ? "active" : ""}`}>
        <NotificationItem
          date={fromNow}
          hasButton={hasButton}
          text={text}
          icon={icon}
          buttonLink={navigationLink}
          buttonText={buttonText}
        />
      </li>
    );
  };

  return (
    <div className="notification-menu">
      {loading ? (
        <Spin className="notification-menu__spin" />
      ) : (
        <ul>{notifications.map((item) => renderItem(item))}</ul>
      )}
    </div>
  );
};

export default NotificationMenu;
