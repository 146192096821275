import { FC } from "react";
import { Row, Typography, Col } from "antd";
import "../css/infBox.css";
import { InfoCircleFilled } from "@ant-design/icons";

interface infoProps {
  text: string;
  space?: boolean;
}

const InfoBox: FC<infoProps> = (props) => {
  return (
    <Row className="info-row" justify={"center"}>
      <div className="info-container">
        <Row justify="center" className="info-items-row">
          <Col span={1}>
            <InfoCircleFilled rev="" style={{ color: "#1890FF", marginLeft: "5px" }} />
          </Col>
          <Col span={23}>
            <Typography.Paragraph
              style={props.space == true ? { whiteSpace: "pre-wrap" } : {}}
              className="info-text">
              {props.text}
            </Typography.Paragraph>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

export default InfoBox;
