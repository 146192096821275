import React, { FC, useState } from "react";
import { Row, Form, Button, Typography, Select, DatePicker, Space } from "antd";

import { LeftOutlined, RightOutlined, CalendarFilled } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { promissorySetDataAction, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import Previous from "../../common/components/Previous";
import moment, { Moment } from "moment";

type FormType = { Agreement: string };

const AgreementPayment: FC = () => {
  const dateFormat = "MM/DD/YYYY";
  const [form] = Form.useForm();

  const nots = useSelector((store: RootState) => store.promissoryReducer);

  const [begin, setbegin] = useState<any>(nots.agreement_begin ? nots.agreement_begin : "");
  const [first, setfirst] = useState<any>(nots.first_payment_due ? nots.first_payment_due : "");
  const [last, setlast] = useState<any>(nots.last_payment_due ? nots.last_payment_due : "");
  const [one, setone] = useState<any>("");
  const [two, settwo] = useState<any>("");
  const [three, setthree] = useState<any>("");

  const initialvalues = {
    agreement_begin: nots.agreement_begin ? moment(nots.agreement_begin) : "",
    first_payment_due: nots.first_payment_due ? moment(nots.first_payment_due) : "",
    last_payment_due: nots.last_payment_due ? moment(nots.last_payment_due) : "",
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <Row className="note" justify={"center"}>
      <Previous location={"/interest"}></Previous>
      <div className="agreement-text">Agreement & Payment Dates</div>
      <Form
        className="agreement-form"
        form={form}
        initialValues={initialvalues}
        onFinish={(values) => {
          const fields: any = {
            agreement_begin: values.agreement_begin
              ? values.agreement_begin.format("YYYY-MM-DD").toString()
              : "",
            first_payment_due: values.first_payment_due
              ? values.first_payment_due.format("YYYY-MM-DD").toString()
              : "",
            last_payment_due: values.last_payment_due
              ? values.last_payment_due.format("YYYY-MM-DD").toString()
              : "",
          };
          dispatch(promissorySetDataAction(fields));
          navigate("/installments");
        }}>
        <Form.Item
          className="lump-input-date"
          label="When will the agreement begin?"
          name="agreement_begin"
          rules={[
            {
              required: true,
              message: "Please select the agreement beginning date.",
            },
            {
              validator: (_, value: Moment) => {
                if (nots && nots.editMode) {
                  if (moment().diff(value, "month") > 1) {
                    return Promise.reject("");
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
              message: "It can be selected only up to one month before today's date",
            },
          ]}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingTop: 9,
            marginLeft: 32,
          }}>
          <DatePicker
            disabledDate={(currentDate) => {
              const oneMonthAgo = moment().subtract(1, "months");
              return currentDate && currentDate.isBefore(oneMonthAgo, "day");
            }}
            suffixIcon={<CalendarFilled rev="" />}
            format={dateFormat}
            name="datePicker-first"
            className="agreement-input-date-inp"
            dropdownClassName={"date-pick-test"}
            value={begin}
            placeholder="mm/dd/yyyy"
            dropdownAlign={{ offset: [322, 9, 0] }}
            onChange={(date) => setone(date)}
          />
        </Form.Item>
        <Form.Item
          name="first_payment_due"
          className="lump-input-date"
          label="When is the first payment due?"
          rules={[
            {
              required: true,
              message: "Please select the first payment due date.",
            },
            {
              validator: async (_, endDatetime) => {
                const startDatetime = form.getFieldValue("first_payment_due");
                const endDateTime = form.getFieldValue("agreement_begin");
                if (startDatetime && endDateTime && endDateTime > startDatetime) {
                  return Promise.reject(
                    "The first payment can not be before the agreement begins.",
                  );
                }
              },
            },
          ]}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: 32,
          }}>
          <DatePicker
            // disabled={one ? false : true}
            disabledDate={(d) => d.isBefore(form.getFieldValue("agreement_begin"))}
            suffixIcon={<CalendarFilled rev="" />}
            name="datePicker-second"
            dropdownClassName={"date-pick-test"}
            format={dateFormat}
            value={first}
            className="agreement-input-date-inp"
            dropdownAlign={{ offset: [322, 9, 0] }}
            placeholder="mm/dd/yyyy"
            // onChange={(date) => console.log(form.getFieldValue("agreement_begin"))}
          />
        </Form.Item>
        <Form.Item
          className="lump-input-date"
          label="When is the last payment due?"
          name="last_payment_due"
          rules={[
            {
              required: true,
              message: "Please select the last payment due date.",
            },
            {
              validator: async (_, endDatetime) => {
                const startDatetime = form.getFieldValue("first_payment_due");
                const endDateTime = form.getFieldValue("last_payment_due");
                if (startDatetime && endDateTime && startDatetime >= endDateTime) {
                  return Promise.reject("The last payment cannot be before the first payment.");
                }
              },
            },
          ]}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: 32,
          }}>
          <DatePicker
            // disabled={two && one ? false : true}
            name="datePicker-third"
            // dropdownClassName={"date-pick-test-"}
            suffixIcon={<CalendarFilled rev="" />}
            disabledDate={(d) => {
              const firstPaymentDue = form.getFieldValue("first_payment_due");
              return d.isBefore(firstPaymentDue) || d.isSame(firstPaymentDue, "day");
            }}
            format={dateFormat}
            value={last}
            dropdownAlign={{ offset: [318, -3, 0] }}
            className="agreement-input-date-inp"
            onChange={(date) => setthree(date)}
            placeholder="mm/dd/yyyy"
          />
        </Form.Item>

        <div className="agreement-form-line"></div>
        {/*<Form.Item className="z">*/}
        <Button
          htmlType={"submit"}
          className="agreement-form-btn"
          type="primary"
          icon={<RightOutlined rev="" style={{ marginTop: 4 }} />}>
          Next
        </Button>
        {/*</Form.Item>*/}
      </Form>
    </Row>
  );
};

export default AgreementPayment;
