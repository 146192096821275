import { FC, useState } from "react";
import { Row, Form, Button, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import Previous from "../../common/components/Previous";

const Borrower: FC = () => {
  const [borrowerType, setBorrowerType] = useState<null | string>(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const previousURL = state ? state.previousURL : null;

  const handleSubmit = () => {
    if (borrowerType == "INDIVIDUAL") {
      navigate("/individual-info/borrower");
    } else {
      navigate("/bussiness-info/borrower");
    }
  };
  return (
    <Row className="note" justify={"center"}>
      <Previous
        location={previousURL ? "/bussiness-info/lender" : "/individual-info/lender"}></Previous>
      <div className="note-default"></div>
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Borrower
      </Typography.Title>
      <Form form={form} className="note-form-box" onFinish={handleSubmit}>
        <Typography.Title
          className="note-title"
          style={{
            color: "white",
            fontSize: 14,
            fontWeight: 400,
            paddingBottom: 10,
          }}>
          Who is the Borrower?
          <span style={{ color: "red" }}>*</span>
        </Typography.Title>
        <Form.Item
          style={{ marginTop: "50px" }}
          name="borrower_type"
          rules={[{ required: true, message: "Please select one of the items" }]}>
          <div className="note-demandPaymenct">
            <Button
              className={`note-secondery-btn-yes ${
                borrowerType == "CORPORATION" ? "note-btn-enable" : ""
              }`}
              onClick={() => {
                setBorrowerType("INDIVIDUAL");
                form.setFieldsValue({ borrower_type: "INDIVIDUAL" });
              }}>
              {" "}
              Individual
            </Button>
            <Button
              className={`note-secondery-btn-no ${
                borrowerType == "INDIVIDUAL" ? "note-btn-enable" : ""
              }`}
              onClick={() => {
                setBorrowerType("CORPORATION");
                form.setFieldsValue({ borrower_type: "CORPORATION" });
              }}>
              Business Entity
            </Button>
          </div>
        </Form.Item>
        <div style={{ height: "50px" }} />
        <div className="note-form-line"></div>
        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
    </Row>
  );
};

export default Borrower;
