import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { promissoryReducer } from "./promissoryReducer";
import { registerBgReducer } from "./registerBgReducer";
import { siteReducer } from "./siteReducer/siteReducer";

const AppReducer = combineReducers({
  authReducer,
  promissoryReducer,
  registerBgReducer,
  siteReducer,
});

export type RootState = ReturnType<typeof AppReducer>;

export default AppReducer;

export * from "./authReducer";
