import React from "react";
import { Badge, Popover } from "antd";
import { BellOutlined } from "@ant-design/icons";

import NotificationMenu from "./NotificationMenu";
import HeaderIcon from "../HeaderIcon/HeaderIcon";
import useNotificationMenu from "./useNotificationMenu";

const NotificationIcon = () => {
  const { notifications, loading, unreadCount, getNotificationsAsync } = useNotificationMenu();

  return (
    <Popover
      placement="topLeft"
      title={""}
      content={
        <NotificationMenu
          notifications={notifications}
          loading={loading}
          onreadNotifications={getNotificationsAsync}
        />
      }
      trigger="hover"
      overlayClassName="notification-main-wrapper">
      <div>
        <Badge dot={unreadCount > 0}>
          <HeaderIcon icon={<BellOutlined rev="" />} title="Notification" />
        </Badge>
      </div>
    </Popover>
  );
};

export default NotificationIcon;
