import { useState } from "react";
import AWS from "aws-sdk";
import { useUser } from "./useUser";

const useS3 = (defaultUrl: string) => {
  const BUCKET = "loanthem";
  const REGION = "us-west-1";
  const ACCESS_KEY = "AKIATZWYHQR62FINBVKW";
  const SECRET_KEY = "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";

  const { getCredential } = useUser();

  const [sending, setSending] = useState(false);
  const [s3Data, setS3Data] = useState<AWS.S3.ManagedUpload.SendData | undefined>();
  const [url, setUrl] = useState<string | undefined>(defaultUrl);

  const uploadToS3 = async (file: File, withUser = true) => {
    setSending(true);
    const response = withUser ? await getCredential() : undefined;

    if (withUser) {
      AWS.config.update({
        region: REGION,
        accessKeyId: response!.data.access_key_id,
        secretAccessKey: response!.data.secret_access_key,
        sessionToken: response!.data.session_token,
        signatureVersion: "v4",
      });
    } else {
      AWS.config.update({
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_KEY,
        signatureVersion: "v4",
      });
    }

    const fileKey = `${new Date().getTime()}-${file.name}`;
    const bucket = withUser ? `${BUCKET}/users/${response!.data.folder}` : BUCKET;
    const params = {
      Bucket: bucket,
      Key: fileKey,
      Body: file,
    };

    const upload = new AWS.S3.ManagedUpload({
      params,
    });
    const promise = upload.promise();
    promise.then(
      (data) => {
        setSending(false);
        setS3Data(data);
        signUrl(bucket, data.Key);
        setUrl(data.Location);
      },
      (err) => {
        setSending(false);
      },
    );
  };

  const signUrl = async (bucket: string, key: string) => {
    if (!(bucket && key)) {
      return;
    }

    const S3ReadParams: AWS.S3.PutObjectAclRequest = {
      Key: key,
      Bucket: "loanthem",
      ACL: "public-read",
    };
    await new AWS.S3().putObjectAcl(S3ReadParams).promise();
  };

  const removeUrl = () => {
    setUrl(undefined);
    setS3Data(undefined);
    setSending(false);
  };

  return { uploadToS3, url, sending, removeUrl, s3Data, signUrl };
};

export default useS3;
