import axios from "axios";
import { UserRestApi, PromissoryNoteRestApi, PaymentRestApi, AppReviewRestApi } from "src/api";
import { BASE_URL, LOGIN_LOCAL_KEY } from "src/ENV";

axios.defaults.headers.common["Content-Type"] = "application/json";
const authUrls = [
  "https://api-dev.loanthem.com/oauth/token",
  "https://api-dev.loanthem.com/api/v1/users/verify",
  "https://api-dev.loanthem.com/api/v1/users/password-reset-verification-code",
  "https://api-dev.loanthem.com/api/v1/users/verification-code",
  "https://api-dev.loanthem.com/api/v1/users/password",
  "https://api-dev.loanthem.com/api/v1/users",
];

axios.interceptors.request.use((config) => {
  if (config.headers) {
    const loginData = localStorage.getItem(LOGIN_LOCAL_KEY);
    if (loginData) {
      try {
        const jsonObj = JSON.parse(loginData);
        if (config.method === "put") {
          config.headers["Authorization"] = `Bearer ${jsonObj.access_token}`;
        } else if (!authUrls.includes(config.url!)) {
          config.headers["Authorization"] = `Bearer ${jsonObj.access_token}`;
        }

        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      return Promise.reject(error);
    }

    // if (error.response.status === 401) {
    //   localStorage.clear();
    //   window.location.reload();
    //   return Promise.reject(error);
    // }

    return Promise.reject(error);
  },
);

export const userRestApi = new UserRestApi(undefined, BASE_URL, undefined);
export const promissoryNoteRestApi = new PromissoryNoteRestApi(undefined, BASE_URL, undefined);
export const paymentRestApi = new PaymentRestApi(undefined, BASE_URL, undefined);
export const reviewRestApi = new AppReviewRestApi(undefined, BASE_URL, undefined);
export function apiPost(url: string, params = {}, config = {}) {
  return axios.post(url, params, config);
}
