import React from "react";
import { useNavigate } from "react-router-dom";

import { GeneralDraft, TopNavController } from "../../common/components";
import { useSelector } from "react-redux";

const TermsOfServices = () => {
  const { isLogin } = useSelector((store: any) => ({
    isLogin: store.authReducer.login.status === "success",
  }));
  const navigate = useNavigate();
  return (
    <div className="terms-of-service-page">
      <TopNavController
        showCancel={false}
        onClickPrev={() => {
          isLogin ? navigate("/home") : navigate(-1);
        }}
      />
      <div className="terms-of-service-page__preview">
        <GeneralDraft headerTitle="Terms Of Services">
          <iframe
            src={`${require("../../assets/documents/TermsOfService.pdf")}#toolbar=0`}
            width="100%"
            height="600px"
            title="1098-MORT"
          />
        </GeneralDraft>
      </div>
    </div>
  );
};

export default TermsOfServices;
