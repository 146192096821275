import React from "react";
import { Input, Select, Form } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { IReminderContact, ISelect } from "../../models";

export interface ContactProps {
  contact: IReminderContact;
  index: number;
  emails: ISelect[];
  onClickRemove: (index: number, contact: IReminderContact) => void;
  onChangeValue: (
    key: "EMAIL" | "PHONE",
    val: string,
    index: number,
    contact: IReminderContact,
  ) => void;
}

const Contact = ({ contact, index, onClickRemove, onChangeValue, emails }: ContactProps) => {
  return (
    <div className="contact" key={index}>
      <Form className="contact__inputs">
        <Select
          style={{ width: "100%" }}
          defaultValue={contact.email}
          showSearch
          placeholder="Email"
          optionFilterProp="children"
          onChange={(val) => onChangeValue("EMAIL", String(val), index, contact)}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={emails}
        />
        <Form.Item
          name="phone"
          style={{ width: "100%" }}
          rules={[
            {
              min: 10,
              message: "Phone Number mush have a minimum length of 10 ",
            },
            {
              max: 12,
              message: "Phone Number mush have a maximum length of 12 ",
            },
          ]}>
          <Input
            value={contact.phone}
            style={{ borderRadius: 5 }}
            placeholder="Phone Number"
            onChange={(e) => {
              onChangeValue("PHONE", e.target.value, index, contact);
            }}
          />
        </Form.Item>
      </Form>
      <div className="contact__buttons">
        <div className="contact__buttons__remove" onClick={() => onClickRemove(index, contact)}>
          <DeleteOutlined rev="" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
