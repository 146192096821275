import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { contents, titles } from "./config";
import { TaxPrepDTO } from "src/api";
import { FormRadioProps } from "src/common/components";
import { userRestApi } from "../../services/http";
import { UseDocumentApi } from "src/hooks";

const useTaxCreate = () => {
  const navigate = useNavigate();

  const [steps, setSteps] = useState<number[]>([]);
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState("");
  const [wrapperTitle, setWrapperTitle] = useState<string | undefined>();
  const [content, setContent] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [model, setModel] = useState<TaxPrepDTO>({});
  const [error, setError] = useState({ isError: false, errorTxt: "", step: 1 });
  const { getDocument } = UseDocumentApi.useGetDocument();

  const createTax = async (
    docType:
      | "DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN"
      | "DOCUMENT_1098_MORT"
      | "DOCUMENT_1040_SCHEDULE_B_REPORT"
      | "DOCUMENT_1099_INT",
  ) => {
    await userRestApi.addTaxPrep(model);
    const responseDoc = await getDocument(docType);
    const blob = new Blob([responseDoc.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    setPreviewUrl(url);
  };

  const onClickNext = () => {
    if (step === 1) {
      if (model.tax_year) {
        setSteps([...steps, 1]);
        setStep(2);
        setError({ isError: false, errorTxt: "", step: 1 });

        return;
      } else {
        setError({ isError: true, errorTxt: "Please choose a year", step: 1 });
        return;
      }
    }

    // if (step === 2 && model.interest && model.interest % 10 === 0) {
    if (step === 2) {
      if (model.interest) {
        setSteps([...steps, 2]);
        setStep(3);
        setError({ isError: false, errorTxt: "", step: 2 });

        return;
      } else {
        setError({ isError: true, errorTxt: "Please enter interst", step: 2 });
      }
    }

    if (step === 3) {
      if (model.less_ten === true) {
        setSteps([...steps, 3]);
        setStep(7);
        setWrapperTitle("Interest Income of Less Than $10");
        createTax("DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN");
        setError({ isError: false, errorTxt: "", step: 3 });

        return;
      } else if (model.less_ten === false) {
        setSteps([...steps, 3]);
        setStep(4);
        setError({ isError: false, errorTxt: "", step: 3 });

        return;
      } else {
        setError({ isError: true, errorTxt: "Please choose one of the options", step: 3 });
      }
    }

    // if (step === 3 && model.less_ten === false) {
    //   setSteps([...steps, 3]);
    //   setStep(4);
    //   return;
    // }

    if (step === 4) {
      if (model.home_equity_loan === true) {
        setSteps([...steps, 4]);
        setStep(5);
        setError({ isError: false, errorTxt: "", step: 4 });

        return;
      } else if (model.home_equity_loan === false) {
        setSteps([...steps, 4]);
        setStep(7);
        createTax("DOCUMENT_1099_INT");
        setError({ isError: false, errorTxt: "", step: 4 });

        return;
      } else {
        setError({ isError: true, errorTxt: "Please choose one of the options", step: 4 });
      }
    }

    // if (step === 4 && model.home_equity_loan === false) {
    //   setSteps([...steps, 4]);
    //   setStep(7);
    //   createTax("DOCUMENT_1099_INT");
    //   return;
    // }

    if (step === 5) {
      if (model.self_financed === true) {
        setSteps([...steps, 5]);
        setStep(7);
        createTax("DOCUMENT_1040_SCHEDULE_B_REPORT");
        setError({ isError: false, errorTxt: "", step: 5 });

        return;
      } else if (model.self_financed === false) {
        setSteps([...steps, 5]);
        setStep(6);
        setError({ isError: false, errorTxt: "", step: 5 });

        return;
      } else {
        setError({ isError: true, errorTxt: "Please choose one of the options", step: 5 });
      }
    }

    // if (step === 5 && model.self_financed === false) {
    //   setSteps([...steps, 5]);
    //   setStep(6);
    //   return;
    // }

    if (step === 6) {
      if (model.real_property === true || model.real_property === false) {
        setSteps([...steps, 6]);
        setStep(7);
        createTax(model.real_property ? "DOCUMENT_1099_INT" : "DOCUMENT_1098_MORT");
        setError({ isError: false, errorTxt: "", step: 6 });

        return;
      } else {
        setError({ isError: true, errorTxt: "Please choose one of the options", step: 6 });
      }
    }
  };

  const onClickPrev = () => {
    // if (step === 7) {
    //   navigate(-1);
    //   return;
    // }
    setWrapperTitle(undefined);
    if (steps.length > 0) {
      const lastStep = Number(steps.pop());
      setSteps([...steps]);
      setStep(lastStep);
    } else {
      navigate(-1);
    }
  };

  const onClickCancel = () => {
    navigate(-1);
  };

  const downloadPdf = () => {
    if (previewUrl) {
      window.open(previewUrl, "_blank");
    }
  };

  const getYears = (): FormRadioProps[] => {
    const lastYear = new Date().getFullYear();
    const result: FormRadioProps[] = [];
    for (let i = 0; i < 100; i++) {
      result.push({
        selected: false,
        title: String(lastYear + i),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick: (_) => {},
      });
    }

    return result;
  };

  useEffect(() => {
    const title =
      step === 3 ? titles[step - 1].replace("{dollar}", String(model.interest)) : titles[step - 1];
    const content =
      step === 3
        ? contents[step - 1].replace("{dollar}", String(model.interest))
        : contents[step - 1];
    setTitle(title);
    setContent(content);
  }, [step]);

  return {
    step,
    title,
    content,
    onClickNext,
    onClickPrev,
    getYears,
    model,
    setModel,
    onClickCancel,
    previewUrl,
    downloadPdf,
    error,
    setError,
    wrapperTitle,
  };
};

export default useTaxCreate;
