import React, { FC } from "react";
import { Route, useLocation, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { Login } from "src/pages/Login";

import ErrorRouter from "./ErrorRouter/ErrorRouter";

import { Admin } from "./Admin";

import LoginUserPages from "./userLoginRoutes";
import NotLoginRoutes from "./NotLoginRoutes";

const Pages: FC = () => {
  const { isLogin } = useSelector((store: RootState) => ({
    isLogin: store.authReducer.login.status === "success",
  }));
  const { login } = useSelector((store: RootState) => ({
    login: store.authReducer.login,
  }));

  if (!isLogin) {
    return <NotLoginRoutes />;
  }

  if (isLogin && !login.data?.authorities.includes("ADMIN")) {
    return <LoginUserPages />;
  }

  return (
    <Routes>
      <Route path={"/login"} element={<Login />} />
      <Route path={"/admin"} element={<Admin />} />
      <Route path="*" element={<ErrorRouter />} />
    </Routes>
  );
};

export default Pages;
