import React from "react";
import { Button } from "antd";

export interface Step3Props {
  onClick: (val: boolean) => void;
  disabled?: boolean;
  error?: any;
  step?: number;
}

const Step3 = ({ onClick, disabled, error, step }: Step3Props) => {
  return (
    <>
      <div className="tax-create-step-3">
        <Button
          onClick={() => onClick(true)}
          className={`styled-button green ${disabled !== undefined && !disabled ? "disabled" : ""}`}
          type="primary">
          Yes
        </Button>
        <Button
          onClick={() => onClick(false)}
          className={`styled-button red ${disabled !== undefined && disabled ? "disabled" : ""}`}
          type="primary">
          No
        </Button>
      </div>
      {error && step == error?.step ? (
        <div style={{ marginTop: 9 }}>
          <span className="tax-create-error">{error?.errorTxt}</span>
        </div>
      ) : null}
    </>
  );
};

export default Step3;
