import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Spin } from "antd";

import { GeneralDraft, TopNavController } from "../../common/components";
import { UsePromissoryNoteApi } from "../../hooks";

import { ReactComponent as IconDownload } from "../../assets/icons/ic-download.svg";

const NotePreview = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getPreview } = UsePromissoryNoteApi.useNotes();

  const [loaded, setLoaded] = useState(false);
  const [content, setContent] = useState("");

  const getContent = async () => {
    const response = await getPreview(id!);

    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    setContent(url);
    setLoaded(true);
  };

  const downloadPdf = () => {
    if (content) {
      window.open(content, "_blank");
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <div className="note-preview-page">
      <TopNavController
        showCancel={false}
        onClickPrev={() => {
          navigate(-1);
        }}
      />
      <div className="note-preview-page__preview">
        <GeneralDraft headerTitle="Note Preview">
          {!loaded ? (
            <div className="note-preview-page__preview__spin">
              <Spin />
            </div>
          ) : (
            <iframe src={`${content}#toolbar=0`} width="100%" height="600px" title="Note Preview" />
          )}
        </GeneralDraft>
        <div className="note-preview-page__preview__buttons">
          <Button
            className="styled-button text-blue border-blue background-white styled-button-hover-white"
            type="primary"
            onClick={downloadPdf}
            icon={<IconDownload />}>
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotePreview;
