import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { MyPromissoryNoteResponseDto } from "../../api";
import { ProfileLoanProps } from "../../common/components/ProfileLoan/ProfileLoan";
import { getRandomColor } from "../../utils/ColorUtil";
import { UsePromissoryNoteApi } from "../../hooks";
import { IGetAllBorrowers, IGetAllLenders, IGetPercent } from "src/models";

const useHome = () => {
  const navigate = useNavigate();

  const [displayListLenders, setDisplayListLenders] = useState<ProfileLoanProps[]>([]);
  const [displayListBorrowers, setDisplayListBorrowers] = useState<ProfileLoanProps[]>([]);
  const [lenders, setLenders] = useState<IGetAllLenders>();
  const [borrowers, setBorrowers] = useState<IGetAllBorrowers>();
  const [percent, setPercent] = useState<IGetPercent>();

  const { getAllBorrows, getAllLenders, getPercent } = UsePromissoryNoteApi.useNotes();

  const localStringToNumber = (val: any) => {
    if (val !== null && val !== "undefined") {
      return "$" + val?.toLocaleString("en-US");
    }
    return;
  };

  const getProfileListItem = (
    item: MyPromissoryNoteResponseDto,
    type: string,
  ): ProfileLoanProps => {
    const primaryObject = type === "loan" ? item.primary_lender : item.primary_borrower;
    const title = primaryObject?.business_name
      ? primaryObject?.business_name
      : primaryObject?.full_name;
    let abbreviation = String(title?.charAt(0).toUpperCase());
    if (String(title).split(" ").length > 0) {
      abbreviation += String(title).split(" ")[1]?.charAt(0).toUpperCase() ?? "";
    }
    return {
      title: title ?? "NO NAME",
      img: primaryObject?.profile_photo?.file_path,
      id: String(item.id),
      selected: true,
      abbreviation,
      color: getRandomColor(String(title)),
    };
  };

  const getData = async () => {
    try {
      const responseLenders = await getAllLenders();
      const responseBorrowers = await getAllBorrows();
      const responsePercent = await getPercent();

      setPercent(responsePercent.data);
      setBorrowers(responseBorrowers);
      setLenders(responseLenders);
      setDisplayListLenders(
        responseLenders.lenders
          // .reverse()
          .map((item: MyPromissoryNoteResponseDto) => getProfileListItem(item, "loan")),
      );
      setDisplayListBorrowers(
        responseBorrowers.borrows
          // .reverse()
          .map((item: MyPromissoryNoteResponseDto) => getProfileListItem(item, "borrow")),
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    displayListLenders,
    displayListBorrowers,
    localStringToNumber,
    lenders,
    borrowers,
    percent,
    navigate,
  };
};

export default useHome;
