import { useDispatch } from "react-redux";

import { LOGIN_LOCAL_KEY, PROMISSORY_NOTE_KEY, PROMISSORY_ROUTE_KEY } from "../../ENV";
import {
  authSetDataAction,
  authSetUser,
  promissoryRemoveDataAction,
  promissorySetRouteAction,
} from "../../store";
import { useNavigate } from "react-router";

const useAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem(LOGIN_LOCAL_KEY);
    localStorage.removeItem(PROMISSORY_NOTE_KEY);
    localStorage.removeItem(PROMISSORY_ROUTE_KEY);
    dispatch(authSetDataAction("login", null, "error"));
    dispatch(authSetUser(undefined));
    dispatch(promissoryRemoveDataAction());
    dispatch(promissorySetRouteAction(""));
    navigate("/", {
      replace: true,
    });
  };

  return { logout };
};

export default useAdmin;
