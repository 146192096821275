import { PromissoryReducerType } from "src/store/reducers/promissoryReducer";

export enum PromissorySetDataAction {
  SetData = "Promissory Set Data [Promissory Reducer]",
  SetRoute = "Promissory Set Route [Promissory Reducer]",
  SetEditMode = "Promissory Set Edit Mode [Promissory Reducer]",
}

export type PromissorySetDataActionType = {
  type: PromissorySetDataAction;
  payload:
    | PromissoryReducerType
    | undefined
    | {
        route?: string;
        editMode?: boolean;
      };
};

export const promissorySetDataAction = (promissory: PromissoryReducerType) => ({
  type: PromissorySetDataAction.SetData,
  payload: promissory,
});

export const promissoryRemoveDataAction = () => ({
  type: PromissorySetDataAction.SetData,
  payload: undefined,
});

export const promissorySetRouteAction = (route: string) => ({
  type: PromissorySetDataAction.SetRoute,
  payload: { route },
});

export const promissorySetEditModeAction = (editMode: boolean) => ({
  type: PromissorySetDataAction.SetEditMode,
  payload: { editMode },
});
