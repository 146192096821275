import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  authSetDataAction,
  authSetUser,
  promissoryRemoveDataAction,
  promissorySetRouteAction,
} from "src/store";
import { LOGIN_LOCAL_KEY, PROMISSORY_NOTE_KEY, PROMISSORY_ROUTE_KEY } from "src/ENV";
import { UsePaymentApi } from "src/hooks";
import { DeleteAccountModal } from "../../components/DeleteAccountModal/index";

import { ReactComponent as IconEdit } from "../../../assets/icons/ic-edit-gray.svg";
import { ReactComponent as IconUser } from "../../../assets/icons/ic-user-gray.svg";
import { ReactComponent as IconQuestion } from "../../../assets/icons/ic-question-gray.svg";
import { ReactComponent as IconShield } from "../../../assets/icons/ic-shield-gray.svg";
import { ReactComponent as IconEnvelop } from "../../../assets/icons/ic-envelop-gray.svg";
import { ReactComponent as IconUserRed } from "../../../assets/icons/ic-user-red.svg";
import { ReactComponent as IconLogout } from "../../../assets/icons/ic-logout-red.svg";

export interface PaymentInstallmentBorrowerProps {
  openModal: boolean;
  setOpenModal: any;
}
const ProfileMenu = ({ openModal, setOpenModal }: PaymentInstallmentBorrowerProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { goToStripe, completeStripe } = UsePaymentApi.usePaymentApi();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const logout = () => {
    localStorage.removeItem(LOGIN_LOCAL_KEY);
    localStorage.removeItem(PROMISSORY_NOTE_KEY);
    localStorage.removeItem(PROMISSORY_ROUTE_KEY);
    dispatch(authSetDataAction("login", null, "error"));
    dispatch(authSetUser(undefined));
    dispatch(promissoryRemoveDataAction());
    dispatch(promissorySetRouteAction(""));
    navigate("/", {
      replace: true,
    });
  };

  const completeStripAccount = async () => {
    const response = await completeStripe();
    const url = response.data?.payment_url;
    window.location.href = url;
  };

  const gotToStripAccount = async () => {
    const response = await goToStripe();
    const url = response.data?.payment_url;
    window.location.href = url;
  };

  return (
    <div style={{ display: openModal ? "block" : "none" }} className="profile-menu">
      <DeleteAccountModal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          // setOpenModal(true);
        }}
      />
      <ul>
        <li>
          <Link to={"/profile"}>
            <IconEdit />
            <span>Edit Profile</span>
          </Link>
        </li>
        <li onClick={gotToStripAccount}>
          <IconUser />
          <span>Complete Payment Information</span>
        </li>
        <li onClick={completeStripAccount}>
          <IconUser />
          <span>Login to Stripe account</span>
        </li>
      </ul>
      <Divider />
      <ul>
        <li>
          <Link to={"/faq"}>
            <IconQuestion />
            <span>FAQ</span>
          </Link>
        </li>
        <li>
          <Link to={"/terms-of-services"}>
            <IconShield />
            <span>Terms of Service</span>
          </Link>
        </li>
        <li>
          <Link to={"/privacy-policy"}>
            <IconShield />
            <span>Privacy Policy</span>
          </Link>
        </li>
        <li>
          <Link to={"/leave-review"}>
            <IconEnvelop />
            <span>Leave a Review</span>
          </Link>
        </li>
      </ul>
      <Divider />
      <ul>
        <li
          onClick={() => {
            setDeleteModalOpen(true);
            setOpenModal(false);
          }}>
          <IconUserRed />
          <span className="red">Delete Account</span>
        </li>
      </ul>
      <Divider />
      <ul>
        <li onClick={logout}>
          <IconLogout />
          <span className="red">Logout</span>
        </li>
      </ul>
    </div>
  );
};

export default ProfileMenu;
