import { Button, Progress } from "antd";

import { ProfileLoanCarousel, UserPagesNavbar } from "src/common/components";
import { usePromissoryNote } from "src/hooks";
import useHome from "./useHome";

const Home = () => {
  const {
    localStringToNumber,
    displayListBorrowers,
    displayListLenders,
    borrowers,
    lenders,
    percent,
    navigate,
  } = useHome();

  const { onClickNewNote } = usePromissoryNote();
  const titleHandler = (type: "borrow" | "loan") => {
    if (type == "loan") {
      if (displayListLenders && displayListLenders.length > 1) {
        return "Lenders";
      }
      return "Lender";
    }
    if (type == "borrow") {
      if (displayListBorrowers && displayListBorrowers.length > 1) {
        return "Borrowers";
      }
      return "Borrower";
    }
  };
  const borrowerPaid = borrowers?.paidAmount ? borrowers?.paidAmount : 0;
  const lenderPaid = lenders?.paidAmount ? lenders?.paidAmount : 0;

  return (
    <div className="homePage-structure">
      <UserPagesNavbar />
      <div className="home-subTitle">
        <div
          style={{
            color: "white",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 24,
          }}>
          Home
        </div>
        <Button onClick={onClickNewNote} className="home-subTitle-btn">
          Make a new note
        </Button>
      </div>
      <div className="home-subTitle-line" />
      <div className="home-carousel-image">
        <h2 style={{ color: "white", marginTop: 10 }}>{titleHandler("borrow")}</h2>
      </div>
      <ProfileLoanCarousel
        profiles={displayListBorrowers}
        showDots={true}
        onClickItem={(id) => {
          navigate(`/dashboard/borrow/${id}`);
        }}
      />
      <div className="home-body-cart-amount" style={{ marginTop: 50 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <div className="home-body-cart-title">Payback Status</div>
          <div style={{ display: "flex" }}>
            <div style={{ color: "white" }}>see details</div>
            <img
              style={{ width: 20, height: 20, marginTop: 2 }}
              src={require("src/assets/images/Arrow-homepage.png")}
              alt="arrowHome"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <div>
            <div className="home-body-cart-titlePrice">Amount Borrowed</div>
            <div className="home-body-cart-price">
              {localStringToNumber(borrowers?.totalAmount) == "$undefined"
                ? 0
                : localStringToNumber(borrowers?.totalAmount)}
            </div>
          </div>
          <div style={{ marginRight: 40 }}>
            <div className="home-body-cart-titlePrice">Paid</div>
            <div className="home-body-cart-price">
              {localStringToNumber(borrowers?.paidAmount) == "$undefined"
                ? 0
                : localStringToNumber((Math.round(borrowerPaid * 100) / 100).toFixed(2) || 0)}
            </div>
          </div>
        </div>
        <Progress percent={percent?.borrows_paid_progress} status="active" />
      </div>
      <div className="home-carousel-image">
        <h2 style={{ color: "white", marginTop: 10 }}>{titleHandler("loan")}</h2>
      </div>
      <ProfileLoanCarousel
        profiles={displayListLenders}
        showDots={true}
        onClickItem={(id) => {
          navigate(`/dashboard/loan/${id}`);
        }}
      />
      <div className="home-body-cart-amount" style={{ marginTop: 50 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <div className="home-body-cart-title">Payback Status</div>
          <div style={{ display: "flex" }}>
            <div style={{ color: "white" }}>see details</div>
            <img
              style={{ width: 20, height: 20, marginTop: 2 }}
              src={require("src/assets/images/Arrow-homepage.png")}
              alt="arrowHome"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <div>
            <div className="home-body-cart-titlePrice">Amount Lent</div>
            <div className="home-body-cart-price">
              {localStringToNumber(lenders?.totalAmount) == "$undefined"
                ? 0
                : localStringToNumber(lenders?.totalAmount)}
            </div>
          </div>
          <div style={{ marginRight: 40 }}>
            <div className="home-body-cart-titlePrice">Amount Repaid</div>
            <div className="home-body-cart-price">
              {localStringToNumber(lenders?.paidAmount) == "$undefined"
                ? 0
                : localStringToNumber((Math.round(lenderPaid * 100) / 100).toFixed(2) || 0)}
            </div>
          </div>
        </div>
        <Progress percent={percent?.loans_paid_progress} status="active" />
      </div>
    </div>
  );
};

export default Home;
