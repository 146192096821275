import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { userRestApi } from "../services/http";

export function useVerificationForm() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState<any>();
  const [resending, setResending] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const username = state && state.email ? state.email : "";
  const prevUrl = state && state.prevUrl ? state.prevUrl : "";

  const initialValues: any = {
    verification_code: "",
    username: username,
  };

  const handleSubmit = async (param: any) => {
    setIsLoading(true);
    const params: any = {
      verification_code: param.verification_code,
      username: username,
    };
    try {
      if (prevUrl == "/forget-password") {
        await userRestApi.checkPasswordResetCodeValidation(params.verification_code, username);
      } else {
        await userRestApi.verify(params);
      }

      navigate(prevUrl == "/forget-password" ? "/reset-password" : "/login", {
        state: { email: username },
      });
    } catch (error: any) {
      setErrMsg(error.response.data.message);
    }

    setIsLoading(false);
  };

  const resendOtp = async () => {
    setResending(true);
    try {
      prevUrl == "/forget-password"
        ? await userRestApi.sendPasswordResetCode(username)
        : await userRestApi.resendOTP(username);
    } catch (e: any) {
      setErrMsg(e.response.data.message);
    }
    setResending(false);
  };

  return {
    handleSubmit,
    initialValues,
    errMsg,
    resendOtp,
    resending,
    prevUrl,
    isLoading,
    state,
  };
}
