import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { IFileResult, ISelect, ProfilePhotoUpdate, ProfilePhoto } from "../../models";
import { convertMimeType } from "../../utils/ImageUtil";
import { useLocation, UseUser } from "src/hooks";
import { RootState, authSetUser } from "src/store";

const useProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((store: RootState) => store.authReducer);

  const { getStates, getCities } = useLocation();
  const { updateProfile: sendNewProfile, getProfile } = UseUser.useUser();

  const [initiate, setInitiate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState<ISelect[]>([]);
  const [cities, setCities] = useState<ISelect[]>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [s3Data, setS3Data] = useState<AWS.S3.ManagedUpload.SendData | undefined>();
  const [fileResult, setFileResult] = useState<IFileResult | undefined>();

  const onProfileUpload = (
    s3Data?: AWS.S3.ManagedUpload.SendData,
    fileResult?: IFileResult,
    url?: string,
  ) => {
    setFileResult(fileResult);
    setS3Data(s3Data);
    setImageUrl(url);
  };

  const updateProfile = async () => {
    const profileImage: ProfilePhotoUpdate | undefined =
      s3Data && fileResult
        ? {
            file_name: s3Data?.Key,
            original_file_name: fileResult.name,
            document_type: "PROFILE_PHOTO",
            file_path: s3Data.Location,
            file_size: fileResult?.size,
            file_type: convertMimeType(fileResult.type),
            description: "",
          }
        : undefined;
    if (!(firstName || lastName || city || state || zipCode || fullAddress)) {
      return;
    }
    setLoading(true);
    await sendNewProfile(firstName, lastName, city, state, zipCode, fullAddress, profileImage);
    setLoading(false);
    if (user) {
      dispatch(
        authSetUser({
          ...user,
          first_name: firstName,
          last_name: lastName,
          zip_code: zipCode,
          full_address: fullAddress,
          profile_photo: profileImage ? (profileImage as ProfilePhoto) : user.profile_photo,
        }),
      );
    }

    navigate(-1);
  };

  const onClickBack = () => {
    // navigate(-1);
    navigate("/home");
  };

  const setProfile = async () => {
    try {
      const response = await getProfile();
      setFirstName(response.data.first_name);
      setLastName(response.data.last_name);
      setZipCode(response.data.zip_code);
      setFullAddress(response.data.full_address);
      setState(response.data.state);
      setCity(response.data.city);
      setCity(response.data.city);
      setMobileNumber(response.data.mobile_number);
      setEmail(response.data.email);
      if (response.data.profile_photo) {
        setImageUrl(response.data.profile_photo.file_path);
      }
    } catch (error) {
      console.log("get profile error, ", error);
    }
    setInitiate(false);
  };

  const setAllStates = async () => {
    const response: any = await getStates();
    try {
      setStates(
        response.data.map((item: any) => ({
          label: item.state_name,
          value: item.state_name,
        })),
      );
    } catch (error) {
      console.log("get states error", error);
    }
  };

  const setAllCities = async () => {
    if (!state) {
      return;
    }

    const response: any = await getCities(state);
    try {
      setCities(
        response.data.map((item: any) => ({
          label: item.city_name,
          value: item.city_name,
        })),
      );
    } catch (error) {
      console.log("get states error", error);
    }
  };

  useEffect(() => {
    setProfile();
    setAllStates();
  }, []);

  useEffect(() => {
    setAllCities();
  }, [state]);

  return {
    initiate,
    onClickBack,
    onProfileUpload,
    updateProfile,
    loading,
    setLoading,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    city,
    setCity,
    state,
    setState,
    zipCode,
    setZipCode,
    fullAddress,
    setFullAddress,
    cities,
    states,
    imageUrl,
    mobileNumber,
    email,
  };
};

export default useProfile;
