import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { SINAN_API_URL } from "../ENV";

export interface cityListHook {
  isFetching: boolean;
  getPageCity: (stateName: string, pageNumber?: number, pageSize?: string) => void;
  getStateCities: (stateName: string) => Promise<AxiosResponse>;
  fetchMore: () => void;
  startFilter: (filters: any) => void;
  filters: any;
  cityList?: [];
}

export interface TCityState {
  city_code: string | null;
  city_name: string;
}

export interface TCityList {
  id: string;
  label: string;
}

export const useLocationService = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [cities, setCities] = useState<undefined | { id: string; label: string }[]>(undefined);
  const [states, setStates] = useState<Array<{ label: string; name: string }>>([]);

  const getPageCity = async (
    stateName = "",
    // pageNumber = 0,
    // pageSize = Config.CITY_LIST_PAGE_SIZE,
    setState = true,
  ): Promise<TCityList[]> => {
    try {
      setIsFetching(true);

      const res: AxiosResponse = await axios.get(
        `${SINAN_API_URL}/api/v1/states/${stateName}/cities`,
      );
      const result = ((res.data as TCityState[]) || []).map((city) => ({
        id: city.city_name,
        label: city.city_name,
      }));

      if (setState) {
        setCities(result);
        setIsFetching(false);
      }
      return result;
    } catch (error: any) {
      alert(`server status response ${error.response?.status}`);
      console.error(error);
      if (setState) {
        setIsFetching(false);
      }
      return [];
    }
  };

  const getStateCities = (stateName = "") =>
    axios.get(`${SINAN_API_URL}/api/v1/states/${stateName}/cities`);

  const getStateList = async () => {
    setIsFetching(true);
    try {
      const response = await axios.get(`${SINAN_API_URL}/api/v1/countries/United States/states`);
      setStates(
        response.data.map((state: { state_name: string }) => ({
          label: state.state_name,
          name: state.state_name,
        })),
      );
      setIsFetching(false);
    } catch (e: any) {
      alert(`server status response ${e.response?.status}`);
      console.error(e);
      setIsFetching(false);
    }
  };

  return {
    getPageCity,
    getStateCities,
    getStateList,
    states,
    isFetching,
    cityList: cities,
  };
};
