/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { UsePaymentApi } from "../../hooks";
import { getRandomColor } from "src/utils/ColorUtil";
import { formatNumber } from "src/utils/NumberUtil";

const usePaymentHistory = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();

  const { getHistory } = UsePaymentApi.usePaymentApi();

  const [activeTab, setactiveTab] = useState<"PAST" | "UPCOMING">("PAST");
  const [dataUpcoming, setDataUpcoming] = useState<any[]>([]);
  const [dataPast, setDataPast] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(false);

  const getHistories = async () => {
    const response = await getHistory(id!);
    //@ts-ignore
    if (
      Array.isArray(response.data?.paymentHistoryList) &&
      response.data?.paymentHistoryList.length > 0
    ) {
      //@ts-ignore
      const past = response.data.paymentHistoryList.filter((item) => {
        const payTime = new Date(item.due_time);
        return payTime.getTime() <= new Date().getTime();
      });
      //@ts-ignore
      const upcoming = response.data.paymentHistoryList.filter((item) => {
        const payTime = new Date(item.due_time);
        return payTime.getTime() > new Date().getTime();
      });

      setDataPast(parseItems(past));
      setDataUpcoming(parseItems(upcoming));
      setLoaded(true);
    } else {
      onClickBack();
    }
  };

  const parseItem = (item: any) => {
    const lender = item.lender;
    let abbreviation = String(lender?.charAt(0).toUpperCase());
    if (String(lender).split(" ").length > 0) {
      abbreviation += String(lender).split(" ")[1]?.charAt(0).toUpperCase() ?? "";
    }
    const payTime = moment(item.due_time).format("MMM DD, YYYY");
    const paidTime = item.payment_time ? moment(item.payment_time).format("MMM DD, YYYY") : "";
    const timestamp = item.payment_time;
    const date = new Date(timestamp);

    // Get hours, minutes
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? "pm" : "am";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    // Format the time
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;
    return {
      paidDate: paidTime,
      payDate: payTime,
      lender,
      location: item.location,
      paymentService: item.payment_service,
      transactionNumber: item.transaction_number,
      abbreviation,
      color: getRandomColor(String(lender)),
      profileImage: item.profile_picture?.filePath ?? null,
      amount: `$${formatNumber(item.amount)}`,
      paymentStatus: item.payment_status,
      paidTime: item.payment_time ? formattedTime : "",
    };
  };

  const parseItems = (item: any[]) => {
    return item.map((item: any) => parseItem(item));
  };

  const onClickBack = () => {
    navigate(`/dashboard/${type}/${id}`);
  };

  useEffect(() => {
    getHistories();
  }, []);

  return {
    activeTab,
    setactiveTab,
    dataPast,
    dataUpcoming,
    loaded,
    onClickBack,
  };
};

export default usePaymentHistory;
