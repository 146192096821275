/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UsePaymentApi, useApp } from "src/hooks";

import { userRestApi } from "src/services";

const useUpdateSubscription = () => {
  const navigate = useNavigate();
  const { setUser } = useApp(false);

  const [planLoaded, setPlanLoaded] = useState(false);
  const [renewLoading, setRenewLoading] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const [currentPlanEndDate, setCurrentPlanEndDate] = useState<string | undefined>();
  const [step, setStep] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState<"YEARLY" | "PREMIUM" | undefined>();

  const { renewPayment, selectPlan, cancelPlan } = UsePaymentApi.usePaymentApi();

  const openModal = () => {
    setShowCancelModal(true);
  };

  const closeModal = () => {
    setShowCancelModal(false);
  };

  const getPlan = async () => {
    try {
      const response = await userRestApi.getActivePlan();
      if (response.data.registration_plan) {
        setCurrentPlan(response.data.registration_plan);
        //@ts-ignore
        setCurrentPlanEndDate(response.data?.end_date ?? undefined);

        if (response.data.registration_plan === "PREMIUM_PACKAGE") {
          setSelectedPlan("PREMIUM");
        }
        if (response.data.registration_plan === "MULTIPLE_PACKAGE") {
          setSelectedPlan("YEARLY");
        }
      }
    } catch (error) {
      console.log("get plan error: ", error);
    }
    setPlanLoaded(true);
  };

  const onClickBuy = async (plan: "PREMIUM_PACKAGE" | "MULTIPLE_PACKAGE" | "GO_NEXT") => {
    if (plan === "GO_NEXT") {
      setStep(3);
    } else {
      onClickBuyPlan(plan);
    }
  };

  const onClickRenew = async () => {
    setRenewLoading(true);
    const response = await renewPayment();
    window.location.href = response.data.payment_url;
    setRenewLoading(false);
  };

  const onClickCancelSubscription = async () => {
    setCancelLoading(true);
    await cancelPlan();
    await setUser();
    onClickCancel();
    setRenewLoading(false);
    setShowCancelModal(false);
  };

  const onClickBuyPlan = async (plan: "PREMIUM_PACKAGE" | "MULTIPLE_PACKAGE") => {
    setBuyLoading(true);
    const response = await selectPlan(plan);
    window.location.href = response.data.payment_url;
    setBuyLoading(false);
  };

  const onClickBack = () => {
    if (step > 2) {
      //   setStep(step - 1);
      // } else if (step === 2) {
      //   if (currentPlan) {
      //     onClickCancel();
      //     return;
      //   } else {
      //     setStep(step - 1);
      //   }
      navigate(-1);
    } else {
      onClickCancel();
    }
  };

  const onClickNext = () => {
    if (step === 1 && selectedPlan) {
      if (selectedPlan === "PREMIUM") {
        setStep(3);
      } else {
        setStep(2);
      }

      return;
    }

    if (step === 2) {
      return;
    }
  };

  const onClickCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    onClickNext();
  }, [selectedPlan]);

  useEffect(() => {
    getPlan();
  }, []);

  return {
    step,
    onClickNext,
    onClickCancel,
    onClickBack,
    selectedPlan,
    setSelectedPlan,
    planLoaded,
    currentPlan,
    onClickRenew,
    onClickBuyPlan,
    buyLoading,
    renewLoading,
    cancelLoading,
    onClickBuy,
    onClickCancelSubscription,
    currentPlanEndDate,
    showCancelModal,
    openModal,
    closeModal,
  };
};

export default useUpdateSubscription;
