import { Input, Button } from "antd";

import useLoginRegisterModal from "./useLoginRegisterModal";

export interface ILoginModalProps {
  onResendDone: (username: string, password: string) => void;
}

const LoginModal = ({ onResendDone }: ILoginModalProps) => {
  const {
    login,
    loading,
    username,
    setUsername,
    password,
    setPassword,
    openRegisterModal,
    usernameError,
    passwordError,
    loginError,
  } = useLoginRegisterModal(onResendDone);

  return (
    <>
      <h4 className="login-register-modal__header">Log in</h4>
      <div className="login-register-modal__items">
        <div className="login-register-modal__items__item">
          <span className="login-register-modal__items__item__title">
            Email / Username <span>*</span>
          </span>
          <Input
            status={usernameError ? "error" : ""}
            placeholder="Email / Username"
            autoComplete="one-time-code"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <span className="login-register-modal__items__item__error">{usernameError}</span>
        </div>

        <div className="login-register-modal__items__item">
          <span className="login-register-modal__items__item__title">
            Password <span>*</span>
          </span>
          <Input
            status={passwordError ? "error" : ""}
            placeholder="Password"
            autoComplete="one-time-code"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="login-register-modal__items__item__error">{passwordError}</span>
        </div>

        <span className="login-register-modal__items__item__error">{loginError}</span>

        <Button
          className="login-btn"
          htmlType={"submit"}
          type="primary"
          block
          loading={loading}
          onClick={login}>
          Login
        </Button>

        <Button
          htmlType={"button"}
          type="link"
          block
          onClick={openRegisterModal}
          className="styled-button-link">
          Register
        </Button>
      </div>
    </>
  );
};

export default LoginModal;
