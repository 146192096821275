/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { promissoryNoteRestApi } from "../../services/http";
import { AmortizationScheduleResponseDTO, FullPromissoryNoteDTOResponse } from "src/api";

const useAmortizationSchedule = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();

  const [loadingPdf, setLoadingPdf] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [scheduleData, setScheduleData] = useState<AmortizationScheduleResponseDTO[] | undefined>();
  const [detail, setDetail] = useState<FullPromissoryNoteDTOResponse | undefined>();

  const getAmortization = async () => {
    try {
      const responseSchedule = await promissoryNoteRestApi.getAmortizations(Number(id!));
      const responseDetail = await promissoryNoteRestApi.getPromissoryNote(Number(id!));
      setScheduleData(responseSchedule.data);
      setDetail(responseDetail.data);
      setDataLoaded(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const onClickPrev = () => {
    navigate(`/dashboard/${type}/${id}`);
  };

  const downloadPdf = async () => {
    setLoadingPdf(true);
    const response = await promissoryNoteRestApi.getAmortizationListPreview(Number(id!), {
      responseType: "blob",
    });
    //@ts-ignore
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    setLoadingPdf(false);
  };

  useEffect(() => {
    getAmortization();
  }, []);

  return {
    onClickPrev,
    dataLoaded,
    downloadPdf,
    scheduleData,
    detail,
    loadingPdf,
  };
};

export default useAmortizationSchedule;
