import { PromissoryActionsType } from "src/store/actions";
import { PromissoryReducerInitial } from "./promissoryReducerInitial";
import { PromissoryReducerType } from "./promissoryReducerType";
import * as Actions from "src/store/actions/promissoryActions";
import { PROMISSORY_NOTE_KEY, PROMISSORY_ROUTE_KEY } from "src/ENV";

export function promissoryReducer(
  state: PromissoryReducerType = PromissoryReducerInitial(),
  action: PromissoryActionsType,
) {
  switch (action.type) {
    case Actions.PromissorySetDataAction.SetData:
      if (action.payload) {
        const casted = action.payload as PromissoryReducerType;
        localStorage.setItem(PROMISSORY_NOTE_KEY, JSON.stringify({ ...state, ...casted }));
        return { ...state, ...casted, route: state.route };
      } else {
        localStorage.setItem(PROMISSORY_NOTE_KEY, JSON.stringify({}));
        return {};
      }
    case Actions.PromissorySetDataAction.SetRoute:
      localStorage.setItem(PROMISSORY_ROUTE_KEY, String(action.payload?.route));
      localStorage.setItem(
        PROMISSORY_NOTE_KEY,
        JSON.stringify({ ...state, route: String(action.payload?.route) }),
      );
      return {
        ...state,
        route: String(action.payload?.route),
      };
    case Actions.PromissorySetDataAction.SetEditMode:
      return {
        ...state,
        editMode: action.payload?.editMode ?? false,
      };
    default:
      return state;
  }
}
