import axios from "axios";

import { BASE_URL } from "../ENV";

export const useGetDocument = () => {
  const getDocument = async (
    docType:
      | "DOCUMENT_INTEREST_INCOME_LESS_THAN_TEN"
      | "DOCUMENT_1098_MORT"
      | "DOCUMENT_1040_SCHEDULE_B_REPORT"
      | "DOCUMENT_1099_INT",
  ) => {
    const response = axios.get(`${BASE_URL}/api/v1/promissory-notes/document/${docType}`, {
      responseType: "blob",
    });
    return response;
  };

  return { getDocument };
};
