import React from "react";
import { Button, Modal } from "antd";

export interface PremiumModalProps {
  open: boolean;
  onCancel: () => void;
}

const PremiumModal = ({ onCancel, open }: PremiumModalProps) => {
  return (
    <Modal open={open} onCancel={onCancel} footer={null}>
      <div className="premium-modal">
        <div className="premium-modal__header">Permission Error</div>
        <div className="premium-modal__text">
          Your account does not have access to this feature. Please upgrade your account.
        </div>
        <div className="premium-modal__buttons">
          <Button type="text" onClick={onCancel}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PremiumModal;
