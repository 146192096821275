import { useState } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";

import { RootState } from "../../store/reducers";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import VerifyModal from "./VerifyModal";
import useLoginRegisterModal from "./useLoginRegisterModal";

const LoginRegisterModal = () => {
  const { showLoginRegisterModal, modalType } = useSelector(
    (state: RootState) => state.siteReducer,
  );
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { onClickCancel } = useLoginRegisterModal(() => {
    return;
  });

  const renderModal = () => {
    switch (modalType) {
      case "LOGIN":
        return (
          <LoginModal
            onResendDone={(username, password) => {
              setUsername(username);
              setPassword(password);
            }}
          />
        );
      case "REGISTER":
        return (
          <RegisterModal
            onRegisterDone={(username, password) => {
              setUsername(username);
              setPassword(password);
            }}
          />
        );
      case "VERIFY":
        return <VerifyModal username={username} password={password} />;
    }
  };

  return (
    <Modal
      open={showLoginRegisterModal}
      onCancel={onClickCancel}
      footer={null}
      className="login-register-modal">
      <div className="premium-modal">{renderModal()}</div>
    </Modal>
  );
};

export default LoginRegisterModal;
