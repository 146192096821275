export const titles = [
  "Tax Year",
  "Amount of Interest ",
  // ">${dollar} of Interest",
  ">$10 of Interest",
  "Home Loan ",
  "Self-Financed Home Sale",
  "Real Property Loan",
];

export const contents = [
  "Will there be a witness to the signing? <span class='red'>*</span>",
  "How much interest will the Borrower pay the Lender during  the tax year?",
  "Did the Lender earn less than $10 of interest income during the tax year (after adjustment to the AFR)?",
  "Is the loan used for a home loan or home equity loan?",
  "Does the loan provide interest income from the sale of a home that you self-financed and that you have a security interest in?",
  "Was  the loan (not self-financed) made for the purchase or improvement of real property (house, land, apartment, condo, etc.), the loan is secured by that property and the lender received $600 or more in interest income from the borrower?",
];
