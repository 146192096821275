import { FC, useState, useReducer } from "react";
import { Row, Form, Button, Input, Typography, Space, DatePicker } from "antd";
import { RightOutlined } from "@ant-design/icons";
import InfoBox from "./../../common/components/InfoBox";
import { useNavigate } from "react-router-dom";
import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import Previous from "src/common/components/Previous";
const WitnessSigning: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const dispatch = useDispatch();
  const [party, setParty] = useState<any>(note.sign_party != undefined ? note.sign_party : null);
  const [radioVal, setRadioValue] = useState(
    note.witness_type != undefined ? note.witness_type : "",
  );
  const [form] = Form.useForm();

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();
  const RadioOnChange = (e: any) => {
    setRadioValue(e.target.value);
    if (e.target.value !== "NO_WITNESS") {
      setParty("HAND");
      form.setFieldsValue({ sign_party: "HAND" });
    }
  };

  const handleSubmit = (val: any) => {
    dispatch(promissorySetDataAction(val));
    if (val.witness_type != "NO_WITNESS") {
      navigate("/witness-info");
    } else {
      navigate("/extra-clause");
    }
  };

  const initialVal = {
    witness_type: note.witness_type != undefined ? note.witness_type : null,
    sign_party: note.sign_party != undefined ? note.sign_party : null,
  };
  const handleParty = () => {
    if (radioVal == "NO_WITNESS") {
      setParty("DIGITALLY");
      form.setFieldsValue({ sign_party: "DIGITALLY" });
    }
  };
  return (
    <Row className="note" justify={"center"}>
      <Previous location="/enforcement" />
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Witness & Signing
      </Typography.Title>
      <Form
        initialValues={initialVal}
        form={form}
        className="note-form-box"
        onFinish={(val) => handleSubmit(val)}>
        <Typography.Title
          className="note-title"
          style={{ color: "white", fontSize: 14, fontWeight: 400 }}>
          Will there be a witness to the signing?<span style={{ color: "red" }}>*</span>
        </Typography.Title>
        <Form.Item
          name={"witness_type"}
          rules={[{ required: true, message: "Please select one of items above" }]}
          style={{ marginTop: "25px" }}>
          <Radio.Group
            defaultValue={radioVal}
            onChange={RadioOnChange}
            value={radioVal}
            className="note-formItem-input">
            <div className="radio-container">
              <Radio value={"WITNESS"}> Witness (unavailable digitally)</Radio>
            </div>
            <div className="radio-container">
              <Radio value={"NOTARY"}> Notary (unavailable digitally)</Radio>
            </div>
            <div className="radio-container">
              <Radio value={"NO_WITNESS"}> No Witness</Radio>
            </div>
          </Radio.Group>
        </Form.Item>
        <Typography.Title
          className="note-title"
          style={{ color: "white", fontSize: 14, fontWeight: 400 }}>
          How will the parties sign? <span style={{ color: "red" }}>*</span>
        </Typography.Title>
        <Form.Item
          name="sign_party"
          rules={[{ required: radioVal ? true : false, message: "Please select one of the items" }]}
          style={{ marginTop: "20px" }}>
          <div className="note-demandPaymenct">
            <Button
              className={`note-secondery-btn-yes ${party == "HAND" ? "note-btn-enable" : ""}`}
              onClick={() => {
                handleParty();
              }}>
              Digitally
            </Button>
            <Button
              className={`note-secondery-btn-no ${party == "DIGITALLY" ? "note-btn-enable" : ""}`}
              onClick={() => {
                setParty("HAND");
                form.setFieldsValue({ sign_party: "HAND" });
              }}>
              By Hand
            </Button>
          </div>
        </Form.Item>
        <div className="note-form-line"></div>
        <Button
          style={{ marginTop: "70px" }}
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
    </Row>
  );
};

export default WitnessSigning;
