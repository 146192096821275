import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Col, Layout as AntdLayout, Row, Typography, List, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { LandingHeader } from "./Langing-header";
import { useApp, useNoteNavigation } from "../hooks";
import { setRegisterBgAction } from "src/store/actions/registerbgActions/registerBgAction";
import LoginRegisterModal from "../pages/loginRegisterModal/LoginRegisterModal";

type PropsType = { children: React.ReactChild };

const Layout: FC<PropsType> = (props) => {
  const locationRout = useLocation().pathname;
  const dispatch = useDispatch();

  useNoteNavigation();
  const { userIsFetching } = useApp();
  const registerBg = useSelector((state: any) => state.registerBgReducer);
  const { isLogin } = useSelector((store: any) => ({
    isLogin: store.authReducer.login.status === "success",
  }));

  const bgExist = localStorage.getItem("REGISTERBG") ? localStorage.getItem("REGISTERBG") : 1;
  useEffect(() => {
    if (bgExist) {
      dispatch(setRegisterBgAction(bgExist));
    }
  }, []);

  const itemsFooterHelp = [
    {
      title: "About",
      location: "/about",
    },
    // {
    //   title: "Send feedback",
    // },
    {
      title: "Advertise with Us",
      location: "",
    },
    isLogin ? { title: "Send feedback", location: "/leave-review" } : { title: "" },
  ];

  const itemsFooterLoan = [
    {
      title: "FAQ",
      location: "/faq",
    },
    {
      title: "Terms of Service",
      location: "/terms-of-services",
    },
    {
      title: "Privacy Notice",
      location: "/privacy-policy",
    },
  ];

  if (userIsFetching) {
    return (
      <div className="init-app">
        <Spin />
      </div>
    );
  }

  return (
    <>
      {locationRout.toLocaleLowerCase() == "/admin" ? (
        props.children
      ) : (
        <AntdLayout
          style={{
            backgroundImage: `url(${require("src/assets/images/login-background.png")})`,
            backgroundRepeat: "repeat",
            backgroundPosition: "center",
            backgroundSize: "auto",
            backgroundColor: "#000",
            minHeight: "100vh",
          }}>
          <LandingHeader />
          <AntdLayout.Content className="background-white d-flex flex-column">
            <Row justify={"center"}>
              <Col span={23} xs={23} sm={23} md={21} lg={21} xl={21}>
                {props.children}
                {locationRout.toLocaleLowerCase() !== "/register" &&
                locationRout.toLocaleLowerCase() !== "/about" ? (
                  <footer className="landing-footer">
                    <div className="landing-footer__section">
                      <img
                        src={require("src/assets/images/logo.png")}
                        alt=""
                        style={{ marginBottom: 15 }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 15,
                        }}>
                        <Typography.Text style={{ color: "#A4B3F4", marginRight: 10 }}>
                          Contact:{" "}
                        </Typography.Text>
                        <Typography.Text>
                          <a style={{ color: "white" }} href="/support-ticket">
                            support@loanthem.com
                          </a>
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text style={{ color: "#A4B3F4", marginRight: 10 }}>
                          Follow us:
                        </Typography.Text>
                        <a href="https://facebook.com">
                          <img
                            src={require("src/assets/images/footer-facebook-icon.png")}
                            style={{ color: "white", marginRight: 10 }}
                            alt=""
                          />
                        </a>
                        <a href="https://twitter.com">
                          <img
                            src={require("src/assets/images/footer-twitter-icon.png")}
                            style={{ width: 34, color: "white", marginRight: 10 }}
                            alt=""
                          />
                        </a>
                        <a href="https://instagram.com">
                          <img src={require("src/assets/images/footer-insta-icon.png")} alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="landing-footer__section__wrapper">
                      <div className="landing-footer__section__col">
                        <Typography.Text
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 20,
                            width: 123,
                          }}>
                          Loanthem
                        </Typography.Text>
                        <List
                          style={{ paddingTop: 10 }}
                          itemLayout="horizontal"
                          dataSource={itemsFooterHelp}
                          renderItem={(item) => (
                            <List.Item className="" style={{ borderBottom: "none" }}>
                              <List.Item.Meta
                                title={
                                  <a
                                    href={item.location}
                                    style={{ color: "white" }}
                                    className="landing-footer-list-loanthem">
                                    {item.title}
                                  </a>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </div>
                      <div className="landing-footer__section__col">
                        <Typography.Text
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 20,
                            width: 123,
                          }}>
                          Help
                        </Typography.Text>
                        <List
                          style={{ paddingTop: 10 }}
                          itemLayout="horizontal"
                          dataSource={itemsFooterLoan}
                          renderItem={(item) => (
                            <List.Item className="" style={{ borderBottom: "none" }}>
                              <List.Item.Meta
                                title={
                                  <a
                                    href={item.location}
                                    className="landing-footer-list-loanthem"
                                    style={{ color: "white" }}>
                                    {item.title}
                                  </a>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </div>
                    </div>
                  </footer>
                ) : null}
              </Col>
            </Row>
          </AntdLayout.Content>
        </AntdLayout>
      )}

      <LoginRegisterModal />
    </>
  );
};

export default Layout;
