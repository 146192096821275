import React, { FC, useState } from "react";
import { Row, Form, Button, Input, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import InfoBox from "src/common/components/InfoBox";
import Previous from "src/common/components/Previous";

import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import { useNavigate } from "react-router-dom";

const LateFee: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const addCommas = (value: any) => {
    return value
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const removeCommas = (str: string) => {
    return str.replace(/,/g, "");
  };
  const [disable, setDisable] = useState<any>(
    note.has_late_fee == true || note.has_late_fee == false ? !note.has_late_fee : true,
  );
  const [hasLateFee, setHasLateFee] = useState<boolean>(false);
  const [amount, setAmount] = useState<any>(
    note.late_fee_amount ? addCommas(note.late_fee_amount) : null,
  );
  const [percent, setPercent] = useState<any>(note.late_fee_percent ? note.late_fee_percent : null);
  const [days, setDays] = useState<any>("");
  const [form] = Form.useForm();

  console.log(amount, note);
  const handleHasLateFee = (has: any, disable: any) => {
    setHasLateFee(has);
    setDisable(disable);
    if (disable) {
      setDays(null);
      setAmount(null);
      setPercent(null);
    }
  };

  const handelSign = (val: string, sign: string, callback: any) => {
    callback(val.replace(sign, ""));
    callback(addCommas(val));
  };
  const handleRemove = (e: any, remove = false, type = "") => {
    if (
      (e.key.length === 1 && /\D/.test(e.key)) ||
      (type == "percent" && amount) ||
      (type == "amount" && percent)
    ) {
      e.preventDefault();
    }
    if (e.key == "Backspace" && remove) setPercent(percent.slice(0, -1));
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialVal = {
    has_late_fee:
      note.has_late_fee == true || note.has_late_fee == false ? note.has_late_fee : false,
    late_fee_days: note.late_fee_days ? note.late_fee_days : null,
    late_fee_percent: note.late_fee_percent || note.late_fee_amount ? "53" : null,
    late_fee_amount: note.late_fee_amount || note.late_fee_percent ? "4646" : null,
  };

  return (
    <Row className="note" justify={"center"}>
      <Previous location="/lump-sum-payment" />
      <div className="note-default"></div>
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Late Fee
      </Typography.Title>
      <Form
        form={form}
        className="note-form-box"
        initialValues={initialVal}
        onFinish={(values) => {
          const paymentWithoutComma =
            values.late_fee_amount && amount ? +removeCommas(amount) : null;
          const fields: any = {
            has_late_fee: values.has_late_fee,
            late_fee_days: values.late_fee_days ? +values.late_fee_days : null,
            late_fee_percent: percent ? +percent : null,
            late_fee_amount: paymentWithoutComma,
          };
          dispatch(promissorySetDataAction(fields));

          navigate("/failure-to-pay");
        }}>
        <Typography.Title
          className="note-title"
          style={{
            color: "white",
            fontSize: 14,
            fontWeight: 400,
            paddingBottom: 8,
            textAlign: "left",
            marginLeft: "2px",
            marginBottom: "0",
          }}>
          Is there a late fee?
        </Typography.Title>
        <Form.Item
          name="has_late_fee"
          rules={[{ required: true, message: "Please select one of the items" }]}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              justifyContent: "stretch",
            }}>
            <Button
              className={`note-btn-yes ${disable == true ? "note-btn-enable" : ""}`}
              style={{ margin: "0", width: "100%" }}
              onClick={() => {
                handleHasLateFee(true, false);
                form.setFieldsValue({ has_late_fee: true });
              }}>
              Yes
            </Button>
            <Button
              className={`note-btn-no ${disable == false ? "note-btn-enable" : ""}`}
              style={{ margin: "0", width: "100%" }}
              onClick={() => {
                handleHasLateFee(false, true);
                form.setFieldsValue({ has_late_fee: false });
                form.setFieldsValue({ late_fee_days: "" });
              }}>
              No
            </Button>
          </div>
        </Form.Item>
        <Form.Item
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column" }}
          label="After how many days late?"
          required
          rules={[
            {
              required: disable == false ? true : false,
              message: "Please enter a number for the late days.",
            },
          ]}
          name="late_fee_days">
          <Input
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            style={{
              height: "37px",
              borderRadius: "5px",
            }}
            value={days}
            placeholder="0"
            className="placeholder-center"
            disabled={disable == true || disable == null ? true : false}
            onKeyDown={(e) => handleRemove(e, true)}
            onChange={(e) => setDays(e.target.value)}
            suffix={
              <div>
                <button
                  style={{ height: "24px" }}
                  onClick={(e) => e.preventDefault()}
                  className="note-btn-group">
                  Day(s) Late
                </button>
              </div>
            }
          />
        </Form.Item>
        <Form.Item
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column" }}
          label="How much is the late fee?"
          rules={[
            {
              required: disable == false ? true : false,
              message: "Please enter the late fee amount in either US Dollars or percentage.",
            },
          ]}
          required
          name="late_fee_amount">
          <div className="lateFee-two-inputs">
            <Input
              className="row-input"
              placeholder="$ Enter the fee"
              style={{ borderRadius: 5 }}
              size={"large"}
              value={amount ? "$" + amount : ""}
              onKeyDown={(e) => handleRemove(e, true, "amount")}
              onChange={(e) => handelSign(e.target.value, "$", setAmount)}
              disabled={disable == true || disable == null ? true : false || percent ? true : false}
            />
            <Input
              className="row-input"
              placeholder="Enter the fee % "
              style={{ borderRadius: 5 }}
              size={"large"}
              maxLength={4}
              max={100}
              value={percent ? percent + "%" : ""}
              onKeyDown={(e) => handleRemove(e, true, "percent")}
              onChange={(e) => handelSign(e.target.value, "%", setPercent)}
              disabled={disable == true || disable == null ? true : false || amount ? true : false}
            />
          </div>
        </Form.Item>
        <div className="note-form-line"></div>
        <Button
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
      <InfoBox text="If user fills in $ amount, user cannot fill in % amount and vice-versa. " />
    </Row>
  );
};

export default LateFee;
