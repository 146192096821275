import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IFileResult, ProfilePhotoUpdate } from "../../models";
import { UseUser } from "../../hooks";
import { convertMimeType } from "src/utils/ImageUtil";
import { url } from "inspector";

const useSupportTicket = () => {
  const navigate = useNavigate();

  const { create } = UseUser.useSupportTicket();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<"ACCOUNT_AND_BILLING_SUPPORT" | "TECHNICAL_SUPPORT" | "">("");
  const [typeError, setTypeError] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [attachments, setAttachments] = useState<ProfilePhotoUpdate[]>([]);
  const [agree, setAgree] = useState(true);

  const onDocumentUpload = (
    s3Data?: AWS.S3.ManagedUpload.SendData,
    fileResult?: IFileResult,
    url?: string,
  ) => {
    setImageUrl(url);

    s3Data &&
      fileResult &&
      setAttachments([
        ...attachments,
        ...[
          {
            file_name: s3Data?.Key,
            original_file_name: fileResult.name,
            document_type: "SUPPORT_TICKET",
            file_path: s3Data.Location,
            file_size: fileResult?.size,
            file_type: convertMimeType(fileResult.type),
            description: "",
          },
        ],
      ]);
  };

  const sendSupport = async () => {
    let error = false;
    setSubjectError("");
    setTypeError("");
    setDescriptionError("");

    if (!subject) {
      setSubjectError("Please enter Subject");
      error = true;
    }
    if (type === "" || !type) {
      setTypeError("Please select one of items");
      error = true;
    }
    if (!description) {
      setDescriptionError("Please enter description");
      error = true;
    }

    if (error || type === "") {
      return;
    }

    setLoading(true);
    try {
      await create({
        description,
        subject,
        ticket_type: type,
        attachments: attachments,
      });
      navigate(-1);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const deleteDoc = (url: string) => {
    setAttachments(attachments.filter((item) => item.file_path !== url));
  };

  return {
    sendSupport,
    onClickBack,
    onDocumentUpload,
    loading,
    setLoading,
    subject,
    setSubject,
    description,
    setDescription,
    descriptionError,
    typeError,
    subjectError,
    imageUrl,
    type,
    setType,
    setAgree,
    agree,
    attachments,
    deleteDoc,
  };
};

export default useSupportTicket;
