export const handleShowLoginRegisterModal = (modalType: "LOGIN" | "REGISTER" | "VERIFY") => ({
  type: "show_login_register_modal",
  payload: {
    loginRegisterModalShow: true,
    modalType,
  },
});

export const handleHideLoginRegisterModal = () => ({
  type: "hide_login_register_modal",
  payload: {
    loginRegisterModalShow: false,
  },
});
