export const emailValidation = (val: string): boolean => {
  const regexp = new RegExp(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/);
  return regexp.test(val);
};

export const mobileValidation = (val: string): boolean => {
  const regexp = new RegExp(/^[+0][1-9]{1}[0-9]{3,14}$/);
  return regexp.test(val);
};

export const onlyEnglish = (val: string): boolean => {
  const regexp = new RegExp(/^[a-zA-Z1-9.?!:;\- ]+$/g);
  return regexp.test(val);
};
