import React from "react";

import { ReactComponent as IconChevron } from "../../../assets/icons/ic-chevron-left-white.svg";

export interface TopNavControllerProps {
  onClickCancel?: () => void;
  onClickPrev: () => void;
  showCancel?: boolean;
}

const TopNavController = ({
  onClickCancel,
  onClickPrev,
  showCancel = true,
}: TopNavControllerProps) => {
  return (
    <div className="top-nav-controller">
      <div className="top-nav-controller__previous" onClick={onClickPrev}>
        <IconChevron />
        <span>Previous</span>
      </div>
      {showCancel && (
        <div className="top-nav-controller__cancel" onClick={() => onClickCancel?.()}>
          Cancel
        </div>
      )}
    </div>
  );
};

export default TopNavController;
