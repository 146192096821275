import React from "react";

export interface HistoryDetailItemProps {
  key: number;
  paidDate: string;
  lender: string;
  location: string;
  paymentService: string;
  transactionNumber: string;
  amount: string;
  paidTime: string;
}

const HistoryItem = ({
  key,
  lender,
  location,
  paidDate,
  paymentService,
  amount,
  transactionNumber,
  paidTime,
}: HistoryDetailItemProps) => {
  return (
    <div className="payment-history-detail" key={key}>
      <div className="payment-history-detail__row">
        <div className="payment-history-detail__row__label">Lender</div>
        <div className="payment-history-detail__row__value">{lender}</div>
      </div>

      <div className="payment-history-detail__row">
        <div className="payment-history-detail__row__label">Date & Time</div>
        <div className="payment-history-detail__row__value">{`${paidDate} ${paidTime}`}</div>
      </div>

      <div className="payment-history-detail__row">
        <div className="payment-history-detail__row__label">Location</div>
        <div className="payment-history-detail__row__value">{location}</div>
      </div>

      <div className="payment-history-detail__row">
        <div className="payment-history-detail__row__label">Payment Service</div>
        <div className="payment-history-detail__row__value">{paymentService}</div>
      </div>

      <div className="payment-history-detail__row">
        <div className="payment-history-detail__row__label">Transaction #</div>
        <div className="payment-history-detail__row__value">{transactionNumber}</div>
      </div>

      <div className="payment-history-detail__row">
        <div className="payment-history-detail__row__label">Payment</div>
        <div className="payment-history-detail__row__value">{amount}</div>
      </div>
    </div>
  );
};

export default HistoryItem;
