import Countdown from "react-countdown";

import { ITimer } from "./interface";
import useTimer from "./useTimer";

const Timer = ({ onSendAgain, className }: ITimer) => {
  const { active, date, renderTimer, setActive } = useTimer(onSendAgain);

  return (
    <div className={`timer ${className ? className : ""}`}>
      <Countdown
        className={className}
        key={String(active)}
        date={date}
        renderer={renderTimer}
        onComplete={() => setActive(false)}
      />
    </div>
  );
};

export default Timer;
