import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UserPagesNavbar = () => {
  const location = useLocation();
  const navigation = useNavigate();

  const isSelected = (path: string) => {
    return location.pathname.includes(path);
  };

  return (
    <nav className="user-pages-navbar">
      <div className="user-pages-navbar__main-wrapper">
        <div
          onClick={() => navigation("/home")}
          className={`user-pages-navbar__main-wrapper__btn ${
            isSelected("home") ? "selected" : ""
          }`}>
          Home
        </div>
        {/*<div*/}
        {/*  onClick={() => navigation("payment-history")}*/}
        {/*  className={`user-pages-navbar__main-wrapper__btn ${*/}
        {/*    isSelected("/payment-history") ? "selected" : ""*/}
        {/*  }`}>*/}
        {/*  Payment History*/}
        {/*</div>*/}
        {/* <div
          onClick={() => navigation("notifications")}
          className={`user-pages-navbar__main-wrapper__btn ${
            isSelected("/notifications") ? "selected" : ""
          }`}>
          Notifications
        </div> */}
        <div
          onClick={() => navigation("/profile")}
          className={`user-pages-navbar__main-wrapper__btn ${
            isSelected("/profile") ? "selected" : ""
          }`}>
          Account Details
        </div>
      </div>
    </nav>
  );
};

export default UserPagesNavbar;
