import React, { FC } from "react";
import { Route, useLocation, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { Login } from "src/pages/Login";
import { RegisterPage } from "./Register";
import { ResetPassword } from "./ResetPassword";
import { ForgetPassword } from "./ForgetPassword";
import { LandingPage } from "./Landing";
import { Amount } from "./Amount";
import { Interest } from "./Interest";
import { AgreementPayment } from "./AgreementAndPayment";
import { Installments } from "./Installments";
import { LumpSumPayment } from "./LumpSumPayment";
import ErrorRouter from "./ErrorRouter/ErrorRouter";
import SupportTicket from "./SupportTicket/SupportTicket";
import { LateFee } from "./LateFee";
import { FailureTopay } from "./FailureToPay";
import { DemandPayment } from "./DemandPayment";
import { Collateral } from "./Collateral";
import { CollateralTransfer } from "./CollateralTransfer";
import { Jurisdiction } from "./Jurisdiction";
import { Enforcement } from "./Enforcement";
import { WitnessSigning } from "./WitnessSigning";
import { ExtraClause } from "./ExtraClause";
import { WitnessInfo } from "./WitnessInfo";
import { Verify } from "./Verify";
import { NoteName } from "./NoteName";
import { ThirdPartyPage } from "./ThirdPartyPage";
import { Lender } from "./Lender";
import { Borrower } from "./Borrower";
import { DraftPage } from "./Draft";
import { SelectPlan } from "./SelectPlan";
import { About } from "./About";
import { CoSigner } from "./CoSigner";
import CoSignerInfo from "./CoSignerInfo/CoSignerInfo";
import { LenderBorrowerBussiness } from "./LenderBorrowerBussiness";
import { IndividualInfo } from "./IndividualInfo";
import { Admin } from "./Admin";
import { Home } from "./Home";
import Dashboard from "./Dashboard/Dashboard";
import TaxCreate from "./TaxCreate/TaxCreate";
import DemandLetter from "./DemandLetter/DemandLetter";
import LoanRelease from "./LoanRelease/LoanRelease";
import LeaveReview from "./LeaveReview/LeaveReview";
import AmortizationSchedule from "./AmortizationSchedule/AmortizationSchedule";
import PaymentInstallment from "./PaymentInstallment/PaymentInstallment";
import PaymentReminder from "./PaymentReminder/PaymentReminder";
import PaymentHistory from "./PaymentHistory/PaymentHistory";
import UpdateSubscription from "./UpdateSubscription/UpdateSubscription";
import TermsOfServices from "./TermsOfServices/TermsOfServices";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import FAQ from "./FAQ/FAQ";
import FAQQuestions from "./FAQ-question/FAQ-question";
import Profile from "./Profile/Profile";
import NotePreview from "./NotePreview/NotePreview";

const LoginUserPages: FC = () => {
  return (
    <Routes>
      <Route path={"/"} element={<LandingPage />} />
      <Route path={"/about"} element={<About />} />
      <Route path={"/login"} element={<Login />} />
      <Route path={"/register"} element={<RegisterPage />} />
      <Route path={"/reset-password"} element={<ResetPassword />} />
      <Route path={"/forget-password"} element={<ForgetPassword />} />
      <Route path={"/amount"} element={<Amount />} />
      <Route path={"/interest"} element={<Interest />} />
      <Route path={"/agreement-payment"} element={<AgreementPayment />} />
      <Route path={"/installments"} element={<Installments />} />
      <Route path={"/lump-sum-payment"} element={<LumpSumPayment />} />
      <Route path={"/support-ticket"} element={<SupportTicket />} />
      <Route path={"/late-fee"} element={<LateFee />} />
      <Route path={"/failure-to-pay"} element={<FailureTopay />} />
      <Route path={"/demand-payment"} element={<DemandPayment />} />
      <Route path={"/collateral"} element={<Collateral />} />
      <Route path={"/borrower"} element={<Borrower />} />
      <Route path={"/co-signer-info"} element={<CoSignerInfo />} />
      <Route path={"/draft"} element={<DraftPage />} />
      <Route path={"/select-plan"} element={<SelectPlan />} />
      <Route path={"/co-signer"} element={<CoSigner />} />
      <Route path={"/collateral-transfer"} element={<CollateralTransfer />} />
      <Route path={"/verify"} element={<Verify />} />
      <Route path={"/jurisdiction"} element={<Jurisdiction />} />
      <Route path={"/enforcement"} element={<Enforcement />} />
      <Route path={"/extra-clause"} element={<ExtraClause />} />
      <Route path={"/witnessInfo"} element={<WitnessInfo />} />
      <Route path={"/witness-signing"} element={<WitnessSigning />} />
      <Route path={"/witness-info"} element={<WitnessInfo />} />
      <Route path={"/note-name"} element={<NoteName />} />
      <Route path={"/third-party"} element={<ThirdPartyPage />} />
      <Route path={"/lender"} element={<Lender />} />
      <Route path={"/bussiness-info/:type"} element={<LenderBorrowerBussiness />} />
      <Route path={"/individual-info/:type"} element={<IndividualInfo />} />
      <Route path={"/home"} element={<Home />} />
      <Route path={"/dashboard/:type/:id"} element={<Dashboard />} />
      <Route path={"/tax-create"} element={<TaxCreate />} />
      <Route path={"/dashboard/:type/:id/demand-letter/scheduled"} element={<DemandLetter />} />
      <Route path={"/dashboard/:type/:id/demand-letter/full-payment"} element={<DemandLetter />} />
      <Route path={"/dashboard/:type/:id/loan-release"} element={<LoanRelease />} />
      <Route path={"/dashboard/:id/preview"} element={<NotePreview />} />
      <Route
        path={"/dashboard/:type/:id/amortization-schedule"}
        element={<AmortizationSchedule />}
      />
      <Route path={"/dashboard/:type/:id/installment-payments"} element={<PaymentInstallment />} />
      <Route path={"/dashboard/:type/:id/payment-reminder"} element={<PaymentReminder />} />
      <Route path={"/dashboard/:type/:id/payment-history"} element={<PaymentHistory />} />
      <Route path={"/leave-review"} element={<LeaveReview />} />
      <Route path={"/terms-of-services"} element={<TermsOfServices />} />
      <Route path={"/update-subscription"} element={<UpdateSubscription />} />
      <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
      <Route path={"/profile"} element={<Profile />} />
      <Route path={"/faq"} element={<FAQ />} />
      <Route path={"/faq-questions"} element={<FAQQuestions />} />
      <Route path="*" element={<ErrorRouter />} />
    </Routes>
  );
};

export default LoginUserPages;
