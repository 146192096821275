import React from "react";
import { Modal } from "antd";

export interface DemandLetterModalProps {
  open: boolean;
  onCancel: () => void;
  onClickItem: (val: "SCHEDULED" | "FULL") => void;
}

const DemandLetterModal = ({ onCancel, onClickItem, open }: DemandLetterModalProps) => {
  return (
    <Modal open={open} onCancel={onCancel} footer={null}>
      <div className="demand-letter-modal">
        <div className="demand-letter-modal__header">Demand Letters</div>
        <div className="demand-letter-modal__buttons">
          <div
            className="demand-letter-modal__buttons__button"
            onClick={() => onClickItem("SCHEDULED")}>
            <div className="demand-letter-modal__buttons__button__header">Demand Letter</div>
            <div className="demand-letter-modal__buttons__button__description">
              Pay As Scheduled
            </div>
          </div>
          <div className="demand-letter-modal__buttons__button" onClick={() => onClickItem("FULL")}>
            <div className="demand-letter-modal__buttons__button__header">Demand Letter</div>
            <div className="demand-letter-modal__buttons__button__description">
              Immediate Full Payment
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DemandLetterModal;
