import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Layout, Row, Typography, Button } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import { RootState } from "src/store";
import { HeaderIcons } from "src/common/components";

import "./Landing-header.css";
import { setRegisterBgAction } from "src/store/actions/registerbgActions/registerBgAction";

const LandingHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = useSelector((store: RootState) => ({
    login: store.authReducer.login,
  }));
  const handleNavigateRegister = (bg: any) => {
    dispatch(setRegisterBgAction(bg.toString()));
  };
  const { isLogin } = useSelector((store: RootState) => ({
    isLogin: store.authReducer.login.status === "success",
  }));
  const { state } = useLocation();
  const handleLogoClick = () => {
    if (
      login.data?.roles &&
      (login.data?.roles.includes("lender") || login.data?.roles.includes("borrower"))
    ) {
      isLogin ? navigate("home") : navigate("/");
    } else if (
      login.data?.plan &&
      (login.data?.plan === "MULTIPLE_PACKAGE" || login.data?.plan === "PREMIUM_PACKAGE")
    ) {
      isLogin ? navigate("home") : navigate("/");
    } else {
      !isLogin ? navigate("/") : false;
    }
  };

  return (
    <Layout.Header className="header-layout" style={{ backgroundColor: "#00092F" }}>
      <Row justify={"space-between"} className="landing-header--sections">
        <div className="logo-header-loanthem" onClick={handleLogoClick}>
          <img id="nav-loga" src={require("src/assets/images/logo.png")} />
        </div>

        {login && login.status === "success" ? (
          <HeaderIcons />
        ) : (
          <div className="landing-header--sections-btns">
            <Link to={"/register"} className="landing-header--sections-btns-register">
              <Button
                type="primary"
                onClick={() => handleNavigateRegister(3)}
                className="header-btn">
                Register
              </Button>
            </Link>
            <Link to={"/login"}>
              <Typography.Text className="support-textL">Login</Typography.Text>
            </Link>
          </div>
        )}
      </Row>
    </Layout.Header>
  );
};

export default LandingHeader;
