import React, { useState } from "react";
import TextArea from "antd/lib/input/TextArea";

import { GeneralForm, TopNavController } from "../../common/components";
import RateSection from "./RateSection";
import useLeaveReview from "./useLeaveReview";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LeaveReview = () => {
  const { setModel, leaveReview, loading, model } = useLeaveReview();
  const { isLogin } = useSelector((store: any) => ({
    isLogin: store.authReducer.login.status === "success",
  }));
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const descValidation =
    !check || (model.description && model.description.length > 1000) || !model.description;
  const rateValidation =
    !check ||
    (model.description && model.description.length > 1000) ||
    (!model!.ease_of_use &&
      !model!.expense &&
      !model!.overall_value &&
      !model!.scope_of_functionality &&
      !model!.speed &&
      !model!.style);
  const notValid = descValidation && rateValidation;

  return (
    <div className="leave-review-page">
      <TopNavController
        showCancel={false}
        onClickPrev={() => {
          isLogin ? navigate("/home") : navigate(-1);
        }}
      />
      <GeneralForm
        headerTitle="Rate Your Experience"
        buttonText="Submit"
        onClick={leaveReview}
        setCheck={setCheck}
        disabled={notValid}
        loading={loading}>
        <div className="leave-review-page__main-wrapper">
          <div className="leave-review-page__main-wrapper__rate-section">
            <span>Rating</span>
            <div className="leave-review-page__main-wrapper__rate-section__divider" />
            <RateSection
              onChange={(val) =>
                setModel({
                  ...model,
                  ease_of_use: val,
                })
              }
              title="Ease of Use"
            />
            <RateSection
              onChange={(val) =>
                setModel({
                  ...model,
                  scope_of_functionality: val,
                })
              }
              title="Functionality"
            />
            <RateSection
              onChange={(val) =>
                setModel({
                  ...model,
                  speed: val,
                })
              }
              title="Speed"
            />
            <RateSection
              onChange={(val) =>
                setModel({
                  ...model,
                  expense: val,
                })
              }
              title="Expense"
            />
            <RateSection
              onChange={(val) =>
                setModel({
                  ...model,
                  style: val,
                })
              }
              title="Style/Design"
            />
            <RateSection
              onChange={(val) =>
                setModel({
                  ...model,
                  overall_value: val,
                })
              }
              title="Overall Value"
            />
          </div>
          <div className="leave-review-page__main-wrapper__comment-section">
            <div className="leave-review-page__main-wrapper__comment-section__header">
              Description of Your Experience
            </div>
            <TextArea
              value={model.description ?? ""}
              placeholder="Describe features that worked well and those that did not."
              rows={5}
              onChange={(e) => {
                setModel({
                  ...model,
                  description: e.target.value,
                });
              }}
            />
            <div className="leave-review-page__main-wrapper__comment-section__footer">
              Max. 1000 characters
            </div>
          </div>
        </div>
      </GeneralForm>
    </div>
  );
};

export default LeaveReview;
