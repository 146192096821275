import React, { useState } from "react";

import PaymentInfo from "./PaymentInfo";
import { formatNumber } from "src/utils/NumberUtil";
import { useDispatch, useSelector } from "react-redux";
import SignModal from "./SignModal";
import { promissorySetDataAction, RootState } from "../../store";

export interface PaymentInstallmentBorrowerProps {
  paidAmount: number;
  paidAmountCount: number;
  dueAmount: number;
  dueAmountCount: number;
  remainingDueAmount: number;
  remainingDueAmountCount: number;
}

const PaymentInstallmentBorrower = ({
  dueAmount,
  dueAmountCount,
  paidAmount,
  paidAmountCount,
  remainingDueAmount,
  remainingDueAmountCount,
}: PaymentInstallmentBorrowerProps) => {
  const note = useSelector((store: RootState) => store.promissoryReducer);
  const [modalOpen, setModalOpen] = useState(note.sign_party == "DIGITALLY" ? true : false);
  console.log(note.sign_party);

  return (
    <>
      <div className="payment-installment__borrower">
        <PaymentInfo
          color="GREEN"
          count={paidAmountCount}
          title="Paid Amount"
          value={formatNumber(paidAmount)}
        />
        <PaymentInfo
          color="RED"
          count={dueAmountCount}
          title="Due Amount"
          value={formatNumber(dueAmount)}
        />
        <PaymentInfo
          color="GRAY"
          count={remainingDueAmountCount}
          title="Remaining Amount Due"
          value={formatNumber(remainingDueAmount)}
        />
      </div>
      <SignModal
        text={"Please sign your Docusign to initiate installment processing"}
        onCancel={() => setModalOpen(false)}
        open={modalOpen}
      />
    </>
  );
};

export default PaymentInstallmentBorrower;
