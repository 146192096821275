import { useEffect } from "react";
import moment from "moment";

import useDashboard from "./useDashboard";
import {
  Header,
  LinkBox,
  LoanInfo,
  ProfileLoanCarousel,
  UserPagesNavbar,
} from "../../common/components";
import DemandLetterModal from "./DemandLetterModal";
import PremiumModal from "./PremiumModal";
import { usePromissoryNote } from "src/hooks";

import { ReactComponent as IconMakeNote } from "../../assets/icons/ic-make-note-purple.svg";
import { ReactComponent as IconAmortization } from "../../assets/icons/ic-amortization-purple.svg";
import { ReactComponent as IconPaymentHistory } from "../../assets/icons/ic-payment-history-purple.svg";
import { ReactComponent as IconBell } from "../../assets/icons/ic-bell-purple.svg";
import { ReactComponent as IconPayment } from "../../assets/icons/ic-payment-purple.svg";
import { ReactComponent as IconDemand } from "../../assets/icons/ic-demand-letters-purple.svg";
import { ReactComponent as IconLoanRelease } from "../../assets/icons/ic-loan-release-purple.svg";
import { ReactComponent as IconTax } from "../../assets/icons/ic-tax-purple.svg";
import { ReactComponent as IconUpload } from "../../assets/icons/ic-upload-purple.svg";
import { ReactComponent as IconSettings } from "../../assets/icons/ic-settings-purple.svg";
import { ReactComponent as IconFaq } from "../../assets/icons/ic-faq-purple.svg";
import { ReactComponent as IconPrivacyPolicy } from "../../assets/icons/ic-privacy-policy-purple.svg";
import { ReactComponent as IconEnvelop } from "../../assets/icons/ic-envelop-purple.svg";
import { ReactComponent as IconInvite } from "../../assets/icons/ic-invite-purple.svg";

const Dashboard = () => {
  const {
    displayList,
    type,
    setSelected,
    selectedItemDetail,
    isPremium,
    onClickDemandLetter,
    onClickDemandLetterItem,
    demandLetterOpen,
    selectedItem,
    premiumOpen,
    onClickPremium,
    onClickUpdateNote,
    onClickViewNote,
  } = useDashboard();
  const { onClickNewNote } = usePromissoryNote();

  const titleHandler = () => {
    if (type == "loan") {
      if (displayList.length > 1) {
        return "Lenders";
      }
      return "Lender";
    }
    if (type == "borrow") {
      if (displayList && displayList.length > 1) {
        return "Borrowers";
      }
      return "Borrower";
    }
  };
  useEffect(() => {
    if (selectedItem != undefined && selectedItem.id !== undefined) {
      window.history.replaceState(null, "Loanthem", `/dashboard/${type}/${selectedItem?.id}`);
    }
  }, [selectedItem]);
  return (
    <>
      <div className="dashboard-page">
        <UserPagesNavbar />
        <div className="dashboard-page__wrapper">
          <Header title={`Dashboard / ${titleHandler()}`} />
          <ProfileLoanCarousel profiles={displayList} onClickItem={setSelected} />
          <div className="dashboard-page__wrapper__content">
            <div className="dashboard-page__wrapper__content__info">
              {selectedItemDetail && (
                <LoanInfo
                  onClickView={onClickViewNote}
                  onClickUpdate={onClickUpdateNote}
                  note={selectedItemDetail}
                  firstPaymentDate={
                    selectedItemDetail.first_payment_due
                      ? moment(selectedItemDetail.first_payment_due).format("MM.DD.YYYY")
                      : ""
                  }
                  lastPaymentDate={
                    selectedItemDetail.last_payment_due
                      ? moment(selectedItemDetail.last_payment_due).format("MM.DD.YYYY")
                      : ""
                  }
                  loanAmount={selectedItemDetail.amount ?? 0}
                  paidBackAmount={selectedItemDetail.note_paid_amount ?? 0}
                  rate={selectedItemDetail.interest ?? 0}
                  startDate={
                    selectedItemDetail.agreement_begin
                      ? moment(selectedItemDetail.agreement_begin).format("MM.DD.YYYY")
                      : ""
                  }
                />
              )}
            </div>
            <div className="dashboard-page__wrapper__content__links">
              <LinkBox
                icon={<IconMakeNote />}
                onClick={onClickNewNote}
                title="Make a New Note"
                onClickPremium={onClickPremium}
              />

              {/* <LinkBox
                icon={<IconAmortization />}
                link={`/dashboard/${type}/${selectedItem?.id}/amortization-schedule`}
                title="Amortization Schedule"
                onClickPremium={onClickPremium}
              /> */}

              {/* <LinkBox
                icon={<IconSettings />}
                link=""
                title="Settings"
                onClickPremium={onClickPremium}
              /> */}

              <LinkBox icon={<IconFaq />} link="/faq" title="FAQ" onClickPremium={onClickPremium} />

              <LinkBox
                onClickPremium={onClickPremium}
                icon={<IconPrivacyPolicy />}
                link="/terms-of-services"
                title="Terms of Service"
              />
              <LinkBox
                icon={<IconPaymentHistory />}
                link={`/dashboard/${type}/${selectedItem?.id}/payment-history`}
                title="Payment History"
                forPremium={!isPremium}
                onClickPremium={onClickPremium}
              />

              <LinkBox
                icon={<IconEnvelop />}
                onClickPremium={onClickPremium}
                link="/leave-review"
                title="Leave a Review"
              />
              <LinkBox
                icon={<IconTax />}
                link="/tax-create"
                title="Create Tax Prep"
                forPremium={!isPremium}
                onClickPremium={onClickPremium}
              />

              <LinkBox
                icon={<IconUpload />}
                link="/update-subscription"
                title="Upgrade/Renew Subscription"
                onClickPremium={onClickPremium}
              />
              {/* <LinkBox
                onClickPremium={onClickPremium}
                icon={<IconInvite />}
                link=""
                title="Invite a Friend"
              /> */}

              {type === "borrow" && (
                <LinkBox
                  icon={<IconLoanRelease />}
                  link={`/dashboard/${type}/${selectedItem?.id}/loan-release`}
                  title="Loan Release Letter"
                  forPremium={!isPremium}
                  onClickPremium={onClickPremium}
                />
              )}

              {type === "borrow" && (
                <LinkBox
                  onClickPremium={onClickPremium}
                  icon={<IconDemand />}
                  title="Demand Letters"
                  onClick={onClickDemandLetter}
                  forPremium={!isPremium}
                />
              )}
              <LinkBox
                icon={<IconPayment />}
                link={`/dashboard/${type}/${selectedItem?.id}/installment-payments`}
                title="Payment Processing"
                forPremium={!isPremium}
                onClickPremium={onClickPremium}
              />

              <LinkBox
                icon={<IconAmortization />}
                link={`/dashboard/${type}/${selectedItem?.id}/amortization-schedule`}
                title="Amortization Schedule"
                onClickPremium={onClickPremium}
              />

              {type === "loan" && (
                <LinkBox
                  icon={<IconBell />}
                  link={`/dashboard/${type}/${selectedItem?.id}/payment-reminder`}
                  title="Payment Reminders"
                  forPremium={!isPremium}
                  onClickPremium={onClickPremium}
                />
              )}
              <LinkBox
                onClickPremium={onClickPremium}
                icon={<IconPrivacyPolicy />}
                link="/privacy-policy"
                title="Privacy Policy"
              />
            </div>
          </div>
        </div>
      </div>
      <DemandLetterModal
        onCancel={onClickDemandLetter}
        onClickItem={onClickDemandLetterItem}
        open={demandLetterOpen}
      />
      <PremiumModal onCancel={onClickPremium} open={premiumOpen} />
    </>
  );
};

export default Dashboard;
