import React, { useState } from "react";
import { Collapse, Spin } from "antd";

import { GeneralDraft, TopNavController } from "src/common/components";
import usePaymentHistory from "./usePaymentHistory";
import HistoryHeaderItem from "./HistoryHeaderItem";
import HistoryDetailItem from "./HistoryDetailItem";

const { Panel } = Collapse;

const PaymentHistory = () => {
  const [activeKey, setActiveKey] = useState(["0"]);
  const { activeTab, dataPast, dataUpcoming, loaded, onClickBack, setactiveTab } =
    usePaymentHistory();
  const currentList = activeTab === "PAST" ? dataPast : dataUpcoming;

  const renderItem = (currentList: any) => {
    if (currentList.length > 0) {
      {
        return currentList.map((item: any, index: number) => (
          <Panel
            header={
              <HistoryHeaderItem
                abbreviation={item.abbreviation}
                color={item.color}
                date={item.payDate}
                key={index}
                profilePic={item.profileImage}
                paymentStatus={item.paymentStatus}
              />
            }
            key={String(index)}>
            <HistoryDetailItem key={index} {...item} />
          </Panel>
        ));
      }
    }
    return (
      <>
        <div className="payment_empty">
          <h5>There is no payment</h5>
        </div>
      </>
    );
  };
  const renderer = () => {
    return (
      <div className="payment-history-page__body">
        <div className="payment-history-page__body__buttons">
          <div
            onClick={() => setactiveTab("UPCOMING")}
            className={`payment-history-page__body__buttons__button ${
              activeTab === "UPCOMING" ? "active" : ""
            }`}>
            Upcoming
          </div>
          <div
            onClick={() => setactiveTab("PAST")}
            className={`payment-history-page__body__buttons__button ${
              activeTab === "PAST" ? "active" : ""
            }`}>
            Past
          </div>
        </div>
        <div className="payment-history-page__body__main">
          <Collapse
            defaultActiveKey={["0"]}
            activeKey={activeKey}
            onChange={(key) => setActiveKey(Array.isArray(key) ? key : [key])}
            expandIconPosition="right">
            {renderItem(currentList)}
          </Collapse>
        </div>
      </div>
    );
  };

  return (
    <div className="payment-history-page">
      <TopNavController
        onClickCancel={onClickBack}
        showCancel={false}
        onClickPrev={() => {
          onClickBack();
        }}
      />
      <GeneralDraft headerTitle="Payments">{loaded ? renderer() : <Spin />}</GeneralDraft>
    </div>
  );
};

export default PaymentHistory;
