import React, { FC, useState } from "react";
import { Row, Form, Button, Input, Typography, Select } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import InfoBox from "src/common/components/InfoBox";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "../../store";
import Previous from "../../common/components/Previous";

const Installments: FC = () => {
  const { Option } = Select;

  const note = useSelector((store: RootState) => store.promissoryReducer);

  const initialvalues = {
    installment: note.payment_period != undefined ? note.payment_period : null,
  };
  const navigate = useNavigate();
  type FormType = { amount: string };
  const [installment, setinstallment] = useState<any>(
    note.payment_period != undefined ? note.payment_period : null,
  );
  const dispatch = useDispatch();

  return (
    <Row className="note" justify={"center"}>
      <Previous location={"/agreement-payment"}></Previous>
      <Typography.Title className="interest-text" style={{ color: "white", fontSize: 25 }}>
        Installments
      </Typography.Title>
      <Form
        className="note-form-box"
        style={{ opacity: 1.5, height: 405 }}
        initialValues={initialvalues}
        onFinish={(values) => {
          const fields: any = {
            payment_period: values.installment,
          };
          dispatch(promissorySetDataAction(fields));
          navigate("/lump-sum-payment");
        }}>
        <Form.Item
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column", paddingBottom: 170, paddingTop: 20 }}
          label="How often will payments be made?"
          rules={[{ required: true, message: "Please Select" }]}
          required
          name="installment">
          <Select
            placeholder="Select"
            size={"large"}
            value={note.payment_period}
            className="note-formItem-input">
            <Option value="ONCE">Once</Option>
            <Option value="WEEKLY">Weekly</Option>
            <Option value="MONTHLY">Monthly</Option>
            <Option value="BI_ANNUALLY">Bi-annually</Option>
            <Option value="YEARLY">Yearly</Option>
          </Select>
        </Form.Item>
        <div className="note-form-line"></div>
        <Button
          style={{ marginTop: "40px" }}
          className="note-form-btn"
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
    </Row>
  );
};

export default Installments;
