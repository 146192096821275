import React, { useState } from "react";
import { Button } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

import PaymentInfo from "./PaymentInfo";
import { formatNumber } from "src/utils/NumberUtil";
import { AmortizationScheduleResponseDTO } from "src/api";

export interface PaymentInstallmentLenderProps {
  paidAmount: number;
  paidAmountCount: number;
  dueAmount: number;
  dueAmountCount: number;
  remainingDueAmount: number;
  remainingDueAmountCount: number;
  listData: AmortizationScheduleResponseDTO[];
  sending: boolean;
  onClickConfirm: (count: number) => void;
}

const PaymentInstallmentLender = ({
  dueAmount,
  dueAmountCount,
  paidAmount,
  paidAmountCount,
  remainingDueAmount,
  remainingDueAmountCount,
  listData,
  sending,
  onClickConfirm,
}: PaymentInstallmentLenderProps) => {
  const [remainingAmountState, setRemainingAmountState] = useState(remainingDueAmount);
  const [remainingAmountCountState, setRemainingAmountCountState] =
    useState(remainingDueAmountCount);
  const [dueAmountState, setDueAmountState] = useState(dueAmount);
  const [dueAmountCountState, setDueAmountCountState] = useState(dueAmountCount);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentAmountCount, setPaymentAmountCount] = useState(0);
  const onClickAdd = () => {
    const sortedList = listData.sort((a: any, b: any) => {
      const dateA = a.formatted_date ? new Date(a.formatted_date) : null;
      const dateB = b.formatted_date ? new Date(b.formatted_date) : null;

      if (dateA && dateB) {
        return dateA.getTime() - dateB.getTime();
      } else if (dateA) {
        return 1; // dateB is null, so a comes after b
      } else if (dateB) {
        return -1; // dateA is null, so a comes before b
      } else {
        return 0; // both dates are null, no preference
      }
    });
    console.log(sortedList);

    const notPaid = sortedList.filter((item) => item.status !== "PAID");
    // const notPaid = listData.filter(
    //   (item) => item.status == "CREATED" || item.status == "FAILED" || item.status == "IN_PROGRESS",
    // );
    const newCount = paymentAmountCount + 1;
    console.log(notPaid);
    // const sortedList = listData.sort((a, b) => {
    //   const dateA = new Date(a.formatted_date);
    //   const dateB = new Date(b.formatted_date);

    //   return dateA - dateB;
    // });
    if (newCount <= notPaid.length) {
      setPaymentAmountCount(newCount);
      setPaymentAmount(paymentAmount + notPaid[newCount - 1].monthly_Amount!);

      if (dueAmountCountState > 0) {
        const newDueCount = dueAmountCountState - 1;
        if (newDueCount >= 0) {
          setDueAmountCountState(newDueCount);
          setDueAmountState(newDueCount * notPaid[0].monthly_Amount!);
          // setRemainingAmountState(0);
        }
      } else {
        const newRemainedCount = remainingAmountCountState - 1;
        if (newRemainedCount >= 0) {
          setRemainingAmountCountState(newRemainedCount);
          // if(paidAmount==0){}
          setRemainingAmountState((prevRemainingAmount) => {
            const newRemainingAmount = prevRemainingAmount - notPaid[newCount - 1].monthly_Amount!;
            return newRemainingAmount <= 0 ? 0 : newRemainingAmount;
          });
        }
      }
    }
  };

  const onClickMinus = () => {
    const notPaid = listData.filter((item) => item.status !== "PAID");
    const newCount = paymentAmountCount - 1;

    if (newCount >= 0) {
      setPaymentAmountCount(newCount);
      setPaymentAmount((prevPaymentAmount) => {
        const newPaymentAmount = prevPaymentAmount - notPaid[newCount].monthly_Amount!;
        return newPaymentAmount <= 0 ? 0 : newPaymentAmount;
      });
      const newRemainedCount = remainingAmountCountState + 1;
      if (newRemainedCount <= remainingDueAmountCount) {
        setRemainingAmountCountState(newRemainedCount);
        setRemainingAmountState(remainingAmountState + notPaid[newCount].monthly_Amount!);
      } else {
        const newDueCount = dueAmountCountState + 1;
        if (newDueCount <= dueAmountCount) {
          setDueAmountCountState(newDueCount);
          setDueAmountState(newDueCount * notPaid[0].monthly_Amount!);
        }
      }
    }
  };

  return (
    <div className="payment-installment-page__lender">
      <div className="payment-installment-page__lender__header">Number of installments to pay</div>
      <div className="payment-installment-page__lender__wrapper">
        <div className="payment-installment-page__lender__wrapper__count">{paymentAmountCount}</div>
        <div className="payment-installment-page__lender__wrapper__buttons">
          <PlusCircleOutlined rev="" onClick={onClickAdd} />
          <MinusCircleOutlined rev="" onClick={onClickMinus} />
        </div>
      </div>
      <div className="payment-installment-page__lender__info">
        <PaymentInfo
          color="GREEN"
          count={paidAmountCount}
          title="Paid Amount"
          value={formatNumber(paidAmount)}
        />
        <PaymentInfo
          color="RED"
          count={dueAmountCountState}
          title="Due Amount"
          value={formatNumber(dueAmountState)}
        />
        <PaymentInfo
          color="GRAY"
          count={remainingAmountCountState}
          title="Remaining Amount Due"
          value={formatNumber(remainingAmountState)}
        />
        <PaymentInfo
          color="BLACK"
          count={paymentAmountCount}
          title="Payment Amount"
          value={formatNumber(paymentAmount)}
        />
      </div>

      <Button
        onClick={() => onClickConfirm(paymentAmountCount)}
        className="styled-button"
        type="primary"
        disabled={paymentAmountCount <= 0}
        loading={sending}>
        Confirm
      </Button>
    </div>
  );
};

export default PaymentInstallmentLender;
