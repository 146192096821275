import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { promissoryNoteRestApi } from "../../services/http";
import { ProfileLoanProps } from "src/common/components/ProfileLoan/ProfileLoan";
import { FullPromissoryNoteDTOResponse, MyPromissoryNoteResponseDto } from "src/api";
import { getRandomColor } from "../../utils/ColorUtil";
import { RootState, promissorySetDataAction, promissorySetEditModeAction } from "src/store";
import { UsePromissoryNoteApi } from "src/hooks";

const useDashboard = () => {
  const { user } = useSelector((state: RootState) => state.authReducer);

  const { type, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPremium = user?.activePlan === "PREMIUM_PACKAGE";

  const [demandLetterOpen, setSetDemandLetterOpen] = useState(false);
  const [premiumOpen, setSetPremiumOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [displayList, setDisplayList] = useState<ProfileLoanProps[]>([]);
  const [data, setData] = useState<MyPromissoryNoteResponseDto[]>([]);
  const [selectedItem, setSelectedItem] = useState<MyPromissoryNoteResponseDto>();
  const [selectedItemDetail, setSelectedItemDetail] = useState<
    FullPromissoryNoteDTOResponse | undefined
  >();

  const { getAllBorrows, getAllLenders } = UsePromissoryNoteApi.useNotes();

  const onClickDemandLetter = () => {
    setSetDemandLetterOpen(!demandLetterOpen);
  };

  const onClickPremium = (callback?: any) => {
    setSetPremiumOpen(!premiumOpen);
    if (callback) {
      callback();
    }
  };

  const onClickDemandLetterItem = (selectedType: "SCHEDULED" | "FULL") => {
    setSetDemandLetterOpen(false);
    if (selectedType === "SCHEDULED") {
      navigate(`/dashboard/${type}/${selectedItem?.id}/demand-letter/scheduled`);
    } else {
      navigate(`/dashboard/${type}/${selectedItem?.id}/demand-letter/full-payment`);
    }
  };

  const getProfileListItem = (
    item: MyPromissoryNoteResponseDto,
    selected: MyPromissoryNoteResponseDto,
  ): ProfileLoanProps => {
    const primaryObject = type === "loan" ? item.primary_lender : item.primary_borrower;
    const title = primaryObject?.business_name
      ? primaryObject?.business_name
      : primaryObject?.full_name;
    let abbreviation = String(title?.charAt(0).toUpperCase());
    if (String(title).split(" ").length > 0) {
      abbreviation += String(title).split(" ")[1]?.charAt(0).toUpperCase() ?? "";
    }
    return {
      title: title ?? "NO NAME",
      img: primaryObject?.profile_photo?.file_path,
      id: String(item.id),
      selected: item.id === selected?.id,
      abbreviation,
      color: getRandomColor(String(title)),
    };
  };

  const getDetailInfo = async () => {
    if (!selectedItem) {
      return;
    }

    setIsLoadingDetail(true);
    setSelectedItemDetail(undefined);
    const response = await promissoryNoteRestApi.getPromissoryNote(Number(selectedItem?.id || id));
    try {
      if (response.data) {
        setSelectedItemDetail(response.data);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoadingDetail(false);
  };

  const setSelected = (id: string, setSelected = true) => {
    const newData = displayList.map((item) => {
      if (item.id === id) {
        item.selected = true;
      } else {
        item.selected = false;
      }

      return item;
    });

    const item = data.find((item) => item.id === Number(id));
    setSelected && setSelectedItem(item);
    setDisplayList(newData);
  };

  const getData = async () => {
    try {
      let list: MyPromissoryNoteResponseDto[] = [];
      if (type === "loan") {
        const response = await getAllLenders();
        list = response.lenders;
      } else {
        const response = await getAllBorrows();
        list = response.borrows;
      }

      setData(list ?? []);

      const selected = list.find(
        (item: MyPromissoryNoteResponseDto) => Number(item.id) === Number(id),
      );
      setSelectedItem(selected);

      let selectedDisplayItem: ProfileLoanProps | undefined;
      const selectedDisplayList: ProfileLoanProps[] = [];

      list.forEach((item: MyPromissoryNoteResponseDto) => {
        const displayItem = getProfileListItem(item, selected!);
        if (displayItem.selected) {
          selectedDisplayItem = displayItem;
        } else {
          selectedDisplayList.push(displayItem);
        }
      });
      setDisplayList([...[selectedDisplayItem!], ...selectedDisplayList]);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const onClickUpdateNote = () => {
    if (!selectedItemDetail) {
      return;
    }

    dispatch(promissorySetEditModeAction(true));
    dispatch(promissorySetDataAction(selectedItemDetail));
    navigate("/amount");
  };

  const onClickViewNote = () => {
    if (!selectedItemDetail) {
      return;
    }

    navigate(`/dashboard/${selectedItemDetail.id}/preview`);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getDetailInfo();
  }, [selectedItem]);

  return {
    displayList,
    isLoading,
    setSelected,
    selectedItem,
    isLoadingDetail,
    selectedItemDetail,
    type,
    isPremium,
    onClickDemandLetter,
    onClickDemandLetterItem,
    demandLetterOpen,
    onClickPremium,
    premiumOpen,
    onClickUpdateNote,
    onClickViewNote,
  };
};

export default useDashboard;
