import React from "react";
import { Spin } from "antd";

import { GeneralForm, TopNavController } from "src/common/components";
import useUpdateSubscription from "./useUpdateSubscription";
import CancelModal from "./CancelModal";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";

const UpdateSubscription = () => {
  const {
    onClickNext,
    step,
    onClickCancel,
    setSelectedPlan,
    onClickBack,
    currentPlan,
    planLoaded,
    onClickRenew,
    buyLoading,
    renewLoading,
    cancelLoading,
    onClickBuy,
    onClickCancelSubscription,
    currentPlanEndDate,
    showCancelModal,
    openModal,
    closeModal,
  } = useUpdateSubscription();

  const renderer = () => {
    switch (step) {
      case 1:
        return <Step1 onClick={setSelectedPlan} />;
      case 2:
        return (
          <Step2
            currentPlanEndDate={currentPlanEndDate}
            onClickCancel={openModal}
            onClickBuy={onClickBuy}
            onClickRenew={onClickRenew}
            currentPlan={currentPlan}
            buyLoading={buyLoading}
            renewLoading={renewLoading}
          />
        );
      case 3:
        return (
          <Step3
            currentPlanEndDate={currentPlanEndDate}
            onClickCancel={openModal}
            onClickBuy={onClickBuy}
            onClickRenew={onClickRenew}
            currentPlan={currentPlan}
            buyLoading={buyLoading}
            renewLoading={renewLoading}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="update-subscription-page">
        <TopNavController
          onClickCancel={onClickCancel}
          showCancel={true}
          onClickPrev={() => {
            onClickBack();
          }}
        />
        <GeneralForm
          showNextSection={false}
          width="424px"
          buttonText="Next"
          headerTitle={"My Subscription"}
          onClick={onClickNext}>
          {planLoaded ? renderer() : <Spin className="spin" />}
        </GeneralForm>
        <CancelModal
          cancelLoading={cancelLoading}
          onCancel={closeModal}
          onClickConfirm={onClickCancelSubscription}
          open={showCancelModal}
        />
      </div>
    </>
  );
};

export default UpdateSubscription;
