import { FC, useState } from "react";
import { Row, Form, Button, Input, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import Previous from "src/common/components/Previous";
const Collateral: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);

  const [disable, setDisable] = useState<any>(
    note.collateral == true || note.collateral == false ? !note.collateral : null,
  );
  const dispatch = useDispatch();

  const [hasCollateral, setHasCollateral] = useState(false);
  const [collateral, setCollateralDeatail] = useState("");
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { TextArea } = Input;
  const handleHasCollateral = (has: any, disable: any) => {
    setHasCollateral(has);
    setDisable(disable);
    if (disable) {
      setCollateralDeatail("");
    }
  };
  const handleSubmit = (values: any) => {
    const fields: any = {
      collateral: values.collateral,
      collateral_description: values.collateral ? values.collateral_description : "",
    };
    dispatch(promissorySetDataAction(fields));
    navigate("/collateral-transfer");
  };
  const initialVal = {
    collateral: note.collateral == true || note.collateral == false ? note.collateral : null,
    collateral_description: note.collateral_description ? note.collateral_description : "",
  };
  return (
    <Row className="note" justify={"center"}>
      <Previous location="/demand-payment" />
      <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
        Collateral
      </Typography.Title>
      <Form
        initialValues={initialVal}
        form={form}
        className="note-form-box"
        onFinish={(val) => handleSubmit(val)}>
        <Typography.Title
          className="note-title"
          style={{ color: "white", fontSize: 14, fontWeight: 400, paddingBottom: 20 }}>
          Will the Borrower provide collateral for the loan?
        </Typography.Title>
        <Form.Item
          name="collateral"
          rules={[{ required: true, message: "Please select one of the items" }]}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Button
              className={`note-btn-yes ${disable == true ? "note-btn-enable" : ""}`}
              onClick={() => {
                handleHasCollateral(true, false);
                form.setFieldsValue({ collateral: true });
              }}>
              Yes
            </Button>
            <Button
              className={`note-btn-no ${disable == false ? "note-btn-enable" : ""}`}
              onClick={() => {
                handleHasCollateral(false, true);
                form.setFieldsValue({ collateral_description: "", collateral: false });
              }}>
              No
            </Button>
          </div>
        </Form.Item>
        <Form.Item
          className="note-formItem-input label-wrap"
          style={{ display: "flex", flexDirection: "column" }}
          label="Describe the collateral: including the color, size, model #, serial number, manufacturing date/model/year, components, and any other identifying features."
          rules={[
            {
              required: disable == false ? true : false,
              message: "Please provide a description for the collateral.",
            },
          ]}
          required
          name="collateral_description">
          <TextArea
            style={{ borderRadius: 5, resize: "none" }}
            rows={6}
            placeholder="Describe the collateral"
            maxLength={1000}
            disabled={disable == true || disable == null ? true : false}
          />
        </Form.Item>
        <span className="note-max">Max. 1000 characters</span>
        <div style={{ marginTop: "68px" }} className="note-form-line"></div>
        <Button
          className="note-form-btn"
          style={{ marginTop: "90px" }}
          type="primary"
          htmlType={"submit"}
          icon={<RightOutlined rev="" />}>
          Next
        </Button>
      </Form>
    </Row>
  );
};

export default Collateral;
