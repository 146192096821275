import React, { ReactNode } from "react";

export interface HeaderIconProps {
  title: string;
  icon: ReactNode;
  onClick?: () => void;
}

const ProfileLoan = ({ title, icon, onClick }: HeaderIconProps) => {
  return (
    <div className="header-icon" onClick={() => onClick?.()}>
      <span>{title}</span>
      {icon}
    </div>
  );
};

export default ProfileLoan;
