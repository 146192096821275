import React, { ReactNode } from "react";

export interface GeneralDraftProps {
  headerTitle: string;
  footerText?: string;
  children: ReactNode;
  wrapperTitle?: string;
  content?: {
    __html: string;
  };
}

const GeneralDraft = ({
  headerTitle,
  children,
  content,
  footerText,
  wrapperTitle,
}: GeneralDraftProps) => {
  return (
    <div className="general-draft">
      <div className="general-draft__header">{headerTitle}</div>
      <div className="general-draft__wrapper">
        {wrapperTitle && <span className="general-draft__wrapper__title">{wrapperTitle}</span>}
        {content && (
          <div className="general-draft__wrapper__content" dangerouslySetInnerHTML={content} />
        )}
        <div className="general-draft__wrapper__main">
          <div className="general-draft__wrapper__main__content">{children}</div>
        </div>
        {footerText && <div className="general-draft__wrapper__footer">{footerText}</div>}
      </div>
    </div>
  );
};

export default GeneralDraft;
