import React, { FC } from "react";
import { Row, Typography } from "antd";

const ErrorRouter: FC = () => {
  return (
    <Row justify={"center"} style={{ marginTop: 110, overflow: "hidden" }}>
      <Typography.Title style={{ textAlign: "center", color: "white" }}>
        404 <br />
        The requested page could not be found!
      </Typography.Title>
    </Row>
  );
};

export default ErrorRouter;
