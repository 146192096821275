import React, { useState } from "react";
import { Input } from "antd";

export interface Step2Props {
  onChange: (amount: number) => void;
  defaultValue?: string;
  error?: any;
}

const Step2 = ({ onChange, defaultValue, error }: Step2Props) => {
  const [val, setVal] = useState(defaultValue ?? "");
  return (
    <div className="tax-create-step-2">
      <Input
        prefix={<>$</>}
        value={val}
        placeholder="Enter Amount of Interest"
        onChange={(e) => {
          onChange(Number(e.target.value));
          setVal(e.target.value);
        }}
      />
      {error && 2 == error?.step ? (
        <div style={{ marginTop: 9 }}>
          <span className="tax-create-error">{error?.errorTxt}</span>
        </div>
      ) : null}
      {/* <span className={`tax-create-step-2__hint ${Number(val) % 10 !== 0 ? "red" : ""}`}>
        Please round numbers to 0
      </span> */}
    </div>
  );
};

export default Step2;
