import React, { ReactNode } from "react";
import { Radio } from "antd";

export interface AlertMessageProps {
  icon: ReactNode;
  message: string;
  backgroundColor: string;
}

const AlertMessage = ({ backgroundColor, icon, message }: AlertMessageProps) => {
  return (
    <div className="alert-message" style={{ backgroundColor: backgroundColor }}>
      <div className="alert-message__icon">{icon}</div>
      <span className="alert-message__message">{message}</span>
    </div>
  );
};

export default AlertMessage;
