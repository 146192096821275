import React, { FC, useEffect, useState } from "react";
import { Row, Form, Button, Input, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promissorySetDataAction, RootState } from "src/store";
import InfoBox from "src/common/components/InfoBox";
import Previous from "../../common/components/Previous";

const Amount: FC = () => {
  const note = useSelector((store: RootState) => store.promissoryReducer);

  const [amountField, setAmount] = useState<any>(note.amount ? note.amount : "");

  const loggedIn = document.cookie.split(";").some((item) => item.trim().startsWith("loggedIn="));

  const initialvalues = { amount: note.amount ? note.amount : null };
  const { login } = useSelector((store: RootState) => ({
    login: store.authReducer.login,
  }));
  const { isLogin } = useSelector((store: RootState) => ({
    isLogin: store.authReducer.login.status === "success",
  }));
  const navigate = useNavigate();

  const addCommas = (value: string) => {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const removeCommas = (str: string) => {
    return str.replace(/,/g, "");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = addCommas(e.target.value);
    setAmount(newValue);
  };

  const handleRemove = (e: any, remove = false, type = "") => {
    if ((e.key.length === 1 && /\D/.test(e.key)) || (type == "percent" && note)) {
      e.preventDefault();
    }
    if (e.key == "Backspace" && remove) setAmount(amountField.slice(0, -1));
  };

  useEffect(() => {
    if (note.amount) {
      setAmount(note.amount.toLocaleString());
    }
  }, [note.amount]);

  const dispatch = useDispatch();
  const { state } = useLocation();

  const handlePrev = () => {
    if (
      login.data?.roles &&
      (login.data?.roles.includes("lender") || login.data?.roles.includes("borrower"))
    ) {
      return <Previous location={login.status === "success" ? "/home" : "/"} />;
    } else if (
      login.data?.plan &&
      (login.data?.plan === "MULTIPLE_PACKAGE" || login.data?.plan === "PREMIUM_PACKAGE")
    ) {
      return <Previous location={login.status === "success" ? "/home" : "/"} />;
    } else {
      if (isLogin) {
        return <></>;
      }
      return <Previous location="/" />;
    }
  };
  return (
    <Row
      style={{
        flexDirection: "column",
      }}
      className="note"
      justify={"center"}>
      {handlePrev()}
      <Typography.Title className="amount-text" style={{ color: "white", fontSize: 25 }}>
        Amount
      </Typography.Title>
      <Form
        className="note-form-box"
        initialValues={initialvalues}
        onFinish={(values) => {
          const fields: any = {
            amount: values && amountField ? +removeCommas(amountField) : "",
          };
          dispatch(promissorySetDataAction(fields));
          navigate("/interest");
        }}>
        <Form.Item
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
          label="How much is the loan?"
          rules={[
            { required: true, message: "Please enter loan amount!" },
            {
              pattern: /[0]$/,
              message: "Amount value most be a round number (multiple of 10)",
              validateTrigger: "onSubmit",
            },
          ]}
          required
          name="amount">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <Input
              maxLength={20}
              className="note-formItem-input"
              placeholder="Enter Loan Amount($)"
              style={{ borderRadius: 5 }}
              size={"large"}
              value={amountField ? "$" + amountField : ""}
              onKeyDown={(e) => handleRemove(e, true, "amount")}
              onChange={handleInputChange}
            />
          </div>
        </Form.Item>
        <Typography.Text style={{ color: "#A4B3F4" }}>Please round numbers to 0</Typography.Text>
        <div className="note-form-line"></div>
        <div className="amount-formItem-btn">
          <Button
            style={{ marginTop: "40px" }}
            className="note-form-btn"
            type="primary"
            htmlType={"submit"}
            icon={<RightOutlined rev="" />}>
            Next
          </Button>
        </div>
      </Form>
      <InfoBox text="All promissory notes must be in $USD.  Your promissory note may only be enforceable in the USA.  " />
    </Row>
  );
};

export default Amount;
