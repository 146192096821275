import React, { useState } from "react";
import { Button, Spin } from "antd";

import { AlertMessage, GeneralDraft, GeneralForm, TopNavController } from "src/common/components";
import useTaxCreate from "./useTaxCreate";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";

import { ReactComponent as IconAlert } from "../../assets/icons/ic-alert.svg";
import { ReactComponent as IconDownload } from "../../assets/icons/ic-download.svg";
import { ReactComponent as IconShare } from "../../assets/icons/ic-share.svg";

const TxCreate = () => {
  const {
    content,
    title,
    onClickNext,
    onClickPrev,
    step,
    getYears,
    model,
    setModel,
    onClickCancel,
    previewUrl,
    downloadPdf,
    wrapperTitle,
    error,
    setError,
  } = useTaxCreate();

  const renderer = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            years={getYears()}
            selectedYear={String(model.tax_year)}
            error={error}
            onClick={(year) => {
              setModel({
                ...model,
                tax_year: Number(year),
              });
              setError({ isError: false, errorTxt: "", step: 1 });
            }}
          />
        );
      case 2:
        return (
          <Step2
            defaultValue={String(model.interest ?? "")}
            error={error}
            onChange={(amount) => {
              setModel({
                ...model,
                interest: amount,
              });
              setError({ isError: false, errorTxt: "", step: 2 });
            }}
          />
        );
      case 3:
        return (
          <Step3
            disabled={model.less_ten}
            error={error}
            step={step}
            onClick={(val) => {
              setModel({
                ...model,
                less_ten: val,
              });
              setError({ isError: false, errorTxt: "", step: 3 });
            }}
          />
        );
      case 4:
        return (
          <Step3
            disabled={model.home_equity_loan}
            error={error}
            step={step}
            onClick={(val) => {
              setModel({
                ...model,
                home_equity_loan: val,
              });
              setError({ isError: false, errorTxt: "", step: 4 });
            }}
          />
        );
      case 5:
        return (
          <Step3
            disabled={model.self_financed}
            error={error}
            step={step}
            onClick={(val) => {
              setModel({
                ...model,
                self_financed: val,
              });
              setError({ isError: false, errorTxt: "", step: 5 });
            }}
          />
        );
      case 6:
        return (
          <Step3
            disabled={model.real_property}
            step={step}
            error={error}
            onClick={(val) => {
              setModel({
                ...model,
                real_property: val,
              });
              setError({ isError: false, errorTxt: "", step: 6 });
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="tax-create-page">
      <TopNavController
        onClickCancel={onClickCancel}
        showCancel={step !== 7}
        onClickPrev={() => {
          onClickPrev();
        }}
      />
      {step < 7 && (
        <GeneralForm
          checkboxVisible={false}
          width="424px"
          buttonText="Next"
          headerContent={{
            __html: content,
          }}
          headerTitle={title}
          onClick={onClickNext}>
          {renderer()}
        </GeneralForm>
      )}
      {step === 7 && (
        <div className="tax-create-page__preview">
          <GeneralDraft
            footerText="Disclaimer: This document is neither a tax nor a legal document.  DO NOT submit this document to the IRS or any tax authority.  The LoanThem™ platform is 
          not a tax platform.  All information provided herein is for information purposes only. "
            wrapperTitle={wrapperTitle}
            headerTitle="">
            {previewUrl ? (
              <iframe
                src={`${previewUrl}#toolbar=0`}
                width="100%"
                height="600px"
                title="1098-MORT"
              />
            ) : (
              <div className="tax-create-page__preview__spin">
                <Spin />
              </div>
            )}
          </GeneralDraft>
          <div className="tax-create-page__preview__buttons">
            <Button
              className="styled-button text-blue border-blue background-white styled-button-hover-white"
              type="primary"
              onClick={downloadPdf}
              icon={<IconDownload />}>
              Download
            </Button>
            {/* <Button
              className="styled-button text-blue border-blue background-white"
              type="primary"
              icon={<IconShare />}>
              Share
            </Button> */}
          </div>
        </div>
      )}
      {step === 2 && (
        <AlertMessage
          backgroundColor="#D7E7FF"
          icon={<IconAlert />}
          message="If the interest rate is below the Applicable Federal Rate (AFR), the IRS may impute interest income at the Appplicable Federal Rate.  Accordingly, you may adjust and report the AFR interest rate.  If you have any questions, please consult your tax professional."
        />
      )}
    </div>
  );
};

export default TxCreate;
