import React, { FC, useState, useEffect } from "react";
import { Row, Form, Button, Input, Typography, Col, Select, Checkbox } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Previous from "../../common/components/Previous";
import { promissorySetDataAction } from "src/store";
import ErrorRouter from "./../ErrorRouter/ErrorRouter";
import { useLocationService } from "./../../hooks/locationService";
import { ICitiesOfState } from "src/models";
import { LocationService } from "src/services";
import {
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
const LenderBorrowerBussiness: FC = () => {
  const { states, getStateList } = useLocationService();
  const { Option } = Select;

  const { type } = useParams();
  const initObj = {
    business_name: "",
    city: "",
    credit_side_type: "CORPORATION",
    email: "",
    first_name: "",
    full_address: "",
    last_name: "",
    middle_name: "",
    official_title: "",
    state: "",
    zip_code: "",
    is_primary: true,
  };
  const note = useSelector((store: any) => store.promissoryReducer);
  const [sameAdress, setSameAdress] = useState(note.rememberAdress ? note.rememberAdress : false);
  const [citiesOfState, setCitiesOfState] = useState<ICitiesOfState[]>([]);

  const [form] = Form.useForm();
  const [initialValue, setInitialValue] = useState<any>(
    note[type + "s"] != undefined ? note[type + "s"] : [initObj],
  );
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: any) => {
    api.error({
      message: `Email issue`,
      description: "one of your emails is same with lenders email please change it",
      placement,
    });
  };
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const setCitiesList = async (state: string) => {
    const cities = await LocationService.GetCities(state);
    const result = {
      state,
      cities,
    };
    setCitiesOfState([...citiesOfState, ...[result]]);
  };

  const renderCitiesOption = (state: string) => {
    if (!state) {
      return <></>;
    }

    const stateOfCities = citiesOfState.find((item) => item.state === state);
    if (!stateOfCities) {
      setCitiesList(state);
      return <></>;
    }

    return stateOfCities.cities.map((city, index) => (
      <Option key={index} value={city.id}>
        {city.label}
      </Option>
    ));
  };

  useEffect(() => {
    getStateList();
  }, []);

  const handleAddContent = () => {
    form.validateFields().then((data) => {
      const valobj = {
        business_name: "",
        city: "",
        credit_side_type: "CORPORATION",
        email: "",
        first_name: "",
        full_address: "",
        last_name: "",
        middle_name: "",
        official_title: "",
        state: "",
        zip_code: "",
        is_primary: false,
      };
      const newVal = [...initialValue];
      newVal.push(valobj);
      setInitialValue(newVal);
    });
  };
  const changeVal = (val: any, index: any, name: any) => {
    const data: any = [...initialValue];
    data[index][name] = val;
    setInitialValue(data);
  };
  const handleSubmit = (val: any) => {
    const fields: any = [...initialValue];
    fields.map((e: any) => {
      e.credit_side_type = "CORPORATION";
    });
    if (type == "lender") {
      dispatch(promissorySetDataAction({ lenders: fields }));
      dispatch(promissorySetDataAction({ rememberAdress: sameAdress! }));

      // navigate("/borrower");
      navigate("/borrower", {
        state: { previousURL: true },
      });
    } else {
      let isEmailSame = false;

      fields.map((e: any) => {
        note.lenders.map((lenderelem: any) => {
          if (e.email === lenderelem.email) {
            openNotification("topRight");
            isEmailSame = true;
          }
        });
      });
      if (isEmailSame == false) {
        dispatch(promissorySetDataAction({ borrowers: fields }));
        navigate("/co-signer", { state: { previousURL: true } });
      }
      // dispatch(promissorySetDataAction({ borrowers: fields }));
    }
  };
  const generateInitial = () => {
    const obj: any = {};

    initialValue.map((e: any, i: any) => {
      obj[`business_name${i}`] = e.business_name;
      obj[`city${i}`] = e.city;
      obj[`credit_side_type${i}`] = e.credit_side_type;
      obj[`email${i}`] = e.email;
      obj[`first_name${i}`] = e.first_name;
      obj[`full_address${i}`] = e.full_address;
      obj[`last_name${i}`] = e.last_name;
      obj[`middle_name${i}`] = e.middle_name;
      obj[`official_title${i}`] = e.official_title;
      obj[`state${i}`] = e.state;
      obj[`zip_code${i}`] = e.zip_code;
    });
    return obj;
  };
  const removeItem = (e: any, i: any) => {
    e.preventDefault();
    const oldVal = [...initialValue];
    const index = oldVal.indexOf(i);
    oldVal.splice(index, 1);
    setInitialValue(oldVal);
  };

  if (type !== "lender" && type !== "borrower") {
    return <ErrorRouter />;
  }
  const handleNum = (e: any) => {
    if (e.key.length === 1 && /\D/.test(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <>
      {contextHolder}
      <Row className="note" justify={"center"}>
        <Previous location={type == "lender" ? "/lender" : "/borrower"}></Previous>
        <div className=" note-default"></div>
        <Typography.Title className="note-title" style={{ color: "white", fontSize: 25 }}>
          {type == "lender" ? "Lender" : "Borrower"} Info
        </Typography.Title>
        <Typography.Text onClick={handleAddContent} className="note-add-content">
          + Add {type == "lender" ? "Lender" : "Borrower"}
        </Typography.Text>
        <Form
          form={form}
          initialValues={generateInitial()}
          className="note-form-box credit-side-form-box"
          onFinish={(values) => {
            handleSubmit(values);
          }}>
          {initialValue.map((e: any, i: any) => (
            <React.Fragment key={i}>
              <Typography.Title
                className="credit-side-form-item-header"
                style={{ color: "white", fontSize: 25 }}>
                {type == "lender" ? "Lender" : "Borrower"} {i + 1}
                {i > 0 ? (
                  <Typography.Text
                    onClick={(e) => removeItem(e, i)}
                    className="note-remove-content">
                    - Remove {type == "lender" ? "Lender" : "Borrower"}
                  </Typography.Text>
                ) : null}
              </Typography.Title>
              <Row justify="space-around">
                <Col
                  span={11}
                  lg={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="credit-side-form-input-container">
                  <Form.Item
                    className="note-formItem-input"
                    style={{ display: "flex", flexDirection: "column" }}
                    label="First Name"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name!",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                    name={`first_name${i}`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}>
                      <Input
                        value={initialValue[i].first_name}
                        onChange={(e) => changeVal(e.target.value, i, "first_name")}
                        className="credit-side-form-input"
                        style={{
                          height: "37px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  lg={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="credit-side-form-input-container">
                  <Form.Item
                    className="note-formItem-input"
                    style={{ display: "flex", flexDirection: "column" }}
                    label="Middle Name"
                    name={`middle_name${i}`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}>
                      <Input
                        value={initialValue[i].middle_name}
                        onChange={(e) => changeVal(e.target.value, i, "middle_name")}
                        className="credit-side-form-input"
                        style={{
                          height: "37px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  lg={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="credit-side-form-input-container">
                  <Form.Item
                    className="note-formItem-input"
                    style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                    label="Last Name"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name!",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                    name={`last_name${i}`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}>
                      <Input
                        value={initialValue[i].last_name}
                        onChange={(e) => changeVal(e.target.value, i, "last_name")}
                        className="credit-side-form-input"
                        style={{
                          height: "37px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  lg={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="credit-side-form-input-container">
                  <Form.Item
                    className="note-formItem-input"
                    style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                    label="Business Name "
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter Business Name !",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                    name={`business_name${i}`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}>
                      <Input
                        value={initialValue[i].business_name}
                        onChange={(e) => changeVal(e.target.value, i, "business_name")}
                        className="credit-side-form-input"
                        style={{
                          height: "37px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  lg={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="credit-side-form-input-container">
                  <Form.Item
                    className="note-formItem-input"
                    style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                    label="Official Title "
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter Official Title !",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                    name={`official_title${i}`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}>
                      <Input
                        value={initialValue[i].official_title}
                        onChange={(e) => changeVal(e.target.value, i, "official_title")}
                        className="credit-side-form-input"
                        style={{
                          height: "37px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  lg={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="credit-side-form-input-container">
                  <Form.Item
                    className="note-formItem-input"
                    style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                    label="Address"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter Address!",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                    name={`full_address${i}`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}>
                      <Input
                        value={initialValue[i].full_address}
                        onChange={(e) => changeVal(e.target.value, i, "full_address")}
                        className="credit-side-form-input"
                        style={{
                          height: "37px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>{" "}
                <Col
                  span={11}
                  lg={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="credit-side-form-input-container">
                  <Form.Item
                    className="note-formItem-input"
                    style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                    label="State"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter State!",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                    name={`state${i}`}>
                    <Select
                      onChange={(e) => {
                        changeVal(e, i, "state");
                      }}
                      value={initialValue[i].state}
                      placeholder="Select"
                      size={"large"}>
                      {states.map((e: any, index) => {
                        return (
                          <Option key={index} value={e.name}>
                            {e.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>{" "}
                <Col
                  span={11}
                  lg={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="credit-side-form-input-container">
                  <Form.Item
                    className="note-formItem-input"
                    style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                    label="City"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter City!",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                    name={`city${i}`}>
                    <Select
                      onChange={(e) => changeVal(e, i, "city")}
                      value={initialValue[i].city}
                      placeholder="Select"
                      size={"large"}>
                      {renderCitiesOption(initialValue[i].state as string)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  lg={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="credit-side-form-input-container">
                  <Form.Item
                    className="note-formItem-input"
                    style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                    label="Zip Code"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter zipcode!",
                        validateTrigger: "onSubmit",
                      },
                      {
                        max: 5,
                        message: "zip code must have a maximum length of 5",
                        validateTrigger: "onSubmit",
                      },
                      {
                        min: 5,
                        message: "zip code must have a minimum length of 5",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                    name={`zip_code${i}`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}>
                      <Input
                        value={initialValue[i].zip_code}
                        onChange={(e) => changeVal(e.target.value, i, "zip_code")}
                        onKeyDown={(e) => handleNum(e)}
                        className="credit-side-form-input"
                        style={{
                          height: "37px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  lg={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="credit-side-form-input-container">
                  <Form.Item
                    className="note-formItem-input"
                    style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
                    label="Email"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter email!",
                        validateTrigger: "onSubmit",
                      },
                      {
                        type: "email",
                        message: "The input is not a valid E-mail!",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                    name={`email${i}`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}>
                      <Input
                        value={initialValue[i].email}
                        onChange={(e) => changeVal(e.target.value, i, "email")}
                        className="credit-side-form-input"
                        style={{
                          height: "37px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  borderBottom: "1px solid #A4B3F4",
                  margin: "25px 0 15px 0 ",
                }}></div>
            </React.Fragment>
          ))}
          {type == "lender" ? (
            <Checkbox
              checked={sameAdress}
              onChange={(val) => setSameAdress(val.target.checked)}
              style={{
                color: "white",
                height: 24,
                marginBottom: 6,
              }}>
              Use this same address as the payment address for the payment of this promissory note.
            </Checkbox>
          ) : null}
          <Button
            className="note-form-btn"
            type="primary"
            htmlType={"submit"}
            style={{ margin: "auto", width: "50%" }}
            icon={<RightOutlined rev="" />}>
            Next
          </Button>
        </Form>
      </Row>
    </>
  );
};

export default LenderBorrowerBussiness;
