import { FC } from "react";
import { Row, Form, Button, Input, Typography } from "antd";

import InfoBox from "src/common/components/InfoBox";
import { useVerificationForm } from "./../../hooks/verifyForm";
import ErrorBox from "../../common/components/ErrorBox";
import Previous from "../../common/components/Previous";
import { useLocation } from "react-router-dom";
import { Timer } from "src/common/components";

const Verify: FC = () => {
  const { handleSubmit, resendOtp, errMsg, prevUrl, isLoading, state } = useVerificationForm();
  const { state: stateVal } = useLocation();
  return (
    <Row className="note" justify={"center"}>
      <Previous stateValues={stateVal.registerValues} location={prevUrl} showCancel={false} />
      <Typography.Title className="amount-text" style={{ color: "white", fontSize: 25 }}>
        Verify
      </Typography.Title>
      <Form
        className="note-form-box"
        onFinish={(values) => {
          const params = {
            verification_code: values.verification_code,
            username: state.email,
          };
          handleSubmit(params);
        }}>
        <Typography.Text className="Verify-lable">
          Please enter the 6-digit verification code sent to your registered e-mail.{" "}
        </Typography.Text>
        <Form.Item
          className="note-formItem-input"
          style={{ display: "flex", flexDirection: "column", paddingBottom: 35, paddingTop: 20 }}
          rules={[
            { required: true, message: "Please enter your code!" },
            {
              len: 6,
              message: "verification code must be exactly 6 characters",
            },
          ]}
          required
          name="verification_code">
          <Input
            placeholder="Enter Code"
            size={"large"}
            className="amount-from-input"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
        <Timer onSendAgain={resendOtp} />
        <div className="amount-form-line"></div>
        <Button className="note-form-btn" type="primary" htmlType={"submit"} loading={isLoading}>
          Verify
        </Button>
        <div className="error-box">
          {errMsg ? <ErrorBox ContainerClass="error-container" text={errMsg} /> : ""}
        </div>
      </Form>
      <InfoBox
        text="If you do not see the email in your inbox,
please check your junk folder. "
      />
    </Row>
  );
};

export default Verify;
