/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { promissoryNoteRestApi } from "../../services/http";

const useLoanRelease = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();

  const [previewUrl, setPreviewUrl] = useState("");

  const getPreview = async () => {
    const response = await promissoryNoteRestApi.getLoanReleasePreview(Number(id!), {
      responseType: "blob",
    });
    //@ts-ignore
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    setPreviewUrl(url);
  };

  const onClickPrev = () => {
    navigate(`/dashboard/${type}/${id}`);
  };

  const downloadPdf = () => {
    if (previewUrl) {
      window.open(previewUrl, "_blank");
    }
  };

  useEffect(() => {
    getPreview();
  }, []);

  return {
    onClickPrev,
    previewUrl,
    downloadPdf,
  };
};

export default useLoanRelease;
