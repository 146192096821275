import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import ProfileLoan, { ProfileLoanProps } from "../ProfileLoan/ProfileLoan";

export interface ProfileLoanCarouselProps {
  profiles: ProfileLoanProps[];
  onClickItem: (id: string) => void;
  showDots?: boolean;
}

const ProfileLoanCarousel = ({
  profiles,
  onClickItem,
  showDots = false,
}: ProfileLoanCarouselProps) => {
  const [list, setList] = useState<ProfileLoanProps[]>([]);

  useEffect(() => {
    setList(profiles);
  }, [profiles]);

  const settings = {
    infinite: false,
    slidesToShow: Math.min(6, list.length),
    slidesToScroll: 1,
    swipeToSlide: true,
    dots: showDots,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(3, list.length),
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: Math.min(1, list.length),
        },
      },
    ],
  };

  return (
    <div className="profile-loan-carousel">
      <Slider {...settings}>
        {list.map((item, index) => (
          <ProfileLoan key={index} {...item} onClick={(id) => onClickItem(id)} />
        ))}
      </Slider>
    </div>
  );
};

export default ProfileLoanCarousel;
