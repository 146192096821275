import React, { useState } from "react";
import { Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { IReminderContact, ISelect } from "../../../models";
import Contact from "../Contact";

export interface Step2Props {
  hsaError: boolean;
  emails: ISelect[];
  contacts: IReminderContact[];
  onClickRemove: (index: number, contact: IReminderContact) => void;
  onClickAddContact: () => void;
  onChangeValue: (
    key: "EMAIL" | "PHONE",
    val: string,
    index: number,
    contact: IReminderContact,
  ) => void;
}

const Step2 = ({
  contacts,
  onChangeValue,
  onClickRemove,
  emails,
  onClickAddContact,
  hsaError,
}: Step2Props) => {
  return (
    <div className="payment-reminder-page__step-2">
      <div className="payment-reminder-page__step-2__header">
        <PlusOutlined rev={""} />
        <span onClick={onClickAddContact}>Add Contact</span>
      </div>
      {hsaError && (
        <div className="payment-reminder-page__step-2__error">please fill all contact info</div>
      )}
      <div className="payment-reminder-page__step-2__contacts">
        {contacts.map((item, index) => (
          <Contact
            emails={emails}
            key={index}
            index={index}
            contact={item}
            onChangeValue={onChangeValue}
            onClickRemove={onClickRemove}
          />
        ))}
      </div>
    </div>
  );
};

export default Step2;
