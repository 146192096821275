import React from "react";
import { Button, Spin } from "antd";

import { GeneralDraft, TopNavController } from "src/common/components";

import useDemandLetter from "./useDemandLetter";
import { ReactComponent as IconDownload } from "../../assets/icons/ic-download.svg";
import { ReactComponent as IconShare } from "../../assets/icons/ic-share.svg";

const DemandLetter = () => {
  const { content, downloadPdf, onClickPrev, previewUrl, title } = useDemandLetter();

  return (
    <div className="demand-letter-page">
      <TopNavController
        showCancel={false}
        onClickPrev={() => {
          onClickPrev();
        }}
      />
      <div className="demand-letter-page__preview">
        <GeneralDraft
          headerTitle={title}
          content={{
            __html: content,
          }}>
          {previewUrl ? (
            <iframe src={`${previewUrl}#toolbar=0`} width="100%" height="600px" title={title} />
          ) : (
            <div className="demand-letter-page__preview__spin">
              <Spin />
            </div>
          )}
        </GeneralDraft>
        <div className="demand-letter-page__preview__buttons">
          <Button
            className="styled-button text-blue border-blue background-white"
            type="primary"
            onClick={downloadPdf}
            icon={<IconDownload />}>
            Download
          </Button>
          <Button
            className="styled-button text-blue border-blue background-white styled-button-hover-white"
            type="primary"
            icon={<IconShare />}>
            Share
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DemandLetter;
